import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import WelcomeImage from "../../components/public/WelcomeImage.jsx";
import LoginFormComponent from "../../components/public/login/LoginForm.jsx";
import VerifyOtpComponent from "../../components/public/login/VerifyOtp";
import { axios } from "../../helpers/axios.js";
import { loginSendOTP, loginVerifyOTP } from "../../helpers/constants.js";
import { authenticate } from "../../helpers/cookies.js";
import withNavigate from "../../routes/withNavigate";

class LoginContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      otpSent: false,
      email: "",
    };
  }

  // componentDidMount() {}

  handleSubmitOtp = async (data) => {
    try {
      this.setState({
        loading: true,
      });
      await axios.post(loginSendOTP, data);
      this.setState({
        otpSent: true,
        loading: false,
        email: data.email,
      });
    } catch (error) {
      this.setState({
        otpSent: false,
        loading: false,
      });
    }
  };
  
  goTOregister = () => {

    this.props.navigate("/register/doctor");

  }



  handleVerifyOtp = async (data) => {
    try {
      this.setState({
        loading: true,
      });
      const login_response = await axios.post(loginVerifyOTP, data);
      if (login_response.success) {
        authenticate(login_response.data, () => window.location.reload());
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { otpSent, loading, email } = this.state;
    return (
      <Box>
        <Grid sx={{ height: "100vh" }} container spacing={0}>
          <Grid item xs={5}>
            <WelcomeImage />
          </Grid>
          <Grid item xs={7}>
            {otpSent ? (
              <VerifyOtpComponent
                email={email}
                loading={loading}
                verifyOtp={this.handleVerifyOtp}
              />
            ) : (
              <LoginFormComponent
              goTOregister={this.goTOregister}
                loading={loading}
                submitOtp={this.handleSubmitOtp}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withNavigate(LoginContainer);
