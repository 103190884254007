import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  doctorGetMyAppointments,
  addDoctorAppointments,
  updateAppointment,
  set_view_appointment_data,
  set_doctor_my_appointments_data,
} from "../../../store/appointment/appointmentSlice";
import {
  getPatientsByNameOrPhone,
  addPatients,
  getUnlinkedPatient,
  linkPatientWithOrg,
} from "../../../store/user/userSlice";
import {
  set_appointment_drawer_state,
  set_chat_window_state,
  set_patient_drawer_state,
  set_patient_link_drawer_state,
} from "../../../store/common/commonSlice";

const mapStateToProps = (state) => {
  return {
    DoctorId: state.common.DoctorId,
    view_appointment_data: state.appointment.view_appointment_data,

    doctor_my_appointments_loading:
      state.appointment.doctor_my_appointments_loading,

    update_appointment_data: state.appointment.update_appointment_data,
    update_appointment_is_loading: state.appointment.update_appointment_is_loading,
    doctor_my_appointments_data: state.appointment.doctor_my_appointments_data,
    doctor_appointment_data_no_filter: state.appointment.doctor_appointment_data_no_filter,
    doctor_my_appointments_kpi: state.appointment.doctor_my_appointments_kpi,

    patients_by_search_loading: state.user.patients_by_search_loading,
    patients_by_search_data: state.user.patients_by_search_data,

    add_appointment_drawer_state: state.common.add_appointment_drawer_state,
    add_patient_drawer_state: state.common.add_patient_drawer_state,
    patient_link_drawer_state: state.common.patient_link_drawer_state,

    add_patient_loading: state.user.add_patient_loading,

    unlinked_patient_is_loading: state.user.unlinked_patient_is_loading,
    unlinked_patient_data: state.user.unlinked_patient_data,

    link_patient_with_org_is_loading:
      state.user.link_patient_with_org_is_loading,
    link_patient_with_org_data: state.user.link_patient_with_org_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      doctorGetMyAppointments,
      getPatientsByNameOrPhone,
      set_appointment_drawer_state,
      set_patient_drawer_state,
      set_patient_link_drawer_state,
      addDoctorAppointments,
      updateAppointment,
      addPatients: addPatients,
      getUnlinkedPatient,
      linkPatientWithOrg,
      set_chat_window_state,
      set_view_appointment_data,
      set_doctor_my_appointments_data,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
