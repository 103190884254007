import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "../../common/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Price = ({ data, setPriceId }) => {
  const [priceId, setPrice] = useState("");

  return (
    <>
      <form id="payment-form">
        <Grid
          container
          spacing={2}
          sx={{
            margin: "auto",
            width: "90%",
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Grid
            item
            sm={8}
            lg={8}
            direction="column"
            alignItems="center"
            sx={{
              border: "1px solid #e0e0e0",
              marginTop: "20px",
              padding: "20px",
              marginBottom: "10px",
            }}
            boxShadow={3}
          >
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
              }}
            >
              {" "}
              Select a plan :-{" "}
            </Typography>

            {data?.data?.map((price) => {
              return (
                <Box
                  key={price?.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "16px",
                      color: "#000",
                    }}
                  >
                    ${price?.unit_amount / 100} / {price?.recurring.interval}
                  </Typography>

                  <Button
                    variant="contained"
                    sx={{
                      width: "25%",
                    }}
                    startIcon={
                      priceId === price?.id ? <CheckCircleIcon /> : null
                    }
                    onClick={() => {
                      setPriceId(price?.id);
                      setPrice(price?.id);
                    }}
                  >
                    Select Plan
                  </Button>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Price;
