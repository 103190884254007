import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDoctorDataUrl, updateDoctorProfile } from "../../../store/user/userSlice";
import {
  getSpecialityList,
} from "../../../store/query/querySlice";

const mapStateToProps = (state) => {
  return {
    // Add Doctor / Update Doctor
    add_doctors_loading: state.user.add_doctors_loading,
    update_profile_loading: state.user.update_profile_loading,

    speciality_is_loading: state.query.speciality_is_loading,
    speciality_list_data: state.query.speciality_list_data,

    // Get doctors data
    doctors_data_is_loading: state.user.doctors_data_is_loading,
    doctors_data: state.user.doctors_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDoctorProfile,
      getDoctorDataUrl,
      getSpecialityList,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
