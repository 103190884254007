import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import { CometChatConversationList } from "../";
import { CometChatMessages } from "../../Messages";
import {
  CometChatIncomingCall,
  CometChatIncomingDirectCall,
} from "../../Calls";

import { CometChatContextProvider } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";

import Translator from "../../../resources/localization/translator";
// import { theme } from "../../../resources/theme";

import {
  chatScreenStyle,
  chatScreenSidebarStyle,
  chatScreenMainStyle,
  contactMsgTxtStyle
} from "./style";

class CometChatConversationListWithMessages extends React.Component {
  loggedInUser = null;

  constructor(props) {
    super(props);

    this.state = {
      tab: "conversations",
      chatOpen: true
    };

    this.contextProviderRef = React.createRef();
    this.chatListRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.chatWithUser.length === 0 &&
      this.props.chatWithGroup.length === 0
    ) {
      this.toggleSideBar();
    }
  }

  componentDidUpdate() {
    if (this.props.chatWithUser.length === 0 &&
      this.props.chatWithGroup.length === 0) {
      this.props.setChatWindowUid({ none: true });
    }
  }

  itemClicked = (item, type) => {
    this.contextProviderRef.setTypeAndItem(type, item);
    if (item.uid)
      this.toggleSideBar({ uid: item.uid });
    else if (item.guid)
      this.toggleSideBar({ guid: item.guid });
  };

  actionHandler = (action, item, count, ...otherProps) => {
    switch (action) {
      case enums.ACTIONS["TOGGLE_SIDEBAR"]:
        this.toggleSideBar();
        break;
      case enums.GROUP_MEMBER_SCOPE_CHANGED:
      case enums.GROUP_MEMBER_KICKED:
      case enums.GROUP_MEMBER_BANNED:
        this.groupUpdated(action, item, count, ...otherProps);
        break;
      default:
        break;
    }
  };

  toggleSideBar = (data) => {
    if (data)
      this.props.setChatWindowUid(data)
    else
      this.props.setChatWindowUid({ none: true });
  };

  /**
 If the logged in user is banned, kicked or scope changed, update the chat window accordingly
 */
  groupUpdated = (key, message, group, options) => {
    switch (key) {
      case enums.GROUP_MEMBER_BANNED:
      case enums.GROUP_MEMBER_KICKED: {
        if (
          this.contextProviderRef.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          this.contextProviderRef.setItem({});
          this.contextProviderRef.setType("");
        }
        break;
      }
      case enums.GROUP_MEMBER_SCOPE_CHANGED: {
        if (
          this.contextProviderRef.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
          this.contextProviderRef.item.guid === group.guid &&
          options.user.uid === this.loggedInUser.uid
        ) {
          const newObject = Object.assign({}, this.contextProviderRef.item, {
            scope: options["scope"],
          });
          this.contextProviderRef.setItem(newObject);
          this.contextProviderRef.setType(CometChat.ACTION_TYPE.TYPE_GROUP);
        }
        break;
      }
      default:
        break;
    }
  };

  render() {
    let messageScreen = (
      <CometChatMessages
        theme={this.props.theme}
        lang={this.props.lang}
        _parent="conversations"
        actionGenerated={this.actionHandler}
        user={this.props.chatWithUser}
        group={this.props.chatWithGroup}
        setChatOpen={() => this.setState({ chatOpen: false })}
      />
    );

    return (
      <CometChatContextProvider
        ref={(el) => (this.contextProviderRef = el)}
        user={this.props.chatWithUser}
        group={this.props.chatWithGroup}
        language={this.props.lang}
        theme={this.props.theme}
      >
        <div
          style={!this.props.chat_window_state ? { display: "none" } : {}}
          css={chatScreenStyle(this.props)}
          className="cometchat cometchat--chats"
          dir={Translator.getDirection(this.props.lang)}
        >
          {
            this.props.chatWithUser
              ?
              <div
                css={chatScreenSidebarStyle(this.state, this.props)}
                className="chats__sidebar"
                style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}
              >
                <p css={contactMsgTxtStyle(this.props)} className="decorator-message">Loading...</p>
              </div>
              :
              <div
                css={chatScreenSidebarStyle(this.state, this.props)}
                className="chats__sidebar"
              >
                <CometChatConversationList
                  ref={(el) => (this.chatListRef = el)}
                  _parent="clwm"
                  theme={this.props.theme}
                  lang={this.props.lang}
                  onItemClick={this.itemClicked}
                  actionGenerated={this.actionHandler}
                />
              </div>
          }

          <div
            css={chatScreenMainStyle(this.state, this.props)}
            className="chats__main"
            style={(this.props.chatWithUser.length || this.props.chatWithGroup.length) ? { zIndex: 10 } : { zIndex: 0 }}
          >
            {this.state.chatOpen ? messageScreen : null}
          </div>
        </div>
        <div style={!this.props.chat_window_state ? { display: "none" } : {}}>
          <CometChatIncomingCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
          <CometChatIncomingDirectCall
            theme={this.props.theme}
            lang={this.props.lang}
            actionGenerated={this.actionHandler}
          />
        </div>
      </CometChatContextProvider>
    );
  }
}

// Specifies the default values for props:
CometChatConversationListWithMessages.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  // theme: theme,
  chatWithUser: "",
  chatWithGroup: "",
};

CometChatConversationListWithMessages.propTypes = {
  lang: PropTypes.string,
  // theme: PropTypes.object,
  chatWithUser: PropTypes.string,
  chatWithGroup: PropTypes.string,
};

export { CometChatConversationListWithMessages };
