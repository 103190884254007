import React from "react";
import Table from "../../../components/common/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Organization = (props) => {
  const { tableDetails, loading } = props;

  const columns = [
    {
      title: "Sr.No.",
    },
    {
      title: "Organization Name",
    },
    {
      title: "Organization Id",
    },
    {
      title: "Unique Id",
    },
   
  ];

  const renderTableData = tableDetails.length ? (
    tableDetails.map((row, index) => {
  
      return (
        <TableRow key={index} sx={{ "td, th": { border: 0, padding: "10px" } }}>
          <TableCell align="center" component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell align="center">
            {row.key }
          </TableCell>
          <TableCell align="center">{row.value ? row.value : "-"}</TableCell>
          <TableCell align="center">{row.unique_id ? row.unique_id : "-"}</TableCell>
   
        </TableRow>
      );
    })
  ) : (
    <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
      <TableCell align="center">Data not found</TableCell>
    </TableRow>
  );

  return (
    <Table columns={columns} tableData={renderTableData} loading={loading} />
  );
};

export default Organization;
