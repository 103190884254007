import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import AddDoctor from "../../../components/admin/doctor/AddDoctor";
import DoctorHeading from "../../../components/admin/doctor/DoctorHeading";
import DialogD from "../../../components/common/DialogD";
class AddDoctorContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      npi_verified: false,
      npi_msg: "",
      npi_correct: false,
      npi_loading: false,
      npiData: [],
      address: [],
    };
  }

  fetchProviderById() {
    if (this.props.params?.ProviderId) {
      this.props.getDoctorDataUrl({ uid: this.props.params?.ProviderId });
    }
  }
  componentDidMount() {
    this.fetchProviderById();
    this.props.getSpecialityList();
    this.props.getOrgList();
    // this.props.getStateList();
    // this.props.getCityList();
    this.props.queryCommonList({ parent: "gender" });
  }

  handleSubmit = async (values) => {
    let res = await this.props.addDoctors(values); //done
    if (!res.error) this.props.navigate("/dashboard");
  };

  handleUpdateDoctorSubmit = async (values) => {
    let res = await this.props.updateDoctors(values);
    if (!res.error) this.props.navigate("/dashboard");
  };

  checkNpi = async (npi, first_name, last_name) => {
    this.props.checkNPI(npi);
    this.setState({ npi_loading: true });

    try {
      await new Promise((resolve) => setTimeout(resolve, 5000));

      const { get_npi_data } = this.props;
      const data = get_npi_data[0] || {};
      const address = data.addresses ? data.addresses[0] : {};

      const nameMatch =
        data.basic?.first_name?.toLowerCase() === first_name?.toLowerCase() &&
        data.basic?.last_name?.toLowerCase() === last_name?.toLowerCase();

      const newState = {
        npi_verified: true,
        npi_loading: false,
        npiData: data,
        address: address,
        npi_correct: nameMatch,
        npi_msg: nameMatch
          ? "NPI Verified Successfully"
          : "Name Does Not Match As Per NPI",
      };

      this.setState(newState);

      return nameMatch;
    } catch (error) {
      this.setState({
        npi_verified: true,
        npi_loading: false,
        npi_correct: false,
        npi_msg: "Error verifying NPI",
      });

      return false;
    }
  };

  render() {
    const {
      speciality_list_data,
      state_list_data,
      city_list_data,
      doctors_data,
      add_doctors_loading,
      // add_doctors_url_loading,
      // state_list_is_loading,
      // speciality_is_loading,
      common_list_data,
    } = this.props;

    return (
      <>
        <DialogD
          message1={this.state.npi_msg}
          handleClose={() => this.setState({ npi_verified: false })}
          open={this.state.npi_verified}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DoctorHeading
              is_update={this.props.params?.ProviderId ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <AddDoctor
                checkNpi={this.checkNpi}
                npi_correct={this.state.npi_correct}
                npi_loading={this.state.npi_loading}
                gender_data={common_list_data}
                loading={add_doctors_loading}
                addDoctors={this.handleSubmit}
                speciality_list={speciality_list_data}
                // onChange={this.handleChange}
                state_data={state_list_data}
                city_data={city_list_data}
                update={this.handleUpdateDoctorSubmit}
                ProvidersData={doctors_data}
                is_update={this.props.params?.ProviderId ? true : false}
                get_org_data={this.props.get_org_data}
              />
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default AddDoctorContainer;
