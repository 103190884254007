import { Grid, Paper } from "@mui/material";
import React from "react";
import DoctorDirectory from "../../../components/doctor/directory/DoctorDirectory";
import Stack from "@mui/material/Stack";
import MyPhysicianModal from "../../../components/doctor/directory/MyPhysicianModal";
import AddDoctorDirectoryModal from "../../../components/doctor/directory/AddDoctorDirectoryModal";
import { isAuth } from "../../../helpers/cookies";
import AddByUniqueCode from "../../../components/doctor/directory/AddByUniqueCode";

class DoctorDirectoryContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      doctor_directory_add_modal: false,
      add_directory_modal: false,
      add_by_unique_code_modal: false,
    };
  }

  componentDidMount() {
    this.getDoctorDirectoryList();
  }

  getDoctorDirectoryList() {
    this.props.doctorDirectoryUrl({ DoctorId: this.props.DoctorId });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.DoctorId !== this.props.DoctorId) {
      this.getDoctorDirectoryList();
    }
  }

  openDoctorDirectoryModal() {
    this.setState({
      doctor_directory_add_modal: true,
    });
  }

  closeDoctorDirectoryModal() {
    this.setState({
      doctor_directory_add_modal: false,
    });
  }

  showPhysicianDetail(id) {
    this.props.getPhysicianDataUrl({ id: id });
    this.openDoctorDirectoryModal();
  }

  showDirectoryModal() {
    this.openAddDirectoryModal();
  }

  openAddDirectoryModal() {
    this.setState({
      add_directory_modal: true,
    });
  }

  closeAddDirectoryModal() {
    this.setState({
      add_directory_modal: false,
    });
  }

  handelAddByUniqueCodeModal() {
    this.setState({
      add_by_unique_code_modal: true,
    });
  }

  removeDirectorylist = (id) => {
    this.props.removeDoctorDirectory({ id: id });
  };

  handleUniqueCodeSubmit = (values) => {
    if (isAuth().role_name === "NURSE") {
      values.type = "NURSE";
      values.user_id = this.props.DoctorId;
      this.props.addDoctorsByCode(values);
    } else {
      values.type = "DOCTOR";
      this.props.addDoctorsByCode(values);
    }
  };

  handleInviteDoctor = (id, Email) => {
    const payload = {
      id: id,
      Email: Email,
    };
    this.props.inviteDoctor(payload);
  };

  render() {
    const {
      speciality_list_data,
      doctor_directory_data,
      doctor_directory_is_loading,
      physician_list_is_loading,
      physician_data,
      add_doctors_by_code_is_loading,
    } = this.props;

    return (
      <>
        <Stack spacing={1}>
          <Grid container justifyContent="flex-end">
            <Grid>
              <AddDoctorDirectoryModal
                handelOpenAddByUniqueCodeModal={() =>
                  this.handelAddByUniqueCodeModal()
                }
                openUniqueCode={this.state.add_by_unique_code_modal}
                handelCloseAddByUniqueCodeModal={() =>
                  this.setState({ add_by_unique_code_modal: false })
                }
                addDoctorsByCode={this.handleUniqueCodeSubmit}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper
              sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
              elevation={0}
            >
              <DoctorDirectory
                loading={doctor_directory_is_loading}
                doctor_directory_data={doctor_directory_data}
                addDoctors={this.handleSubmit}
                speciality_list={speciality_list_data}
                showDetails={(d) => this.showPhysicianDetail(d)}
                editDoctorDirectory={(id) =>
                  this.props.navigate("/add-update-doctor-directory/" + id)
                }
                handleOpenChat={(ProviderId) => {
                  this.props.set_chat_window_ids({ uid: ProviderId });
                }}
                removeDoctorDirectory={this.removeDirectorylist}
                inviteDoctor={this.handleInviteDoctor}
              />
              <MyPhysicianModal
                loading={physician_list_is_loading}
                data={physician_data}
                open={this.state.doctor_directory_add_modal}
                handleClose={() => this.closeDoctorDirectoryModal()}
              />
              <AddByUniqueCode
                open={this.state.add_by_unique_code_modal}
                handleClose={() =>
                  this.setState({ add_by_unique_code_modal: false })
                }
                addDoctorsByCode={this.handleUniqueCodeSubmit}
                loading={add_doctors_by_code_is_loading}
              />
            </Paper>
          </Grid>
        </Stack>
      </>
    );
  }
}

export default DoctorDirectoryContainer;
