import * as React from "react";
import Drawer from "@mui/material/Drawer";
import PatientView from "../../../pages/doctor/Patient";

export default function PatientDetailsDrawer({
  guid,
  reschedule,
  appointment_data,
  close,
  updateDoctorAppointment,
  update_appointment_is_loading,
}) {
  return (
    <Drawer
      anchor={"right"}
      open={appointment_data ? true : false}
      PaperProps={{
        sx: { width: "35%" },
      }}
      onClose={() => close()}
    >
      <PatientView
        guid={guid}
        patientData={appointment_data}
        update_appointment_is_loading={update_appointment_is_loading}
        reschedule={(d) => reschedule(d)}
        updateDoctorAppointment={(d) => updateDoctorAppointment(d)}
        close={() => close()}
      />
    </Drawer>
  );
}
