import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import Button from "../../components/common/Button";

const Cancel = () => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: "100vh" }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <CancelIcon
          sx={{
            color: "red",
            fontSize: 70,
            marginBottom: "10px",
          }}
        />

        <Typography
          variant="body1"
          sx={{
            color: "green",
            margin: "10px",
            fontSize: "20px",
            lineHeight: "1.5",
          }}
        >
          You have cancelled your payment. Please re-login to proceed with the payment.
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "gray",
            margin: "10px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          In case of any questions, email us at
          <EmailIcon sx={{ fontSize: "16px", marginLeft: "5px", marginRight: "2px" }} />
          <a href="mailto:support@myhipaasafe.com">support@myhipaasafe.com</a>.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            width: "20%",
            "&:hover": {
              backgroundColor: "#1976d2",
            },
          }}
          href="/login"
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
};

export default Cancel;
