import React from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import PatientProfileDetails from "../profile/PatientProfileDetails";

const PatientModal = (props) => {
  const { data, loading, open, handleClose } = props;


  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Stack justifyContent={"center"} alignItems="center" sx={{ m: 3 }}>
          {!loading ? (
            <PatientProfileDetails data={data} />
          ) : (
            <CircularProgress />
          )}
        </Stack>
      </Dialog>
    </>
  );
};

export default PatientModal;
