import { get } from "lodash-es";

export const parser_get_patient_notes_url = (response) => {
  try {
    if (response?.data.rows) {
      response = response.data?.rows;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      PatientId: get(e, "PatientId", ""),
      NoteText: get(e, "NoteText", ""),
      ProviderId: get(e, "ProviderId", ""),
      createdAt: get(e, "createdAt", ""),
      DoctorName:
        get(e, "doctor_details.FirstName", "") +
        " " +
        get(e, "doctor_details.LastName", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_add_patient_notes_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return {
      PatientId: get(response, "PatientId", ""),
      NoteText: get(response, "NoteText", ""),
      ProviderId: get(response, "ProviderId", ""),
      createdAt: get(response, "createdAt", ""),
      DoctorName:
        get(response, "doctor_details.FirstName", "") +
        " " +
        get(response, "doctor_details.LastName", ""),
    };
  } catch (error) {
    throw new Error(error);
  }
};
