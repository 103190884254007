import { axios } from "../../helpers/axios";

import {
  FETCH_PATIENT_DOCUMENTS_URL,
  REMOVE_REQUEST_DOCS_URL,
  REQUEST_PATIENT_DOCUMENTS_URL,
  REQUEST_COMPREHEND_MEDICAL_URL,
} from "./documentConstants";

export const api_fetch_patient_documents_url = async (params) => {
  return axios.get(FETCH_PATIENT_DOCUMENTS_URL, { params });
};
export const api_request_patient_documents = async (data) => {
  return axios.post(REQUEST_PATIENT_DOCUMENTS_URL, data);
};
export const api_remove_request_docs_url = async (data) => {
  return axios.post(REMOVE_REQUEST_DOCS_URL, data);
};
export const api_documents_comprehend_medical = async (data) => {
  return axios.post(REQUEST_COMPREHEND_MEDICAL_URL, data);
};

