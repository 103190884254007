import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";

import Routes from "./routes";
import SnackBarContainer from "./components/common/Snackbar";
import DarkTheme from "./helpers/theme/DarkTheme";
import LightTheme from "./helpers/theme/LightTheme";
import { set_dark_mode } from "./store/common/commonSlice";
import { initializeOneSignal } from "./helpers/config/initializeOneSignal";

function App() {
  const dispatch = useDispatch();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { dark_mode } = useSelector((state) => state.common);

  React.useEffect(() => {
    initializeOneSignal();
  }, []);

  React.useEffect(() => {
    dispatch(set_dark_mode(prefersDarkMode ? "dark" : "light"));
  }, [dispatch, prefersDarkMode]);

  const theme = React.useMemo(
    () => createTheme(dark_mode === "dark" ? DarkTheme : LightTheme),
    [dark_mode]
  );
  

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackBarContainer />
        <Stack>
          

          <Routes />
         
        </Stack>
      </ThemeProvider>
    </>
  );
}

export default App;
