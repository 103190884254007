import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { isAuth, signout } from "../../../helpers/cookies";

import { makeStyles, createStyles, useTheme } from "@mui/styles";
import {
  set_dark_mode,
  set_doctor_id,
} from "../../../store/common/commonSlice";

import DoctorSwitch from "./DoctorSwitch";
import ProfileImage from "../../common/ProfileImage";
import { Feedback } from "@mui/icons-material";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: "100%",

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const BaseLayout = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mode } = useTheme().palette;
  const { DoctorId } = useSelector((state) => state.common);
  const path_url = window.location.pathname;
  const {
    modules,
    ChildComponent,
    doctor_orgnization_data,
    handleOrganizationSwitch,
    isDrawerExpanded = true,
    onDrawerToggle,
    userLogout,
  } = props;
  const classes = useStyles();
  const handleSignOut = async () => {
    await userLogout();
    signout(() => navigate(0));
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          elevation={1}
          open={true}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 1200,
            bgcolor: "background.paper",
            shadow: "none",
            px: 3,
            py: 0.7,
          }}
          enableColorOnDark
        >
          <Stack justifyContent="center" alignItems="center">
            {isAuth().role_name === "NURSE" &&
            props.params["*"] !== "profile" &&
            props.params["*"].split("/") &&
            props.params["*"].split("/")[0] !== "profile-update" &&
            props.params["*"] !== "qr-code" ? (
              <DoctorSwitch
                ProviderId={DoctorId}
                MappedDoctors={isAuth().doctors_mapped || []}
                isDrawerExpanded={isDrawerExpanded}
                handleChange={(NewProviderId) => {
                  dispatch(set_doctor_id({ ProviderId: NewProviderId }));
                }}
              />
            ) : null}

            {isAuth().role_name === "DOCTOR" &&
            props.params["*"] !== "profile" &&
            props.params["*"].split("/") &&
            props.params["*"].split("/")[0] !== "profile-update" &&
            props.params["*"] !== "qr-code" ? (
              <FormControl
                sx={{
                  width: 300,
                  marginTop: 1,
                  marginRight: "200px",
                  marginLeft: isDrawerExpanded ? "300px" : "100px",
                }}
              >
                <InputLabel id="doctor-id-select-label">
                  Select Organization
                </InputLabel>
                <Select
                  fullWidth
                  labelId="doctor-id-select-label"
                  id="doctor-id-select"
                  size="small"
                  label="Select Organization"
                  sx={{ width: 300, marginTop: 1 }}
                  onChange={(event) =>
                    console.log(event.target.value) ||
                    handleOrganizationSwitch(event.target.value)
                  }
                  value={isAuth().organization_id}
                >
                  {doctor_orgnization_data?.map(function (dd) {
                    return (
                      <MenuItem
                        value={dd?.organization_id}
                        key={dd?.organization_mapping?.name}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography>
                            {dd?.organization_mapping?.name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              <IconButton
                title="View profile"
                size="small"
                onClick={() => navigate("/profile")}
              >
                <ProfileImage alt={isAuth().FirstName} src={isAuth().avatar} />
                {/* <AccountCircle
                  sx={{ color: "#bdbdbd", width: "32px", height: "32px" }}
                /> */}
              </IconButton>
              <Typography
                onClick={() => navigate("/profile")}
                variant="subtitle1"
                color="text.primary"
              >
                {isAuth().FirstName}
              </Typography>
            </Stack>
            <IconButton title="Log Out" size="small" onClick={handleSignOut}>
              <LogoutIcon
                sx={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </IconButton>
          </Stack>
        </AppBar>
        <Drawer
          variant="permanent"
          open={isDrawerExpanded}
          onClose={() => onDrawerToggle(false)}
          PaperProps={{
            sx: {
              bgcolor: "background.paper",
              border: "none",
            },
            elevation: 1,
          }}
          classes={{
            root: cx({
              [classes.drawerRoot]: true,
              [classes.drawerExpanded]: isDrawerExpanded,
              [classes.drawerCollapsed]: !isDrawerExpanded,
            }),
            paper: cx({
              [classes.drawerPaper]: true,
              [classes.drawerExpanded]: isDrawerExpanded,
              [classes.drawerCollapsed]: !isDrawerExpanded,
            }),
          }}
        >
          <DrawerHeader
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img
              src={
                mode === "dark"
                  ? "/hipaa_safe_logo_dark.svg"
                  : "/hipaa_safe_logo_light.svg"
              }
              alt="hipaasafe_logo"
            /> */}

            {isDrawerExpanded ? (
              <Box
                className={cx({
                  [classes.drawerHeader]: true,
                  [classes.drawerHeaderExpanded]: isDrawerExpanded,
                })}
                onClick={() => onDrawerToggle(false)}
              >
                <img
                  alt="hipaasafe_logo"
                  src={
                    mode === "dark"
                      ? "/hipaa_safe_logo_dark.svg"
                      : "/hipaa_safe_logo_light.svg"
                  }
                  height={38}
                />
              </Box>
            ) : (
              <Box
                className={cx({
                  [classes.drawerHeader]: true,
                  [classes.drawerHeaderCollapsed]: !isDrawerExpanded,
                })}
                onClick={() => onDrawerToggle(true)}
              >
                <Avatar
                  src={mode === "dark" ? "/logo192.png" : "/logo192.png"}
                  sx={{ height: 38, width: 38 }}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            )}
          </DrawerHeader>
          <List>
            {modules.map((module, i) => {
              return isDrawerExpanded ? (
                <Tooltip
                  key={("module_", i)}
                  placement="right"
                  title={module.title}
                >
                  <ListItem
                    button
                    key={module.id}
                    onClick={() => navigate(module.link)}
                    selected={path_url === module.link ? true : false}
                    sx={{
                      mx: 2,
                      my: 0.5,
                      width: "unset",
                      borderRadius: "10px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "35px",
                        ...(path_url === module.link && { color: "#0098FF" }),
                        ...(mode === "dark" && module.title === "Connections"
                          ? { filter: "invert(1)" }
                          : ""),
                      }}
                    >
                      {module.logo}
                    </ListItemIcon>
                    <ListItemText
                      primary={module.title}
                      sx={{
                        ...(path_url === module.link && { color: "#0098FF" }),
                      }}
                    />
                  </ListItem>
                </Tooltip>
              ) : (
                <Tooltip
                  key={("module_", i)}
                  placement="right"
                  title={module.title}
                >
                  <ListItem
                    button
                    key={module.id}
                    onClick={() => navigate(module.link)}
                    selected={path_url === module.link ? true : false}
                    sx={{
                      mx: 2,
                      my: 0.5,
                      px: 1,
                      width: "unset",
                      borderRadius: "10px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "35px",
                        ...(path_url === module.link && { color: "#0098FF" }),
                        ...(mode === "dark" && module.title === "Connections"
                          ? { filter: "invert(1)" }
                          : ""),
                      }}
                    >
                      {module.logo}
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
          <Divider />
          {isDrawerExpanded ? (
            <List>
              <Tooltip placement="right" title="Dark Mode">
                <ListItem
                  button
                  key={"Dark Mode"}
                  onClick={() => {
                    dispatch(set_dark_mode());
                    // window.location.reload();
                  }}
                  sx={{ mx: 2, my: 0.5, width: "unset", borderRadius: "10px" }}
                >
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    {<Brightness4Icon />}
                  </ListItemIcon>
                  <ListItemText primary={"Dark Mode"} />
                </ListItem>
              </Tooltip>
              <ListItem
                button
                key={"Feedback"}
                onClick={() => {
                  navigate("/feedback");
                }}
                sx={{ mx: 2, my: 0.5, width: "unset", borderRadius: "10px" }}
              >
                <ListItemIcon sx={{ minWidth: "35px" }}>
                  {<HeadsetMicIcon />}
                </ListItemIcon>
                <ListItemText primary={"Help & Support"} />
              </ListItem>
            </List>
          ) : (
            <List>
              <Tooltip placement="right" title="Dark Mode">
                <ListItem
                  button
                  key={"Dark Mode"}
                  onClick={() => {
                    dispatch(set_dark_mode());
                    // window.location.reload();
                  }}
                  sx={{ mx: 2, my: 0.5, width: "unset", borderRadius: "10px" }}
                >
                  <ListItemIcon sx={{ minWidth: "35px" }}>
                    {<Brightness4Icon />}
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
              <ListItem
                button
                key={"Feedback"}
                onClick={() => {
                  navigate("/feedback");
                }}
                sx={{ mx: 2, my: 0.5, width: "unset", borderRadius: "10px" }}
              >
                <ListItemIcon sx={{ minWidth: "35px" }}>
                  {<HeadsetMicIcon />}
                </ListItemIcon>
              </ListItem>
            </List>
          )}
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mx: 3,
            my: 1,
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <Toolbar />
          <ChildComponent />
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    drawerRoot: {
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      border: `0 !important`,
    },
    drawerExpanded: {
      width: drawerWidth,
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerCollapsed: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(9),
    },

    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    drawerHeaderExpanded: {
      justifyContent: "space-between",
    },
    drawerHeaderCollapsed: {
      justifyContent: "center",
    },
  })
);

export default React.memo(BaseLayout);
