import { Avatar } from "@mui/material";
import React from "react";
import { S3ProfileImage } from "../../helpers/constants";

const ProfileImage = (props) => {
  const { alt, src, width = "40", height = "40", style } = props;
  return (
    <>
      <Avatar
        alt={alt}
        src={S3ProfileImage + src}
        sx={{ bgcolor: "grey", width, height }}
        style={style}
      />
    </>
  );
};

export default ProfileImage;
