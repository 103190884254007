import * as React from "react";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import CloudDownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Dialog,
  Slide,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import FileViewer from "react-file-viewer";
import { S3ImageBaseUrl } from "../../../helpers/constants";

export default function DocumentDialog({ data, close }) {
  return (
    <Dialog
      fullScreen
      open={data ? true : false}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ px: 3, py: 0.7 }}>
        {/* <Toolbar> */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack
            direction="row"
            alignItems="ceter"
            justifyContent="center"
            spacing={1}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="h6">
              {data.document_name}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="center"
            spacing={1}
          >
            <Tooltip title="Print">
              <IconButton>
                <PrintIcon
                  onClick={() => {
                    printPdf(S3ImageBaseUrl + data.document_file);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton
                onClick={() => {
                  downloadURI(S3ImageBaseUrl + data.document_file)
                }}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        {/* </Toolbar> */}
      </AppBar>

      <Box
        sx={{ bgcolor: "background.paper", width: "100%", height: "100%" }}
        className="section-to-print"
      >
        <FileViewer
          fileType={data.document_extension}
          filePath={S3ImageBaseUrl + data.document_file}
          errorComponent={() => {
            return "Error";
          }}
          onError={(e) => console.log("e", e)}
        />
      </Box>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const downloadURI = (uri, name) => {

  var a = document.createElement('A');
  a.href = uri;
  a.download = uri.substr(uri.lastIndexOf('/') + 1);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const printPdf = (url) => {
  var content =
    '<html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en">' +
    '<head><link href="/css/print.css" media="all" rel="stylesheet" type="text/css"></head>' +
    '<body><img src="' +
    url +
    '" />' +
    '<script type="text/javascript">function printPage() { window.focus(); window.print();return; }</script>' +
    "</body></html>";

  var newIframe = document.createElement("iframe");
  newIframe.width = "100%";
  newIframe.height = "auto";
  newIframe.src = "about:blank";
  document.body.appendChild(newIframe);

  newIframe.contentWindow.contents = content;
  newIframe.src = 'javascript:window["contents"]';

  newIframe.onload = function () {
    newIframe.contentWindow.printPage();
  };
};
