import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../pages/public/LoginPage.jsx";
import DoctorRegister from "../pages/public/DoctorRegister.jsx";
import DoctorRegisterPortal from "../pages/public/DoctorRegisterPortal.jsx";
import Cancel from "../pages/public/Cancel.jsx";
import CancelPortal from "../pages/public/CancelPortal.jsx";
import Payment from "../pages/public/Payment.jsx";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<DoctorRegister />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/success" element={<DoctorRegister />} />
      <Route path="/cancel" element={<Cancel />} />
      
       {/******  register page for portal is diffrent from register page for app because of the payment fuctionality *******/}

      <Route path="/register/doctor" element={<DoctorRegisterPortal />} />
      <Route path="/register/doctor/success" element={<DoctorRegisterPortal />} />
      <Route path="/register/doctor/cancel" element={<CancelPortal />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default PublicRoutes;
