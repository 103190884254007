
import {loadStripe} from '@stripe/stripe-js';


let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51MCR8ZJx1pX7Qf6zkQZCH2n94bJUiLGrnfRXWemM9yKhTjbHFNxTcXXwDtlzH9OsixOnUF4yckamktQolPcKllCa00NmT6oMmi");
  }
  return stripePromise;
};

export default getStripe; 