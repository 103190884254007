import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import HeaderDateRange from "../../../components/doctor/history/HeaderDateRange";
// import PatientAppointment from "../../../components/doctor/history/PatientAppointment";
import AppntSkeleton from "../../../components/common/Skeleton";
import { sub, isValid } from "date-fns";

class HistoryContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      value: false,
      from_date: sub(new Date(), { days: 4 }),
      to_date: sub(new Date(), { days: 1 }),
    };
    this.setDate = this.setDate.bind(this);
  }

  componentDidMount() {
    this.fetchAppointmentsDataByRange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.DoctorId !== this.props.DoctorId) {
      this.fetchAppointmentsDataByRange();
    }
  }

  fetchAppointmentsDataByRange() {
    const { from_date, to_date } = this.state;
    const { DoctorId } = this.props;

    if (
      from_date &&
      to_date &&
      from_date !== "Invalid Date" &&
      to_date !== "Invalid Date" &&
      isValid(from_date) &&
      isValid(to_date)
    ) {
      this.props.queryAppointmentHistoryList({
        from_date: from_date.toISOString(),
        to_date: to_date.toISOString(),
        DoctorId,
      });
    } else {
      console.log("Invalid Date");
    }
  }

  setDate(newValue, type) {
    this.setState(
      {
        [type]: newValue,
      },
      () => this.fetchAppointmentsDataByRange()
    );
  }

  render() {
    const {
      theme,
      doctor_appointment_history_loading,
      doctor_appointment_history_data,
    } = this.props;
    const { from_date, to_date } = this.state;

    return (
      <>
        <div
          className="sticky_appointment"
          style={
            theme?.palette?.mode === "dark"
              ? { background: "#000" }
              : { background: "#fff" }
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HeaderDateRange
                from_date={from_date}
                to_date={to_date}
                setDate={this.setDate}
              />
            </Grid>
          </Grid>
        </div>
        <Stack direction="column" spacing={1}>
          <Paper sx={{ p: 4 }}>
            {doctor_appointment_history_loading ? (
              <AppntSkeleton />
            ) : doctor_appointment_history_data.length > 0 ? (
              doctor_appointment_history_data.map((masterElement, index) => {
                return (
                  <Stack
                    direction="column"
                    key={"appointment_history_master_element_" + index}
                  >
                    <Stack sx={{ my: 1 }}>
                      <Typography color="primary.main">
                        {masterElement[0].AppointmentDate}
                      </Typography>
                    </Stack>

                    <Grid container spacing={1}>
                      {masterElement.map((element, index2) => {
                        return (
                          <Grid
                            item
                            xs={6}
                            key={"appointment_history_element_" + index2}
                          >
                            <Paper
                              sx={{
                                px: 4,
                                py: 2,
                              }}
                              variant="outlined"
                            >
                              <Stack>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {element.AppointmentTime}
                                  </Typography>
                                  <Typography
                                    color={
                                      theme?.palette[
                                        element?.AppointmentParsedStatus?.color
                                      ]?.main
                                    }
                                  >
                                    {element.AppointmentParsedStatus?.value}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Typography sx={{ fontWeight: 600 }}>
                                    {element.FirstName + " " + element.LastName}
                                  </Typography>
                                  {element.BirthDate? (
                                    <Typography sx={{ fontWeight: 600 }}>
                                      {element.BirthDate + " Yrs"}
                                    </Typography>
                                  ): null}
                                </Stack>
                              </Stack>
                            </Paper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Stack>
                );
              })
            ) : (
              <Stack
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: 200,
                }}
              >
                <Typography variant="body1">No Data Found</Typography>
              </Stack>
            )}
          </Paper>
        </Stack>
      </>
    );
  }
}

export default HistoryContainer;
