import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const generateLabel = (label, info) => {
  return label + " (" + info?.length + ")";
};

export default function ComprehendTree({ data }) {
  let similarData = [];
 
  data?.forEach((item) => {

    let found = similarData?.find((x) => x?.Category === item?.Category);
    let data = [];
    if (found) {
      if (!found.Data.find((x) => x.Type === item.Type)) {
        found.Data.push({
          Type: item.Type,
        });
      }
      // pushed text in its type
    } else {
      similarData.push({
        Category: item.Category,
        Data: [
          {
            Type: item.Type,
          },
        ],
      });
    }
  });

  // pushed text in its type i
  similarData.map((item) => {
    item.Data.map((a) => {
      let arr = [];
      data.map((b) => {
        if (a.Type === b.Type) {
          arr.push({
            Text: b.Text,
            Traits: b.Traits,
            Attributes: b.Attributes,
          });
        }
      });
      a.Text = arr;
    });
  });

  return (
    <TreeView
      aria-label="doctor"
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {similarData?.map((item, i) => (
        <StyledTreeItem
          nodeId={i.toString()}
          labelText={item?.Category}
          labelIcon={LocalHospitalIcon}
        >
          {item?.Data?.map((data, j) => (
            <StyledTreeItem
              nodeId={i.toString() + j.toString()}
              labelText={data?.Type}
              color="#1a73e8"
              bgColor="#e8f0fe"
              onClick={() => {
              }}
            >
              {data?.Text?.map((text, k) => (
                <StyledTreeItem
                  nodeId={i.toString() + j.toString() + k.toString()}
                  labelText={text.Text}
                  color="#1a73e8"
                  bgColor="#e8f0fe"
                  onClick={() => {
                  }}
                >
                  {/* {text?.Traits?.map((trait, l) => (
                    <>
                    <StyledTreeItem
                      nodeId={
                        i.toString() + j.toString() + k.toString() + l.toString()
                      }
                      labelText={trait?.Name}
                      color="#1a73e8"
                      bgColor="#e8f0fe"
                      />
                      
                      </>
                        )
                      )} */}


                </StyledTreeItem>
              ))}
            </StyledTreeItem>
          ))}
        </StyledTreeItem>
      ))}
    </TreeView>
  );
}
