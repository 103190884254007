import React from "react";
import Organization from "../../../components/support/dashboard/Organization";
import SupportHeader from "../../../components/support/dashboard/SupportHeader";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import BackdropComponent from "../../../components/common/Backdrop";

import { isAuth } from "../../../helpers/cookies";

class SupportDashboardContainer extends React.Component {
  constructor() {
    super();
    this.state = {
     
    };
  }

  componentDidMount() {
    this.props.getOrgList();
  
  }


  render() {
    const {
        get_org_is_loading,
      get_org_data,
    } = this.props;
 

    return (
      <>
       
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SupportHeader title="Organizations" />

          
          </Grid>
        
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Paper elevation={0}>
                <Stack sx={{ p: 5 }} spacing={1}>
                <Organization
                        tableDetails={get_org_data}
                        loading={get_org_is_loading}
                        
                      />
                </Stack>
              </Paper>
            </Stack>
          </Grid>
        </Grid>
       
      </>
    );
  }
}

export default SupportDashboardContainer;
