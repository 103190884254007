import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import IconButtonIcons from "../../../components/common/IconButtonIcons";
import AddByUniqueCode from "../../../components/doctor/directory/AddByUniqueCode";
import { isAuth } from "../../../helpers/cookies";
class AddDoctorsByCodeContainer extends React.Component {
  //   constructor() {
  //     super();

  //   }

  //   componentDidMount() {
  //     this.addDoctorsByCode();
  //   }
  handleSubmit = (values) => {



    if (isAuth().role_name === "NURSE") {
      values.type = "NURSE";
      values.user_id = this.props.DoctorId;
      this.props.addDoctorsByCode(values);
    }
    else{
      values.type = "DOCTOR";
      this.props.addDoctorsByCode(values);
    }

   
  };

  render() {
    const { add_doctors_by_code_is_loading } = this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <IconButtonIcons
              onClick={() => {
                this.props.navigate("/chat");
              }}
              title="Previous"
              IconComponent={NavigateBeforeOutlinedIcon}
            />
            <Typography variant="h6">Add Provider ​</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
              <Paper
                sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                elevation={0}
              >
                <AddByUniqueCode
                  loading={add_doctors_by_code_is_loading}
                  addDoctorsByCode={this.handleSubmit}
                />
              </Paper>
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default AddDoctorsByCodeContainer;
