import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButtonIcons from "./IconButtonIcons";
import CloseIcon from "@mui/icons-material/Close";

const DrawerHeader = ({ title = "Header", onClick }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Stack>
        <Typography variant="h6" component="h6">
          {title}
        </Typography>
      </Stack>
      <Stack>
        <IconButtonIcons
          title="Close"
          IconComponent={CloseIcon}
          color="error"
          onClick={onClick}
        />
      </Stack>
    </Stack>
  );
};

export default DrawerHeader;
