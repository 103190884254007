import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { Bar } from "react-chartjs-2";

const AppointmentBarChart = ({ loading, labels, aptData, noShowData }) => {
  const stackedBarData = {
    labels: labels,
    datasets: [
      {
        label: "Appointments",
        data: aptData,
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "No Shows",
        data: noShowData,
        backgroundColor: "rgb(75, 192, 192)",
      },
    ],
  };
  return (
    <>
      <Stack spacing={1}>
        <Paper elevation={0}>
          <Stack sx={{ p: 5 }} spacing={1}>
            <Typography variant="body2">
              Total Appointment and No Shows
            </Typography>
            <Bar data={stackedBarData} />
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default AppointmentBarChart;
