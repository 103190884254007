import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  api_fetch_patient_documents_url,
  api_remove_request_docs_url,
  api_request_patient_documents,
  api_documents_comprehend_medical
} from "./documentApis";
import {
  parser_fetch_patient_documents_request_url,
  parser_fetch_patient_documents_url,
  parser_remove_request_docs_url,
  parser_documents_comprehend_medical,
} from "./documentParser";

export const fetchPatientDocuments = createAsyncThunk(
  "fetchPatientDocuments",
  async (payload) => {
    try {
      const { guid } = payload;
      const response = await api_fetch_patient_documents_url({ guid });

      let data = {};
      data.documents = parser_fetch_patient_documents_url(response);
      data.documents_request =
        parser_fetch_patient_documents_request_url(response);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const requestPatientDocuments = createAsyncThunk(
  "requestPatientDocuments",
  async (payload) => {
    try {
      const { data } = payload;
      const response = await api_request_patient_documents(data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const removeRequestDocs = createAsyncThunk(
  "removeRequestDocs",
  async (payload) => {
    try {
      const { data: requestData } = payload;
      const response = await api_remove_request_docs_url(requestData);
      const data = parser_remove_request_docs_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

// api_documents_comprehend_medical


export const ComprehendMedical = createAsyncThunk(
  "ComprehendMedical",
  async (payload) => {
    try {
      const { s3Url } = payload;
      const response = await api_documents_comprehend_medical({s3Url});
      const data = parser_documents_comprehend_medical(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

const initialState = {
  // Fetch patient documents
  patient_documents_loading: false,
  patient_documents_data: [],
  patient_documents_request_data: [],

  // Request Patient Documents
  request_patient_documents_loading: false,

  // Remove Documents
  remove_request_docs_is_loading: false,

 // Comprehend Medical
  comprehend_medical_is_loading: false,
  comprehend_medical_data: [],



};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {},
  extraReducers: {
    // Fetch patient documents
    [fetchPatientDocuments.pending]: (state, action) => {
      state.patient_documents_loading = true;
    },
    [fetchPatientDocuments.fulfilled]: (state, action) => {
      const { documents, documents_request } = action.payload;
      state.patient_documents_data = documents;
      state.patient_documents_request_data = documents_request;
      state.patient_documents_loading = false;
    },
    [fetchPatientDocuments.rejected]: (state, action) => {
      state.patient_documents_loading = false;
    },
    // Request patient documents
    [requestPatientDocuments.pending]: (state, action) => {
      state.request_patient_documents_loading = true;
    },
    [requestPatientDocuments.fulfilled]: (state, action) => {
      state.request_patient_documents_loading = false;
    },
    [requestPatientDocuments.rejected]: (state, action) => {
      state.request_patient_documents_loading = false;
    },

    // Remove Documents
    [removeRequestDocs.pending]: (state, action) => {
      state.remove_request_docs_is_loading = true;
    },
    [removeRequestDocs.fulfilled]: (state, action) => {
      const { data } = action.payload;
      for (const removedRequest of data) {
        const index = state.patient_documents_request_data.findIndex(
          (ele) => ele.id === removedRequest.id
        );
        state.patient_documents_request_data.splice(index, 1);
      }
      state.remove_request_docs_is_loading = false;
    },
    [removeRequestDocs.rejected]: (state, action) => {
      state.remove_request_docs_is_loading = false;
    },
    [ComprehendMedical.pending]: (state, action) => {
      state.comprehend_medical_is_loading = true;
    },
    [ComprehendMedical.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.comprehend_medical_data = data;
      state.comprehend_medical_is_loading = false;
    },
    [ComprehendMedical.rejected]: (state, action) => {
      state.comprehend_medical_is_loading = false;
    }
  },
});

// Action creators are generated for each case reducer function

export default documentSlice.reducer;
