import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import {
  generateAppointmentHeight,
  generateAppointmentTop,
} from "../../../helpers/utils";
import Button from "../../common/Button";

const BookedSlotsComponent = ({
  data,
  view,
  open,
  handlePopoverOpen,
  handlePopoverClose,
}) => {

  return (
    <>
      {data?.data && data.data.map(function (dd, key) {

        return (
          <Button
            key={key}
            disableElevation
            onClick={(e) => {
              view(dd);
              e.stopPropagation();
            }}
            color={dd.AppointmentParsedStatus.color}
            sx={{
              // backgroundColor: "#0098FF",
              borderRadius: "5px",
              zIndex: 5,
              px: 1,
              lineHeight: 0,
              position: "absolute",
              left: 180 + 160 * key,
              cursor: "pointer",
              width: "150px",
              top: generateAppointmentTop(dd.AppointmentParsedStatus),
              height: generateAppointmentHeight(dd.AppointmentParsedStatus),
            
            }}
            onMouseEnter={(e) =>
              handlePopoverOpen(e, {
                value: dd.AppointmentParsedStatus.value,
                queue_no: dd.queue_no,
              })
            }
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseLeave={() => handlePopoverClose()}
          >
            <Typography variant="body2" color={"#fff"}>
              {dd.FirstName} {dd.LastName}
            </Typography>
          
          </Button>
        );
      })}
    </>
  );
};

export default BookedSlotsComponent;