import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addDoctors, getDoctorDataUrl, updateDoctors , checkNPI } from "../../../store/user/userSlice";
import {
  getSpecialityList,
  getCityList,
  queryCommonList,
  // getStateList
} from "../../../store/query/querySlice";

import { getOrgList } from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  return {
    // Add Doctor / Update Doctor
    add_doctors_loading: state.user.add_doctors_loading,

    speciality_is_loading: state.query.speciality_is_loading,
    speciality_list_data: state.query.speciality_list_data,

    state_list_is_loading: state.query.state_list_is_loading,
    state_list_data: state.query.state_list_data,

    city_list_is_loading: state.query.city_list_is_loading,
    city_list_data: state.query.city_list_data,

    get_npi_data: state.user.get_npi_data,
    get_npi_is_loading: state.user.get_npi_is_loading,


    // Get doctors data
    doctors_data_is_loading: state.user.doctors_data_is_loading,
    doctors_data: state.user.doctors_data,
    get_org_data: state.user.get_org_data,
    common_list_is_loading: state.query.common_list_is_loading,
    common_list_data: state.query.common_list_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDoctors: addDoctors,
      updateDoctors,
      getDoctorDataUrl,
      getSpecialityList,
      getCityList,
      // getStateList
      getOrgList, 
      queryCommonList,
      checkNPI,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
