import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

const NotesSkeleton = () => {
  return (
    <>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Skeleton variant="rounded" width={210} height={20} />

          <Skeleton variant="rounded" width={80} height={20} />
        </Stack>
        <Grid sx={{ mt: 1.5 }}>
          <Skeleton variant="rounded" width={250} height={25} />
        </Grid>
      </Grid>
    </>
  );
};

const MultiplyNotesSkeleton = () => {
  let multipleComponent = [];
  for (let i = 0; i < 3; i++) {
    multipleComponent.push(<NotesSkeleton key={"notes_skeleton_key_" + i} />);
  }
  return multipleComponent;
};

const MyNotesSkeleton = () => {
  return <MultiplyNotesSkeleton />;
};

export default MyNotesSkeleton;
