import * as React from "react";
import { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import CloudDownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Dialog,
  Slide,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import FileViewer from "react-file-viewer";
import { S3ImageBaseUrl } from "../../../helpers/constants";
import { Grid } from "@mui/material";
import Chip from "@mui/material/Chip";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import PdfViewerWithHighlighting from "./PdfViewerWithHighlighting";
import ComprehendTree from "./ComprehendTree";
// import PdfDist from "./PdfDist";
// import PdfHighlighterR from "./PdfHighlighter";

//take pdf from s3 and show

// function MyDocument() {
//   return (

export default function DocumentDialogReport({
  doc,
  data,
  close,
  comprehend_medical_is_loading,
}) {
  const [categories, setCategories] = useState([]);
  const [tabValue, setTabValue] = useState("1");
  const [highlightWord, setHighlightWord] = useState("");

  // function PdfViewer({ pdfUrl }) {
  //   const viewerRef = useRef(null);

  // useEffect(() => {
  //   const handleMessage = event => {
  //     const { type, data } = event.data;
  //     if (type === 'highlight') {
  //       const { x, y, width, height } = data;
  //       const viewerIframe = viewerRef.current;
  //       const viewerDoc = viewerIframe.contentDocument || viewerIframe.contentWindow.document;
  //       const viewerBody = viewerDoc.querySelector('body');
  //       const highlightDiv = viewerDoc.createElement('div');
  //       highlightDiv.style.position = 'absolute';
  //       highlightDiv.style.left = x + 'px';
  //       highlightDiv.style.top = y + 'px';
  //       highlightDiv.style.width = width + 'px';
  //       highlightDiv.style.height = height + 'px';
  //       highlightDiv.style.background = 'yellow';
  //       viewerBody.appendChild(highlightDiv);
  //     }
  //   };

  //   window.addEventListener('message', handleMessage);

  //   return () => {
  //     window.removeEventListener('message', handleMessage);
  //   };
  // }, []);

  //   const handleHighlightClick = () => {
  //     // Get the viewer iframe
  //     const viewerIframe = viewerRef.current;

  //     // Send a message to the viewer to highlight text
  //     viewerIframe.contentWindow.postMessage(
  //       {
  //         type: "highlight",
  //         data: {
  //           x: 100, // x-coordinate of the text
  //           y: 100, // y-coordinate of the text
  //           width: 200, // width of the text
  //           height: 20, // height of the text
  //         },
  //       },
  //       "*"
  //     );
  //   };

  //   return (
  //     <>
  //       <button onClick={handleHighlightClick}>Highlight Text</button>
  //       <iframe
  //         ref={viewerRef}
  //         title="PDF Viewer"
  //         style={{ width: "100%", height: "100vh" }}
  //         src={pdfUrl}
  //       />

  //     </>
  //   );
  // }

  // function PdfViewer({ pdfUrl, highlightWord }) {
  //   const viewerRef = useRef(null);
  //   console.log("viewerRef", viewerRef);
  //   console.log("pdfUrl", pdfUrl);
  //   console.log("highlightWord", highlightWord);

  //   useEffect(() => {
  //     const loadPdf = async () => {
  //       try {
  //         const pdfPath = pdfUrl;
  //         const pdfDoc = await pdfjsLib.getDocument(pdfPath).promise;
  //         console.log("pdfDoc", pdfDoc);
  //         const viewerIframe = viewerRef.current;
  //         const viewerWindow = viewerIframe.contentWindow;
  //         const viewerDoc =
  //           viewerIframe.contentDocument || viewerWindow.document;
  //         const viewerBody = viewerDoc.querySelector("body");

  //         for (let i = 1; i <= pdfDoc.numPages; i++) {
  //           const page = await pdfDoc.getPage(i);
  //           const pageView = await page.getView({ scale: 1.0 });
  //           const pageCanvas = viewerDoc.createElement("canvas");
  //           const pageCtx = pageCanvas.getContext("2d");

  //           pageCanvas.width = pageView.width;
  //           pageCanvas.height = pageView.height;

  //           const renderContext = {
  //             canvasContext: pageCtx,
  //             viewport: pageView,
  //           };

  //           await page.render(renderContext);

  //           const pageText = await page.getTextContent();
  //           const pageWords = pageText.items.map((item) => item.str);

  //           const firstWordIndex = pageWords.findIndex((word) =>
  //             word.includes(highlightWord)
  //           );
  //           if (firstWordIndex !== -1) {
  //             const firstWord = pageWords[firstWordIndex];
  //             const firstWordNode = viewerDoc.evaluate(
  //               `//text()[contains(., "${firstWord}")]`,
  //               viewerDoc,
  //               null,
  //               XPathResult.FIRST_ORDERED_NODE_TYPE,
  //               null
  //             ).singleNodeValue;
  //             if (firstWordNode) {
  //               const firstWordRect = firstWordNode.getBoundingClientRect();
  //               const highlightDiv = viewerDoc.createElement("div");
  //               highlightDiv.style.position = "absolute";
  //               highlightDiv.style.left = firstWordRect.left + "px";
  //               highlightDiv.style.top = firstWordRect.top + "px";
  //               highlightDiv.style.width = firstWordRect.width + "px";
  //               highlightDiv.style.height = firstWordRect.height + "px";
  //               highlightDiv.style.background = "yellow";
  //               viewerBody.appendChild(highlightDiv);
  //               viewerWindow.scrollTo(0, firstWordRect.top);
  //               break;
  //             }
  //           }
  //         }
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     };

  //     loadPdf();
  //   }, [pdfUrl, highlightWord]);

  //   return (
  //     <iframe
  //       ref={viewerRef}
  //       title="PDF Viewer"
  //       style={{ width: "100%", height: "100vh" }}
  //       src={pdfUrl}
  //     />
  //   );
  // }

  function renderTabs(data) {
    let similarData = [];
    data?.forEach((item) => {
      let found = similarData?.find((x) => x?.Category === item?.Category);
      let data = [];
      if (found) {
        found.count++;
        found.Data.push({
          Text: item.Text,
        });
      } else {
        similarData.push({
          Category: item.Category,
          count: 1,
          Data: [
            {
              Text: item.Text,
            },
          ],
        });
      }
    });

    return (
      <TabContext value={tabValue}>
        <Paper sx={{ width: "100%" }}>
          <Grid container>
            <Grid item xs={6}>
            

          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            orientation="vertical"
          >
            {similarData?.map((item, index) => {
              return (
                <Tab
                  label={item.Category}
                  value={index + 1}
                  sx={{
                    alignItems: "flex-start",
                  }}
                  TabIndicatorProps={{
                    fontSize: "big",
                  }}
                />
              );
            })}
          </Tabs>
          </Grid>
          <Grid item xs={6}>
          {similarData?.map((item, index) => {
          return (
            <TabPanel value={index + 1}>
              {item.Data?.map((item, index) => {
                return (
                 
                  <Typography variant="body2" sx={{ m: 0.3 }}>
                    {item.Text}
                  </Typography>
                );
              })}
            </TabPanel>
          );
        })}
          </Grid>
          </Grid>
        </Paper>
       
      </TabContext>
    );
  }

  function handleChange(e, newValue) {
    setTabValue(newValue);
  }
  return (
    <Dialog
      fullScreen
      open={data ? true : false}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ px: 3, py: 0.7 }}>
        {/* <Toolbar> */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack
            direction="row"
            alignItems="ceter"
            justifyContent="center"
            spacing={1}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="h6">
              {data.document_name}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="center"
            spacing={1}
          >
            <Tooltip title="Print">
              <IconButton>
                <PrintIcon
                  onClick={() => {
                    printPdf(S3ImageBaseUrl + doc.document_file);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton
                onClick={() => {
                  downloadURI(S3ImageBaseUrl + doc.document_file);
                }}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        {/* </Toolbar> */}
      </AppBar>
      {comprehend_medical_is_loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box
          sx={{ bgcolor: "background.paper", width: "100%", height: "100%" }}
          className="section-to-print"
        >
          <Grid container>
            <Grid item xs={8} md={8} lg={8} 
            sx={{
              borderRight: 1,
              mt: 7,
              borderRightColor: "divider",
             
            }}
            >
              {/* <PdfDist
                style={{ height: "100vh", marginTop: "200px" }}
                pdfUrl={S3ImageBaseUrl + doc.document_file}
                highlightWord={highlightWord}
              /> */}
              {/* <PdfHighlighterR
                style={{ height: "100vh", marginTop: "200px" }}
                pdfUrl={S3ImageBaseUrl + doc.document_file}
                highlightWord={highlightWord}
              /> */}
            </Grid>

            <Grid item xs={4} md={4} lg={4} sx={{ mt: 3 }}>
              <Paper elevation={0}>
                <Stack sx={{ p: 5 }} spacing={1}>
                  {/* {renderTabs(data)} */}
                  <ComprehendTree
                  data={data}
                  />



                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const downloadURI = (uri, name) => {
  var a = document.createElement("A");
  a.href = uri;
  a.download = uri.substr(uri.lastIndexOf("/") + 1);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const printPdf = (url) => {
  var content =
    '<html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en">' +
    '<head><link href="/css/print.css" media="all" rel="stylesheet" type="text/css"></head>' +
    '<body><img src="' +
    url +
    '" />' +
    '<script type="text/javascript">function printPage() { window.focus(); window.print();return; }</script>' +
    "</body></html>";

  var newIframe = document.createElement("iframe");
  newIframe.width = "100%";
  newIframe.height = "auto";
  newIframe.src = "about:blank";
  document.body.appendChild(newIframe);

  newIframe.contentWindow.contents = content;
  newIframe.src = 'javascript:window["contents"]';

  newIframe.onload = function () {
    newIframe.contentWindow.printPage();
  };
};
