import React from "react";
import Button from "../../../components/common/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
// import AddByUniqueCode from "./AddByUniqueCode";

const AddDoctorDirectoryModal = (props) => {
   const { handelOpenAddByUniqueCodeModal, openUniqueCode , handelCloseAddByUniqueCodeModal ,addDoctorsByCode , loading} = props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        disableElevation
        variant="contained"
        sx={{ borderRadius: "50px" }}
        onClick={handleClickOpen}
      >
        Add Doctor Directory
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        // maxWidth={"xl"}
        // fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Stack
          spacing={2}
          sx={{ justifyContent: "center", alignItems: "center", m: 4 }}
        >
          <Typography variant="h6">Add Provider</Typography>
          {/* <AddByUniqueCode /> */}

          <Button
            sx={{ mt: 2 }}
            disableElevation
            variant="contained"
            onClick={() => {
              handleClose();
              handelOpenAddByUniqueCodeModal();
            }}
            children="By Unique Code"
          />
          <Button
            sx={{ mt: 2 }}
            disableElevation
            variant="contained"
            onClick={() => navigate("/add-update-doctor-directory")}
            children="Manually"
          />
        </Stack>
      </Dialog>
    </>
  );
};

export default AddDoctorDirectoryModal;
