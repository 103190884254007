import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

const KpiCards = (props) => {
  const { data, loading } = props;

  return (
    <Paper elevation={0} sx={{ alignItems: "center", p: 3, width: "100%", boxShadow: 1 }}>
      <Stack direction="row" spacing={2}>
        {loading ? (
          <Skeleton variant="circular" width={57} height={57} />
        ) : (
          <img src={data.img} width={57} height={57} alt={data.alt} />
        )}

        <Stack>
          {loading ? (
            <Skeleton variant="text" width={70} />
          ) : (
            <Typography
              variant="h5"
              sx={{ color: "#1D9BFF", fontWeight: "bold" }}
            >
              {data.count}
            </Typography>
          )}
          {loading ? (
            <Skeleton variant="text" width={150} />
          ) : (
            <Typography variant="subtitle1" sx={{ color: "#808591" }}>
              {data.title}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default KpiCards;
