import React from "react";
import Grid from "@mui/material/Grid";
import Button from "../common/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { Paper, TextField, Typography } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@mui/icons-material/Phone';

const validateForm = yup.object().shape({

    query: yup.string().trim().required("Please enter your query"),
});

const FeedbackComponent = (props) => {
    const {
        feedback_add_loading,
        addFeedback,
    } = props;

    const {
        FirstName,
        LastName,
        mobile,
    } = props.data;

    const onSubmit = async (values) => {
        addFeedback(values);


    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            query: ""
        },
        onSubmit: (values) => {
            onSubmit(values);
           
            formik.resetForm();

            

        },
        validationSchema: validateForm,
    });


    return (
        <>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Grid container sx={{ m: 1 }} spacing={2}>
                    <Typography variant="h6" gutterBottom component="div">
                        Help & Support
                    </Typography>
                </Grid>
                <Grid container sx={{ mt: 1 ,
                display: "flex",
                justifyContent: "center", }} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Send us an e-mail"
                            variant="outlined"
                            type="text"
                            InputProps={{
                                readOnly: true,
                                startAdornment: <InputAdornment position="start"><EmailOutlinedIcon
                                sx={{ backgroundColor: '#F8F8F8', color : '#438FCD' }} /></InputAdornment>,
                              
                              }}

                             
                             onClick={() => window.location = 'mailto:support@myhipaasafe.com'}

                            value={"support@myhipaasafe.com"}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: 1 ,
                display: "flex",
                justifyContent: "center", 
                
                }} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="You can call us"
                            variant="outlined"
                            type="text"

                            InputProps={{
                                readOnly: true,
                                startAdornment: <InputAdornment position="start"><PhoneIcon
                                sx={{ backgroundColor: '#F8F8F8', color : '#438FCD' }}
                                /></InputAdornment>,
                              }}

                             


                            value={"+1 800-709-9580"}
                        />
                    </Grid>
                </Grid>

                <Grid container sx={{ m: 1, mt: 4 }} spacing={2}>
                    <Typography variant="h6" gutterBottom component="div">
                        Feedback
                    </Typography>
                </Grid>
                <form onSubmit={formik.handleSubmit}>

                    <Grid container sx={{ mt: 1 ,
                display: "flex",
                justifyContent: "center", }} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Mobile"
                                variant="outlined"
                                type="text"
                                InputProps={{
                                    readOnly: true,
                                  }}
                                value={mobile}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 1 ,
                display: "flex",
                justifyContent: "center", }} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                variant="outlined"
                                type="text"
                                InputProps={{
                                    readOnly: true,
                                  }}
                                value={FirstName}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 1 ,
                display: "flex",
                justifyContent: "center", }} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                variant="outlined"
                                type="text"
                                
                                value={LastName}
                             
                              InputProps={{
                                readOnly: true,
                              }}


                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 1 ,
                display: "flex",
                justifyContent: "center", }} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Write Your Comments Here"
                                variant="outlined"
                                type="text"
                              
                                multiline
                                onChange={formik.handleChange("query")}
                                onBlur={formik.handleBlur("query")}
                                value={formik?.values?.query}
                                error={
                                    !formik.touched.query && Boolean(formik.errors.query)
                                }
                                helperText={!formik.touched.query && formik.errors.query}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 1 ,
                display: "flex",
                justifyContent: "center", }} spacing={2}>
                        <Grid item xs={4}>
                            <Button
                                type="submit"
                                disableElevation
                                variant="contained"
                                disabled={!formik.isValid}
                                loading={feedback_add_loading}
                                fullWidth
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    );
};

export default FeedbackComponent;
