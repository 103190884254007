import { Divider, Grid, Paper } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import PopoverComponent from "../../common/Popover";
import BookedSlotsComponent from "./BookedSlotsComponent";

const ScheduleTimes = ({
  data,
  loading,
  toggleDrawer,
  appointmentHours,
  appointmentSlots,
  viewAppointment,
}) => {
  const myRef = useRef(null);
  const mainRef = useRef(null);
  const [divOffset, setDivOffset] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverData, setPopoverData] = React.useState(null);

  useEffect(() => {
    if (!loading) {
      if (mainRef && mainRef.current) {
        myRef.current.scrollIntoView({ block: "start" });
        setDivOffset(
          mainRef.current.offsetTop ? mainRef.current.offsetTop : divOffset
        );
      }
    }
  }, [loading, divOffset]);

  const handlePopoverOpen = (e, data) => {
    setAnchorEl(e.target);
    setPopoverData(data);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverData(null);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      ref={mainRef}
    >
      <Grid item xs={12}>
        <Paper sx={{ p: 3, borderRadius: "10px" }} elevation={0}>
          <Box>
            <Divider />
            <Grid container sx={{ position: "relative" }}>
              {data?.length > 0 &&
                data.map((dd, index) => (
                  <BookedSlotsComponent
                    key={index}
                    data={dd}
                    view={viewAppointment}
                    handlePopoverOpen={(e) => handlePopoverOpen(e, dd)}
                    handlePopoverClose={handlePopoverClose}
                    open={Boolean(anchorEl)}
                    index={index}
                  />
                ))}

              <PopoverComponent popoverData={popoverData} anchorEl={anchorEl} />
              {appointmentHours.map((hr, index) => (
                <React.Fragment key={format(hr, "hh a")}>
                  {index === 0 && (
                    <div
                      ref={myRef}
                      style={{ height: 0, overflow: "hidden" }}
                    />
                  )}
                  <Grid item xs={1}>
                    <Typography>{format(hr, "hh a")}</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    {appointmentSlots.map((slot, key) => (
                      <Grid
                        key={key}
                        item
                        xs={12}
                        sx={{
                          height: "20px",
                          cursor: "pointer",
                          width: "100%",
                          lineHeight: 0,
                          position: "relative",
                        }}
                        onClick={() => toggleDrawer(true, hr, key)}
                      >
                        <Typography variant="caption" sx={{ pt: 0, pb: 0 }}>
                          {slot}-{parseInt(slot) + 10}
                        </Typography>
                        <Divider />
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Stack>
  );
};

export default ScheduleTimes;
