import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";

const Tags = (props) => {
  const { formik, data } = props;
  const [filter, setFilter] = React.useState("");

  const handleTagChange = (event, newValue) => {
    if (newValue.length > 5) {
      newValue = newValue.slice(0, 5); // Limit the number of tags to 5
    }
    formik.setFieldValue("tags", newValue);
  };

  return (
    <Autocomplete
      id="tags"
      name="tags"
      fullWidth
      multiple
      options={data}
      getOptionLabel={(option) => option.title}
      onChange={handleTagChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.title}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tags"
          fullWidth
          error={formik.touched.tags && Boolean(formik.errors.tags)}
          helperText={formik.touched.tags && formik.errors.tags}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      onInputChange={(event, newInputValue) => setFilter(newInputValue)}
      inputValue={filter}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      filterSelectedOptions
      value={formik?.values?.tags ? formik?.values?.tags : []}
    />
  );
};

export default Tags;
