import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  GET_FEEDBACK,statusFeedback
} from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  return {
    
    get_feedback_is_loading: state.user.get_feedback_is_loading,
    get_feedback_data: state.user.get_feedback_data,
    status_feedback_is_loading: state.user.status_feedback_is_loading,
    status_feedback_data: state.user.status_feedback_data,


   
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GET_FEEDBACK,
      statusFeedback,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
