import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import { format, parseISO } from "date-fns";

import DocumentsComponent from "../../../components/doctor/patient/Documents";
import DocumentsRequests from "../../../components/doctor/patient/DocumentsRequests";
import PatientDataComponent from "../../../components/doctor/patient/PatientDataComponent";
import RequestDocument from "../../../components/doctor/patient/RequestDocument";
import DocumentDialog from "../../../components/doctor/patient/DocumentDialog";
import DocumentDialogReport from "../../../components/doctor/patient/DocumentDialogReport";
import DocumentSkeleton from "../../../components/common/DocumentSkeleton";
import RequestSkeleton from "../../../components/common/RequestSkeleton";
import DocumentRequestSkeleton from "../../../components/common/DocumentRequestSkeleton";
import MyNotesSkeleton from "../../../components/common/NotesSkeleton";
import AddPatientNotes from "../../../components/doctor/patient/AddPatientNotes";

class PatientContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      topNavigationIndex: 0,
      dialogData: null,
      comprehendMedicalData: null,
    };
    // this.setDate = this.setDate.bind(this);
  }
  fetchData() {
    if (this.state.topNavigationIndex === 0) {
      this.props.fetchPatientDocuments({ guid: this.props.guid });
    } else if (this.state.topNavigationIndex === 1) {
      this.props.requestDocumentsUrl();
    } else if (this.state.topNavigationIndex === 2) {
      this.props.getPatientNotesUrl({ guid: this.props.guid });
    }
  }
  componentDidMount() {
    this.fetchData();
    // this.props.removeRequestDocs();
  }

  handleChange = (event, newValue) => {
    this.setState({ topNavigationIndex: newValue }, () => {
      this.fetchData();
    });
  };
  handleChangeIndex = (index) => {
    this.setState({ topNavigationIndex: index }, () => {
      this.fetchData();
    });
  };

  openDocument = (docs) => {
    // if (docs.document_extension === "pdf") {
    //   window.open(S3ImageBaseUrl + docs.document_file, "_blank").focus();
    // } else {
    this.setState({ dialogData: docs });
    // }
  };

  handleRequestDocumentSubmit = (hospital_reports_id) => {
    const data = { guid: this.props.guid, hospital_reports_id };
    this.props.requestPatientDocuments({ data });
  };

  handleNotesSubmit = (values) => {
    this.props.addPatientNotes(values);
  };

  removeRequestDocs = (values) => {
    this.props.removeRequestDocs({ data: { request_ids: [values] } });
  };

  updateAppointmentAndRefresh = async (payload) => {
    await this.props.updateDoctorAppointment(payload);
  };

  ComprehendMedicalReport = async (values) => {
    const s3Url = "hs/user/reports/" + values.document_file;
    this.setState({ comprehendMedicalData: values });
    await this.props.ComprehendMedical({ s3Url });
  };

  render() {
    const { topNavigationIndex } = this.state;

    const {
      theme,
      guid,
      patientData,
      patient_documents_loading,
      patient_documents_data,
      patient_documents_request_data,
      update_appointment_is_loading,
      patient_notes_loading,
      patient_notes_data,
      add_notes_is_loading,
      request_documents_loading,
      request_documents_data,
      reschedule,
      comprehend_medical_data,
      comprehend_medical_is_loading,
      // remove_request_docs_is_loading,
    } = this.props;

    return (
      <Box
        sx={{
          bgcolor: "background.paper",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
        }}
      >
        {/* <div className="sticky_patient" style={theme?.palette?.mode === "dark" ? {background: "#000"} : {background: "#fff"}}> */}
        {patientData ? (
          <PatientDataComponent
            theme={theme}
            update={(payload) => this.updateAppointmentAndRefresh(payload)}
            loading={update_appointment_is_loading}
            data={patientData}
            reschedule={() => reschedule()}
            handleChatOpen={() => {
              // this.props.set_patient_drawer_state({ show: false });
              this.props.set_chat_window_ids({ guid });
              this.props.close();
            }}
          />
        ) : null}
        {/* </div> */}

        {/* <div className="sticky-patient-header" style={theme?.palette?.mode === "dark" ? {background: "#000"} : {background: "#fff"}}> */}
        <AppBar position="static">
          <Tabs
            value={topNavigationIndex}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs examxple"
          >
            <Tab label="Documents" {...a11yProps(0)} />
            <Tab label="Request" {...a11yProps(1)} />
            <Tab label="Notes" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        {/* </div> */}
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={topNavigationIndex}
          onChangeIndex={this.handleChangeIndex}
          containerStyle={{ height: "100%" }}
          style={{ height: "100%" }}
        >
          <TabPanel value={topNavigationIndex} index={0} dir={theme.direction}>
            <Stack
              justifyContent={"center"}
              spacing={1}
              sx={{ overflow: "auto" }}
            >
              {patient_documents_loading ? (
                <DocumentRequestSkeleton />
              ) : (
                <GenerateDocumentsRequest
                  data={patient_documents_request_data}
                  removeRequestDocs={this.removeRequestDocs}
                />
              )}
              <Divider />
              {patient_documents_loading ? (
                <DocumentSkeleton />
              ) : (
                <GenerateDocuments
                  data={patient_documents_data}
                  openDocument={(docs) => this.openDocument(docs)}
                  ComprehendMedicalReport={(values) =>
                    this.ComprehendMedicalReport(values)
                  }
                />
              )}
            </Stack>
          </TabPanel>
          <TabPanel
            value={topNavigationIndex}
            index={1}
            dir={theme.direction}
            style={{ height: "100%", overflow: "hidden", position: "relative" }}
          >
            {request_documents_loading ? (
              <RequestSkeleton />
            ) : (
              <RequestDocument
                selectedData={patient_documents_request_data}
                data={request_documents_data}
                loading={request_documents_loading}
                handleSubmit={(hospital_reports_id) => {
                  this.handleRequestDocumentSubmit(hospital_reports_id);
                }}
              />
            )}
          </TabPanel>
          <TabPanel
            value={topNavigationIndex}
            index={2}
            dir={theme.direction}
            style={{ height: "100%", overflow: "hidden", position: "relative" }}
          >
            <Stack sx={{ overflow: "auto", height: "calc(100% - 145px)" }}>
              {patient_notes_loading ? (
                <MyNotesSkeleton />
              ) : (
                <GeneratePatientNotes
                  data={patient_notes_data}
                  add_notes_is_loading={add_notes_is_loading}
                  handleNotesSubmit={this.handleNotesSubmit}
                  guid={guid}
                />
              )}
            </Stack>
            <Stack sx={{ position: "absolute", bottom: "10px", width: "100%" }}>
              <AddPatientNotes
                loading={add_notes_is_loading}
                addPatientNotes={this.handleNotesSubmit}
                guid={guid}
              />
            </Stack>
          </TabPanel>
        </SwipeableViews>
        {/* dialog component */}
        {this.state.dialogData ? (
          <DocumentDialog
            data={this.state.dialogData}
            close={() => this.setState({ dialogData: null })}
          />
        ) : null}

        {comprehend_medical_data &&
        
        this.state.comprehendMedicalData ? (
          <DocumentDialogReport
            doc={this.state.comprehendMedicalData}
            comprehend_medical_is_loading={comprehend_medical_is_loading}
            data={comprehend_medical_data}
            close={() => this.setState({ comprehendMedicalData: null })}
          />
        ) : null}
      </Box>
    );
  }
}

export default PatientContainer;

const GenerateDocuments = ({ data, openDocument, ComprehendMedicalReport }) => {
  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
        sx={{ overflowY: "auto" }}
      >
        {data && data[0] ? (
          data.map((groupedDocument, index) => {
            return (
              <Stack sx={{ width: "100%" }} key={"grouped_document_" + index}>
                <Typography>{groupedDocument.key}</Typography>
                <DocumentsComponent
                  documents={groupedDocument.data}
                  openDocument={(docs) => openDocument(docs)}
                  ComprehendMedicalReport={(values) =>
                    ComprehendMedicalReport(values)
                  }
                />
              </Stack>
            );
          })
        ) : (
          <Box
            sx={{
              bgcolor: "#1976d214",
              width: "100%",
              p: 2,
              borderRadius: "10px",
            }}
          >
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              <img src="/assets/images/no_data_found.svg" alt="no_data_found" />
            </Stack>

            <Stack spacing={1} sx={{ width: "100%", alignItems: "center" }}>
              No Documents available
            </Stack>
          </Box>
        )}
      </Stack>
    </>
  );
};

const GenerateDocumentsRequest = ({ data, removeRequestDocs }) => {
  return (
    <Stack spacing={1}>
      {data && data[0] ? (
        <Typography variant="body1">Documents Request</Typography>
      ) : null}
      {data && data[0]
        ? data.map((document_request, index) => {
            return (
              <Stack key={"document_request_" + index}>
                <DocumentsRequests
                  id={document_request.id}
                  title={document_request.title}
                  removeRequestDocs={removeRequestDocs}
                />
              </Stack>
            );
          })
        : null}
    </Stack>
  );
};

const GeneratePatientNotes = ({
  data,
  add_notes_is_loading,
  handleNotesSubmit,
  guid,
}) => {
  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        {data && data[0] ? (
          data.map((note, index) => {
            return (
              <Box
                key={"notes_" + index}
                sx={{
                  bgcolor: "#1976d214",
                  width: "100%",
                  p: 2,
                  borderRadius: "10px",
                }}
              >
                <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="caption">{note.DoctorName}</Typography>
                    <Typography variant="caption">
                      {format(parseISO(note.createdAt), "MMMM dd, yyyy")}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography>{note.NoteText}</Typography>
                  </Stack>
                </Stack>
              </Box>
            );
          })
        ) : (
          <Box
            sx={{
              bgcolor: "#1976d214",
              width: "100%",
              p: 2,
              borderRadius: "10px",
            }}
          >
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              <img src="/assets/images/no_data_found.svg" alt="no_data_found" />
            </Stack>

            <Stack spacing={1} sx={{ width: "100%", alignItems: "center" }}>
              No Notes available
            </Stack>
          </Box>
        )}
      </Stack>
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            marginLeft: 2,
            marginRight: 2,
            overflow: "hidden",
            height: "100%",
            position: "relative",
            paddingTop: 2,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};
