
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addFeedback } from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
    return {
        feedback_add_loading: state.user.feedback_add_loading,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addFeedback
        },
        dispatch
    );

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
