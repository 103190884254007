import React from "react";
import Feedback from "../../../components/admin/Feedback";
import SupportHeader from "../../../components/support/dashboard/SupportHeader";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CommonDetailDialog from "../../../components/admin/CommonDetailDialog";

class FeedbackContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      id: "",
      msg: "",
    };
  }

  componentDidMount() {
    this.props.GET_FEEDBACK();
  }

  handleOpen = (row) => {
    const id = row.id;
    const msg = row.query;
    this.setState({ open: true, id: id, msg: msg });
  };

  handleOnChange = (id, value) => {
    let payload = {
      id: id,
      status: value,
    };

    this.props.statusFeedback(payload);

    setTimeout(() => {
      this.props.GET_FEEDBACK();
    }, 1000);
  };

  render() {
    const { get_feedback_is_loading, get_feedback_data } = this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SupportHeader title="Feedback Receipts" />
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <Paper elevation={0}>
                <Stack sx={{ p: 5 }} spacing={1}>
                  <Feedback
                    tableDetails={get_feedback_data}
                    loading={get_feedback_is_loading}
                    handleOpen={this.handleOpen}
                    handleOnChange={this.handleOnChange}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Grid>
        </Grid>

        <CommonDetailDialog
          open={this.state.open}
          Data={this.state.msg}
          handleClose={() => this.setState({ open: false })}
        />
      </>
    );
  }
}

export default FeedbackContainer;
