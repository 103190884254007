import React from "react";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import getStripe from "../../../components/public/AddDoctor/Stripe";
import Typography from "@mui/material/Typography";

class PaymentContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      paymentInProgress: true,
      paymentCompleted: false,
      payment_session_id: "",
    };
  } 

  componentDidMount() {
    const payment_session_id = this.getParameterByName("session_id");
    this.setState({ payment_session_id });
    this.handleCheckout();
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    const searchParams = new URLSearchParams(new URL(url).search);
    const result = searchParams.get(name) || "";
    return result;
  }

  async handleCheckout() {
    const url = window.location.href;
    const email = new URL(url).searchParams.get("email");

    if (email) {
      try {
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
          lineItems: [
            {
              price: "price_1MFNqOJx1pX7Qf6zoiOqHju1",
              quantity: 1,
            },
          ],
          mode: "subscription",
          successUrl: `https://partner.hipaasafe.app/payment?session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: `https://partner.hipaasafe.app/register/doctor/cancel`,
          customerEmail: email,
        });
        console.warn(error?.message);
      } catch (error) {
        console.log("Stripe Checkout Error:", error);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.payment_session_id !== prevState.payment_session_id) {
      this.props.STRIPE_DOCTOR_VERIFY({
        id: this.state.payment_session_id,
      });
      this.setState({ paymentInProgress: false, paymentCompleted: true });
    }
  }

  render() {
    const { paymentInProgress, paymentCompleted } = this.state;

    return (
      <>
        {paymentInProgress && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Typography variant="h5" align="center" color="textSecondary">
              Payment in progress. Please wait...
            </Typography>
          </Grid>
        )}

        {paymentCompleted && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={12} textAlign="center">
              <CheckCircleIcon
                sx={{
                  color: "green",
                  fontSize: 100,
                }}
              />

              <Typography
                variant="h5"
                align="center"
                color="green"
                gutterBottom
              >
                Payment done successfully.
              </Typography>
              <Typography variant="body1" align="center" color="textSecondary">
                Please click the Done/Back button to login.
              </Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

export default PaymentContainer;
