import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  set_chat_window_ids,
  setTabValue,
} from "../../../store/common/commonSlice";
import {
  queryDoctorMyPatients,
  queryDoctorMyTeams,
} from "../../../store/query/querySlice";
import {
  getDoctorDataUrl,
  getPatientsByNameOrPhone,
  getPatientDataUrl,
} from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  return {
    DoctorId: state.common.DoctorId,

    tab_value_state: state.common.tab_value_state,

    doctor_my_teams_loading: state.query.doctor_my_teams_loading,
    doctor_my_teams_data: state.query.doctor_my_teams_data,

    doctor_my_patients_loading: state.query.doctor_my_patients_loading,
    doctor_my_patients_data: state.query.doctor_my_patients_data,

    doctors_data_is_loading: state.user.doctors_data_is_loading,
    doctors_data: state.user.doctors_data,

    patients_by_search_loading: state.user.patients_by_search_loading,
    patients_by_search_data: state.user.patients_by_search_data,

    patients_list_is_loading: state.user.patients_list_is_loading,
    patients_data: state.user.patients_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTabValue,
      queryDoctorMyTeams,
      queryDoctorMyPatients,
      set_chat_window_ids,
      getDoctorDataUrl,
      getPatientsByNameOrPhone,
      getPatientDataUrl,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
