
import React from "react";

import Button from "../../common/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Review = ({
  data,
  address,
  newOrgData,
  handlefinalSubmit,
  orgId,
  organization_type,
  goBackToStep1,
  gender_data,
}) => {

  return (
    <form>
      <Grid
        container
        spacing={2}
        sx={{
          margin: "auto",
          width: "90%",
          height: "100%",
          display: "flex",
          justifyContent: "space-around",
          marginBottom: 5,
        }}
      >
        <Grid item xs={11} lg={8}>
          <IconButton
            onClick={goBackToStep1}
            sx={{
              position: "relative",
              top: 10,
              left: 0,
              color: "primary.main",
              fontSize: "1rem",
              padding: 1,
            }}
          >
            <ArrowBackIcon
              sx={{
                fontSize: "1.5rem",
              }}
            />
           (Go Back To Edit)
          </IconButton>

          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Review Your Information
          </Typography>
        </Grid>

        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="First Name"
            value={data?.FirstName}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <PersonOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Last Name"
            value={data?.LastName}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <PersonOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {organization_type === "Add" ? (
          <Grid item xs={11} lg={8}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Organization Name"
              value={newOrgData?.name}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <PersonOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ) : (
          <>
            {organization_type === "individual" ? (
              <></>
            ) : (
              <Grid item xs={11} lg={8}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Organization Id"
                  value={orgId}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "#B2B9CC" }}
                      >
                        <PersonOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Email"
            value={data?.Email}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <EmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={data?.country_code + data?.Phone}
            label="Contact Number"
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <PhoneAndroidIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Sex"
           value={ gender_data?.filter((item) => item?.key === data?.Sex).map((item) => item?.value)}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        { data.type === "Doctor" ? (

        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="NPI Number"
            value={data?.npi_no}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <PersonOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        ) : (
          <>
          </>
        )}
        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Address1"
            value={address.address_1 ? address.address_1 : data.Address1}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <FmdGoodIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="City"
            value={address.city ? address.city : data.City}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <FmdGoodIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="State"
            value={address.state ? address.state : data.State}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <FmdGoodIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {address.country_name ? (
           <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={address?.country_name}
            label="Country"
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <FmdGoodIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
  ):
  (
<></>
  )
}
        <Grid item xs={11} lg={8}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Zip Code"
            value={address.postal_code ? address.postal_code : data.Zipcode}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <FmdGoodIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={11} lg={8} sx={{ mb: 2 }}>
          <Button
            style={{
              width: "100%",
              marginTop: "40px",
              margin: "auto",
            }}
            disableElevation
            variant="contained"
            onClick={() => handlefinalSubmit()}
          >
            REGISTER
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Review;
