import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken } from "firebase/messaging";
import { getAnalytics, logEvent } from "firebase/analytics";
import { setCookie } from "../cookies";

const firebaseConfig = {
  apiKey: "AIzaSyCyk3JdJDmBpL758cN2n-Cg8hM4vnLjM2o",
  authDomain: "hipaasafe-25d51.firebaseapp.com",
  projectId: "hipaasafe-25d51",
  storageBucket: "hipaasafe-25d51.appspot.com",
  messagingSenderId: "164650831436",
  appId: "1:164650831436:web:8d1cca5e552c94314b2ee9",
  measurementId: "G-0CRESQTS6X",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const analytics = getAnalytics(firebaseApp);

logEvent(analytics, "notification_received");

export const generateFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BCx21ROfM1b9xWU1h7KXbSNosr30MwD-WNuN9i1vd2VxJ9M5mSVo6CRAOMMobyXaKw2hPGs0SBXtwgb5MbeFgXs",
    });
    if (currentToken) {
      console.log("== FCM TOKEN GENERATED ==");
      setCookie("fcm_token", currentToken);
      return currentToken;
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return false;
      // shows on the UI that permission is required
    }
  } catch (error) {
    console.log(error.message);
    throw new Error(error.message);
  }
};

export const deleteFCMToken = async () => {
  try {
    const res = await deleteToken(messaging);
    console.log(res, "delete token res");
  } catch (error) {
    console.log(error.message, "delete token");
    throw new Error(error.message);
  }
};

// export const getTokenFunction = (setTokenFound) => {
//   return getToken(messaging, {
//     vapidKey:
//       "BCx21ROfM1b9xWU1h7KXbSNosr30MwD-WNuN9i1vd2VxJ9M5mSVo6CRAOMMobyXaKw2hPGs0SBXtwgb5MbeFgXs",
//   })
//     .then(async (currentToken) => {
//       if (currentToken) {
//         console.log("current token for client: ", currentToken);
//         setCookie("fcm_token", currentToken);
//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {
//         console.log(
//           "No registration token available. Request permission to generate one."
//         );
//         // shows on the UI that permission is required
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token. ", err);
//       // catch error while creating client token
//     });
// };
