import React from "react";
import Table from "../../components/common/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const SupportUser = (props) => {
  const { tableDetails, loading , toggleDialog} = props;


  const columns = [
    {
      title: "uid",
    },
    {
      title: "Name",
    },
    {
      title: "Email Id",
    },
    {
      title: "Organization",
    },
    {
      title: "Action",
    },
  ];

  const renderTableData = tableDetails.length ? (
    tableDetails.map((row, index) => {
     
      return (
        <TableRow key={index} sx={{ "td, th": { border: 0, padding: "10px" } }}>
          <TableCell align="center" component="th" scope="row">
            {row.uid}
          </TableCell>
          <TableCell align="center">{row.name}</TableCell>
          <TableCell align="center">{row.email}</TableCell>
          <TableCell align="center">
            <Box sx={{ display: "flex", flexDirection: "column" , justifyContent: "space-around"}}>
              <Typography variant="body2">
                {row?.organization_details?.name}
              </Typography>
              <Typography variant="body2">
                Id {row?.organization_details?.id}
              </Typography>

              <Typography variant="body2" sx={{ color: "#1D9BE6" }}>
                Unique Id {row.organization_details?.unique_id}
              </Typography>
            </Box>
          </TableCell>
          <TableCell align="center">
            <EditOutlinedIcon
              onClick={() => {
                toggleDialog(row);
              }}
              sx={{ cursor: "pointer" }}

            />
          </TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
      <TableCell align="center">Data not found</TableCell>
    </TableRow>
  );

  return (
    <Table columns={columns} tableData={renderTableData} loading={loading} />
  );
};

export default SupportUser;
