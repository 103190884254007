import TextField from "@mui/material/TextField";

import NewReleasesIcon from "@mui/icons-material/NewReleases";

const DetailsTextField = ({
  label,
  name,
  value,
  Icon = NewReleasesIcon,
  ...others
}) => {
  return (
    <TextField
      type="text"
      sx={{ width: "70%" }}
      variant="filled"
      label={label}
      name={name}
      value={value ? value : "-"}
      multiline={true}
      disabled={true}
      InputProps={{
        startAdornment: <Icon />,
      }}
      {...others}
    />
  );
};

export default DetailsTextField;
