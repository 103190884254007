import { axios } from "../../helpers/axios";

import { ADD_PATIENT_NOTES_URL, GET_PATIENT_NOTES_URL } from "./notesConstants";

export const api_get_patient_notes_url = async (params) => {
  return axios.get(GET_PATIENT_NOTES_URL, { params });
};

export const api_add_patient_notes_url = async (data) => {
  return axios.post(ADD_PATIENT_NOTES_URL, data);
};
