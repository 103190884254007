import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import QRCodeComponent from "../../common/QRCode";
import { generateDoctorQRCode } from "../../../helpers/utils";
import Button from "../../common/Button";
import { useState } from "react";

const QRCode = ({
  data,
  index = "single",
  handleDownloadClick,
  loading = false,
}) => {
  const {
    FirstName,
    LastName,
    ProviderId,
    organization_id,
    organization_details_doctor,
  } = data;
  const [downlaodLoading, setDownloadLoading] = useState(false);

  const handleLocal = async () => {
    setDownloadLoading(true);
    await handleDownloadClick(data);
    setDownloadLoading(false);
  };

  return (
    <>
      <div id={"qr_downloadable_div_" + index}>
        <Stack sx={{ width: "100%" }} spacing={1}>
          {/* <Typography variant="h6">Organization and Other Details</Typography> */}
          <Paper elevation={0}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              spacing={2}
              p={2}
            >
              <Typography variant="h5">
                <b>{FirstName + " " + LastName}</b>
              </Typography>
              <Typography variant="h6">
                Organization ID: <b>{organization_details_doctor?.unique_id}</b>
              </Typography>
              <Stack>
                {/* <img
                  src={S3QRCodeBaseUrl + qr_code}
                  width={100}
                  height={100}
                  alt="doctor_qr_code"
                /> */}
                <QRCodeComponent
                  value={generateDoctorQRCode(
                    ProviderId,
                    organization_id,
                    organization_details_doctor?.unique_id
                  )}
                />
              </Stack>
              <Stack alignItems={"center"} alignContent={"center"}>
                <Typography variant={"h5"}>
                  Provider's Code :{" "}
                  {ProviderId + organization_details_doctor?.unique_id}
                </Typography>
                <Typography variant={"caption"}>
                  Scan this code with HipaaSafe App to book your appointment.
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </div>
      <Stack justifyContent="center" alignItems={"center"} my={1}>
        <Button
          onClick={() => handleLocal(data)}
          children={<Typography>Download as PDF</Typography>}
          loading={downlaodLoading}
        />
      </Stack>
    </>
  );
};

export default QRCode;
