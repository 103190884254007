import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAuth } from "../../helpers/cookies";
import {
  api_doctor_my_teams,
  api_doctor_my_patients,
  api_appointment_history,
  api_dashboard_kpi_counts,
  api_support_dashboard_kpi,
  api_doctor_nurse_list,
  api_speciality_list_url,
  api_state_list_url,
  api_city_list_url,
  api_request_documents_url,
  api_doctor_directory_url,
  api_nurse_doctor_map_list,
  api_nurse_doctor_map_update,
  api_dashboard_stats,
  api_common_list,
  add_speciality_api,
  update_speciality_api,
  add_report_api,
  update_report_api,
  api_common_add,
  api_common_update,
  admin_doctor_mapping_add_api,
  admin_doctor_mapping_update_api,
  admin_doctor_mapping_list_api,
  admin_doctor_list_api,
} from "./queryApis";
import {
  parser_doctor_my_teams,
  parser_doctor_my_patients,
  parser_appointment_history,
  parser_kpi_dashboard_count,
  parser_support_dashboard_kpi,
  parser_doctor_nurse_list,
  parser_speciality_list_url,
  parser_state_list_url,
  parser_city_list_url,
  parser_request_documents_url,
  parser_doctor_directory_url,
  parser_nurse_doctor_map_list,
  parser_dashboard_stats,
  parser_org_doctor_map_list,
} from "./queryParser";

export const queryDoctorMyTeams = createAsyncThunk(
  "queryDoctorMyTeams",
  async (payload, { getState }) => {
    try {
      const { ignore_nurse ,organization_id} = payload || {};
      const { doctor_my_teams_page: page, doctor_my_teams_limit: limit } =
        getState().query;

      const response = await api_doctor_my_teams({ page, limit, ignore_nurse ,organization_id });
      const data = parser_doctor_my_teams(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const queryDoctorMyPatients = createAsyncThunk(
  "queryDoctorMyPatients",
  async (payload, { getState }) => {
    try {
      const { ProviderId: DoctorId } = payload;
      const { doctor_my_patients_page: page, doctor_my_patients_limit: limit } =
        getState().query;
      const response = await api_doctor_my_patients({ page, limit, DoctorId });
      const data = parser_doctor_my_patients(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const queryAppointmentHistoryList = createAsyncThunk(
  "queryAppointmentHistoryList",
  async (payload) => {
    try {
      const { from_date, to_date, DoctorId } = payload;
      const response = await api_appointment_history({
        DoctorId,
        from_date,
        to_date,
      });
      const data = parser_appointment_history(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const queryDoctorDashboardKPICounts = createAsyncThunk(
  "queryDoctorDashboardKPICounts",
  async (payload) => {
    try {
      const { DoctorId } = payload;
      const date = new Date().toISOString();
      const response = await api_dashboard_kpi_counts({
        DoctorId,
        date,
      });
      const data = parser_kpi_dashboard_count(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const queryDcotorDashboardStats = createAsyncThunk(
  "queryDcotorDashboardStats",
  async (payload) => {
    try {
      const { DoctorId, days } = payload;
      const today = new Date().toISOString();
      const response = await api_dashboard_stats({
        DoctorId,
        today,
        days
      });
      const data = parser_dashboard_stats(response, days);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const querySupportDashboardKpi = createAsyncThunk(
  "querySupportDashboardKpi",
  async (payload) => {
    try {
      const { organization_id } = payload;
      const { ProviderId: DoctorId } = isAuth();
      const date = new Date().toISOString();
      const response = await api_support_dashboard_kpi({
        DoctorId,
        date,
        organization_id,  
      });
      const data = parser_support_dashboard_kpi(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const queryDoctorNurseList = createAsyncThunk(
  "queryDoctorNurseList",
  async (payload, { getState , }) => {
    try {
      const { organization_id } = payload;
      const { doctor_my_teams_page: page, doctor_my_teams_limit: limit } =
        getState().query;
      // const ignoreNurse = true;
      const response = await api_doctor_nurse_list({ page, limit , organization_id });
      const data = parser_doctor_nurse_list(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const queryNurseDoctorMappingList = createAsyncThunk(
  "queryNurseDoctorMappingList",
  async (payload) => {
    try {
     
      let { nurse_id , organization_id} = payload;
      const response = await api_nurse_doctor_map_list({ nurse_id , organization_id});
      const data = parser_nurse_doctor_map_list(response);

      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateNurseDoctorMapping = createAsyncThunk(
  "updateNurseDoctorMapping",
  async (payload) => {
    try {
      const response = await api_nurse_doctor_map_update(payload);
      const data = parser_nurse_doctor_map_list(response);

      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getSpecialityList = createAsyncThunk(
  "getSpecialityList",
  async (payload, { getState }) => {
    try {
      const { speciality_list_page: page, speciality_list_limit: limit } =
        getState().user;
      const response = await api_speciality_list_url({ page, limit });
      const data = parser_speciality_list_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getStateList = createAsyncThunk(
  "getStateList",
  async (payload) => {
    try {
      const { country_code } = payload;
      const response = await api_state_list_url({ country_code });
      const data = parser_state_list_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getCityList = createAsyncThunk("getCityList", async (payload) => {
  try {
    const { country_code, state_code } = payload;
    const response = await api_city_list_url({ country_code, state_code });
    const data = parser_city_list_url(response);
    return { data };
  } catch (error) {
    throw new Error(error);
  }
});

export const requestDocumentsUrl = createAsyncThunk(
  "requestDocumentsUrl",
  async (payload) => {
    try {
      const response = await api_request_documents_url(payload);
      const data = parser_request_documents_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const doctorDirectoryUrl = createAsyncThunk(
  "doctorDirectoryUrl",
  async (payload, { getState }) => {
    try {
      const { DoctorId: user_id } = payload;
      const { doctor_directory_page: page, doctor_directory_limit: limit } =
        getState().query;
      const response = await api_doctor_directory_url({ page, limit, user_id });
      const data = parser_doctor_directory_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);
// export const requestDocumentsUrl = createAsyncThunk(
//   "requestDocumentsUrl",
//   async (payload) => {
//     try {
//       console.log(payload);
//       const { guid } = payload;
//       const response = await api_request_documents_url({ guid });
//       return response;
//     } catch (error) {
//       throw new Error(error);
//     }
//   }
// );

export const queryCommonList = createAsyncThunk(
  "queryCommonList",
  async (payload) => {
    try {
      const response = await api_common_list(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addCommonList = createAsyncThunk(
"addCommonList",
async (payload) => {
  try {
    const response = await api_common_add(payload);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}
);


export const updateCommonList = createAsyncThunk(
  "updateCommonList",
  async (payload) => {
    try {
      const response = await api_common_update(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);


export const addSpeciality = createAsyncThunk(
  "addSpeciality",
  async (payload) => {
    try {
      const response = await add_speciality_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateSpeciality = createAsyncThunk(
  "updateSpeciality",
  async (payload) => {
    try {
      const response = await update_speciality_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addReport = createAsyncThunk(
  "addReport",
  async (payload) => {
    try {
      const response = await add_report_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateReport = createAsyncThunk(
  "updateReport",
  async (payload) => {
    try {
      const response = await update_report_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);


export const adminDoctorMappingAdd = createAsyncThunk(
  "adminDoctorMappingAdd",
  async (payload) => {
    try {
      const response = await admin_doctor_mapping_add_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);


export const adminDoctorMappingUpdate = createAsyncThunk(
  "adminDoctorMappingUpdate",
  async (payload) => {
    try {
      const response = await admin_doctor_mapping_update_api(payload);
      const data = parser_org_doctor_map_list(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const adminDoctorMappingList = createAsyncThunk(
  "adminDoctorMappingList",
  async (payload) => {
    try {
      const response = await admin_doctor_mapping_list_api(payload);
      const data = parser_org_doctor_map_list(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);


export const adminDoctorList = createAsyncThunk(
  "adminDoctorList",
  async (payload, { getState }) => {
    try {
      const {organization_id} = payload || {};
      const { doctor_my_teams_page: page, doctor_my_teams_limit: limit } =
        getState().query;
      const response = await admin_doctor_list_api({ page, limit ,organization_id });
      const data = parser_doctor_my_teams(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);



const initialState = {
  //fetch my teams
  doctor_my_teams_loading: false,
  doctor_my_teams_page: 1,
  doctor_my_teams_limit: 15,
  doctor_my_teams_data: [],

  // Fetch My patients
  doctor_my_patients_loading: false,
  doctor_my_patients_page: 1,
  doctor_my_patients_limit: 15,
  doctor_my_patients_data: [],

  // Fetch Appointment history
  doctor_appointment_history_loading: false,
  doctor_appointment_history_data: [],

  // Fetch Dashboard KPI Counts
  dashboard_kpi_count_loading: false,
  dashboard_kpi_count_data: [
    {
      img: "/assets/images/patient.svg",
      alt: "total patient",
      count: 0,
      title: "Total Patients",
      id: "total_patients",
    },
    {
      img: "/assets/images/completed.svg",
      alt: "completed patient",
      count: 0,
      title: "Completed Patients",
      id: "completed_patients",
    },
    {
      img: "/assets/images/waiting.svg",
      alt: "waiting patient",
      count: 0,
      title: "Waiting Patient",
      id: "waiting_patients",
    },
  ],

  // Support Dashboard kpi count
  support_dashboard_kpi_loading: false,
  support_dashboard_kpi_data: [
    {
      img: "/assets/images/doctor.svg",
      alt: "total doctor",
      count: 0,
      title: "Total Doctors",
      id: "total_doctors",
    },
    {
      img: "/assets/images/nurse.svg",
      alt: "total nurse",
      count: 0,
      title: "Total Nurses",
      id: "total_nurses",
    },
  ],

  // Dashboard stats
  dashboard_stats_loading: false,
  dashboard_stats_data: [],

  //fetch Nurse List
  doctor_nurse_list_loading: false,
  doctor_nurse_list_page: 1,
  doctor_nurse_list_limit: 15,
  doctor_nurse_list_data: [],

  //Speciality List
  speciality_is_loading: false,
  speciality_list_page: 1,
  speciality_list_limit: 15,
  speciality_list_data: [],

  //Speciality List
  state_list_is_loading: false,
  state_list_data: [],
  country_code: "",

  // city list
  city_list_is_loading: false,
  city_list_data: [],

  // Request Docs
  request_documents_loading: false,
  request_documents_data: [],

  // Doctor Directory
  doctor_directory_is_loading: false,
  doctor_directory_page: 1,
  doctor_directory_limit: 15,
  doctor_directory_data: [],

  // Nurse Doctor Mapping Data
  nurse_doctor_map_list_is_loading: false,
  nurse_doctor_map_list_data: { leftArray: [], rightArray: [] },

  //Update Doctor Mapping Update Data
  nurse_doctor_map_update_is_loading: false,
  nurse_doctor_map_update_data: { leftArray: [], rightArray: [] },

  // Common List
  common_list_is_loading: false,
  common_list_data: [],


  // add common list
  add_common_list_is_loading: false,
  add_common_list_data: [],


  // update common list

  update_common_list_is_loading: false,
  update_common_list_data: [],
  

  add_speciality_is_loading: false,
  add_speciality_data: [],

  update_speciality_is_loading: false,
  update_speciality_data: [],

  add_report_is_loading: false,
  add_report_data: [],
  update_report_is_loading: false,
  update_report_data: [],

  //doctor org mapping

   // Nurse Doctor Mapping Data
   org_doctor_map_list_is_loading: false,
   org_doctor_map_list_data: { leftArray: [], rightArray: [] },
 
   //Update Doctor Mapping Update Data
   org_doctor_map_update_is_loading: false,
   org_doctor_map_update_data: { leftArray: [], rightArray: [] },

   //admin doctor list
    admin_doctor_list_is_loading: false,
    admin_doctor_list_data: [],



};

export const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    set_state_data_list: (state, action) => {
      const { state_data } = action.payload;
      state.state_list_data = state_data;
    },
    set_country_code: (state, action) => {
      const { country_code } = action.payload;
      state.country_code = country_code;
    },
    set_city_list_data: (state, action) => {
      const { city_list_data } = action.payload;
      state.city_list_data = city_list_data;
    },
  },
  extraReducers: {
    // Fetch my teams
    [queryDoctorMyTeams.pending]: (state, action) => {
      state.doctor_my_teams_loading = true;
    },
    [queryDoctorMyTeams.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.doctor_my_teams_data = data;
      state.doctor_my_teams_loading = false;
    },
    [queryDoctorMyTeams.rejected]: (state, action) => {
      state.doctor_my_teams_loading = false;
    },

    // Fetch my Patients
    [queryDoctorMyPatients.pending]: (state, action) => {
      state.doctor_my_patients_loading = true;
    },
    [queryDoctorMyPatients.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.doctor_my_patients_data = data;
      state.doctor_my_patients_loading = false;
    },
    [queryDoctorMyPatients.rejected]: (state, action) => {
      state.doctor_my_patients_loading = false;
    },

    //Fetch Appointment history
    [queryAppointmentHistoryList.pending]: (state, action) => {
      state.doctor_appointment_history_loading = true;
    },
    [queryAppointmentHistoryList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.doctor_appointment_history_data = data;
      state.doctor_appointment_history_loading = false;
    },
    [queryAppointmentHistoryList.rejected]: (state, action) => {
      state.doctor_appointment_history_loading = false;
    },

    //Fetch dashboard_kpi_count
    [queryDoctorDashboardKPICounts.pending]: (state, action) => {
      state.dashboard_kpi_count_loading = true;
    },
    [queryDoctorDashboardKPICounts.fulfilled]: (state, action) => {
      const { data } = action.payload;
      const newData = [];
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          for (const kpi_count of state.dashboard_kpi_count_data) {
            if (kpi_count.id === key) {
              newData.push({ ...kpi_count, count: data[key] });
            }
          }
        }
      }
      state.dashboard_kpi_count_data = newData;
      state.dashboard_kpi_count_loading = false;
    },
    [queryDoctorDashboardKPICounts.rejected]: (state, action) => {
      state.dashboard_kpi_count_loading = false;
    },

    //Fetch dashboard_kpi_count
    [queryDcotorDashboardStats.pending]: (state, action) => {
      state.dashboard_stats_loading = true;
    },
    [queryDcotorDashboardStats.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.dashboard_stats_data = data;
      state.dashboard_stats_loading = false;
    },
    [queryDcotorDashboardStats.rejected]: (state, action) => {
      state.dashboard_stats_loading = false;
    },

    //Fetch suport_kpi_count
    [querySupportDashboardKpi.pending]: (state, action) => {
      state.support_dashboard_kpi_loading = true;
    },
    [querySupportDashboardKpi.fulfilled]: (state, action) => {
      const { data } = action.payload;
      const newData = [];
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          for (const kpi_count of state.support_dashboard_kpi_data) {
            if (kpi_count.id === key) {
              newData.push({ ...kpi_count, count: data[key] });
            }
          }
        }
      }
      state.support_dashboard_kpi_data = newData;
      state.support_dashboard_kpi_loading = false;
    },
    [querySupportDashboardKpi.rejected]: (state, action) => {
      state.support_dashboard_kpi_loading = false;
    },

    // Fetch Nurse List
    [queryDoctorNurseList.pending]: (state, action) => {
      state.doctor_nurse_list_loading = true;
    },
    [queryDoctorNurseList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.doctor_nurse_list_data = data;
      state.doctor_nurse_list_loading = false;
    },
    [queryDoctorNurseList.rejected]: (state, action) => {
      state.doctor_nurse_list_loading = false;
    },

    //speciality list
    [getSpecialityList.pending]: (state, action) => {
      state.speciality_is_loading = true;
    },
    [getSpecialityList.fulfilled]: (state, action) => {
      let { data } = action.payload;
      state.speciality_list_data = data;
      state.speciality_is_loading = false;
    },
    [getSpecialityList.rejected]: (state, action) => {
      state.speciality_is_loading = false;
    },

    //state list
    [getStateList.pending]: (state, action) => {
      state.state_list_is_loading = true;
    },
    [getStateList.fulfilled]: (state, action) => {
      let { data } = action.payload;
      state.state_list_data = data;
      state.state_list_is_loading = false;
    },
    [getStateList.rejected]: (state, action) => {
      state.state_list_is_loading = false;
    },
    //city list
    [getCityList.pending]: (state, action) => {
      state.city_list_is_loading = true;
    },
    [getCityList.fulfilled]: (state, action) => {
      let { data } = action.payload;
      state.city_list_data = data;
      state.city_list_is_loading = false;
    },
    [getCityList.rejected]: (state, action) => {
      state.city_list_is_loading = false;
    },

    // Request Docs
    [requestDocumentsUrl.pending]: (state, action) => {
      state.request_documents_loading = true;
    },
    [requestDocumentsUrl.fulfilled]: (state, action) => {
      let { data } = action.payload;
      state.request_documents_data = data;
      state.request_documents_loading = false;
    },
    [requestDocumentsUrl.rejected]: (state, action) => {
      state.request_documents_loading = false;
    },

    // Doctor Directory
    [doctorDirectoryUrl.pending]: (state, action) => {
      state.doctor_directory_is_loading = true;
    },
    [doctorDirectoryUrl.fulfilled]: (state, action) => {
      let { data } = action.payload;
      state.doctor_directory_data = data;
      state.doctor_directory_is_loading = false;
    },
    [doctorDirectoryUrl.rejected]: (state, action) => {
      state.doctor_directory_is_loading = false;
    },

    // Nurse Doctor Mapping

    [queryNurseDoctorMappingList.pending]: (state, action) => {
      state.nurse_doctor_map_list_is_loading = true;
    },
    [queryNurseDoctorMappingList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.nurse_doctor_map_list_data = data;
      state.nurse_doctor_map_list_is_loading = false;
    },
    [queryNurseDoctorMappingList.rejected]: (state, action) => {
      state.nurse_doctor_map_list_is_loading = false;
    },

    [updateNurseDoctorMapping.pending]: (state, action) => {
      state.nurse_doctor_map_update_is_loading = true;
    },
    [updateNurseDoctorMapping.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.nurse_doctor_map_list_data = data;
      state.nurse_doctor_map_update_is_loading = false;
    },
    [updateNurseDoctorMapping.rejected]: (state, action) => {
      state.nurse_doctor_map_update_is_loading = false;
    },
    [queryCommonList.pending]: (state, action) => {
      state.common_list_is_loading = true;
    },
    [queryCommonList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.common_list_data = data;
      state.common_list_is_loading = false;
    },
    [queryCommonList.rejected]: (state, action) => {
      state.common_list_is_loading = false;
    },
    [addCommonList.pending]: (state, action) => {
      state.add_common_list_is_loading = true;
    },
    [addCommonList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.add_common_list_data = data;
      state.add_common_list_is_loading = false;
    },
    [addCommonList.rejected]: (state, action) => {
      state.add_common_list_is_loading = false;
    },
    [updateCommonList.pending]: (state, action) => {
      state.update_common_list_is_loading = true;
    },
    [updateCommonList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.update_common_list_data = data;
      state.update_common_list_is_loading = false;
    },
    [updateCommonList.rejected]: (state, action) => {
      state.update_common_list_is_loading = false;
    },
    [addSpeciality.pending]: (state, action) => {
      state.add_speciality_is_loading = true;
    },
    [addSpeciality.fulfilled]: (state, action) => {
      state.add_speciality_is_loading = false;
      state.add_speciality_data = action.payload.data;

    },
    [addSpeciality.rejected]: (state, action) => {
      state.add_speciality_is_loading = false;
    },
    [updateSpeciality.pending]: (state, action) => {
      state.update_speciality_is_loading = true;
    },
    [updateSpeciality.fulfilled]: (state, action) => {
      state.update_speciality_is_loading = false;
      state.update_speciality_data = action.payload.data;
    },
    [updateSpeciality.rejected]: (state, action) => {
      state.update_speciality_is_loading = false;
    },
    [addReport.pending]: (state, action) => {
      state.add_report_is_loading = true;
    },
    [addReport.fulfilled]: (state, action) => {
      state.add_report_is_loading = false;
      state.add_report_data = action.payload.data;
    },
    [addReport.rejected]: (state, action) => {
      state.add_report_is_loading = false;
    },
    [updateReport.pending]: (state, action) => {
      state.update_report_is_loading = true;
    },
    [updateReport.fulfilled]: (state, action) => {
      state.update_report_is_loading = false;
      state.update_report_data = action.payload.data;
    },
    [updateReport.rejected]: (state, action) => {
      state.update_report_is_loading = false;
    },
    [adminDoctorMappingList.pending]: (state, action) => {
      state.admin_doctor_mapping_add_is_loading = true;
    },
    [adminDoctorMappingList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.admin_doctor_mapping_add_is_loading = false;
      state.admin_doctor_mapping_add_data = data;
    },
    [adminDoctorMappingList.rejected]: (state, action) => {
      state.admin_doctor_mapping_add_is_loading = false;
    },
    [adminDoctorMappingUpdate.pending]: (state, action) => {
      state.admin_doctor_mapping_update_is_loading = true;
    },
    [adminDoctorMappingUpdate.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.admin_doctor_mapping_update_is_loading = false;
      state.admin_doctor_mapping_add_data = data;
    },
    [adminDoctorMappingUpdate.rejected]: (state, action) => {
      state.admin_doctor_mapping_update_is_loading = false;
    },
    [adminDoctorList.pending]: (state, action) => {
      state.admin_doctor_list_is_loading = true;
    },
    [adminDoctorList.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.admin_doctor_list_is_loading = false;
      state.admin_doctor_list_data = data;
    },
    [adminDoctorList.rejected]: (state, action) => {
      state.admin_doctor_list_is_loading = false;
    },



  },
});

// Action creators are generated for each case reducer function
export const { set_state_data_list, set_country_code, set_city_list_data } =
  querySlice.actions;
export default querySlice.reducer;
