import * as React from "react";
import Box from "@mui/material/Box";

import Button from "../common/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from 'yup';


const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
})

export default function EditSupportDialog({
  open,
  handleClose,
  handleSave,
  Data,
}) {
  const handleSubmit = async (val) => {
    handleSave(val);
    handleClose();

  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <Box
          sx={{
            boxSizing: "border-box",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            borderRadius: "8px",
          }}
        >
          <DialogContent
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Box sx={{justifyContent: "flex-end", display: "flex"}}>
              <Typography onClick={handleClose} sx={{cursor: "pointer"}} >
                X
              </Typography>
            </Box>
            
            <Typography
              sx={{
                fontFamily: "inherit",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                textAlign: "center",
                letterSpacing: "-0.01em",
                flex: "none",
                order: "0",
                flexGrow: "0",
                
              }}
            >
              Edit Support User
            </Typography>
            <Formik
              initialValues={{ name: Data.name, email: Data.email }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}

            >
              {({ values, handleChange, handleSubmit, handleBlur, errors, isValid }) => (
                <>
                  <TextField
                  defaultValue={Data.name}
                    required={true}
                    disableUnderline={true}
                    label=" Name"
                    sx={{
                      borderRadius: "10px",
                   
                      height: "40px",
                      width: "250px",
                      marginTop: "20px",
                      alignSelf: "center",
                      marginLeft: "20%",
                    }}
                    placeholder="Enter  Name"
                    size="small"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    
                    value={values.name}
                    error={errors.name}

                    onInput={(e) => {
                      e.target.value = e.target.value.trimStart();
                    }}
                  />

                  <TextField
                    defaultValue={Data.email}
                    required={true}
                    disableUnderline={true}
                    label="Email"
                    sx={{
                      borderRadius: "10px",
                   
                      height: "40px",
                      width: "250px",
                      marginTop: "20px",
                      alignSelf: "center",
                      marginLeft: "20%",
                    }}
                    placeholder="Enter Email "
                    size="small"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    error={errors.email}
                   
                    value={values.email}
                    onInput={(e) => {
                      e.target.value = e.target.value.trimStart();
                    }}
                  />
             

          
            <Button
              disabled={!isValid}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "187px",

                background: "#004AAD",
                borderRadius: "5px",
                alignSelf: "center",
                marginLeft: "27%",
                marginTop: "5%",
                // flex: "none",
                // order: "0",
                // flexGrow: "0",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </Button>
            </>
              )}
            </Formik>
          </DialogContent>
          <DialogActions></DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
