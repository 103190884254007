import React from "react";
import SupportBaseLayout from "../../../components/support/layout/SupportBaseLayout";
import SupportRoutes from "../../../routes/SupportRoutes";
import HomeIcon from "@mui/icons-material/Home";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";

const doctorModules = [
  {
    id: "dashboard",
    title: "Dashboard",
    logo: <HomeIcon />,
    link: "/dashboard",
  },
  {
    id: "add_doctors",
    title: "Add Doctors/Nurses",
    logo: <LocalHospitalOutlinedIcon />,
    link: "/add-update-doctors",
  },
];

const SupportContainer = () => {
  return (
    <>
      <SupportBaseLayout
        modules={doctorModules}
        ChildComponent={SupportRoutes}
      />
    </>
  );
};

export default SupportContainer;
