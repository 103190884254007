import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { InputAdornment } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import {
  getStateList,
  set_state_data_list,
  set_country_code,
  set_city_list_data,
} from "../../../store/query/querySlice";
import { useDispatch } from "react-redux";
import countries from "../../../helpers/countries";

export default function CountrySelect(props) {
  const { formik } = props;
  const dispatch = useDispatch();

  const getValue = () => {
    let foundValue =
      countries.find((obj) => "+"+obj.phone === formik.values?.country_code) || null;
    return foundValue;
  };

  return (
    <Autocomplete
      id="country_code"
      name="country_code"
      fullWidth
      options={countries}
      getOptionLabel={(option) => option.label + " +" + option.phone}
      onChange={(values, newValue) => {
        formik.setFieldValue("State", "");
        formik.setFieldValue("City", "");
        if (newValue?.code) {
          dispatch(set_country_code({ country_code: newValue.code }));
          dispatch(getStateList({ country_code: newValue.code }));
          formik.setFieldValue("country_code", "+" + newValue.phone);
        } else {
          dispatch(set_state_data_list({ state_data: [] }));
          dispatch(set_city_list_data({ city_list_data: [] }));
          dispatch(set_country_code({ country_code: "" }));
          formik.setFieldValue("country_code", "");
        }
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country *"
          fullWidth
          error={
            formik.touched.country_code && Boolean(formik.errors.country_code)
          }
          helperText={formik.touched.country_code && formik.errors.country_code}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <PublicIcon sx={{ color: "#b2b9cc" }} />
              </InputAdornment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      value={getValue()}
    />
  );
}
