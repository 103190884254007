
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DatePickerComponent from "../../common/DatePicker";
import IconButtonIcons from "../../common/IconButtonIcons";

import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import SkipPreviousOutlinedIcon from "@mui/icons-material/SkipPreviousOutlined";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import { add, format, sub } from "date-fns";
import React from "react";

const ScheduleHeader = ({ date, handleDateChange, loading }) => {
  const [StartTime, setStartTime] = React.useState(new Date());

  React.useEffect(() => {
    setStartTime(date);
  }, [date]);

  return (
    <>
    <Stack
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      spacing={1}
     
    >
      <Stack>
        <Typography>Schedule Appointment</Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <IconButtonIcons
          onClick={() => {
            handleDateChange(sub(StartTime, { days: 7 }));
          }}
          disabled={loading}
          title="Previous Week"
          IconComponent={SkipPreviousOutlinedIcon}
        />
        <IconButtonIcons
          onClick={() => {
            handleDateChange(sub(StartTime, { days: 1 }));
          }}
          disabled={loading}
          title="Previous"
          IconComponent={NavigateBeforeOutlinedIcon}
        />
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="caption">
            {format(StartTime, "MMMM dd, yyyy")}
          </Typography>
          <Typography variant="body2">{format(StartTime, "EEEE")}</Typography>
        </Stack>
        <IconButtonIcons
          onClick={() => {
            handleDateChange(add(StartTime, { days: 1 }));
          }}
          disabled={loading}
          title="Next"
          IconComponent={NavigateNextOutlinedIcon}
        />
        <IconButtonIcons
          onClick={() => {
            handleDateChange(add(StartTime, { days: 7 }));
          }}
          disabled={loading}
          title="Next Week"
          IconComponent={SkipNextOutlinedIcon}
        />
      </Stack>
      <Stack>
        <DatePickerComponent
          date={StartTime}
          setDate={(newDate) => {
            handleDateChange(newDate);
          }}
          onError={(err) => {
            console.log(err);
          }}
        />
      </Stack>
    </Stack>
    
    </>
   
  );
};

export default ScheduleHeader;
