import { eachDayOfInterval, format, parseISO, sub } from "date-fns";
import { get, groupBy, map } from "lodash-es";
import { calculateBirthDate } from "../../helpers/datetime";
import { appointmentParseStatus } from "../appointment/appointmentParser";

export const parser_doctor_my_teams = (response) => {
  try {
    if (response?.data?.rows) {
      response = response.data.rows;
    }
    if (!response) {
      return [];
    }

    return response.map((e) => ({
      FirstName: get(e, "FirstName", ""),
      LastName: get(e, "LastName", ""),
      avatar: get(e, "avatar", ""),
      City: get(e, "City", ""),
      Email: get(e, "Email", ""),
      Phone: get(e, "Phone", ""),
      ProviderId: get(e, "ProviderId", ""),
      role_name: get(e, "role_name", ""),
      speciality: get(e, "speciality", ""),
      organization_id: get(e, "organization_id", ""),
      experience: get(e, "experience", ""),
      qr_code: get(e, "qr_code", ""),
      payment_status: get(e, "payment_status", ""),
      organization_details_doctor: get(e, "organization_details_doctor", ""),
      is_payment_made_by_admin: get(e, "is_payment_made_by_admin", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_nurse_doctor_map_list = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }
    let leftArray = [];
    let rightArray = [];
    response?.forEach((e) =>
      e?.mapped_doctors?.id ? rightArray.push({
        ProviderId: get(e, "ProviderId", ""),
        name: get(e.organization_doctor_details, "FirstName", "") + " " + get(e.organization_doctor_details, "LastName", ""),
      }) : leftArray.push({
        ProviderId: get(e, "ProviderId", ""),
        name: get(e.organization_doctor_details, "FirstName", "") + " " + get(e.organization_doctor_details, "LastName", ""),
      })
    );
    return { leftArray, rightArray };
  } catch (error) {
    throw new Error(error);
  }
};



export const parser_org_doctor_map_list = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }
    let leftArray = [];
    let rightArray = [];
    response?.forEach((e) =>
      e?.organization_mapping?.length > 0 ? rightArray.push({
        name: get(e, "name", "") ,
        id: get(e, "id", "") ,
      }) : leftArray.push({
        name: get(e, "name", "") ,
        id: get(e, "id", "") ,
      })
    );
    return { leftArray, rightArray };
  } catch (error) {
    throw new Error(error);
  }
};


export const parser_doctor_my_patients = (response) => {
  try {
    if (response?.data?.rows) {
      response = response.data.rows;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      guid: get(e, "guid", ""),
      PatientId: get(e, "PatientId", ""),
      FirstName: get(e, "list_patient_details.FirstName", ""),
      LastName: get(e, "list_patient_details.LastName", ""),
      avatar: get(e, "list_patient_details.avatar", ""),
      mobile: get(e, "list_patient_details.mobile", ""),
      EmailAddress: get(e, "list_patient_details.EmailAddress", ""),
      City: get(e, "list_patient_details.City", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_appointment_history = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }
    const rawData = response.map((e) => ({
      AppointmentId: get(e, "AppointmentId", ""),
      PatientId: get(e, "PatientId", ""),
      StartTime: get(e, "StartTime", ""),
      AppointmentDate: format(
        parseISO(get(e, "StartTime", new Date())),
        "MMMM dd, yyyy"
      ),
      AppointmentTime: format(
        parseISO(get(e, "StartTime", new Date())),
        "hh:mm bbb"
      ),
      EndTime: get(e, "EndTime", ""),
      FirstName: get(e, "appointment_patient.FirstName", ""),
      LastName: get(e, "appointment_patient.LastName", ""),
      BirthDate: calculateBirthDate(
        get(e, "appointment_patient.BirthDate", new Date())
      ).years,
      CellPhone: get(e, "appointment_patient.CellPhone", ""),
      AppointmentParsedStatus: appointmentParseStatus(e),
    }));

    return map(groupBy(rawData, "AppointmentDate"));
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const parser_kpi_dashboard_count = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_dashboard_stats = (response, days) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }

    const result = eachDayOfInterval({
      start: sub(new Date(), { days: days }),
      end: new Date()
    });

    let barChartLabel = [];
    let stackBarChartData = [];

    let pieChartLabel = ["Cancelled", "Confirmed", "Rescheduled", "No-show"];
    let pieChartData = [0, 0, 0, 0];

    let barChartAppointments = [];
    let barChartNoShows = [];

    response.map(function (dd) {
      pieChartData[0] += parseInt(dd.cancelled);
      pieChartData[1] += parseInt(dd.confirmed);
      pieChartData[2] += parseInt(dd.reschedule);
      pieChartData[3] += parseInt(dd.no_show);
      return true;
    })

    result.map(function (date) {
      let formatDate = format(date, 'd MMM');
      barChartLabel.push(formatDate);

      let find = response.find(obj => obj.date === format(date, 'yyyy-MM-dd'));

      if (find) {
        stackBarChartData.push(find.total_appointments);
        barChartAppointments.push(find.total_appointments);
        barChartNoShows.push(find.no_show);
      } else {
        stackBarChartData.push(0);
        barChartAppointments.push(0);
        barChartNoShows.push(0);
      }
      return true;
    })

    let finalData = { barChartLabel: barChartLabel, stackBarChartData: stackBarChartData, pieChartLabel: pieChartLabel, pieChartData: pieChartData, barChartNoShows: barChartNoShows, barChartAppointments: barChartAppointments }
   
    return finalData;
  } catch (error) {
    throw new Error(error);
  }
};

// support
export const parser_support_dashboard_kpi = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_doctor_nurse_list = (response) => {
  try {
    if (response?.data?.rows) {
      response = response.data.rows;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      FirstName: get(e, "FirstName", ""),
      LastName: get(e, "LastName", ""),
      avatar: get(e, "avatar", ""),
      City: get(e, "City", ""),
      speciality: get(e, "speciality", ""),
      experience: get(e, "experience", ""),
      Phone: get(e, "Phone", ""),
      Email: get(e,"Email", ""),
      ProviderId: get(e, "ProviderId", ""),
      payment_status: get(e, "payment_status", ""),
      is_payment_made_by_admin: get(e, "is_payment_made_by_admin", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_speciality_list_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      speciality_id: get(e, "speciality_id", ""),
      value: get(e, "speciality_id", ""),
      title: get(e, "title", ""),
      key: get(e, "title", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_state_list_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      name: get(e, "name", ""),
      state_code: get(e, "state_code", ""),
      key: get(e, "name", ""),
      value: get(e, "state_code", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_city_list_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      name: get(e, "name", ""),
      key: get(e, "name", ""),
      value: get(e, "name", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_request_documents_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      id: get(e, "id", ""),
      title: get(e, "title", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_doctor_directory_url = (response) => {
  try {
    if (response?.data?.rows) {
      response = response.data.rows;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      id: get(e, "id", ""),
      FirstName: get(e, "FirstName", ""),
      LastName: get(e, "LastName", ""),
      avatar: get(e, "avatar", ""),
      City: get(e, "City", ""),
      speciality: get(e, "speciality2", ""),
      mobile: get(e, "mobile", ""),
      tags: get(e, "tags2", ""),
      ProviderId: get(e, "ProviderId", ""),
      is_hipaasafe_user: get(e, "is_hipaasafe_user", ""),
      Email: get(e, "Email", ""),
      directory_organization : get(e, "directory_organization", ""),

    }));
  } catch (error) {
    throw new Error(error);
  }
};
