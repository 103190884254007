import React from "react";
import SupportHeader from "../../../components/support/dashboard/SupportHeader";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Common from "../../../components/admin/Common";
import CommonAddDialog from "../../../components/admin/CommonAddDialog";
import CommonEditDialog from "../../../components/admin/CommonEditDialog";
import Button from "../../../components/common/Button";

class CommonContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      openAdd: false,
      openEdit: false,
      editData: {},
    };
  }

  componentDidMount() {
    this.props.queryCommonList({ parent: "help" });
  }

  handelAddCommon = async (data) => {
    data.parent = "help";
    this.props.addCommonList(data);
    this.setState({ openAdd: false });
    await this.props.queryCommonList({ parent: "help" });
  };

  handelEditCommon = (data) => {
    this.props.updateCommonList({
      key: data.key,
      value: data.value,
      parent: data.parent,
      id: this.state.editData.id,
    });
    this.setState({ openEdit: false });
    this.props.queryCommonList({ parent: "help" });
  };

  handleClose = (data) => {
    this.setState({ openEdit: !this.state.openEdit, editData: data });
  };

  render() {
    const { common_list_is_loading, common_list_data } = this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SupportHeader title="Help Page" />

            <Button
              variant="contained"
              sx={{
                borderRadius: "50px",
                backgroundColor: "#004AAD",
                marginRight: "1rem",
                color: "white",
                width: "150px",
              }}
              onClick={() => this.setState({ openAdd: true })}
            >
              + Add
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <Paper elevation={0}>
                <Stack sx={{ p: 5 }} spacing={1}>
                  <Common
                    tableDetails={common_list_data}
                    loading={common_list_is_loading}
                    handleClose={this.handleClose}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Grid>
        </Grid>

        <CommonAddDialog
          open={this.state.openAdd}
          handleClose={() => this.setState({ openAdd: false })}
          handleSave={this.handelAddCommon}
        />
        <CommonEditDialog
          open={this.state.openEdit}
          handleClose={() => this.setState({ openEdit: false })}
          handleSave={this.handelEditCommon}
          Data={this.state.editData}
        />
      </>
    );
  }
}

export default CommonContainer;
