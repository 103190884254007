import React from "react";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import Button from "../../common/Button";
import * as yup from "yup";
import Input from "../../common/Input";

const AddPatientNotes = (props) => {
  const { loading, addPatientNotes, guid } = props;

  const validateAddNotes = yup.object().shape({
    NoteText: yup
      .string()
      .min(5, "Please enter atleast 5 characters.")
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      guid,
      NoteText: "",
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
    validationSchema: validateAddNotes,
  });

  const onSubmit = async (values) => {
    addPatientNotes(values);
    formik.resetForm()
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
          spacing={1}
        >
          <Input
            fullWidth
            id="outlined-multiline-flexible"
            label="Add a note"
            multiline
            rows={3}
            placeholder="Add a note"
            value={formik.values.NoteText}
            onChange={formik.handleChange("NoteText")}
            onBlur={formik.handleBlur("NoteText")}
            error={!formik.touched.NoteText && Boolean(formik.errors.NoteText)}
            helperText={!formik.touched.NoteText && formik.errors.NoteText}
          />
          <Button
            type="submit"
            disabled={!formik.isValid}
            loading={loading}
            fullWidth
          >
            Save
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default AddPatientNotes;
