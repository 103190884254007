import React from "react";
import Table from "../../components/common/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";


const HospitalReports = (props) => {
  const { tableDetails, loading ,handleClose} = props;

  

  const columns = [
    // {
    //   title: "id",
    // },
    {
      title: "Gender",
    },
    {
      title: "Value",
    },
    {
      title: "Action",
    },
  ];

  const renderTableData = tableDetails?.length ? (
    tableDetails?.map((row, index) => {
      return (
        <TableRow key={index} sx={{ "td, th": { border: 0, padding: "10px" } }}>
          {/* <TableCell align="center" component="th" scope="row">
            {row.id}
          </TableCell> */}
          <TableCell align="center">{row.value}</TableCell>
          <TableCell align="center">{row.key}</TableCell>
         
         
          <TableCell align="center">
           
            <EditOutlinedIcon
              onClick={() => {
                handleClose(row);
              }}
              sx={{ cursor: "pointer" }}
            />
          </TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
      <TableCell align="center">Data not found</TableCell>
    </TableRow>
  );

  return (
    <Table columns={columns} tableData={renderTableData} loading={loading} />
  );
};

export default HospitalReports;
