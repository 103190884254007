import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../helpers/constants";
import { updateLocalStorage } from "../../helpers/cookies";
// import { isAuth } from "../../helpers/cookies";
import {
  api_add_doctors_by_code,
  api_add_doctors_url,
  api_add_doctor_directory_url,
  api_add_feedback_url,
  api_add_patients_url,
  api_download_qr_code_url,
  api_get_doctor_directory_url,
  api_get_patients_by_name_or_phone,
  api_get_patients_list,
  api_get_physician_list,
  api_get_unlinked_patient,
  api_get_update_doctor_url,
  api_link_profile_with_org,
  api_remove_doctor_directory,
  api_update_doctors_url,
  api_update_doctor_directory_url,
  api_update_doctor_profile_url,
  api_update_profile_pic,
  // api_update_doctors_url,
  api_update_terms_and_conditions,
  api_add_doctors_web_url,
  api_get_org_url,
  api_post_org_url,
  api_npi_get,
  api_verify_org_url,
  api_get_single_org_url,
  stripe_payment_verify,
  stripe_payment_price,
  support_user,
  update_org_api,
  api_get_feedback_url,
  register_support_user_api,
  admin_user_update_api,
  admin_user_tree_api,
  admin_user_payment_api,
  doctor_orgnization_list_api,
  doctor_orgnization_switch_api,
  api_add_doctor_org_mapping,
  api_status_feedback_url,
  api_doctor_invite,
  api_user_logout,
} from "./userApis";
import {
  parser_add_doctors_url,
  parser_add_doctor_directory_url,
  parser_add_patients_url,
  parser_download_qr_code_url,
  parser_get_doctor_directory_url,
  parser_get_patients_by_name_or_phone,
  parser_get_patients_list,
  parser_get_physician_list,
  parser_get_unlinked_patient,
  parser_get_update_doctor_url,
  parser_link_profile_with_org,
  parser_update_doctors_url,
  parser_update_doctor_directory_url,
  parser_update_doctor_profile_url,
  parser_update_profile_pic,
  // parser_update_doctors_url,
  get_org_list_parser,
} from "./userParser";

export const addFeedback = createAsyncThunk("addFeedback", async (payload) => {
  try {
    const response = await api_add_feedback_url(payload);
    return response;
  } catch (error) {
    throw new Error(error);
  }
});

export const addDoctors = createAsyncThunk("addDoctors", async (payload) => {
  try {
    const response = await api_add_doctors_url(payload);
    const data = parser_add_doctors_url(response);
    return { data };
  } catch (error) {
    throw new Error(error);
  }
});

export const AddDoctorWeb = createAsyncThunk(
  "AddDoctorWeb",
  async (payload) => {
    try {
      const response = await api_add_doctors_web_url(payload);
      const data = parser_add_doctors_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getOrgList = createAsyncThunk("getOrgList", async (payload) => {
  try {
    const response = await api_get_org_url();
    const data = get_org_list_parser(response);

    return data;
  } catch (error) {
    throw new Error(error);
  }
});

export const postOrgList = createAsyncThunk("postOrgList", async (payload) => {
  try {
    const response = await api_post_org_url(payload);

    return response;
  } catch (error) {
    throw new Error(error);
  }
});

export const verifyOrg = createAsyncThunk("verifyOrg", async (payload) => {
  try {
    const response = await api_verify_org_url(payload);

    return response;
  } catch (error) {
    throw new Error(error);
  }
});

export const getOrg = createAsyncThunk("getOrg", async (payload) => {
  try {
    const response = await api_get_single_org_url(payload);

    return response;
  } catch (error) {
    throw new Error(error);
  }
});

export const checkNPI = createAsyncThunk("checkNPI", async (npi) => {
  try {
    const payload = {
      npi: npi,
    };

    const response = await api_npi_get(payload);
    return response;
  } catch (error) {
    throw new Error(error);
  }
});

export const updateDoctors = createAsyncThunk(
  "updateDoctors",
  async (payload) => {
    try {
      const response = await api_update_doctors_url(payload);
      const data = parser_update_doctors_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getPatientsByNameOrPhone = createAsyncThunk(
  "getPatientsByNameOrPhone",
  async (payload) => {
    try {
      const { keyword } = payload;
      const response = await api_get_patients_by_name_or_phone({ keyword });
      const data = parser_get_patients_by_name_or_phone(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addPatients = createAsyncThunk("addPatients", async (payload) => {
  try {
    const response = await api_add_patients_url(payload);
    const data = parser_add_patients_url(response);
    return { data };
  } catch (error) {
    throw new Error(error);
  }
});

export const getDoctorDataUrl = createAsyncThunk(
  "getDoctorDataUrl",
  async (payload) => {
    try {
      const { uid } = payload;
      const params = { uid };
      const response = await api_get_update_doctor_url(params);
      const data = parser_get_update_doctor_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateDoctorProfile = createAsyncThunk(
  "updateDoctorProfile",
  async (payload) => {
    try {
      const response = await api_update_doctor_profile_url(payload);
      const data = parser_update_doctor_profile_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addDoctorDirectory = createAsyncThunk(
  "addDoctorDirectory",
  async (payload) => {
    try {
      const response = await api_add_doctor_directory_url(payload);
      const data = parser_add_doctor_directory_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateDoctorDirectory = createAsyncThunk(
  "updateDoctorDirectory",
  async (payload) => {
    try {
      const response = await api_update_doctor_directory_url(payload);
      const data = parser_update_doctor_directory_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const getDoctorDirectory = createAsyncThunk(
  "getDoctorDirectory",
  async (payload) => {
    try {
      const response = await api_get_doctor_directory_url(payload);
      const data = parser_get_doctor_directory_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const downloadQrCode = createAsyncThunk(
  "downloadQrCode",
  async (payload) => {
    try {
      const response = await api_download_qr_code_url(payload);
      const data = parser_download_qr_code_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const updateProfilePic = createAsyncThunk(
  "updateProfilePic",
  async (payload, { dispatch }) => {
    try {
      const response = await api_update_profile_pic(payload);
      const data = parser_update_profile_pic(response);
      return { data, dispatch };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getPatientDataUrl = createAsyncThunk(
  "getPatientDataUrl",
  async (payload) => {
    try {
      const { PatientId } = payload;
      const params = { PatientId };
      const response = await api_get_patients_list(params);
      const data = parser_get_patients_list(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getPhysicianDataUrl = createAsyncThunk(
  "getPhysicianDataUrl",
  async (payload) => {
    try {
      const { id } = payload;
      const params = { id };
      const response = await api_get_physician_list(params);
      const data = parser_get_physician_list(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getUnlinkedPatient = createAsyncThunk(
  "getUnlinkedPatient",
  async (payload = {}) => {
    try {
      const { CellPhone } = payload;
      if(!CellPhone) return { data: [] };
      const params = { CellPhone };
      const response = await api_get_unlinked_patient(params);
      const data = parser_get_unlinked_patient(response);
      return { data };
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }
);

export const linkPatientWithOrg = createAsyncThunk(
  "linkPatientWithOrg",
  async (payload) => {
    try {
      const response = await api_link_profile_with_org(payload);
      const data = parser_link_profile_with_org(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addDoctorsByCode = createAsyncThunk(
  "addDoctorsByCode",
  async (payload) => {
    try {
      const response = await api_add_doctors_by_code(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const removeDoctorDirectory = createAsyncThunk(
  "removeDoctorDirectory",
  async (payload) => {
    try {
      const { id } = payload;
      const params = { id };
      const response = await api_remove_doctor_directory(params);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const AcceptTermsAndConditions = createAsyncThunk(
  "AcceptTermsAndConditions",
  async () => {
    try {
      const response = await api_update_terms_and_conditions();
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const STRIPE_DOCTOR_VERIFY = createAsyncThunk(
  "STRIPE_DOCTOR_VERIFY",
  async (payload) => {
    try {
      const response = await stripe_payment_verify(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const STRIPE_DOCTOR_PRICE = createAsyncThunk(
  "STRIPE_DOCTOR_PRICE",
  async (payload) => {
    try {
      const response = await stripe_payment_price(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const SUPPORT_USER = createAsyncThunk(
  "SUPPORT_USER",
  async (payload) => {
    try {
      const { organization_id } = payload;
      const params = { organization_id };
      const response = await support_user(params);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const REGISTER_SUPPORT_USER = createAsyncThunk(
  "REGISTER_SUPPORT_USER",
  async (payload) => {
    try {
      const response = await register_support_user_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const update_org = createAsyncThunk("update_org", async (payload) => {
  try {
    const response = await update_org_api(payload);
    return response;
  } catch (error) {
    throw new Error(error);
  }
});

export const GET_FEEDBACK = createAsyncThunk(
  "GET_FEEDBACK",
  async (payload) => {
    try {
      const response = await api_get_feedback_url(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const adminUserUpdate = createAsyncThunk(
  "adminUserUpdate",
  async (payload) => {
    try {
      const response = await admin_user_update_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const adminUserTree = createAsyncThunk(
  "adminUserTree",
  async (payload) => {
    try {
      const response = await admin_user_tree_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const adminUserPayment = createAsyncThunk(
  "adminUserPayment",
  async (payload) => {
    try {
      const response = await admin_user_payment_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const doctorOrgnization = createAsyncThunk(
  "doctorOrgnization",
  async (payload) => {
    try {
      const response = await doctor_orgnization_list_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const doctorOrgnizationUpdate = createAsyncThunk(
  "doctorOrgnizationUpdate",
  async (payload) => {
    try {
      const response = await doctor_orgnization_switch_api(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);


export const addDoctorOrgnizationMappingSingle = createAsyncThunk(
  "addDoctorOrgnizationMappingSingle",
  async (payload) => {
    try {
      const response = await api_add_doctor_org_mapping(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);




export const statusFeedback = createAsyncThunk(
  "statusFeedback",
  async (payload) => {
    try {
      const response = await api_status_feedback_url(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);




export const inviteDoctor = createAsyncThunk(
  "inviteDoctor",
  async (payload) => {
    try {
      const response = await api_doctor_invite(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);



// user logout api 
export const userLogout = createAsyncThunk(
  "userLogout",
  async (payload) => {
    try {
      const response = await api_user_logout(payload);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);


const initialState = {
  // Add Feedback
  feedback_add_loading: false,

  // Add Doctor
  add_doctors_loading: false,

  // add doctor web

  user_add_doctors_web_loading: false,

  // Get Patients By Name Or phone
  patients_by_search_loading: false,
  patients_by_search_data: [],

  // Add Patient
  add_patient_loading: false,

  // update doctor
  doctors_data_is_loading: false,
  doctors_data: {},

  // add doctor directory
  add_doctors_directory_loading: false,
  update_doctor_directory_data: {},

  // qr code download state
  qr_code_download_loading: false,
  qr_code_download_data: [],

  // update profile pic state
  update_profile_pic_loading: false,

  // get patient list
  patients_list_is_loading: false,
  patients_data: [],

  // get Physician list
  physician_list_is_loading: false,
  physician_data: [],

  // get unlinked patient
  unlinked_patient_is_loading: false,
  unlinked_patient_data: [],

  link_patient_with_org_data: [],
  link_patient_with_org_is_loading: false,

  // by Unique Code
  add_doctors_by_code_is_loading: false,

  // Remove Directory
  remove_doctor_directory_is_loading: false,

  // get org
  get_org_is_loading: false,
  get_org_data: [],
  // get org
  get_npi_is_loading: false,
  get_npi_data: [],

  post_org_is_loading: false,
  post_org_data: [],
  // verify org
  verify_org_is_loading: false,
  verify_org_data: [],

  get_single_org_is_loading: false,
  get_single_org_data: [],

  stripe_doctor_verify_is_loading: false,
  stripe_doctor_verify_data: [],

  stripe_doctor_price_is_loading: false,
  stripe_doctor_price_data: [],

  support_user_is_loading: false,
  support_user_data: [],

  update_org_is_loading: false,
  update_org_data: [],
  get_feedback_is_loading: false,
  get_feedback_data: [],

  admin_user_update_is_loading: false,
  admin_user_update_data: [],

  admin_user_tree_is_loading: false,
  admin_user_tree_data: [],

  admin_user_payment_is_loading: false,
  admin_user_payment_data: [],

  doctor_orgnization_is_loading: false,
  doctor_orgnization_data: [],

  doctor_orgnization_update_is_loading: false,
  doctor_orgnization_update_data: [],

  add_doctor_orgnization_mapping_single_is_loading: false,
  add_doctor_orgnization_mapping_single_data: [],

  status_feedback_is_loading: false,
  status_feedback_data: [],


  status_invite_doctor_is_loading: false,
  status_invite_doctor_data: [],

  user_logout_is_loading: false,
  user_logout_data: [],


  
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    //  Add Feedback
    [addFeedback.pending]: (state, action) => {
      state.feedback_add_loading = true;
    },
    [addFeedback.fulfilled]: (state, action) => {
      state.feedback_add_loading = false;
    },
    [addFeedback.rejected]: (state, action) => {
      state.feedback_add_loading = false;
    },
    //  Fetch Add doctors
    [addDoctors.pending]: (state, action) => {
      state.user_add_doctors_loading = true;
    },
    [addDoctors.fulfilled]: (state, action) => {
      state.user_add_doctors_loading = false;
    },
    [addDoctors.rejected]: (state, action) => {
      state.user_add_doctors_loading = false;
    },
    // add doctor web
    [AddDoctorWeb.pending]: (state, action) => {
      state.user_add_doctors_web_loading = true;
    },
    [AddDoctorWeb.fulfilled]: (state, action) => {
      state.user_add_doctors_web_loading = false;
    },
    [AddDoctorWeb.rejected]: (state, action) => {
      state.user_add_doctors_web_loading = false;
    },

    //  Fetch Add doctors
    [updateDoctors.pending]: (state, action) => {
      state.user_add_doctors_loading = true;
    },
    [updateDoctors.fulfilled]: (state, action) => {
      state.user_add_doctors_loading = false;
    },
    [updateDoctors.rejected]: (state, action) => {
      state.user_add_doctors_loading = false;
    },
    // Add doctors directory
    [addDoctorDirectory.pending]: (state, action) => {
      state.add_doctors_directory_loading = true;
    },
    [addDoctorDirectory.fulfilled]: (state, action) => {
      state.add_doctors_directory_loading = false;
      // window.location.href = "/chat";
    },
    [addDoctorDirectory.rejected]: (state, action) => {
      state.add_doctors_directory_loading = false;
    },
    //  Update doctors directory
    [updateDoctorDirectory.pending]: (state, action) => {
      state.add_doctors_directory_loading = true;
    },
    [updateDoctorDirectory.fulfilled]: (state, action) => {
      state.add_doctors_directory_loading = false;
      // window.location.href = "/chat";
    },
    [updateDoctorDirectory.rejected]: (state, action) => {
      state.add_doctors_directory_loading = false;
    },
    //  Fetch doctors directory
    [getDoctorDirectory.pending]: (state, action) => {
      state.update_doctor_directory_data = true;
    },
    [getDoctorDirectory.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.update_doctor_directory_data = data;
      // window.location.reload();
    },
    [getDoctorDirectory.rejected]: (state, action) => {
      state.update_doctor_directory_data = false;
    },

    // Get Patients by name or Phone
    [getPatientsByNameOrPhone.pending]: (state, action) => {
      state.patients_by_search_loading = true;
    },
    [getPatientsByNameOrPhone.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.patients_by_search_data = data;
      state.patients_by_search_loading = false;
    },
    [getPatientsByNameOrPhone.rejected]: (state, action) => {
      state.patients_by_search_loading = false;
    },

    // Add patient
    [addPatients.pending]: (state, action) => {
      state.add_patient_loading = true;
    },
    [addPatients.fulfilled]: (state, action) => {
      let data = action.payload;
      state.add_patient_loading = false;
    },
    [addPatients.rejected]: (state, action) => {
      state.add_patient_loading = false;
    },

    // get doctor doctor
    [getDoctorDataUrl.pending]: (state, action) => {
      state.doctors_data_is_loading = true;
    },
    [getDoctorDataUrl.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.doctors_data = data;
      state.doctors_data_is_loading = false;
    },
    [getDoctorDataUrl.rejected]: (state, action) => {
      state.doctors_data_is_loading = false;
    },
    // get doctor doctor
    [updateDoctorProfile.pending]: (state, action) => {
      state.update_profile_loading = true;
    },
    [updateDoctorProfile.fulfilled]: (state, action) => {
      const { data } = action.payload;
      updateLocalStorage(data, () => {});
      state.update_profile_loading = false;
    },
    [updateDoctorProfile.rejected]: (state, action) => {
      state.update_profile_loading = false;
    },
    // download qr code
    [downloadQrCode.pending]: (state, action) => {
      state.qr_code_download_loading = true;
    },
    [downloadQrCode.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.qr_code_download_data = data;
      state.qr_code_download_loading = false;
      window.open(baseUrl + "/" + data.filename, "_blank").focus();
    },
    [downloadQrCode.rejected]: (state, action) => {
      state.qr_code_download_loading = false;
    },
    // update profile pic
    [updateProfilePic.pending]: (state, action) => {
      state.update_profile_pic_loading = true;
    },
    [updateProfilePic.fulfilled]: (state, action) => {
      const { data } = action.payload;
      updateLocalStorage({ avatar: data.avatar }, () => {});
      state.update_profile_pic_loading = false;
    },
    [updateProfilePic.rejected]: (state, action) => {
      state.update_profile_pic_loading = false;
    },

    // Get Patients list
    [getPatientDataUrl.pending]: (state, action) => {
      state.patients_list_is_loading = true;
    },
    [getPatientDataUrl.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.patients_data = data;
      state.patients_list_is_loading = false;
    },
    [getPatientDataUrl.rejected]: (state, action) => {
      state.patients_list_is_loading = false;
    },

    // Get Physician list
    [getPhysicianDataUrl.pending]: (state, action) => {
      state.physician_list_is_loading = true;
    },
    [getPhysicianDataUrl.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.physician_data = data;
      state.physician_list_is_loading = false;
    },
    [getPhysicianDataUrl.rejected]: (state, action) => {
      state.physician_list_is_loading = false;
    },

    // Get Unlinked patient
    [getUnlinkedPatient.pending]: (state, action) => {
      state.unlinked_patient_is_loading = true;
    },
    [getUnlinkedPatient.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.unlinked_patient_data = data;
      state.unlinked_patient_is_loading = false;
    },
    [getUnlinkedPatient.rejected]: (state, action) => {
      state.unlinked_patient_is_loading = false;
    },

    // Get Organization list

    [getOrgList.pending]: (state, action) => {
      state.get_org_is_loading = true;
    },
    [getOrgList.fulfilled]: (state, action) => {
      const data = action.payload;

      state.get_org_data = data;
      state.get_org_is_loading = false;
    },
    [getOrgList.rejected]: (state, action) => {
      state.get_org_is_loading = false;
    },

    // Check NPI
    [checkNPI.pending]: (state, action) => {
      state.check_npi_is_loading = true;
    },
    [checkNPI.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.get_npi_data = data;
      state.get_npi_is_loading = false;
    },
    [checkNPI.rejected]: (state, action) => {
      state.check_npi_is_loading = false;
    },

    // Link patient with org
    [linkPatientWithOrg.pending]: (state, action) => {
      state.link_patient_with_org_is_loading = true;
    },
    [linkPatientWithOrg.fulfilled]: (state, action) => {
      const { data } = action.payload;
      for (const index in state.unlinked_patient_data) {
        if (state.unlinked_patient_data[index].PatientId === data.PatientId) {
          state.unlinked_patient_data[index].linked_with_org = data.PatientId;
        }
      }
      state.link_patient_with_org_is_loading = false;
    },
    [linkPatientWithOrg.rejected]: (state, action) => {
      state.link_patient_with_org_is_loading = false;
    },
    // By Unique code
    [addDoctorsByCode.pending]: (state, action) => {
      state.add_doctors_by_code_is_loading = true;
    },
    [addDoctorsByCode.fulfilled]: (state, action) => {
      state.add_doctors_by_code_is_loading = false;
      window.location.href = "/chat";
    },
    [addDoctorsByCode.rejected]: (state, action) => {
      state.add_doctors_by_code_is_loading = false;
    },

    // Remove Directory

    [removeDoctorDirectory.pending]: (state, action) => {
      state.remove_doctor_directory_is_loading = true;
    },
    [removeDoctorDirectory.fulfilled]: (state, action) => {
      state.remove_doctor_directory_is_loading = false;
      window.location.href = "/chat";
    },
    [removeDoctorDirectory.rejected]: (state, action) => {
      state.remove_doctor_directory_is_loading = false;
    },

    // post org data
    [postOrgList.pending]: (state, action) => {
      state.post_org_is_loading = true;
    },
    [postOrgList.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.post_org_is_loading = false;
      state.post_org_data = data;
    },
    [postOrgList.rejected]: (state, action) => {
      state.post_org_is_loading = false;
    },
    [verifyOrg.pending]: (state, action) => {
      state.verify_org_is_loading = true;
    },
    [verifyOrg.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.verify_org_is_loading = false;
      state.verify_org_data = data;
    },
    [verifyOrg.rejected]: (state, action) => {
      state.verify_org_is_loading = false;
    },
    [getOrg.pending]: (state, action) => {
      state.get_single_org_is_loading = true;
    },
    [getOrg.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.get_single_org_is_loading = false;
      state.get_single_org_data = data;
    },
    [getOrg.rejected]: (state, action) => {
      state.get_single_org_is_loading = false;
    },
    [STRIPE_DOCTOR_VERIFY.pending]: (state, action) => {
      state.stripe_doctor_verify_is_loading = true;
    },
    [STRIPE_DOCTOR_VERIFY.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.stripe_doctor_verify_is_loading = false;
      state.stripe_doctor_verify_data = data;
    },
    [STRIPE_DOCTOR_VERIFY.rejected]: (state, action) => {
      state.stripe_doctor_verify_is_loading = false;
    },
    [STRIPE_DOCTOR_PRICE.pending]: (state, action) => {
      state.stripe_doctor_price_is_loading = true;
    },
    [STRIPE_DOCTOR_PRICE.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.stripe_doctor_price_is_loading = false;
      state.stripe_doctor_price_data = data;
    },
    [STRIPE_DOCTOR_PRICE.rejected]: (state, action) => {
      state.stripe_doctor_price_is_loading = false;
    },
    [SUPPORT_USER.pending]: (state, action) => {
      state.support_user_is_loading = true;
    },
    [SUPPORT_USER.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.support_user_is_loading = false;
      state.support_user_data = data;
    },
    [SUPPORT_USER.rejected]: (state, action) => {
      state.support_user_is_loading = false;
    },
    [update_org.pending]: (state, action) => {
      state.update_org_is_loading = true;
    },

    [update_org.fulfilled]: (state, action) => {
      state.update_org_is_loading = false;
    },
    [update_org.rejected]: (state, action) => {
      state.update_org_is_loading = false;
    },
    [GET_FEEDBACK.pending]: (state, action) => {
      state.get_feedback_is_loading = true;
    },
    [GET_FEEDBACK.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.get_feedback_is_loading = false;
      state.get_feedback_data = data;
    },
    [GET_FEEDBACK.rejected]: (state, action) => {
      state.get_feedback_is_loading = false;
    },
    [adminUserUpdate.pending]: (state, action) => {
      state.admin_user_update_is_loading = true;
    },
    [adminUserUpdate.fulfilled]: (state, action) => {
      state.admin_user_update_is_loading = false;
      state.admin_user_update_data = action.payload.data;
    },
    [adminUserUpdate.rejected]: (state, action) => {
      state.admin_user_update_is_loading = false;
    },
    [adminUserTree.pending]: (state, action) => {
      state.admin_user_tree_is_loading = true;
    },
    [adminUserTree.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.admin_user_tree_is_loading = false;
      state.admin_user_tree_data = data;
    },
    [adminUserTree.rejected]: (state, action) => {
      state.admin_user_tree_is_loading = false;
    },
    [adminUserPayment.pending]: (state, action) => {
      state.admin_user_payment_is_loading = true;
    },
    [adminUserPayment.fulfilled]: (state, action) => {
      state.admin_user_payment_is_loading = false;
      state.admin_user_payment_data = action.payload.data;
    },
    [adminUserPayment.rejected]: (state, action) => {
      state.admin_user_payment_is_loading = false;
    },

    [doctorOrgnization.pending]: (state, action) => {
      state.doctor_orgnization_is_loading = true;
    },
    [doctorOrgnization.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.doctor_orgnization_is_loading = false;
      state.doctor_orgnization_data = data;
    },
    [doctorOrgnization.rejected]: (state, action) => {
      state.doctor_orgnization_is_loading = false;
    },

    [doctorOrgnizationUpdate.pending]: (state, action) => {
      state.doctor_orgnization_update_is_loading = true;
    },
    [doctorOrgnizationUpdate.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.doctor_orgnization_update_is_loading = false;
      state.doctor_orgnization_update_data = data;
    },
    [doctorOrgnizationUpdate.rejected]: (state, action) => {
      state.doctor_orgnization_update_is_loading = false;
    },
    [addDoctorOrgnizationMappingSingle.pending]: (state, action) => {
      state.add_doctor_orgnization_mapping_single_is_loading = true;
    },
    [addDoctorOrgnizationMappingSingle.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.add_doctor_orgnization_mapping_single_is_loading = false;
      state.add_doctor_orgnization_mapping_single_data = data;
    },
    [addDoctorOrgnizationMappingSingle.rejected]: (state, action) => {
      state.add_doctor_orgnization_mapping_single_is_loading = false;
    },
    [statusFeedback.pending]: (state, action) => {
      state.status_feedback_is_loading = true;
    },
    [statusFeedback.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.status_feedback_is_loading = false;
      state.status_feedback_data = data;
    },
    [statusFeedback.rejected]: (state, action) => {
      state.status_feedback_is_loading = false;
    },
    [inviteDoctor.pending]: (state, action) => {
      state.invite_doctor_is_loading = true;
    },
    [inviteDoctor.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.invite_doctor_is_loading = false;
      state.invite_doctor_data = data;
    },
    [inviteDoctor.rejected]: (state, action) => {
      state.invite_doctor_is_loading = false;
    },
    [userLogout.pending]: (state, action) => {
      state.user_logout_is_loading = true;
    },
    [userLogout.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.user_logout_is_loading = false;
      state.user_logout_data = data;
    },
    [userLogout.rejected]: (state, action) => {
      state.user_logout_is_loading = false;
    }
  },
});

// Action creators are generated for each case reducer function
// export const {} = userSlice.actions;
export default userSlice.reducer;
