import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
//components
import Button from "../../common/Button";
import CountrySelect from "../../support/doctor/CountrySelect";
import SelectComponent from "../../common/Select";
import { FormHelperText, Paper } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { getCityList } from "../../../store/query/querySlice";
//icons
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SyncIcon from "@mui/icons-material/Sync";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PersonIcon from "@mui/icons-material/Person";

const validateLoginForm = yup.object().shape({
  Email: yup.string().email().required(),
  FirstName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
  LastName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
  Phone: yup.number().required("Phone  number is required!").positive().min(10),
  organization_type: yup.string().required("Required"),
  type: yup.string().required("Required"),
});

const AddDoctor = (props) => {
  const { country_code } = useSelector((state) => state.query);
  const dispatch = useDispatch();
  const {
    loading,
    addDoctors,
    state_data,
    city_data,
    orgSubmit,
    checkNpi,
    npi_correct,
    npi_loading,
    data,
    handleVerifyOrg,
    orgverify,
    orgNpiSubmit,
    npi_org_correct,
    npi_org_loading,
    gender_data,
  } = props;

  const onSubmit = async (values) => {
    let payload = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      Phone: values.Phone,
      country_code: values.country_code,
      Address1: values.Address1,
      Address2: values.Address2,
      State: values.State,
      City: values.City,
      Sex: values.Sex,
      Zipcode: values.Zipcode,
      speciality_id: values.speciality_id,
      tags: values.tags.map(function (d) {
        return d["value"];
      }),
      timezone: values.timezone,
      organization_id: values.organization_id,
      npi_no: values.npi_no,
      organization_type: values.organization_type,
      name: values.name,
      organization_domain: values.organization_domain,
      org_npi: values.org_npi,
    };

    payload.type = values.type;
    addDoctors(payload);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization_type: data?.organization_type || "",
      FirstName: data?.FirstName || "",
      LastName: data?.LastName || "",
      Address1: data?.Address1 || "",
      Address2: data?.Address2 || "",
      City: data?.City || "",
      Email: data?.Email || "",
      State: data?.State || "",
      Zipcode: data?.Zipcode || "",
      country_code: data?.country_code || "",
      Phone: data?.Phone || "",
      Sex: data?.Sex || "",
      type: data?.type || "",
      speciality_id: data?.speciality_id || "",
      tags: [],
      timezone: data?.timezone || "",
      organization_id: data?.organization_id || "",
      name: data?.name || "",
      organization_domain: data?.organization_domain || "",
      npi_no: data?.npi_no || "",
      org_npi: data?.org_npi || "",
      npi_type: data?.npi_type || "",
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
    validationSchema: validateLoginForm,
  });

  return (
    <>
      {/* <Paper> */}
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{
              margin: "auto",
              width: "90%",
              height: "100%",
              display: "flex",
              justifyContent: "space-around",
              borderRadius: "10px",
              marginBottom:"20px"
            }}
          >
            <Grid item xs={12} lg={8}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={formik.handleChange("type")}
                  onBlur={formik.handleBlur("type")}
                  value={formik?.values?.type}
                  error={formik.errors.type ? true : false}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      value="DOCTOR"
                      control={<Radio />}
                      label="Doctor"
                    />
                    <FormControlLabel
                      value="Nurse"
                      control={<Radio />}
                      label="Nurse"
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Grid>
            {formik?.values?.type === "Nurse" ? (
              <Grid item xs={12} lg={8}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Organization Type
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={formik.handleChange("organization_type")}
                    onBlur={formik.handleBlur("organization_type")}
                    value={formik?.values?.organization_type}
                    error={formik.errors.organization_type ? true : false}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        value="Add"
                        control={<Radio />}
                        label="New"
                      />

                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Existing"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} lg={8}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Organization Type
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={formik.handleChange("organization_type")}
                    onBlur={formik.handleBlur("organization_type")}
                    value={formik?.values?.organization_type}
                    error={formik.errors.organization_type ? true : false}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        value="Add"
                        control={<Radio />}
                        label="New"
                      />

                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Existing"
                      />

                      <FormControlLabel
                        value="individual"
                        control={<Radio />}
                        label="Individual"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}

            {formik?.values?.organization_type === "yes" ? (
              <>
                <Grid
                  xs={11}
                  lg={8}
                  sx={{
                    marginTop: "1rem",
                    marginLeft: "16px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="Enter Organization Code"
                    onChange={formik.handleChange("organization_id")}
                    onBlur={formik.handleBlur("organization_id")}
                    value={formik?.values?.organization_id}
                    error={
                      !formik.touched.organization_id &&
                      Boolean(formik.errors.organization_id)
                    }
                    helperText={
                      !formik.touched.organization_id &&
                      formik.errors.organization_id
                    }
                    sx={{
                      width: "60%",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ color: "#B2B9CC" }}
                        >
                          <PersonOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    variant="contained"
                    sx={{ width: "30%", height: "50px", background: "#3F51B5" }}
                    disabled={
                      formik?.values?.organization_id === "" ? true : false
                    }
                    onClick={() =>
                      handleVerifyOrg(formik?.values?.organization_id)
                    }
                  >
                    Verify Organization
                  </Button>
                </Grid>
              </>
            ) : null}

            {formik?.values?.organization_type === "Add" ? (
              <>
                <Grid item xs={11} lg={8}>
                  <TextField
                    fullWidth
                    label="Organization Name"
                    variant="outlined"
                    type="text"
                    onChange={formik.handleChange("name")}
                    onBlur={formik.handleBlur("name")}
                    value={formik?.values?.name}
                    error={!formik.touched.name && Boolean(formik.errors.name)}
                    helperText={!formik.touched.name && formik.errors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ color: "#B2B9CC" }}
                        >
                          <PersonOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  xs={11}
                  lg={8}
                  sx={{
                    marginTop: "1rem",
                    marginLeft: "16px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Organization NPI"
                    variant="outlined"
                    type="text"
                    onChange={formik.handleChange("org_npi")}
                    onBlur={formik.handleBlur("org_npi")}
                    value={formik?.values?.org_npi}
                    sx={{
                      width: "60%",
                    }}
                    error={
                      !formik.touched.org_npi && Boolean(formik.errors.org_npi)
                    }
                    helperText={
                      !formik.touched.org_npi && formik.errors.org_npi
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ color: "#B2B9CC" }}
                        >
                          {npi_org_correct ? (
                            <CheckCircleIcon sx={{ color: "green" }} />
                          ) : (
                            <>
                              {npi_org_loading ? (
                                <SyncIcon sx={{ color: "blue" }} />
                              ) : (
                                <CancelIcon sx={{ color: "blue" }} />
                              )}
                            </>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    variant="contained"
                    sx={{ width: "30%", height: "50px", background: "#3F51B5" }}
                    disabled={
                      formik?.values?.org_npi === "" ||
                      formik?.values?.name === ""
                        ? true
                        : false
                    }
                    onClick={() =>
                      orgNpiSubmit(formik.values.org_npi, formik.values.name)
                    }
                  >
                    Verify Organization
                  </Button>
                </Grid>

                <Grid
                  xs={11}
                  lg={8}
                  sx={{
                    marginTop: "1rem",
                    marginLeft: "16px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Organization Domain"
                    variant="outlined"
                    type="text"
                    onChange={formik.handleChange("organization_domain")}
                    onBlur={formik.handleBlur("organization_domain")}
                    value={formik?.values?.organization_domain}
                    sx={{
                      width: "60%",
                    }}
                    error={
                      !formik.touched.organization_domain &&
                      Boolean(formik.errors.organization_domain)
                    }
                    helperText={
                      !formik.touched.organization_domain &&
                      formik.errors.organization_domain
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ color: "#B2B9CC" }}
                        >
                          <PersonOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    variant="contained"
                    sx={{ width: "30%", height: "50px", background: "#3F51B5" }}
                    disabled={
                      formik?.values?.organization_domain === "" ||
                      formik?.values?.name === "" ||
                      npi_org_correct === false
                        ? true
                        : false
                    }
                    onClick={() =>
                      orgSubmit(
                        formik.values.name,
                        formik.values.organization_domain
                      )
                    }
                  >
                    Add Organization
                  </Button>
                </Grid>
              </>
            ) : null}

            <Grid item xs={11} lg={8}>
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                type="text"
                disabled={npi_correct ? true : false}
                onChange={formik.handleChange("FirstName")}
                onBlur={formik.handleBlur("FirstName")}
                value={formik?.values?.FirstName}
                error={
                  !formik.touched.FirstName && Boolean(formik.errors.FirstName)
                }
                helperText={
                  !formik.touched.FirstName && formik.errors.FirstName
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                      <PersonOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={11} lg={8}>
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                type="text"
                onChange={formik.handleChange("LastName")}
                onBlur={formik.handleBlur("LastName")}
                value={formik?.values?.LastName}
                disabled={npi_correct ? true : false}
                error={
                  !formik.touched.LastName && Boolean(formik.errors.LastName)
                }
                helperText={!formik.touched.LastName && formik.errors.LastName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                      <PersonOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {formik?.values?.type === "Nurse" ? (
              <> </>
            ) : (
              <Grid
                item
                xs={11}
                lg={8}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TextField
                  fullWidth
                  label="NPI Number"
                  variant="outlined"
                  type="text"
                  onChange={formik.handleChange("npi_no")}
                  onBlur={formik.handleBlur("npi_no")}
                  value={formik?.values?.npi_no}
                  error={
                    !formik.touched.npi_no && Boolean(formik.errors.npi_no)
                  }
                  helperText={!formik.touched.npi_no && formik.errors.npi_no}
                  sx={{
                    width: "80%",
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "#B2B9CC" }}
                      >
                        {npi_correct ? (
                          <CheckCircleIcon sx={{ color: "green" }} />
                        ) : (
                          <>
                            {npi_loading ? (
                              <SyncIcon sx={{ color: "blue" }} />
                            ) : (
                              <CancelIcon sx={{ color: "blue" }} />
                            )}
                          </>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  disabled={formik.values.npi_no === "" ? true : false}
                  sx={{
                    width: "20%",
                    height: "55px",
                    marginLeft: "5px",
                    background: "#3F51B5",
                  }}
                  onClick={() =>
                    checkNpi(
                      formik.values.npi_no,
                      formik.values.FirstName,
                      formik.values.LastName
                    )
                  }
                >
                  Check Npi
                </Button>
              </Grid>
            )}

            <Grid
              xs={12}
              lg={5}
              sx={{ marginTop: "1rem", marginLeft: "16px" }}
            ></Grid>

            {npi_correct || formik?.values?.type === "Nurse" ? (
              <>
                <Grid item xs={11} lg={8}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="email"
                    onChange={formik.handleChange("Email")}
                    onBlur={formik.handleBlur("Email")}
                    value={formik?.values?.Email}
                    error={
                      !formik.touched.Email && Boolean(formik.errors.Email)
                    }
                    helperText={!formik.touched.Email && formik.errors.Email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ color: "#B2B9CC" }}
                        >
                          <EmailOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={11} lg={8}>
                  <CountrySelect formik={formik} />
                </Grid>

                <Grid item xs={11} lg={8}>
                  <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    type="text"
                    onChange={formik.handleChange("Phone")}
                    onBlur={formik.handleBlur("Phone")}
                    value={formik?.values?.Phone}
                    error={
                      !formik.touched.Phone && Boolean(formik.errors.Phone)
                    }
                    helperText={!formik.touched.Phone && formik.errors.Phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ color: "#B2B9CC" }}
                        >
                          <PhoneAndroidIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={11} lg={8}>
                  <SelectComponent
                    labelId="Sex"
                    id="demo-simple-select"
                    label="Sex"
                    onChange={formik.handleChange("Sex")}
                    onBlur={formik.handleBlur("Sex")}
                    value={formik?.values?.Sex}
                    error={formik.errors.Sex ? true : false}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        sx={{ color: "#B2B9CC" }}
                      >
                        <PersonIcon />
                      </InputAdornment>
                    }
                    items={gender_data?.map((item) => {
                      return { key: item?.value, value: item?.key };
                    })}
                  />

                  <FormHelperText>
                    {formik.errors.Sex ? "Sex is invalid" : ""}
                  </FormHelperText>
                </Grid>

                {formik?.values?.type === "Nurse" ? (
                  <>
                    <Grid item xs={11} lg={8}>
                      <TextField
                        fullWidth
                        label="Address Line 1"
                        variant="outlined"
                        type="text"
                        onChange={formik.handleChange("Address1")}
                        onBlur={formik.handleBlur("Address1")}
                        value={formik?.values?.Address1}
                        error={
                          !formik.touched.Address1 && Boolean(formik.Address1)
                        }
                        helperText={!formik.touched.Address1 && formik.Address1}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <FmdGoodIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={11} lg={8}>
                      <TextField
                        fullWidth
                        label="Address Line 2"
                        variant="outlined"
                        type="text"
                        onChange={formik.handleChange("Address2")}
                        onBlur={formik.handleBlur("Address2")}
                        value={formik?.values?.Address2}
                        error={
                          !formik.touched.Address2 && Boolean(formik.Address2)
                        }
                        helperText={!formik.touched.Address2 && formik.Address2}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <FmdGoodIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={11} lg={8}>
                      <SelectComponent
                        fullWidth
                        labelId="State"
                        id="demo-simple-select"
                        label="State"
                        onChange={(e) => {
                          formik.setFieldValue("City", "");
                          dispatch(
                            getCityList({
                              country_code,
                              state_code: e.target.value,
                            })
                          );
                          formik.setFieldValue("State", e.target.value);
                        }}
                        onBlur={formik.handleBlur("State")}
                        value={formik?.values?.State}
                        error={formik.errors.State ? true : false}
                        disabled={!state_data.length}
                        startAdornment={
                          <InputAdornment
                            position="start"
                            sx={{ color: "#B2B9CC" }}
                          >
                            <FmdGoodIcon />
                          </InputAdornment>
                        }
                        items={state_data}
                      />
                      <FormHelperText>
                        {formik.errors.State ? "State is invalid" : ""}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={11} lg={8}>
                      <SelectComponent
                        labelId="City"
                        id="demo-simple-select"
                        label="City"
                        onChange={formik.handleChange("City")}
                        onBlur={formik.handleBlur("City")}
                        value={formik?.values?.City}
                        error={formik.errors.City ? true : false}
                        disabled={!state_data.length}
                        startAdornment={
                          <InputAdornment
                            position="start"
                            sx={{ color: "#B2B9CC" }}
                          >
                            <FmdGoodIcon />
                          </InputAdornment>
                        }
                        items={city_data}
                      />
                      <FormHelperText>
                        {formik.errors.City ? "City is invalid" : ""}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={11} lg={8}>
                      <TextField
                        fullWidth
                        label="Zipcode"
                        variant="outlined"
                        type="text"
                        onChange={formik.handleChange("Zipcode")}
                        onBlur={formik.handleBlur("Zipcode")}
                        value={formik?.values?.Zipcode}
                        error={
                          !formik.touched.Zipcode &&
                          Boolean(formik.errors.Zipcode)
                        }
                        helperText={
                          !formik.touched.Zipcode && formik.errors.Zipcode
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ color: "#B2B9CC" }}
                            >
                              <FmdGoodIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={8} lg={8}>
                  {formik?.values?.organization_type === "individual" ? (
                    <Button
                      style={{ width: "100%", float: "right" }}
                      type="submit"
                      disableElevation
                      variant="contained"
                      disabled={!formik.isValid}
                      loading={loading}
                      fullWidth
                      sx={{
                        marginBottom: "50px",
                      }}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      style={{ width: "100%", float: "right" }}
                      type="submit"
                      disableElevation
                      variant="contained"
                      disabled={!formik.isValid || !orgverify}
                      loading={loading}
                      fullWidth
                      sx={{
                        marginBottom: "50px",
                      }}
                    >
                      Next
                    </Button>
                  )}
                </Grid>
              </>
            ) : null}
          </Grid>
        </form>
      {/* </Paper> */}
    </>
  );
};

export default AddDoctor;
