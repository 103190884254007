import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LegendPopOver from "./LegenPopOver";

const AppointmentHeader = (props) => {
  const { title, date } = props;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="h6">{title}</Typography>
        <LegendPopOver />
        <Typography variant="h6">{date}</Typography>
      </Stack>
    </>
  );
};

export default AppointmentHeader;
