import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Button from "../../common/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PersonIcon from "@mui/icons-material/Person";
import { useFormik } from "formik";
import * as yup from "yup";
import CountrySelect from "./CountrySelect";
import { FormHelperText } from "@mui/material";
import {
  getCityList,
  getStateList,
  set_country_code,
} from "../../../store/query/querySlice";
import SelectComponent from "../../common/Select";
import Tags from "../../support/doctor/Tags";
import { isAuth } from "../../../helpers/cookies";
import { getUniqueCountryByPhone } from "../../../helpers/countries";

import momentTZ from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SyncIcon from "@mui/icons-material/Sync";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";

const validateLoginForm = yup.object().shape({
  Email: yup.string().email().required(),
  FirstName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
  LastName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
  Phone: yup.number().required("Phone  number is required!").positive().min(10),
  Address1: yup.string().required("Address is required!"),
  Address2: yup.string().optional(),
  City: yup.string().required("City is required!"),
  State: yup.string().required("State is required!"),
  Zipcode: yup.string().required("Zipcode is required!"),
  country_code: yup.string().required("Country code is required!"),
  Sex: yup.string().required("Sex is required!"),
  type: yup.string().required("Type is required!"),
  timezone: yup.string().required("Timezone is required!"),
  // organization_id: yup.number().required("Organization is required!"),
  // speciality: yup.string().optional().nullable(),
});

const AddDoctor = (props) => {
  const { country_code } = useSelector((state) => state.query);
  const dispatch = useDispatch();
  const {
    loading,
    addDoctors,
    speciality_list,
    state_data,
    city_data,
    ProvidersData,
    update,
    is_update,
    get_org_data,
    gender_data,
    checkNpi,
    npi_correct,
    npi_loading,
  } = props;

  const timeZonesList = momentTZ.tz.names();

  const onSubmit = async (values) => {
    let payload = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      Phone: values.Phone,
      country_code: values.country_code,
      Address1: values.Address1,
      Address2: values.Address2,
      State: values.State,
      City: values.City,
      Sex: values.Sex,
      Zipcode: values.Zipcode,
      speciality_id: values.speciality_id,
      tags: values.tags.map(function (d) {
        return d["value"];
      }),
      timezone: values.timezone,
      organization_id: values.organization_id,
      npi_no: values.npi_no,
    };

    if (is_update) {
      payload.ProviderId = ProvidersData.ProviderId;
      update(payload);
    } else {
      payload.type = values.type;
      addDoctors(payload);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true, // validate fields on blur
    validateOnSubmit: true, // validate fields on submit
    initialValues: is_update
      ? ProvidersData
      : {
          FirstName: "",
          LastName: "",
          Address1: "",
          Address2: "",
          City: "",
          Email: "",
          State: "",
          Zipcode: "",
          country_code: "",
          Phone: "",
          Sex: "",
          type: "DOCTOR",
          speciality_id: "",
          tags: [],
          timezone: "",
          // organization_id: "",
          npi_no: "",
        },
    onSubmit: (values) => {
      onSubmit(values);
    },
    validationSchema: validateLoginForm,
  });

  useEffect(() => {
    if (formik.values?.country_code) {
      let countryCodeData = getUniqueCountryByPhone(
        formik.values?.country_code.replace("+", "")
      );
      dispatch(
        set_country_code({
          country_code: countryCodeData?.code,
        })
      );
      dispatch(getStateList({ country_code: countryCodeData?.code }));
      if (formik?.values?.State) {
        dispatch(
          getCityList({
            country_code: countryCodeData?.code,
            state_code: formik?.values?.State,
          })
        );
      }
    }
  }, [formik.values?.country_code, formik?.values?.State]);


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* <FormControl> */}
        <Grid container spacing={2}>
          {isAuth().role_name === "SUPPORT" ||
          isAuth().role_name === "ADMIN" ? (
            <Grid item xs={4}>
              <SelectComponent
                labelId="Type"
                id="demo-simple-select"
                label="Type"
                onChange={formik.handleChange("type")}
                onBlur={formik.handleBlur("type")}
                value={formik?.values?.type}
                defaultValue={formik?.values?.type}
                error={formik.errors.type ? true : false}
                startAdornment={
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <PersonIcon />
                  </InputAdornment>
                }
                items={[
                  { key: "Doctor", value: "DOCTOR" },
                  { key: "Nurse", value: "NURSE" },
                ]}
                disabled={is_update ? true : false}
              />
            </Grid>
          ) : null}
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="First Name *"
              variant="outlined"
              type="text"
              onChange={formik.handleChange("FirstName")}
              onBlur={formik.handleBlur("FirstName")}
              value={formik?.values?.FirstName}
              disabled={npi_correct ? true : false}
              error={
                !formik.touched.FirstName && Boolean(formik.errors.FirstName)
              }
              helperText={!formik.touched.FirstName && formik.errors.FirstName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <PersonOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Last Name *"
              variant="outlined"
              type="text"
              onChange={formik.handleChange("LastName")}
              onBlur={formik.handleBlur("LastName")}
              disabled={npi_correct ? true : false}
              value={formik?.values?.LastName}
              error={
                !formik.touched.LastName && Boolean(formik.errors.LastName)
              }
              helperText={!formik.touched.LastName && formik.errors.LastName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <PersonOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Email *"
              variant="outlined"
              type="email"
              onChange={formik.handleChange("Email")}
              onBlur={formik.handleBlur("Email")}
              value={formik?.values?.Email}
              error={!formik.touched.Email && Boolean(formik.errors.Email)}
              helperText={!formik.touched.Email && formik.errors.Email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <EmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <CountrySelect formik={formik} />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Phone *"
              variant="outlined"
              type="text"
              onChange={formik.handleChange("Phone")}
              onBlur={formik.handleBlur("Phone")}
              value={formik?.values?.Phone}
              error={!formik.touched.Phone && Boolean(formik.Phone)}
              helperText={!formik.touched.Phone && formik.Phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <PhoneAndroidIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Address Line 1"
              variant="outlined"
              type="text"
              onChange={formik.handleChange("Address1")}
              onBlur={formik.handleBlur("Address1")}
              value={formik?.values?.Address1}
              error={!formik.touched.Address1 && Boolean(formik.Address1)}
              helperText={!formik.touched.Address1 && formik.Address1}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <FmdGoodIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Address Line 2"
              variant="outlined"
              type="text"
              onChange={formik.handleChange("Address2")}
              onBlur={formik.handleBlur("Address2")}
              value={formik?.values?.Address2}
              error={!formik.touched.Address2 && Boolean(formik.Address2)}
              helperText={!formik.touched.Address2 && formik.Address2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <FmdGoodIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <SelectComponent
              fullWidth
              labelId="State"
              id="demo-simple-select"
              label="State"
              onChange={(e) => {
                formik.setFieldValue("City", "");
                dispatch(
                  getCityList({
                    country_code,
                    state_code: e.target.value,
                  })
                );
                formik.setFieldValue("State", e.target.value);
              }}
              onBlur={formik.handleBlur("State")}
              value={formik?.values?.State}
              error={formik.errors.State ? true : false}
              disabled={!state_data.length}
              startAdornment={
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <FmdGoodIcon />
                </InputAdornment>
              }
              items={state_data}
              // onChange={formik.handleChange("State")}
              // onBlur={formik.handleBlur("State")}
              // value={formik?.values?.State}
              // error={formik.errors.State ? true : false}
              // startAdornment={
              //   <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
              //     <FmdGoodIcon />
              //   </InputAdornment>
              // }
              // items={state_data}
            />
          </Grid>

          <Grid item xs={4}>
            <SelectComponent
              labelId="City"
              id="demo-simple-select"
              label="City"
              onChange={formik.handleChange("City")}
              onBlur={formik.handleBlur("City")}
              value={formik?.values?.City}
              error={formik.errors.City ? true : false}
              disabled={!state_data.length}
              startAdornment={
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <FmdGoodIcon />
                </InputAdornment>
              }
              items={city_data}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Zipcode *"
              variant="outlined"
              type="text"
              onChange={formik.handleChange("Zipcode")}
              onBlur={formik.handleBlur("Zipcode")}
              value={formik?.values?.Zipcode}
              error={!formik.touched.Zipcode && Boolean(formik.errors.Zipcode)}
              helperText={!formik.touched.Zipcode && formik.errors.Zipcode}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <FmdGoodIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {formik?.values?.type === "DOCTOR" ? (
            <Grid item xs={4}>
              <SelectComponent
                labelId="speciality_id"
                id="demo-simple-select"
                label="Speciality"
                onChange={formik.handleChange("speciality_id")}
                // onBlur={formik.handleBlur("speciality_id")}
                value={formik?.values?.speciality_id}
                // error={formik.errors.speciality_id ? true : false}
                startAdornment={
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <FmdGoodIcon />
                  </InputAdornment>
                }
                items={speciality_list}
              />
            </Grid>
          ) : null}

          <Grid item xs={4}>
            <SelectComponent
              labelId="Sex"
              id="demo-simple-select"
              label="Sex *"
              onChange={formik.handleChange("Sex")}
              onBlur={formik.handleBlur("Sex")}
              value={formik?.values?.Sex}
              error={formik.errors.Sex ? true : false}
              startAdornment={
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <PersonIcon />
                </InputAdornment>
              }
              items={gender_data?.map((item) => {
                return { key: item?.value, value: item?.key };
              })}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              id="country-select-demo"
              options={timeZonesList}
              autoHighlight
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                formik.setFieldValue("timezone", newValue);
              }}
              onBlur={formik.handleBlur("timezone")}
              value={formik?.values?.timezone}
              error={formik.errors.timezone ? true : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Timezone *"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
          {is_update ? null : (
            <Grid item xs={4}>
              <SelectComponent
                labelId="doctor-org-select-label"
                id="doctor-org-select"
                label="Select Organization *"
                onChange={formik.handleChange("organization_id")}
                onBlur={formik.handleBlur("organization_id")}
                value={formik?.values?.organization_id}
                error={formik.errors.organization_id ? true : false}
                startAdornment={
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <PersonIcon />
                  </InputAdornment>
                }
                items={get_org_data}
              />
            </Grid>
          )}

          {formik?.values?.type === "DOCTOR" ? (
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextField
                fullWidth
                label="NPI Number"
                variant="outlined"
                type="text"
                onChange={formik.handleChange("npi_no")}
                onBlur={formik.handleBlur("npi_no")}
                value={formik?.values?.npi_no}
                error={!formik.touched.npi_no && Boolean(formik.errors.npi_no)}
                helperText={!formik.touched.npi_no && formik.errors.npi_no}
                sx={{
                  width: "80%",
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 10);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                      {npi_correct ? (
                        <CheckCircleIcon sx={{ color: "green" }} />
                      ) : (
                        <>
                          {npi_loading ? (
                            <SyncIcon sx={{ color: "blue" }} />
                          ) : (
                            <CancelIcon sx={{ color: "blue" }} />
                          )}
                        </>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                disabled={formik.values.npi_no === "" ? true : false}
                sx={{
                  width: "20%",
                  height: "55px",
                  marginLeft: "5px",
                  background: "#3F51B5",
                }}
                onClick={() =>
                  checkNpi(
                    formik.values.npi_no,
                    formik.values.FirstName,
                    formik.values.LastName
                  )
                }
              >
                Check Npi
              </Button>
            </Grid>
          ) : null}

          {formik?.values?.type === "DOCTOR" ? (
            <Grid item xs={4}>
              <Tags data={speciality_list} formik={formik} />
            </Grid>
          ) : null}
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={4.5}>
            <Button
              type="submit"
              disableElevation
              variant="contained"
              disabled={
                is_update
                  ? false
                  : formik.values.type === "DOCTOR"
                  ? !formik.isValid || !npi_correct
                  : !formik.isValid
              }
              loading={loading}
              fullWidth
            >
              {is_update
                ? `UPDATE ${formik?.values?.type}`
                : `ADD ${formik?.values?.type}`}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddDoctor;
