import { format, parseISO } from "date-fns/esm";
import { get } from "lodash-es";
import { S3ProfileImage } from "../../helpers/constants";
import { calculateBirthDate } from "../../helpers/datetime";

export const parser_add_doctors_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const get_org_list_parser = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response.map((item) => {
      return {
        value: item.id,
        key: item.name,
        unique_id: item.unique_id,
        organization_domain: item.organization_domain,
      };
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_get_patients_by_name_or_phone = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      PatientId: get(e, "PatientId", ""),
      FirstName: get(e, "FirstName", ""),
      LastName: get(e, "LastName", ""),
      EmailAddress: get(e, "EmailAddress", ""),
      BirthDate: get(e, "BirthDate", ""),
      mobile: get(e, "mobile", ""),
      avatar: get(e, "avatar", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_add_patients_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_get_update_doctor_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return {
      ProviderId: get(response, "ProviderId", ""),
      FirstName: get(response, "FirstName", ""),
      LastName: get(response, "LastName", ""),
      address:
        get(response, "Address1", "") + " " + get(response, "Address2", ""),
      Address1: get(response, "Address1", ""),
      Address2: get(response, "Address2", ""),
      City: get(response, "City", ""),
      avatar: get(response, "avatar", ""),
      Email: get(response, "Email", ""),
      State: get(response, "State", ""),
      Zipcode: get(response, "Zipcode", ""),
      Phone: get(response, "Phone", ""),
      country_code: get(response, "country_code", ""),
      mobile: get(response, "mobile", ""),
      organization_id: get(response, "organization_id", ""),
      organization_details_doctor: get(
        response,
        "organization_details_doctor",
        ""
      ),
      speciality_id: get(response, "speciality_id", ""),
      Sex: get(response, "Sex", ""),
      Status: get(response, "Status", ""),
      role_id: get(response, "role_id", ""),
      role_name: get(response, "role_name", ""),
      type: get(response, "role_name", ""),
      mute_notifications: get(response, "mute_notifications", ""),
      player_id: get(response, "player_id", ""),
      createdAt: get(response, "createdAt", ""),
      updatedAt: get(response, "updatedAt", ""),
      qr_code: get(response, "qr_code", ""),
      speciality: get(response, "speciality", ""),
      tags: response.tags.map((tags) => {
        return {
          speciality_id: get(tags, "speciality_id", ""),
          value: get(tags, "speciality_id", ""),
          title: get(tags, "title", ""),
          key: get(tags, "title", ""),
        };
      }),
      termsAndConditions: get(response, "terms_and_conditions", ""),
      timezone: get(response, "time_zone_name", ""),
      login_sms_enable: get(response, "login_sms_enable", ""),
      npi_no: get(response, "npi_no", ""),
      payment_status: get(response, "payment_status", ""),
      is_payment_made_by_admin: get(response, "is_payment_made_by_admin", ""),
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_add_doctor_directory_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_update_doctor_directory_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const parser_update_doctor_profile_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const parser_get_doctor_directory_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }

    return {
      ProviderId: get(response, "ProviderId", ""),
      FirstName: get(response, "FirstName", ""),
      LastName: get(response, "LastName", ""),
      Email: get(response, "Email", ""),
      number: get(response, "Phone", ""),
      country_code: get(response, "country_code", ""),
      speciality_id: get(response, "speciality_id", ""),
      tags: response.tags2.map((tags) => {
        return {
          speciality_id: get(tags, "speciality_id", ""),
          value: get(tags, "speciality_id", ""),
          title: get(tags, "title", ""),
          key: get(tags, "title", ""),
        };
      }),
    };
  } catch (error) {
    throw new Error(error);
  }
};
export const parser_download_qr_code_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_update_profile_pic = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_get_patients_list = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    let gender = get(response, "Sex", "sex");
    if (gender) {
      gender = gender.toLowerCase();
    }
    let sex = "-";
    if (gender === "m") {
      sex = "Male";
    }
    if (gender === "f") {
      sex = "Female";
    }
    if (gender === "o") {
      sex = "Others";
    }
    return {
      PatientId: get(response, "PatientId", ""),
      FirstName: get(response, "FirstName", ""),
      LastName: get(response, "LastName", ""),
      EmailAddress: get(response, "EmailAddress", ""),
      mobile: get(response, "mobile", ""),
      avatar: get(response, "avatar", ""),
      role_name: get(response, "role_name", ""),
      BirthDate: get(response, "BirthDate", "")
        ? format(parseISO(get(response, "BirthDate", "")), "MMMM dd, yyyy")
        : "-",
      Sex: sex,
      my_pharmacy: get(response, "my_pharmacy", ""),
      address:
        get(response, "Address1", "") + " " + get(response, "Address2", ""),
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_get_physician_list = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }
    return {
      id: get(response, "id", ""),
      FirstName: get(response, "FirstName", ""),
      LastName: get(response, "LastName", ""),
      avatar: get(response, "avatar", ""),
      Email: get(response, "Email", ""),
      role_name: get(response, "role_name", ""),
      speciality2: get(response, "speciality2", ""),
      mobile: get(response, "mobile", ""),
      is_hipaasafe_user: get(response, "is_hipaasafe_user", ""),
      tags2: get(response, "tags2", ""),
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_get_unlinked_patient = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }
    return response.map((e) => ({
      PatientId: get(e, "PatientId", ""),
      FirstName: get(e, "FirstName", ""),
      LastName: get(e, "LastName", ""),
      name: get(e, "FirstName", "") + " " + get(e, "LastName", ""),
      EmailAddress: get(e, "EmailAddress", ""),
      mobile: get(e, "mobile", ""),
      avatar: S3ProfileImage + get(e, "avatar", ""),
      BirthDate: calculateBirthDate(get(e, "BirthDate", new Date())).years,
      City: get(e, "City", ""),
      CellPhone: get(e, "CellPhone", ""),
      linked_with_org: get(e, "organization_patient.PatientId", ""),
    }));
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_link_profile_with_org = (response) => {
  try {
    if (response?.data && response?.data[0]) {
      response = response.data[0];
    }
    if (!response) {
      return {};
    }
    return {
      PatientId: get(response, "PatientId", ""),
      organization_id: get(response, "organization_id", ""),
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_update_doctors_url = (response) => {
  try {
    if (response?.data && response?.data[0]) {
      response = response.data[0];
    }
    if (!response) {
      return {};
    }
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

// export const parser_add_doctors_by_code = (response) => {
//   try {
//     if (response?.data) {
//       response = response.data;
//     }
//     if (!response) {
//       return {};
//     }
//     return{
//       uid_or_email: get(response, "uid_or_email", ""),
//     };
//   } catch (error) {
//     throw new Error(error);
//   }
// };
