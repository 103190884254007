import { CometChat } from "@cometchat-pro/chat";
import { getCookie } from "../cookies";

export const cometChatInitialize = async (region, appID) => {
  try {
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .autoEstablishSocketConnection(true)
      .build();
    await CometChat.init(appID, appSetting).then(
      () => {
        console.log("Comet Chat Initialization completed successfully");
      },
      (error) => {
        console.log("Initialization failed with error:", error);
      }
    );
  } catch (error) {
    console.log("CometChat initialization failed with error:", error.message);
    return false;
  }
};

export const cometChatLoginUser = async (uid, authKey) => {
  try {
    let user = await CometChat.getLoggedinUser();

    if (!user) {
      user = await CometChat.login(uid, authKey);
      console.log("Login Successful:", { user });
      return user;
    } else {
      CometChat.logout();
      user = await CometChat.login(uid, authKey);
      console.log("Login Successful:", { user });
      return user;
    }
  } catch (error) {
    console.log("Some Error Occurred", { error });
    throw error;
  }
};

export const cometChatLogoutUser = async () => {
  try {
    const check = await CometChat.logout();
    if (check) {
      console.log("== Comet Chat User Logged Out ==");
    } else {
      console.log("== Comet Chat User Logged Out Failed ==");
    }
    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const cometChatInitializeFCM = async () => {
  try {
    const token = getCookie("fcm_token");
    if (token) {
      const init = await CometChat.registerTokenForPushNotification(token);
      console.log("== Comet Chat Registering FCM Token ==", init);
    } else {
      console.log("FCM Notifications permission not allowed.");
    }
  } catch (error) {
    console.log("Error in registering FCM token", error);
    throw new Error(error.message);
  }
};

// export const cometChatListener = async (set_chat_window_state) => {
//   const ListenerId = "hipaa-safe-call-listener";
//   try {
//    await new CometChat.addCallListener(
//       ListenerId,
//       new CometChat.CallListener({
//         onIncomingCallReceived: (call) => {
//           set_chat_window_state({ state: true });
//         },
//         onOutgoingCallAccepted: (call) => {
//           set_chat_window_state({ state: true });
//         },
//         onOutgoingCallRejected: (call) => {
//           set_chat_window_state({ state: true });
//         },
//         onIncomingCallCancelled: (call) => {
//           set_chat_window_state({ state: true });
//         },
//       })
//     );

//     await  new CometChat.addGroupListener(
//       "hipaa-safe-group-listener",
//       new CometChat.GroupListener({
//         onGroupMemberJoined: (message, joinedUser, joinedGroup) => {
//           //  console.log("onGroupMemberJoined", { message, joinedUser, joinedGroup });
//         },
//         onMemberAddedToGroup: (message, userAdded, addedby, addedTo) => {
//           // window.location.reload();
//           console.log("onMemberAddedToGroup", {
//             message,
//             addedby,
//             userAdded,
//             addedTo,
//           });
//         },
//       })
//     );
//   } catch (e) {
//     console.log("Error in adding listener", e);
//     throw new Error(e.message);
//   }
// };


export const cometChatListener = async (set_chat_window_state) => {
  const ListenerId = "hipaa-safe-call-listener";
  try {
    await CometChat.addCallListener(
      ListenerId,
      new CometChat.CallListener({
        onIncomingCallReceived: (call) => {
          set_chat_window_state({ state: true });
        },
        onOutgoingCallAccepted: (call) => {
          set_chat_window_state({ state: true });
        },
        onOutgoingCallRejected: (call) => {
          set_chat_window_state({ state: true });
        },
        onIncomingCallCancelled: (call) => {
          set_chat_window_state({ state: true });
        },
      })
    );

    await CometChat.addGroupListener(
      "hipaa-safe-group-listener",
      new CometChat.GroupListener({
        onGroupMemberJoined: (message, joinedUser, joinedGroup) => {
          // console.log("onGroupMemberJoined", { message, joinedUser, joinedGroup });
        },
        onMemberAddedToGroup: (message, userAdded, addedby, addedTo) => {
          // window.location.reload();
          console.log("onMemberAddedToGroup", {
            message,
            addedby,
            userAdded,
            addedTo,
          });
        },
      })
    );
  } catch (e) {
    console.log("Error in adding listener", e);
    throw new Error(e.message);
  }
};





export const cometChatMessageListener = async (
  set_unread_message_count,
  set_chat_window_state
) => {
  const ListenerId = "hipaa-safe-message-listener";
  try {
    CometChat.addMessageListener(
      ListenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage) => {
          setUnreadMessageCount(set_unread_message_count);
        },
        onMediaMessageReceived: (mediaMessage) => {
          setUnreadMessageCount(set_unread_message_count);
        },
        onCustomMessageReceived: (customMessage) => {
          set_chat_window_state({ state: true });
          setUnreadMessageCount(set_unread_message_count);
        },
      })
    );
  } catch (e) {
    console.log("Error in adding listener", e);
    throw new Error(e.message);
  }
};

export const setUnreadMessageCount = async (set_unread_message_count) => {
  try {
    CometChat.getUnreadMessageCount().then(
      (array) => {
        let count = 0;
        if (array.users) {
          for (let key in array.users) {
            if (array.users.hasOwnProperty(key)) {
              count += array.users[key];
            }
          }
        }
        if (array.groups) {
          for (let key in array.groups) {
            if (array.groups.hasOwnProperty(key)) {
              count += array.groups[key];
            }
          }
        }

        set_unread_message_count(count);
      },
      (error) => {
        console.log("Error in getting message count", error);
      }
    );
  } catch (e) {
    console.log("Error in getting message count", e);
    throw new Error(e.message);
  }
};

export default CometChat;
