import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  querySupportDashboardKpi,
  queryDoctorMyTeams,
  queryDoctorNurseList,
  queryNurseDoctorMappingList,
  updateNurseDoctorMapping,
  adminDoctorList,
} from "../../../store/query/querySlice";
import { getOrgList ,getOrg} from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  return {
    support_dashboard_kpi_loading: state.query.support_dashboard_kpi_loading,
    support_dashboard_kpi_data: state.query.support_dashboard_kpi_data,

    doctor_my_teams_loading: state.query.admin_doctor_list_is_loading,
    doctor_my_teams_data: state.query.admin_doctor_list_data,

    doctor_nurse_list_loading: state.query.doctor_nurse_list_loading,
    doctor_nurse_list_data: state.query.doctor_nurse_list_data,
    nurse_doctor_map_list_data: state.query.nurse_doctor_map_list_data,
    nurse_doctor_map_list_is_loading: state.query.nurse_doctor_map_list_is_loading,
    nurse_doctor_map_update_is_loading: state.query.nurse_doctor_map_update_is_loading,
    get_org_data: state.user.get_org_data,
    get_single_org_is_loading: state.user.get_single_org_is_loading,
    get_single_org_data: state.user.get_single_org_data,

   
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      querySupportDashboardKpi,
      queryDoctorMyTeams:adminDoctorList,
      queryDoctorNurseList,
      queryNurseDoctorMappingList,
      updateNurseDoctorMapping,
      getOrgList,
      getOrg,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
