import { createSlice } from "@reduxjs/toolkit";
import { isAuth } from "../../helpers/cookies";

const initialState = {
  DoctorId:
    (isAuth().doctors_mapped &&
      isAuth().doctors_mapped[0] &&
      isAuth().doctors_mapped[0].ProviderId) ||
    isAuth().ProviderId,
  UserAvatar: isAuth().avatar,
  dark_mode: "light",
  snackbar: {
    open: false,
    message: "",
    severity: "info",
  },
  chat_window_state: false,
  chat_window_uid: "",
  chat_window_guid: "",
  unread_message_count: 0,
  add_appointment_drawer_state: false,
  add_patient_drawer_state: false,
  patient_link_drawer_state: false,
  tab_value_state: "1",
  termsAndConditions: isAuth().terms_and_conditions || false,
};

export const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    set_dark_mode: (state, action) => {
      state.dark_mode = action.payload
        ? action.payload
        : state.dark_mode === "light"
          ? "dark"
          : "light";
    },
    setSnackBar: (state, action) => {
      state.snackbar = action.payload;
    },
    setTabValue: (state, action) => {
      state.tab_value_state = action.payload.state;
    },
    set_chat_window_state: (state, action) => {
      if (action.payload) {
        state.chat_window_state = action.payload.state;
      } else {
        state.chat_window_uid = "";
        state.chat_window_guid = "";
        state.chat_window_state = !state.chat_window_state;
      }
    },
    set_chat_window_ids: (state, action) => {
      const { uid, guid, none } = action.payload;
      if (uid) {
        state.chat_window_uid = uid;
        state.chat_window_guid = "";
        state.chat_window_state = true;
      }
      if (guid) {
        state.chat_window_uid = "";
        state.chat_window_guid = guid;
        state.chat_window_state = true;
      }
      if (none) {
        state.chat_window_uid = "";
        state.chat_window_guid = "";
      }
    },
    set_unread_message_count: (state, action) => {
      state.unread_message_count = action.payload;
    },
    set_appointment_drawer_state: (state, action) => {
      const { show } = action.payload;
      state.add_appointment_drawer_state = show ? true : false;
      state.patients_by_search_data = [];
    },
    set_patient_drawer_state: (state, action) => {
      const { show } = action.payload;
      state.add_patient_drawer_state = show ? true : false;
    },
    set_patient_link_drawer_state: (state, action) => {
      const { show } = action.payload;
      state.patient_link_drawer_state = show ? true : false;
    },
    set_doctor_id: (state, action) => {
      const { ProviderId } = action.payload;
      state.DoctorId = ProviderId;
    },
    set_organization_id: (state, action) => {
      const { OrganizationId } = action.payload;
      state.OrganizationId = OrganizationId;
    },
    set_profile_avatar: (state, action) => {
      const { avatar } = action.payload;
      state.UserAvatar = avatar;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTabValue,
  set_dark_mode,
  setSnackBar,
  set_chat_window_state,
  set_chat_window_ids,
  set_unread_message_count,
  set_appointment_drawer_state,
  set_patient_drawer_state,
  set_patient_link_drawer_state,
  set_doctor_id,
  set_profile_avatar,
} = common.actions;

export default common.reducer;
