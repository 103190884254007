import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { set_appointment_drawer_state } from "../common/commonSlice";
// import { isAuth } from "../../helpers/cookies";
import {
  api_doctor_add_appointments,
  api_doctor_get_my_appointments,
  api_doctor_update_appointments,
} from "./appointmentApis";
import { parser_doctor_my_appointments } from "./appointmentParser";

export const doctorGetMyAppointments = createAsyncThunk(
  "doctorGetMyAppointments",
  async (payload) => {
    try {
      const { DoctorId, date } = payload;
      const params = {
        page: 1,
        limit: 100,
        date,
        DoctorId,
        type: "SCHEDULE",
      };
      const response = await api_doctor_get_my_appointments(params);

  
      
      const data = parser_doctor_my_appointments(response);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addDoctorAppointments = createAsyncThunk(
  "addDoctorAppointments",
  async (payload) => {
    try {
      const response = await api_doctor_add_appointments(payload);
      return response;
    } catch (error) {
      console.log("Error,", error.message);
      throw new Error(error);
    }
  }
);

export const updateAppointment = createAsyncThunk(
  "updateAppointment",
  async (payload) => {
    try {
      const response = await api_doctor_update_appointments(payload);
      // const data = parser_doctor_update_appointments(response);
      return response;
    } catch (error) {
      console.log("Error,", error.message);
      throw new Error(error);
    }
  }
);

const initialState = {
  // Fetch Appointments
  doctor_my_appointments_loading: false,
  doctor_add_appointments_loading: false,
  update_appointment_data: {},
  update_appointment_is_loading: false,
  doctor_my_appointments_data: [],
  doctor_my_appointments_kpi: {},
  view_appointment_data: null,
  doctor_appointment_data_no_filter: [],
};

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    set_view_appointment_data: (state, action) => {
      state.view_appointment_data = action.payload.data;
    },
    set_doctor_my_appointments_data_created: (state, action) => {
      const { data } = action.payload;
      console.table("data", data);
    
      let filter = state.doctor_appointment_data_no_filter.filter(
        (obj) => obj.AppointmentId === data.AppointmentId
      );

      if (filter.length <= 0) {
        const serializedData = {
          ...data,
          start_time: data.StartTime,
        };

        state.doctor_appointment_data_no_filter = [
          ...state.doctor_appointment_data_no_filter,
          serializedData,
        ];
      }
    },

    // set_doctor_my_appointments_data_created: (state, action) => {
    //   const { data } = action.payload;
    //   const exists = state.doctor_appointment_data_no_filter.some(
    //     (obj) => obj.AppointmentId === data.AppointmentId
    //   );
    //   if (exists) {
    //     return;
    //   }
    //   const { start_time, ...rest } = data;
    //   const serializedData = {
    //     ...rest,
    //     start_time: start_time.toISOString(),
    //   };
    //   state.doctor_appointment_data_no_filter.push(serializedData);
    // }

    set_doctor_my_appointments_data_update: (state, action) => {
      const { data, type } = action.payload;
      if (type === "array") {
        for (let j = 0; j < data.length; j++) {
          const socketAppointment = data[j];
          state.doctor_appointment_data_no_filter.forEach((obj, index) => {
            if (obj.AppointmentId === socketAppointment.AppointmentId) {
              state.doctor_appointment_data_no_filter[index] = {
                ...obj,
                ...socketAppointment,
              };
            }
          });
        }
      } else if (data) {
        state.doctor_appointment_data_no_filter.forEach((obj, index) => {
          if (obj.AppointmentId === data.AppointmentId) {
            state.doctor_appointment_data_no_filter[index] = {
              ...obj,
              ...data,
            };
          }
        });
      }
    },
    set_doctor_my_appointments_data: (state, action) => {
      const { data, kpi } = action.payload;
      state.doctor_my_appointments_data = data;
      state.doctor_my_appointments_kpi = kpi;
    },
  },
  extraReducers: {
    // Get my appointments
    [doctorGetMyAppointments.pending]: (state, action) => {
      state.doctor_my_appointments_loading = true;
    },
    [doctorGetMyAppointments.fulfilled]: (state, action) => {
      const { no_filter, data, kpi } = action.payload;
      state.doctor_my_appointments_data = [];
      state.doctor_my_appointments_data = data;
      state.doctor_appointment_data_no_filter = no_filter;
      state.doctor_my_appointments_kpi = kpi;
      state.doctor_my_appointments_loading = false;
    },
    [doctorGetMyAppointments.rejected]: (state, action) => {
      state.doctor_my_appointments_loading = false;
    },
    // Add doctor appointments
    [addDoctorAppointments.pending]: (state, action) => {
      state.doctor_add_appointments_loading = true;
    },
    [addDoctorAppointments.fulfilled]: (state, action) => {
      state.doctor_add_appointments_loading = false;
      set_appointment_drawer_state({ add_appointment_drawer_state: false });
    },
    [addDoctorAppointments.rejected]: (state, action) => {
      state.doctor_add_appointments_loading = false;
    },

    // update appointment
    [updateAppointment.pending]: (state, action) => {
      state.update_appointment_is_loading = true;
    },
    [updateAppointment.fulfilled]: (state, action) => {
      // state.update_appointment_is_loading = false;
      // const { data } = action.payload;
      // let breakF = false;
      // for (const first of state.doctor_my_appointments_data) {
      //   for (let index = 0; index < first.data.length; index++) {
      //     const second = first.data[index];

      //     if (second.AppointmentId === data.AppointmentId) {
      //       first.data[index] = { ...second, ...data };
      //       breakF = true;
      //       break;
      //     }
      //   }
      //   if (breakF) break;
      // }
      // state.view_appointment_data = { ...state.view_appointment_data, ...data };
      // state.update_appointment_data = data;
      state.update_appointment_is_loading = false;
    },
    [updateAppointment.rejected]: (state, action) => {
      state.update_appointment_is_loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_view_appointment_data,
  set_doctor_my_appointments_data_created,
  set_doctor_my_appointments_data_update,
  set_doctor_my_appointments_data,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
