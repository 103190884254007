import React from "react";
import ProfileComponent from "../../../components/doctor/profile/ProfileComponent";
import { isAuth } from "../../../helpers/cookies";

class MyProfileContainer extends React.Component {
  //   constructor() {
  //     super();
  //   }

  componentDidMount() {}
  render() {
    return <ProfileComponent {...this.props} data={isAuth()}  />;
  }
}

export default MyProfileContainer;
