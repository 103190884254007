import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import AddDoctor from "../../../components/public/AddDoctor/AddDoctor";
import Review from "../../../components/public/AddDoctor/Review";
import getStripe from "../../../components/public/AddDoctor/Stripe";
import Price from "../../../components/public/AddDoctor/Price";
import DoctorHeading from "../../../components/public/AddDoctor/DoctorHeading";
import DialogD from "../../../components/common/DialogD";
import Button from "@mui/material/Button";
import {
  StripePriceId,
  stripeSuccessUrl,
  stripeCancelUrl,
} from "../../../helpers/constants";
import { Paper } from "@mui/material";
import PaymentSuccess from "../../../components/public/AddDoctor/PaymentSuccess";
class AddDoctorContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      npi_correct: false,
      npi_loading: false,
      npi_org_loading: false,
      npi_org_correct: false,
      npi_verified: false,
      npi_msg: "",
      customer: null,
      customer_id: "",
      subscriptionData: null,
      step: 1,
      data: null,
      npiData: [],
      address: [],
      newOrgData: [],
      orgId: null,
      organization_type: "",
      organization_id: 3,
      paymentDialog: false,
      stripeurl: "",
      orgverify: false,
      finalorgid: "",
      payment_session_id: "",
      priceId: "",
      npiOrgData: [],
    };
  }

  componentDidMount() {
    this.props.getOrgList();
    this.props.STRIPE_DOCTOR_PRICE();
    this.getParameterByName("session_id");
    this.props.queryCommonList({ parent: "gender" });
    if (window.location.pathname === "/register/doctor/success") {
      setTimeout(() => {
        this.props.navigate("/login");
      }, 5000);
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    const searchParams = new URLSearchParams(new URL(url).search);
    const result = searchParams.get(name) || "";
    this.setState({
      payment_session_id: decodeURIComponent(result),
    });
    return result;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.payment_session_id !== prevState.payment_session_id) {
      console.log("this.state.payment_session_id", this.state.payment_session_id);
      this.props.STRIPE_DOCTOR_VERIFY({
        id: this.state.payment_session_id,
      });
      this.setState({ step: 4 });
    }
  }

  handleSubmit = async (payload) => {
    if (payload.organization_type === "Add" && this.state.orgId === null) {
      this.props.setSnackBar({
        open: true,
        message: "Please Add Organization",
        severity: "error",
      });
    } else {
      this.setState({
        step: 2,
        data: payload,
        organization_type: payload.organization_type,
        organization_id: payload.organization_id,
      });
    }
  };

 
  handlefinalSubmit = async () => {
    const { data, address, organization_type, orgId, finalorgid } = this.state;

    const payload = {
      FirstName: data.FirstName,
      LastName: data.LastName,
      Email: data.Email,
      Phone: data.Phone,
      country_code: data.country_code,
      Address1: address.address_1 ?? data.Address1,
      Address2: address.address_2 ?? data.Address2,
      State: address.state ?? data.State,
      City: address.city ?? data.City,
      Sex: data.Sex,
      Zipcode: address.postal_code ?? data.Zipcode,
      organization_id:
        organization_type === "individual"
          ? 4
          : organization_type === "Add"
          ? orgId
          : finalorgid ?? undefined,
      npi_no: data.npi_no,
      type: data.type,
      organization_type,
    };

    const res = await this.props.addDoctors(payload);

    if (!res.error) {
      this.setState({
        npi_verified: true,
        npi_msg: "Registered Successfully Proceeding to Payment",
        step: 4,
      });

      this.handleCheckout();
    }
  };

  

  handleVerifyOrg = async (id) => {
    let payload = {
      id: id,
    };
    let res = await this.props.verifyOrg(payload); //done

    const verify = res?.payload?.data?.verified;
    const idu = res?.payload?.data?.id;

    setTimeout(() => {
      this.setState({ orgverify: verify, finalorgid: idu });
    }, 3000);
  };

  orgSubmit = async (name, domain) => {
    let payload = {
      name: name,
      organization_domain: domain,
    };
    await this.props.postOrgList(payload);

    this.props.getOrgList();

    setTimeout(() => {
      this.setState({
        newOrgData: this.props.post_org_data,
        orgId: this.props.post_org_data.id,
        orgverify: true,
      });
    }, 3000);
  };

  checkNpi = async (npi, first_name, last_name) => {
    this.props.checkNPI(npi);

    this.setState({ npi_loading: true });

    setTimeout(() => {
      if (
        Array.isArray(this.props.get_npi_data) &&
        this.props.get_npi_data.length > 0 &&
        this.props.get_npi_data[0]?.basic?.first_name.toLowerCase() ===
          first_name.toLowerCase() &&
        this.props.get_npi_data[0]?.basic?.last_name.toLowerCase() ===
          last_name.toLowerCase()
      ) {
        this.setState({
          npi_verified: true,
          npi_msg: "NPI Verified Successfully",
          npi_correct: true,
          npi_loading: false,
          npiData: this.props.get_npi_data[0],
          address: this.props.get_npi_data[0]?.addresses[0],
        });

        return true;
      } else {
        this.setState({
          npi_verified: true,
          npi_msg: "NPI Not Verified",
          npi_correct: false,
          npi_loading: false,
        });
        return false;
      }
    }, 5000);
  };
  orgNpiSubmit = async (npi, name) => {
    this.props.checkNPI(npi);
    this.setState({ npi_org_loading: true });

    setTimeout(() => {
      if (
        this.props.get_npi_data[0]?.basic?.organization_name.toLowerCase() ===
        name.toLowerCase()
      ) {
        this.setState({
          npi_verified: true,
          npi_msg: "Organization Verified Successfully",
          npi_org_correct: true,
          npi_org_loading: false,
          npiOrgData: this.props.get_npi_data[0],
        });

        return true;
      } else {
        this.setState({
          npi_verified: true,
          npi_msg: "Organization Not Verified",
          npi_org_loading: false,
          npi_loading: false,
        });
        return false;
      }
    }, 5000);
  };


  handleCheckout = async () => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: StripePriceId,
          quantity: 1,
        },
      ],
      mode: "subscription",
      successUrl: stripeSuccessUrl,
      cancelUrl: stripeCancelUrl,
      customerEmail: this.state.data.Email,
    });
    if (error) {
      console.warn(error.message);
    }
  };

 

  RenderStepContent = () => {
    const { step } =
      this.state;
console.log("step",step,);
    const {
      speciality_list_data,
      state_list_data,
      city_list_data,
      add_doctors_loading,
      common_list_data,
    } = this.props;

    switch (step) {
      case 1:
        return (
          <AddDoctor
            loading={add_doctors_loading}
            addDoctors={this.handleSubmit}
            speciality_list={speciality_list_data}
            state_data={state_list_data}
            city_data={city_list_data}
            org_data={this.props.get_org_data}
            orgSubmit={this.orgSubmit}
            checkNpi={this.checkNpi}
            npi_correct={this.state.npi_correct}
            npi_org_correct={this.state.npi_org_correct}
            npi_loading={this.state.npi_loading}
            is_update={this.props.params?.ProviderId ? true : false}
            data={this.state.data}
            handleVerifyOrg={this.handleVerifyOrg}
            orgverify={this.state.orgverify}
            orgNpiSubmit={this.orgNpiSubmit}
            npi_org_loading={this.state.npi_org_loading}
            gender_data={common_list_data}
          />
        );

      case 2:
        return (
          <Review
            data={this.state.data}
            npiData={this.state.npiData}
            address={this.state.address}
            newOrgData={this.state.newOrgData}
            handlefinalSubmit={this.handlefinalSubmit}
            orgId={this.state.organization_id}
            organization_type={this.state.organization_type}
            goBackToStep1={() => this.setState({ step: 1 })}
            gender_data={common_list_data}
          />
        );
      case 3:
        return (
          <>
            <>
              <Price
                data={this.props.stripe_doctor_price_data}
                setPriceId={this.setPriceId}
              />
              <Button
                variant="contained"
                sx={{
                  width: "30%",
                  height: "50px",
                  background: "#3F51B5",
                }}
                onClick={this.handleCheckout}
              >
                Checkout
              </Button>
              ;
            </>
          </>
        );
   
  
      default:
        return (
          <>
          <PaymentSuccess />
           </>
        );
    }
  };

  render() {
    console.log("this.props", this.state.payment_session_id);
    return (
      <>
        <Paper elevation={3} sx={{ p: 2 }}>
          <DialogD
            message1={this.state.npi_msg}
            handleClose={() =>  this.setState({ npi_verified: false })}
            open={this.state.npi_verified}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DoctorHeading />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <this.RenderStepContent />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default AddDoctorContainer;
