import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { queryCommonList , addCommonList , updateCommonList } from "../../../store/query/querySlice";

const mapStateToProps = (state) => {
  return {
    common_list_is_loading: state.query.common_list_is_loading,
    common_list_data: state.query.common_list_data,
    add_common_list_is_loading: state.query.add_common_list_is_loading,
    add_common_list_data: state.query.add_common_list_data,
    update_common_list_is_loading: state.query.update_common_list_is_loading,
    update_common_list_data: state.query.update_common_list_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      queryCommonList,
      updateCommonList,
      addCommonList,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
