import React, { useEffect } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "../../../components/common/Button";

const RequestDocument = (props) => {
  const [hospital_reports_id, set_hospital_reports_id] = React.useState([
    268,
    279,
    266,
    267,
    269
]);
  const { data, loading, handleSubmit, selectedData } = props;
 

  const handleChange = (event) => {
    const newValue = parseInt(event.target.id);
    if (event.target.checked) {
      const tempArray = [...hospital_reports_id, newValue];
      set_hospital_reports_id(tempArray);
    } else {
      const filteredArray = hospital_reports_id.filter((e) => e !== newValue);
      set_hospital_reports_id(filteredArray);
    }
  };

  const handleSubmitAction = () => {
    handleSubmit(hospital_reports_id);
    set_hospital_reports_id([]);
  };

  useEffect(() => {
  
    let checkedId = [];
    selectedData.map(function (dd) {
      return checkedId.push(dd.document_id);
    });
    set_hospital_reports_id(checkedId);
  }, [selectedData]);


  return (
    <>
      <Box xs={12} sx={{ paddingBottom: 1, overflow: "auto", height: "82%" }}>
        <FormControl sx={{ my: 1 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Select Documents to request</FormLabel>
          <FormGroup>
          
            {data &&
              data.map(function (hospital_report, index) {
                return (
                  <FormControlLabel
                    key={hospital_report.id}
                    value={hospital_report.id}
                    label={hospital_report.title}
                    name="hospital_report"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        control={hospital_reports_id ? "true" : "false"}
                        // control={hospital_reports_id ? true : false}
                        checked={
                          hospital_reports_id.filter(
                            (e) => e === hospital_report.id
                          ).length > 0
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        name={hospital_report.name}
                        id={hospital_report.id}
                      />
                    }
                  />
                );
              })}
          </FormGroup>
        </FormControl>
      </Box>

      <Box xs={12}>
        <Button
          loading={loading}
          onClick={() => handleSubmitAction(hospital_reports_id)}
          children="SEND REQUEST"
          disabled={hospital_reports_id.length > 0 ? false : true}
        />
      </Box>
    </>
  );
};

export default RequestDocument;
