import { Routes, Route, Navigate } from "react-router-dom";

// Views
import DashboardView from "../pages/admin/Dashboard";
import AddDoctorsView from "../pages/admin/AddDoctors";
import Organizations from "../pages/admin/Organizations";
import SupportUser from "../pages/admin/SupportUser";
import Speciality from "../pages/admin/Speciality";
import Common from "../pages/admin/Common";
import HospitalReport from "../pages/admin/HospitalReport";
import Feedback from "../pages/admin/Feedback";
import Gender from "../pages/admin/Gender";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route exact path="/dashboard" element={<DashboardView />} />
      <Route exact path="/add-update-doctors" element={<AddDoctorsView />} />
      <Route exact path="/organizations/list" element={<Organizations />} />
      <Route exact path="/support/user" element={<SupportUser />} />
      <Route exact path="/speciality" element={<Speciality />} />
      <Route exact path="/help" element={<Common />} />
      <Route exact path="/gender" element={<Gender />} />
      <Route exact path="/hospital/report" element={<HospitalReport />} />
      <Route exact path="/feedback" element={<Feedback />} />

      <Route
        exact
        path="/add-update-doctors/:ProviderId"
        element={<AddDoctorsView />}
      />
      <Route path="*" element={<Navigate replace to="/dashboard" />} />
    </Routes>
  );
};

export default AdminRoutes;
