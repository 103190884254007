import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import OrgDetail from "./OrgDetail";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function OrgDetailDialog({
  open,
  handleClose,
  handleSave,
  orgData,
  loading,
}) {
  const handleSubmit = async (val) => {
    handleSave(val);
    handleClose();
  };
 
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <Box
          sx={{
            boxSizing: "border-box",
            justifyContent: "center",
            alignItems: "center",
            width: "550px",
            
            borderRadius: "8px",
            boxShadow: 3,
          }}
        >
          <DialogTitle sx={{ justifyContent: "center", alignItems: "center" }}>
            Organization Details
          </DialogTitle>

          <DialogContent
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
              {loading ? (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <>
                  <OrgDetail orgData={orgData} />
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions></DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
