export const ADD_FEEDBACK_URL = "/feedback/add";
export const GET_FEEDBACK_URL = "/feedback/get";
export const STATUS_FEEDBACK_URL = "/feedback/status";
export const ADD_DOCTORS_URL = "/user/doctor/register";
export const UPDATE_DOCTOR_URL = "/user/doctor/update-profile";
export const GET_PATIENTS_BY_NAME_OR_PHONE = "/user/patient/list";
export const ADD_PATIENTS_URL = "/user/patient/register-by-doctor-nurse";
export const GET_UPDATE_DOCTOR_URL = "/user/profile/by-id";
export const GET_DOCTOR_DIRECTORY_ADD_URL = "/user/add-doctors-in-directory";
export const GET_DOCTOR_DIRECTORY_UPDATE_URL =
  "/user/update-doctors-in-directory";
export const GET_DOCTOR_DIRECTORY_URL =
  "/query/fetch/my-doctors-single-directory";
export const UPDATE_DOCTOR_PROFILE_URL = "/user/doctor/update-profile-self";
export const DOWNLOAD_QR_CODE_URL = "/user/generate/qr-codes";
export const UPDATE_PROFILE_PIC = "/user/update-profile-pic";
export const GET_PATIENTS_LIST = "/user/patient/list/single";
export const GET_PHYSICIAN_LIST = "query/fetch/my-doctors-single-directory";
export const GET_UNLINKED_PATIENT = "/user/patient/list/unlinked";
export const LINK_PATIENT_WITH_ORG = "/user/patient/link";
export const ADD_DOCTORS_BY_CODE = "/user/add-doctors";
export const REMOVE_DOCTOR_DIRECTORY_URL =
  "user/remove-doctors-in-directory-doctor";
export const UPDATE_TERMS_AND_CONDITIONS =
  "/user/doctor/terms-and-conditions/accept";
export const ADD_DOCTORS_WEB_URL = "/user/doctor/register/web";
export const GET_ORGANIZATION_LIST =
  "/organization/list?page=0&perPage=100&sortBy=DESC&sortField=createdAt";
export const POST_ORGANIZATION_LIST = "/organization/add";
export const VERIFY_ORGANIZATION = "/organization/verify";
export const GET_ORGANIZATION = "/organization/get";
export const CHECK_NPI_NUMBER = "/developer/npi";
export const STRIPE_DOCTOR_VERIFY = "/user/doctor/stripe/registration_complete";
export const STRIPE_DOCTOR_PRICE = "/user/doctor/stripe/price";
export const SUPPORT_USER_LIST = "/user/admin/user";

export const UPDATE_ORGANIZATION = "/organization/edit";

export const REGISTER_SUPPORT_USER = "/user/admin/register";
export const ADMIN_USER_UPDATE = "/user/admin/user/edit";

export const ADMIN_USER_TREE = "/user/admin/tree";

export const ADMIN_PAYMENT_STATUS = "/user/admin/payment/doctor";

export const DOCTOR_ORGNIZATION_MAP_LIST = "/user/doctor/get/org-mapping";
export const DOCTOR_ORGNIZATION_MAP_SWITCH = "/user/doctor/switch/organization";
export const DOCTOR_ORGNIZATION_MAP_ADD = "/user/add-doctor-organization-mapping";

export const DOCTOR_INVITE = "/user/invite/doctor";

export const USER_LOGOUT = "/user/logout";