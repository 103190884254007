import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "../../common/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useFormik } from "formik";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { FormHelperText } from "@mui/material";
import * as yup from "yup";
import SelectComponent from "../../common/Select";
import Tags from "../../../components/support/doctor/Tags";
import { PhoneOutlined } from "@mui/icons-material";
import CountryList from "../schedule/CountriesList";

const validateAddDoctors = yup.object().shape({
  country_code: yup.string().required("Country Code is Required"),
  Email: yup.string().required("Email is Required"),
  speciality_id: yup.string().required("Speciality id is Required"),

  FirstName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("First Name is Required"),
  LastName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Last Name is Required"),
  number: yup
    .string()
    .min(8, "Please enter at least 8 digits")
    .max(12, "Too Long!")
    .matches(/^\d+$/, "Phone number must contain only numbers")
    .required("Mobile Number is Required"),
});

const AddDoctorDirectory = (props) => {
  const {
    loading,
    addDoctors,
    speciality_list,
    ProvidersData,
    is_update,
    update,
  } = props;

  const [commonLoading, setCommonLoading] = useState(true);

  const onSubmit = async (values) => {
    let payload = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      number: values.number,
      country_code: values.country_code,
      speciality_id: values.speciality_id,
      tags: values.tags.map(function (d) {
        return d["value"];
      }),
    };

    if (is_update) {
      update(payload);
    } else {
      addDoctors(payload);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: is_update
      ? ProvidersData
      : {
          FirstName: "",
          LastName: "",
          Email: "",
          number: "",
          country_code: "",
          speciality_id: "",
          tags: [],
        },
    onSubmit: (values) => {
      onSubmit(values);
    },
    validationSchema: validateAddDoctors,
  });

  useEffect(() => {
    if (speciality_list && speciality_list[0]) {
      setCommonLoading(false);
    }
  }, [speciality_list]);

  if (commonLoading) {
    return "Loading";
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="First Name"
            variant="outlined"
            type="text"
            onChange={formik.handleChange("FirstName")}
            onBlur={formik.handleBlur("FirstName")}
            value={formik.values.FirstName}
            error={formik.touched.FirstName && Boolean(formik.errors.FirstName)}
            helperText={formik.touched.FirstName && formik.errors.FirstName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <PersonOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            type="text"
            onChange={formik.handleChange("LastName")}
            onBlur={formik.handleBlur("LastName")}
            value={formik.values.LastName}
            error={formik.touched.LastName && Boolean(formik.errors.LastName)}
            helperText={formik.touched.LastName && formik.errors.LastName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <PersonOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            type="email"
            onChange={formik.handleChange("Email")}
            onBlur={formik.handleBlur("Email")}
            value={formik.values.Email}
            error={formik.touched.Email && Boolean(formik.errors.Email)}
            helperText={formik.touched.Email && formik.errors.Email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <EmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <CountryList
            formik={formik}
            error={
              formik.touched.country_code && Boolean(formik.errors.country_code)
            }
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Mobile"
            variant="outlined"
            type="text"
            onChange={formik.handleChange("number")}
            onBlur={formik.handleBlur("number")}
            value={formik.values.number}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                  <PhoneOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <SelectComponent
            labelId="speciality_id"
            id="demo-simple-select"
            label="Speciality"
            onChange={formik.handleChange("speciality_id")}
            onBlur={formik.handleBlur("speciality_id")}
            value={formik.values.speciality_id}
            error={
              formik.touched.speciality_id &&
              Boolean(formik.errors.speciality_id)
            }
            startAdornment={
              <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                <FmdGoodIcon />
              </InputAdornment>
            }
            items={speciality_list}
          />
          <FormHelperText>
            {formik.touched.speciality_id && formik.errors.speciality_id}
          </FormHelperText>
        </Grid>

        <Grid item xs={4}>
          <Tags data={speciality_list} formik={formik} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={4.5}>
          <Button
            type="submit"
            // disabled={!formik.isValid}
            variant="contained"
            loading={loading}
            fullWidth
          >
            {is_update ? "UPDATE DOCTORS DIRECTORY" : "ADD DOCTORS DIRECTORY"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddDoctorDirectory;
