import React from "react";
import Table from "../../common/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ProfileImage from "../../common/ProfileImage";
import MuiButton from "../../common/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import IconButtonIcons from "../../common/IconButtonIcons";
import ShareIcon from '@mui/icons-material/Share';

const DoctorDirectory = (props) => {
  const {
    loading,
    doctor_directory_data,
    editDoctorDirectory,
    handleOpenChat,
    removeDoctorDirectory,
    inviteDoctor,
  } = props;
  let columns = [
    {
      title: "Sr.No.",
    },
    {
      title: "Doctors Name",
    },
    {
      title: "Email Id",
    },
    {
      title: "Phone Number",
    },
    {
      title: "Speciality",
    },
    {
      title: "Action",
    },
  ];
  const renderTableData =
    doctor_directory_data && doctor_directory_data[0] ? (
      doctor_directory_data.map((row, index) => {
        const { id } = row;
    
        return (
          <TableRow
            key={index}
            sx={{ "td, th": { border: 0, padding: "10px" } }}
          >
            <TableCell align="center" component="th" scope="row">
              {index + 1}
            </TableCell>
            <TableCell align="center" onClick={() => props.showDetails(id)}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ProfileImage
                  alt={row.FirstName}
                  src={row.avatar}
                  style={{ marginRight: 10 }}
                />
                {row.FirstName + " " + row.LastName}
              </div>
            </TableCell>
            <TableCell align="center">{row.Email ? row.Email : "-"}</TableCell>
            <TableCell align="center">
              {row.mobile ? row.mobile : "-"}
            </TableCell>
            <TableCell align="center">{row.speciality?.title}</TableCell>
            <TableCell align="center" sx={{ width: "120px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {row.is_hipaasafe_user ? (
                  <MuiButton
                    variant="contained"
                    sx={{ borderRadius: "50px" }}
                    color="warning"
                    onClick={() =>
                      handleOpenChat(
                        row.ProviderId + row?.directory_organization?.unique_id
                      )
                    }
                  >
                    Chat
                  </MuiButton>
                ) : (
                  <>
                    

                    <IconButtonIcons
                      title="Invite"
                      IconComponent={ShareIcon}
                      color="info"
                      onClick={() => inviteDoctor(row.id,row.Email)}
                    />
                    <IconButtonIcons
                      title="Edit"
                      IconComponent={EditIcon}
                      color="info"
                      onClick={() => editDoctorDirectory(row.id)}
                    />
                  </>
                )}
                <IconButtonIcons
                  title="Delete"
                  IconComponent={DeleteIcon}
                  color="error"
                  onClick={() => removeDoctorDirectory(row.id)}
                />
              </div>
            </TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
        <TableCell align="center" colSpan={columns.length}>
         Data not found
        </TableCell>
      </TableRow>
    );

  return (
    <Table columns={columns} tableData={renderTableData} loading={loading} />
  );
};

export default DoctorDirectory;
