import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { isAuth } from "../helpers/cookies.js";
import PublicRoutes from "./PublicRoutes.jsx";
import DoctorView from "../pages/doctor/layout/DoctorView.jsx";
import SupportView from "../pages/support/layout/SupportView.jsx";
import AdminView from "../pages/admin/layout/AdminView.jsx";

function Index() {

  return (
    <>
      <Router>
        <Routes>
          {isAuth().role_name === "DOCTOR" || isAuth().role_name === "NURSE" ? (
            <Route path="/*" element={<DoctorView />} />
          ) : isAuth().role_name === "SUPPORT" ? (
            <Route path="/*" element={<SupportView />} />
          ): isAuth().role_name === "ADMIN" ? (
            <Route path="/*" element={<AdminView />} />
          )
          : (
            <Route path="/*" element={<PublicRoutes />} />
          )}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default Index;
