import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "../../../components/common/Button";
import QRCode from "../../../components/doctor/qr code/QRCodeComponent";
import QRCodeSkeleton from "../../../components/doctor/qr code/QRCodeSkeleton";
import Typography from "@mui/material/Typography";
class QRContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      doctor_profile_modal: false,
    };
  }

  componentDidMount() {
    this.props.queryDoctorMyTeams();
  }

  handleOpenChat(type, id) {
    try {
      if (type === "uid") {
        this.props.set_chat_window_ids({ uid: id });
      } else {
        this.props.set_chat_window_ids({ guid: id });
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  showProfileDetail(id) {
    this.props.getDoctorDataUrl({ uid: id });
    this.openDoctorProfileModal();
  }

  openDoctorProfileModal() {
    this.setState({
      doctor_profile_modal: true,
    });
  }

  closeDoctorProfileModal() {
    this.setState({
      doctor_profile_modal: false,
    });
  }

  handleDownloadApiAll() {
    const data = [];
    this.props.doctor_my_teams_data.forEach((element) => {
      if (element.role_name === "DOCTOR") {
        data.push({
          ProviderId: element.ProviderId,
          FirstName: element.FirstName,
          LastName: element.LastName,
          organization_id: element.organization_id,
        });
      }
    });
    this.props.downloadQrCode({ data, type });
  }

  async handleDownloadApiSingle(element) {
    const { ProviderId, FirstName, LastName, organization_id } = element;
    const data = [{ ProviderId, FirstName, LastName, organization_id }];
    await this.props.downloadQrCode({ data, type });
  }

  render() {
    const {
      doctor_my_teams_data,
      doctor_my_teams_loading,
      qr_code_download_loading,
    } = this.props;


    return (
      <>
        <Grid container spacing={1}>
          {doctor_my_teams_data.filter((ele) => ele.role_name !== "NURSE")
            .length > 0 ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* <Grid item xs={3}>
                <Button
                  onClick={() => this.handleDownloadApiAll()}
                  loading={doctor_my_teams_loading || qr_code_download_loading}
                  children={"Download All as PDF"}
                ></Button>
              </Grid> */}
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Stack justifyContent={"center"} alignItems="center">
              <Grid container spacing={1}>
                {doctor_my_teams_loading ? (
                  [1, 2, 3, 4].map((ele, index) => {
                    return (
                      <Grid key={"qr_code_skeleton_" + index} item xs={6}>
                        <QRCodeSkeleton />
                      </Grid>
                    );
                  })
                ) : doctor_my_teams_data.length > 0 ? (
                  doctor_my_teams_data.map((ele, index) => {
                    return ele.role_name !== "NURSE" ? (
                      <Grid key={"qr_code_index_" + index} item xs={6}>
                        <QRCode
                          data={ele}
                          index={index}
                          handleDownloadClick={(divId) =>
                            this.handleDownloadApiSingle(divId)
                          }
                          loading={
                            doctor_my_teams_loading || qr_code_download_loading
                          }
                        />
                      </Grid>
                    ) : null;
                  })
                ) : (
                  <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ minHeight: 200 }}
                >
                  <Typography variant="body1">No Data Found</Typography>
                </Grid>
                )}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default QRContainer;

const type = "DOCTOR_APPOINTMENT_QR";
