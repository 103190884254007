import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "../../common/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormHelperText } from "@mui/material";
import SelectComponent from "../../common/Select";
import Tags from "../../support/doctor/Tags";
import { isAuth } from "../../../helpers/cookies";
import CountryList from "../schedule/CountriesList";

const validateLoginForm = yup.object().shape({
  Email: yup.string().email().required(),
  FirstName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
  LastName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
  Phone: yup.number().required("Phone  number is required!").positive().min(10),
  speciality_id:
    isAuth().role_name === "DOCTOR"
      ? yup.number().required("Speciality is required!").nullable()
      : yup.object().nullable(),
  country_code: yup.string().required("Country code is required!"),
});

const UpdateDoctorProfileComponent = (props) => {
  const {
    loading,
    speciality_list,
    speciality_is_loading,
    ProvidersData,
    update,
  } = props;

  const [commonLoading, setCommonLoading] = useState(true);

  const onSubmit = async (values) => {
    let payload = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      speciality_id: values.speciality_id,
      country_code: values.country_code,
      number: values.Phone,
      tags: values.tags.map(function (d) {
        return d["value"];
      }),
      login_sms_enable: values.login_sms_enable,
    };
    update(payload);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: ProvidersData,
    onSubmit: (values) => {
      onSubmit(values);
    },
    validationSchema: validateLoginForm,
  });

  useEffect(() => {
    if (
      !loading &&
      !speciality_is_loading &&
      speciality_list &&
      speciality_list[0]
    ) {
      setCommonLoading(false);
    }
  }, [loading, speciality_is_loading, speciality_list]);

  if (commonLoading) {
    return "Loading";
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* <FormControl> */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              type="text"
              onChange={formik.handleChange("FirstName")}
              onBlur={formik.handleBlur("FirstName")}
              value={formik?.values?.FirstName}
              error={
                !formik.touched.FirstName && Boolean(formik.errors.FirstName)
              }
              helperText={!formik.touched.FirstName && formik.errors.FirstName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <PersonOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              type="text"
              onChange={formik.handleChange("LastName")}
              onBlur={formik.handleBlur("LastName")}
              value={formik?.values?.LastName}
              error={
                !formik.touched.LastName && Boolean(formik.errors.LastName)
              }
              helperText={!formik.touched.LastName && formik.errors.LastName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <PersonOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              type="email"
              onChange={formik.handleChange("Email")}
              onBlur={formik.handleBlur("Email")}
              value={formik?.values?.Email}
              error={!formik.touched.Email && Boolean(formik.errors.Email)}
              helperText={!formik.touched.Email && formik.errors.Email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <EmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <CountryList formik={formik} />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Phone"
              variant="outlined"
              type="text"
              onChange={formik.handleChange("Phone")}
              onBlur={formik.handleBlur("Phone")}
              value={formik?.values?.Phone}
              error={!formik.touched.Phone && Boolean(formik.Phone)}
              helperText={!formik.touched.Phone && formik.Phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                    <PhoneAndroidIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {isAuth().role_name !== "NURSE" ? (
            <>
              <Grid item xs={4}>
                <SelectComponent
                  labelId="speciality_id"
                  id="demo-simple-select"
                  label="Speciality"
                  onChange={formik.handleChange("speciality_id")}
                  onBlur={formik.handleBlur("speciality_id")}
                  value={formik.values.speciality_id}
                  defaultValue={formik.values.speciality_id}
                  error={formik.errors.speciality_id ? true : false}
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                      <FmdGoodIcon />
                    </InputAdornment>
                  }
                  items={speciality_list}
                />
                <FormHelperText>
                  {formik.touched.speciality_id && formik.errors.speciality_id}
                </FormHelperText>
              </Grid>

              <Grid item xs={4}>
                <Tags data={speciality_list} formik={formik} />
              </Grid>
            </>
          ) : null}
        </Grid>

        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs>
            <FormGroup>
              <FormControlLabel
                control={<Switch />}
                label="Opt in for One Time Password to login into the application"
                onChange={formik.handleChange("login_sms_enable")}
                onBlur={formik.handleBlur("login_sms_enable")}
                checked={formik.values.login_sms_enable}
                error={
                  !formik.touched.login_sms_enable &&
                  Boolean(formik.errors.login_sms_enable)
                }
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={4.5}>
            <Button
              type="submit"
              disableElevation
              variant="contained"
              disabled={!formik.isValid}
              loading={loading}
              fullWidth
            >
              UPDATE PROFILE
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UpdateDoctorProfileComponent;
