import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DatePickerComponent = ({
  label = "Select date",
  date,
  setDate,
  minDate,
  onError,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={date}
        onChange={setDate}
        minDate={minDate}
        size="small"
        renderInput={(params) => {
          return <TextField size="small" {...params} fullWidth />;
        }}
        onError={(err) => {
          onError(err);
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
