import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { Pie } from "react-chartjs-2";

const AppointmentPieChart = ({ loading, labels, data }) => {
  const pieChartData = {
    labels: labels,
    datasets: [
      {
        label: "No. of Appointments",
        data,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(255, 206, 100, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 206, 100, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Stack spacing={1}>
        <Paper elevation={0}>
          <Stack sx={{ p: 5, width: "80%" }} spacing={1}>
            <Typography variant="body2">Appointments by Status</Typography>
            <Pie
              data={pieChartData}
              legend={{ display: true, position: "right" }}
              datalabels={{
                display: true,
                color: "white",
              }}
              tooltips={{
                backgroundColor: "#5a6e7f",
              }}
            />
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default AppointmentPieChart;
