import * as React from "react";
import Box from "@mui/material/Box";

import Button from "../common/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from 'yup';


const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
})

export default function EditSpecDialog({
  open,
  handleClose,
  handleSave,
  data,
  title,
  isLoading=false
}) {


  const handleSubmit = async (val) => {
   await handleSave(val);
    handleClose();

  };

  return (  
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <Box
          sx={{
            boxSizing: "border-box",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            borderRadius: "8px",
          }}
        >
          <DialogContent
            sx={{ alignItems: "center", justifyContent: "center", display:"flex", flexDirection:"column" }}
          >
            <Box sx={{justifyContent: "flex-end", display: "flex", width:"100%"}}>
              <Typography onClick={handleClose} sx={{cursor: "pointer"}} >
                X
              </Typography>
            </Box>
            
            <Typography
              sx={{
                fontFamily: "inherit",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                textAlign: "center",
                letterSpacing: "-0.01em",
                flex: "none",
                order: "0",
                flexGrow: "0",
                
              }}
            >
              Edit {title}
            </Typography>
            <Formik
              initialValues={{ name: data.title,}}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}

            >
              {({ values, handleChange, handleSubmit, handleBlur, errors, isValid }) => (
                <>
                  <TextField
                  defaultValue={data.title}
                    required={true}
                    disableUnderline={true}
                    label={`${title} name`}
                    sx={{
                      borderRadius: "10px",
                   
                      height: "40px",
                      width: "250px",
                      marginTop: "20px",
                      alignSelf: "center",
                    //   marginLeft: "20%",
                    }}
                    placeholder={`Enter ${title} name`}
                    size="small"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    
                    value={values.name}
                    error={errors.name}

                    onInput={(e) => {
                      e.target.value = e.target.value.trimStart();
                    }}
                  />

                  {/* <TextField
                    defaultValue={orgData.domain}
                    required={true}
                    disableUnderline={true}
                    label="Domain Name"
                    sx={{
                      borderRadius: "10px",
                   
                      height: "40px",
                      width: "250px",
                      marginTop: "20px",
                      alignSelf: "center",
                      marginLeft: "20%",
                    }}
                    placeholder="Enter Domain name"
                    size="small"
                    onChange={handleChange("domain")}
                    onBlur={handleBlur("domain")}
                    error={errors.domain}
                   
                    value={values.domain}
                    onInput={(e) => {
                      e.target.value = e.target.value.trimStart();
                    }}
                  /> */}
             

          
            <Button
              disabled={!isValid}
              loading={isLoading}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "187px",

                background: "#004AAD",
                borderRadius: "5px",
                alignSelf: "center",
                marginTop: "5%",
                // flex: "none",
                // order: "0",
                // flexGrow: "0",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </Button>
            </>
              )}
            </Formik>
          </DialogContent>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
