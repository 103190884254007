import React from "react";
import Table from "../../components/common/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";

const Organization = (props) => {
  const { tableDetails, loading, toggleDialog,toggleDetail } = props;

  const columns = [
    {
      title: "Sr.No.",
    },
    {
      title: "Organization Name",
    },
    {
      title: "Organization Id",
    },
    {
      title: "Unique Id",
    },
    {
      title: "Action",
    },
  ];

  const renderTableData = tableDetails.length ? (
    tableDetails.map((row, index) => {

      return (
        <TableRow key={index} sx={{ "td, th": { border: 0, padding: "10px" } }}>
          <TableCell align="center" component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell align="center">{row.key}</TableCell>
          <TableCell align="center">{row.value ? row.value : "-"}</TableCell>
          <TableCell align="center">
            {row.unique_id ? row.unique_id : "-"}
          </TableCell>
          <TableCell align="center" >
            <EditOutlinedIcon
              onClick={() => {
                 toggleDialog(row);
              }}
              sx={{ cursor: "pointer" }}
            />
             <InfoIcon
              onClick={() => {
                  toggleDetail(row);
              }}
              sx={{ cursor: "pointer" , marginLeft:"10px"  }}
            />
          </TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
      <TableCell align="center">Data not found</TableCell>
    </TableRow>
  );

  return (
    <Table columns={columns} tableData={renderTableData} loading={loading} />
  );
};

export default Organization;
