import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { set_chat_window_state, set_chat_window_ids, set_unread_message_count } from "../../../store/common/commonSlice";
import { getDoctorDataUrl ,AcceptTermsAndConditions , doctorOrgnization , doctorOrgnizationUpdate , userLogout} from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  const { chat_window_state, chat_window_uid, chat_window_guid, unread_message_count } = state.common;
  return {
    DoctorId: state.common.DoctorId,
    termsAndConditions: state.common.termsAndConditions,
    chat_window_state,
    chat_window_uid,
    chat_window_guid,
    unread_message_count,
    doctors_data: state.user.doctors_data,
    doctor_orgnization_is_loading: state.user.doctor_orgnization_is_loading,
    doctor_orgnization_data : state.user.doctor_orgnization_data,
    user_logout_is_loading : state.user.user_logout_is_loading,
    user_logout_data : state.user.user_logout_data,
    
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ set_chat_window_state, set_chat_window_ids, set_unread_message_count, dispatch , AcceptTermsAndConditions ,getDoctorDataUrl,doctorOrgnization,doctorOrgnizationUpdate ,userLogout}, dispatch);

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
