import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import { Chart as ChartJS } from "chart.js/auto"; // Don't remove
import { Chart } from "react-chartjs-2"; // Don't remove

import DashboardHeader from "../../../components/doctor/dashboard/DashboardHeader";
// import PatientCount from "../../../components/doctor/dashboard/PatientCount";
import KpiCards from "../../../components/common/KpiCards";

import AppointmentPieChart from "../../../components/doctor/dashboard/PieChart";
import AppointmentBarChart from "../../../components/doctor/dashboard/BarChartStacked";
import AppointmentStackedBarChart from "../../../components/doctor/dashboard/BarChart";
import { format } from "date-fns";

class DashboardContainer extends React.Component {
  constructor() {
    super();
    this.state = { time_now: new Date() };
  }

  fetchData() {
    this.props.queryDoctorDashboardKPICounts({ DoctorId: this.props.DoctorId });
    this.loadDashboardData(30);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.DoctorId !== this.props.DoctorId) {
      this.fetchData();
    }
  }

  loadDashboardData(days) {
    this.props.queryDcotorDashboardStats({
      DoctorId: this.props.DoctorId,
      days: days,
    });
  }

  render() {
    const {
      dashboard_kpi_count_data,
      dashboard_kpi_count_loading,
      dashboard_stats_loading,
      dashboard_stats_data,
    } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardHeader
            title="Dashboard"
            time={format(this.state.time_now, "MMMM dd, yyyy")}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            {dashboard_kpi_count_data.map((element, index) => {
              return (
                <KpiCards
                  key={"kpi_cards_index_" + index}
                  data={element}
                  loading={dashboard_kpi_count_loading}
                />
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AppointmentStackedBarChart
            handleDays={(days) => this.loadDashboardData(days)}
            loading={dashboard_stats_loading}
            labels={dashboard_stats_data?.barChartLabel}
            data={dashboard_stats_data?.stackBarChartData}
          />
        </Grid>
        <Grid item xs={6}>
          <AppointmentPieChart
            loading={dashboard_stats_loading}
            labels={dashboard_stats_data?.pieChartLabel}
            data={dashboard_stats_data?.pieChartData}
          />
        </Grid>
        <Grid item xs={6}>
          <AppointmentBarChart
            loading={dashboard_stats_loading}
            labels={dashboard_stats_data?.barChartLabel}
            aptData={dashboard_stats_data?.barChartAppointments}
            noShowData={dashboard_stats_data?.barChartNoShows}
          />
        </Grid>
      </Grid>
    );
  }
}

export default DashboardContainer;
