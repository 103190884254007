import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getOrgList ,getOrg} from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  return {
    
    get_org_data: state.user.get_org_data,
    get_org_is_loading: state.user.get_org_is_loading,
   

   
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrgList,
      getOrg,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
