import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const TimePickerComponent = ({
  label = "Select Time",
  date,
  setDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        label={label}
        value={date}
        onChange={setDate}
        size="small"
        renderInput={(params) => {
          return <TextField size="small" {...params} fullWidth />;
        }}
      />
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
