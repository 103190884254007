
import React from "react";
import FeedbackComponent from "../../../components/feedback/FeedbackComponent";
import { isAuth } from "../../../helpers/cookies";

class FeedbackContainer extends React.Component {
  componentDidMount() {}

  addFeedback(payload) {
    this.props.addFeedback(payload);
  }

  render() {
    const { feedback_add_loading } = this.props;

    return (

 
 <FeedbackComponent
        feedback_add_loading={feedback_add_loading}
        addFeedback={(val) => this.addFeedback(val)}
        data={isAuth()}
      />

     
    );
  }
}

export default FeedbackContainer;
