import React from "react";
import SupportHeader from "../../../components/support/dashboard/SupportHeader";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Organization from "../../../components/admin/Organization";
import Button from "../../../components/common/Button";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditOrgDialog from "../../../components/admin/EditOrgDialog";
import OrgDetailDialog from "../../../components/admin/OrgDetailDialog";

class SupportDashboardContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      addInputVisible: false,
      organization_name: "@",
      domain_name: "@",
      editDialog: false,
      detailDialog: false,
      id: "",
      name: "",
      organization_domain: "",
    };
  }

  componentDidMount() {
    this.props.getOrgList();
  }

  toggleAddInput = () => {
    this.setState({
      addInputVisible: !this.state.addInputVisible,
    });
  };
  handleAddOrganization = async () => {
    const { organization_name, domain_name } = this.state;
    if (domain_name === "@") {
      this.setState({
        domain_name: "",
      });
    }
    if (organization_name === "@") {
      this.setState({
        organization_name: "",
      });
    } else if (
      organization_name !== "" &&
      domain_name !== "" &&
      domain_name !== "@" &&
      organization_name !== "@"
    ) {
      await this.props.postOrgList({
        name: organization_name,
        organization_domain: domain_name,
      });
      await this.props.getOrgList();
      this.setState({
        organization_name: "@",
        domain_name: "@",
      });
    }
  };

  handleClose = (val) => {
    this.setState({
      editDialog: !this.state.editDialog,
      id: val.value,
      name: val.key,
      organization_domain: val.organization_domain,
    });
  };
   
  handleDetail = (val) => {

    this.props.adminUserTree({organization_id:val.value});
    this.setState({
      detailDialog: !this.state.detailDialog,
    });
  };


  handleSave = async (data) => {
    await this.props.update_org({name:data.name,organization_domain:data.domain,id:this.state.id});
    await this.props.getOrgList();
  };
  render() {
    const { get_org_is_loading, get_org_data, postOrgIsLoading ,admin_user_tree_is_loading ,admin_user_tree_data} = this.props;

   
    return (
      <>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SupportHeader title="Organizations" />

            {this.state.addInputVisible ? (
              <Stack
                direction="row"
                spacing={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CloseIcon
                  onClick={this.toggleAddInput}
                  sx={{ cursor: "pointer" }}
                />
                <TextField
                  required={true}
                  disableUnderline={true}
                  label="Organization Name"
                  sx={{
                    borderRadius: "10px",
                    height: "40px",
                    width: "250px",
                  }}
                  placeholder="Enter Organization Name"
                  size="small"
                  onChange={(e) => {
                    this.setState({
                      organization_name: e.target.value,
                    });
                  }}
                  error={this.state.organization_name === ""}
                  value={
                    this.state.organization_name === "@"
                      ? ""
                      : this.state.organization_name
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.trimStart();
                  }}
                />
                <TextField
                  required={true}
                  disableUnderline={true}
                  label="Domain Name"
                  sx={{
                    borderRadius: "10px",
                    height: "40px",
                  }}
                  placeholder="Enter Domain Name"
                  size="small"
                  onChange={(e) => {
                    this.setState({
                      domain_name: e.target.value,
                    });
                  }}
                  error={this.state.domain_name === ""}
                  value={
                    this.state.domain_name === "@" ? "" : this.state.domain_name
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.trimStart();
                  }}
                />
                <Button
                loading={postOrgIsLoading}
                  onClick={() => {
                   
                    this.handleAddOrganization();
                  }}
                  sx={{
                    borderRadius: "50px",
                    backgroundColor: "#004AAD",
                    marginRight: "1rem",
                    color: "white",
                    width: "150px",
                    
                  }}
                  disabled={
                    this.state.organization_name === "" ||
                    this.state.domain_name === ""
                  }
                >
                  + Add
                </Button>
              </Stack>
            ) : (
              <Button
                onClick={() => {
                  this.toggleAddInput();
                }}
                sx={{
                  borderRadius: "50px",
                  backgroundColor: "#004AAD",
                  marginRight: "1rem",
                  color: "white",
                  width: "200px",
                  
                }}
              >
                + Organization
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <Paper elevation={0}>
                <Stack sx={{ p: 5 }} spacing={1}>
                  <Organization
                    tableDetails={get_org_data}
                    loading={get_org_is_loading}
                    toggleDialog={this.handleClose}
                    toggleDetail={this.handleDetail}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Grid>
        </Grid>
        <EditOrgDialog
          open={this.state.editDialog}
          orgData={{
            id: this.state.id,
            name: this.state.name,
            domain: this.state.organization_domain,
          }}
          handleClose={() => {
            this.setState({
              editDialog: !this.state.editDialog,
              });
              }}
          handleSave={this.handleSave}
        />
       <OrgDetailDialog
          open={this.state.detailDialog}
          orgData={admin_user_tree_data}
          loading={admin_user_tree_is_loading}
          handleClose={() => {
            this.setState({
              detailDialog: !this.state.detailDialog,
              });
              }
            }
        />


      </>
    );
  }
}

export default SupportDashboardContainer;
