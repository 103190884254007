import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { Bar } from "react-chartjs-2";
import { Grid } from "@mui/material";
import BasicSelect from "../../common/Select";

const AppointmentStackedBarChart = ({ handleDays, loading, labels, data }) => {
  const [selectedDays, setSelectedDays] = useState(30);

  const handleDaysChange = (val) => {
    setSelectedDays(val);
    handleDays(val);
  };

  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: "Appointments",
        data: data,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <>
      <Stack spacing={1}>
        <Paper elevation={0}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={2}>
              <Typography variant="body2" sx={{ p: 5, pb: 0 }}>
                Daily Appointments
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <BasicSelect
                width={50}
                value={selectedDays}
                onChange={(e) => handleDaysChange(e.target.value)}
                displayEmpty
                label="Days"
                items={[
                  { key: "Today", value: 1 },
                  { key: "Last 7 Days", value: 7 },
                  { key: "Last 15 Days", value: 15 },
                  { key: "Last 30 Days", value: 30 },
                ]}
              />
            </Grid>
          </Grid>
          <Stack sx={{ p: 5 }} spacing={1}>
            <Bar data={barChartData} />
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default AppointmentStackedBarChart;
