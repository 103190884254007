import { Grid, Typography } from "@mui/material";
import React from "react";
import { Stack } from "@mui/system";
import AddDoctorDirectory from "../../../components/doctor/directory/AddDoctorDirectory";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import IconButtonIcons from "../../../components/common/IconButtonIcons";

class DoctorDirectoryAddContainer extends React.Component {
  // constructor() {
  //   super();
  //   this.state = { doctor_directory_add_modal: false };
  // }

  fetchProviderById() {
    if (this.props.params?.ProviderId) {
      this.props.getDoctorDirectory({ id: this.props.params?.ProviderId });
    }
  }

  componentDidMount() {
    this.fetchProviderById();
    this.props.getSpecialityList();
  }

  handleSubmit = async (values) => {
    let res = await this.props.addDoctorDirectory({
      user_id: this.props.DoctorId,
      ...values,
    }); //done
    if (!res.error) {
      this.props.navigate("/chat");
    }
  };

  handleUpdateDoctorSubmit = async (values) => {
    let res = await this.props.updateDoctorDirectory({
      id: this.props.params?.ProviderId,
      ...values,
    });
    if (!res.error) {
      this.props.navigate("/chat");
    }
  };

  render() {
    const {
      speciality_list_data,
      speciality_is_loading,
      add_doctors_directory_loading,
      update_doctor_directory_data,
    } = this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <IconButtonIcons
              onClick={() => {
                this.props.navigate("/chat");
              }}
              title="Previous"
              IconComponent={NavigateBeforeOutlinedIcon}
            />
            <Typography variant="h6">{this.props.params?.ProviderId ? "Update Doctors Directory" : "Add Doctors Directory"} ​</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <AddDoctorDirectory
                loading={add_doctors_directory_loading}
                speciality_is_loading={speciality_is_loading}
                addDoctors={this.handleSubmit}
                speciality_list={speciality_list_data}
                update={this.handleUpdateDoctorSubmit}
                ProvidersData={update_doctor_directory_data}
                is_update={this.props.params?.ProviderId ? true : false}
              />
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default DoctorDirectoryAddContainer;
