import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { isAuth } from "../../helpers/cookies";

import {
  api_add_patient_notes_url,
  api_get_patient_notes_url,
} from "./notesApis";
import {
  parser_add_patient_notes_url,
  parser_get_patient_notes_url,
} from "./notesParser";

export const getPatientNotesUrl = createAsyncThunk(
  "getPatientNotesUrl",
  async (payload) => {
    try {
      const { guid } = payload;
      const params = {
        page: 1,
        limit: 50,
        guid,
      };
      const response = await api_get_patient_notes_url(params);
      const data = parser_get_patient_notes_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const addPatientNotes = createAsyncThunk(
  "addPatientNotes",
  async (payload) => {
    try {
      const response = await api_add_patient_notes_url(payload);
      const data = parser_add_patient_notes_url(response);
      return { data };
    } catch (error) {
      throw new Error(error);
    }
  }
);

const initialState = {
  // Fetch patient notes
  patient_notes_loading: false,
  patient_notes_data: [],

  // Add  Patient notes
  add_notes_is_loading: false,
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {},
  extraReducers: {
    // Fetch patient documents
    [getPatientNotesUrl.pending]: (state, action) => {
      state.patient_notes_loading = true;
    },
    [getPatientNotesUrl.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.patient_notes_data = data;
      state.patient_notes_loading = false;
    },
    [getPatientNotesUrl.rejected]: (state, action) => {
      state.patient_notes_loading = false;
    },

    // Add Patient Notes
    [addPatientNotes.pending]: (state, action) => {
      state.add_notes_is_loading = true;
    },
    [addPatientNotes.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.patient_notes_data.splice(0, 0, data);
      state.add_notes_is_loading = false;
    },
    [addPatientNotes.rejected]: (state, action) => {
      state.add_notes_is_loading = false;
    },
  },
});

// Action creators are generated for each case reducer function

export default notesSlice.reducer;
