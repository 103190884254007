import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import UpdateDoctorProfileComponent from "../../../components/doctor/update-profile/UpdateProfileComponent";
import IconButtonIcons from "../../../components/common/IconButtonIcons";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";

class MyProfileContainer extends React.Component {
  //   constructor() {
  //     super();
  //   }

  fetchProviderById() {
    if (this.props.params?.ProviderId) {
      this.props.getDoctorDataUrl({ uid: this.props.params?.ProviderId });
    }
  }
  componentDidMount() {
    this.fetchProviderById();
    this.props.getSpecialityList();
  }

  handleUpdateDoctorSubmit = async (values) => {
    await this.props.updateDoctorProfile(values);
    if (!this.props.update_profile_loading) this.props.navigate("/profile");
  };

  render() {
    const {
      speciality_is_loading,
      speciality_list_data,
      doctors_data,
      update_profile_loading,
    } = this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <IconButtonIcons
              onClick={() => {
                this.props.navigate("/profile");
              }}
              title="Previous"
              IconComponent={NavigateBeforeOutlinedIcon}
            />
            <Grid item xs={12}>
              <Typography variant="h6">Update Profile​</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <UpdateDoctorProfileComponent
                loading={update_profile_loading}
                speciality_is_loading={speciality_is_loading}
                speciality_list={speciality_list_data}
                update={(d) => this.handleUpdateDoctorSubmit(d)}
                ProvidersData={doctors_data}
              />
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default MyProfileContainer;
