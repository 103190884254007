import { axios } from "../../helpers/axios";
import {
  DOCTOR_Add_APPOINTMENTS,
  DOCTOR_GET_MY_APPOINTMENTS,
  DOCTOR_UPDATE_APPOINTMENTS,
} from "./appointmentConstants";

// Appointment API's
export const api_doctor_get_my_appointments = async (params) => {
  return axios.get(DOCTOR_GET_MY_APPOINTMENTS, { params });
};

export const api_doctor_add_appointments = async (data) => {
  return axios.post(DOCTOR_Add_APPOINTMENTS, data);
};

export const api_doctor_update_appointments = async (data) => {
  return axios.put(DOCTOR_UPDATE_APPOINTMENTS, data);
};
