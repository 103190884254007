import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getSpecialityList,
  addSpeciality,
  updateSpeciality,
} from "../../../store/query/querySlice";

const mapStateToProps = (state) => {
  return {
    
    speciality_is_loading: state.query.speciality_is_loading,
    speciality_list_data: state.query.speciality_list_data,
    add_speciality_is_loading: state.query.add_speciality_is_loading,
    add_speciality_data: state.query.add_speciality_data,
    update_speciality_is_loading: state.query.update_speciality_is_loading,
    update_speciality_data: state.query.update_speciality_data,


   
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSpecialityList,
      addSpeciality,
      updateSpeciality,
      
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
