import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addDoctors,
  getPhysicianDataUrl,
  removeDoctorDirectory,
  addDoctorsByCode,
  inviteDoctor,
} from "../../../store/user/userSlice";
import {
  getSpecialityList,
  doctorDirectoryUrl,
} from "../../../store/query/querySlice";
import { set_chat_window_ids } from "../../../store/common/commonSlice";

const mapStateToProps = (state) => {
  return {
    DoctorId: state.common.DoctorId,
    // Add Doctor
    add_doctors_loading: state.user.add_doctors_loading,

    doctor_directory_data: state.query.doctor_directory_data,
    doctor_directory_is_loading: state.query.doctor_directory_is_loading,

    speciality_is_loading: state.query.speciality_is_loading,
    speciality_list_data: state.query.speciality_list_data,

    physician_list_is_loading: state.user.physician_list_is_loading,
    physician_data: state.user.physician_data,

    add_doctors_by_code_is_loading: state.user.add_doctors_by_code_is_loading,
    DoctorId: state.common.DoctorId,

    // add_doctors_by_code_is_loading: state.user.add_doctors_by_code_is_loading,
    // add_doctors_by_code_data: state.user.add_doctors_by_code_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDoctors: addDoctors,
      getSpecialityList,
      doctorDirectoryUrl,
      getPhysicianDataUrl,
      set_chat_window_ids,
      removeDoctorDirectory,
      addDoctorsByCode,
      inviteDoctor,
      // addDoctorsByCode,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
