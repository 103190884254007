import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getOrgList,
  getOrg,
  postOrgList,
  update_org,
  adminUserTree,
} from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  return {
    get_org_data: state.user.get_org_data,
    get_org_is_loading: state.user.get_org_is_loading,
    update_org_is_loading: state.user.update_org_is_loading,

    postOrgIsLoading: state.user.post_org_is_loading,
    admin_user_tree_is_loading: state.user.admin_user_tree_is_loading,
    admin_user_tree_data: state.user.admin_user_tree_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrgList,
      getOrg,
      postOrgList,
      update_org,
      adminUserTree,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
