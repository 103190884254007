import * as React from "react";
import Box from "@mui/material/Box";
import Button from "../common/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  domain: yup.string().required("Domain is required"),
});

export default function EditOrgDialog({
  open,
  handleClose,
  handleSave,
  orgData,
}) {
  const handleSubmit = async (val) => {
    handleSave(val);
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <Box
          sx={{
            boxSizing: "border-box",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            borderRadius: "8px",
            boxShadow: 3,
          }}
        >
          <DialogContent
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
              <Typography onClick={handleClose} sx={{ cursor: "pointer" }}>
                X
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                textAlign: "center",
                letterSpacing: "-0.01em",
                flex: "none",
                order: "0",
                flexGrow: "0",
              }}
            >
              Edit Organization
            </Typography>
            <Formik
              initialValues={{ name: orgData.name, domain: orgData.domain }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                errors,
                isValid,
              }) => (
                <>
                  <TextField
                    defaultValue={orgData.name}
                    required={true}
                    disableUnderline={true}
                    label="Organization Name"
                    sx={{
                      borderRadius: "10px",

                      height: "40px",
                      width: "250px",
                      marginTop: "20px",
                      alignSelf: "center",
                      marginLeft: "20%",
                    }}
                    placeholder="Enter Organization Name"
                    size="small"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                    error={errors.name}
                    onInput={(e) => {
                      e.target.value = e.target.value.trimStart();
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ color: "#B2B9CC" }}>
                          <FmdGoodIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    defaultValue={orgData.domain}
                    required={true}
                    disableUnderline={true}
                    label="Domain Name"
                    sx={{
                      borderRadius: "10px",

                      height: "40px",
                      width: "250px",
                      marginTop: "20px",
                      alignSelf: "center",
                      marginLeft: "20%",
                    }}
                    placeholder="Enter Domain name"
                    size="small"
                    onChange={handleChange("domain")}
                    onBlur={handleBlur("domain")}
                    error={errors.domain}
                    value={values.domain}
                    onInput={(e) => {
                      e.target.value = e.target.value.trimStart();
                    }}
                  />

                  <Button
                    disabled={!isValid}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "187px",

                      background: "#004AAD",
                      borderRadius: "5px",
                      alignSelf: "center",
                      marginLeft: "27%",
                      marginTop: "5%",
                    }}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </>
              )}
            </Formik>
          </DialogContent>
          <DialogActions></DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
