import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const SupportHeader = (props) => {
  const { title, time } = props;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="h6">{title}</Typography>

        <Typography variant="h6">{time}</Typography>
      </Stack>
    </>
  );
};

export default SupportHeader;