import { io } from "socket.io-client";
import { wsBaseUrl } from "./helpers/constants";
import { getCookie } from "./helpers/cookies";
import {
  set_doctor_my_appointments_data_created,
  set_doctor_my_appointments_data_update,
} from "./store/appointment/appointmentSlice";

let socket;

export const initiateSocketConnection = (DoctorId) => {
  socket = io(wsBaseUrl, {
    auth: {
      Authorization: `BEARER ${getCookie("token")}`,
    },
    query: {
      DoctorId: DoctorId,
    },
    transports: ["websocket"],
  });

  console.log(`****** socket connected  *****`);

  socket.on("disconnect", (socket) => {
    console.log(`${socket} -- socket disconnected`);
  });
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const receiveMsgHere = (dispatch) => {
  socket.on("DOCTOR_APPOINTMENT_CREATED", (data) => {
    dispatch(set_doctor_my_appointments_data_created(data));
  });
  socket.on("DOCTOR_APPOINTMENT_UPDATED", (data) => {
  
    dispatch(set_doctor_my_appointments_data_update(data));
  });
};
export const sendMessage = (room, message) => {
  if (socket) socket.emit("chat", { message, room });
};
