import { Routes, Route, Navigate } from "react-router-dom";

// Views
import DashboardView from "../pages/support/Dashboard";
import AddDoctorsView from "../pages/support/AddDoctors";
import Organizations from "../pages/support/Organizations";

const SupportRoutes = () => {
  return (
    <Routes>
      <Route exact path="/dashboard" element={<DashboardView />} />
      <Route exact path="/add-update-doctors" element={<AddDoctorsView />} />
      <Route exact path="/organizations_list" element={<Organizations />} />
      <Route
        exact
        path="/add-update-doctors/:ProviderId"
        element={<AddDoctorsView />}
      />
      <Route path="*" element={<Navigate replace to="/dashboard" />} />
    </Routes>
  );
};

export default SupportRoutes;
