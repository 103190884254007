import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  requestDocumentsUrl,
  addReport,
  updateReport,
} from "../../../store/query/querySlice";

const mapStateToProps = (state) => {
  return {
    
    request_documents_loading: state.query.request_documents_loading,
    request_documents_data: state.query.request_documents_data,

    add_report_loading: state.query.add_report_is_loading,
    add_report_data: state.query.add_report_data,

    update_report_loading: state.query.update_report_is_loading,
    update_report_data: state.query.update_report_data,



   
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestDocumentsUrl,
      addReport,
      updateReport,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
