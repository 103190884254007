import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { queryDoctorDashboardKPICounts, queryDcotorDashboardStats } from "../../../store/query/querySlice";

const mapStateToProps = (state) => {
  return {
    DoctorId: state.common.DoctorId,
    dashboard_kpi_count_loading: state.query.dashboard_kpi_count_loading,
    dashboard_kpi_count_data: state.query.dashboard_kpi_count_data,
    dashboard_stats_loading: state.query.dasboard_stats_loading,
    dashboard_stats_data: state.query.dashboard_stats_data
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ queryDoctorDashboardKPICounts, queryDcotorDashboardStats }, dispatch);

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
