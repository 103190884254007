export const DOCTOR_MY_TEAMS = "/query/doctors/my-team";
export const DOCTOR_MY_PATIENTS = "/query/doctors/fetch/my-patients";
export const APPOINTMENT_HISTORY = "/query/appointments/fetch/by-date-range";
export const KPI_DASHBOARD_COUNT_URL = "/query/kpi-cards/fetch/dashboard";
export const DASHBOARD_STATS_URL = "/query/dashboard/fetch/stats";
export const REQUEST_DOCUMENTS_URL = "/static/hospital-reports/list";
export const DOCTOR_DIRECTORY_URL = "/query/fetch/my-doctors-directory";
export const MAP_NURSE_DOCTOR_URL = "/user/doctor/get-nurse-mapping";
export const MAP_NURSE_DOCTOR_UPDATE_URL = "/user/doctor/update-nurse-mapping";

// Support
export const SUPPORT_DASHBOARD_KPI = "/query/kpi-cards/fetch/organization";
export const DOCTOR_NURSE_LIST = "/query/nurse/my-team";
export const ADMIN_DOCTOR_LIST = "/query/admin/doctor/list";
export const SPECIALITY_LIST_URL = "/static/speciality/list";

// Country
export const STATE_LIST_URL = "/static/states/by-country-id";
export const CITY_LIST_URL = "/static/cities/by-state-id";
export const COMMON_LIST_URL = "/static/common/get";
export const COMMON_ADD_URL = "/static/common/add";
export const COMMON_UPDATE_URL = "/static/common/edit";

export const ADD_SPECIALITY = "/static/speciality/add";
export const UPDATE_SPECIALITY = "/static/speciality/update";


export const ADD_REPORT = "/static/hospital-reports/add";
export const UPDATE_REPORT = "/static/hospital-reports/update";
export const DOCTOR_ORGNIZATION_MAP_ADD = "/user/admin/update-doctor-organization-mapping";
export const DOCTOR_MAPPING_GET = "/user/admin/get-doctor-organization-mapping";
