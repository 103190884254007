import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import countries from "../../../helpers/countries";

export default function CountryList(props) {
  const { formik } = props;
  const [filter, setFilter] = React.useState("");

  const getValue = () => {
    return (
      countries.find(
        (obj) => "+" + obj.phone === formik.values?.country_code
      ) || null
    );
  };

  return (
    <Autocomplete
      id="country_code"
      fullWidth
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label + " +" + option.phone}
      onChange={(values, newValue) => {
        if (newValue?.phone) {
          formik.setFieldValue("country_code", "+" + newValue.phone);
        } else {
          formik.setFieldValue("country_code", "");
        }
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          fullWidth
          error={
            formik.touched.country_code && Boolean(formik.errors.country_code)
          }
          helperText={formik.touched.country_code && formik.errors.country_code}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      onInputChange={(event, newInputValue) => setFilter(newInputValue)}
      inputValue={filter}
      value={getValue()}
    />
  );
}
