import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const DocumentRequestSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Skeleton animation="wave" height={25} width="40%" />
      <Stack>
        <Skeleton animation="wave" height={40} width="80%" />
      </Stack>
    </Stack>
  );
};

export default DocumentRequestSkeleton;
