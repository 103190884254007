import { get } from "lodash-es";
import fileTypes from "../../helpers/fileTypes";

export const parser_fetch_patient_documents_url = (response) => {
  try {
    if (response?.data?.documents) {
      response = response.data?.documents;
    }
    if (!response) {
      return [];
    }

    return response.map((groupedDocuments) => {
      return {
        key: get(groupedDocuments, "key", ""),
        data: groupedDocuments.data.map((documents) => {
          const document_extension =
            get(documents, "patient_documents.document_file", "")
              .split(".")
              .pop() || "docx";
          return {
            db_id: get(documents, "db_id", ""),
            id: get(documents, "id", ""),
            createdAt: get(documents, "createdAt", ""),
            document_file: get(
              documents,
              "patient_documents.document_file",
              ""
            ),
            document_name: get(
              documents,
              "patient_documents.document_name",
              ""
            ),
            document_date: get(
              documents,
              "patient_documents.document_date",
              ""
            ),
            document_extension,
            document_type: fileTypes[document_extension]?.type || "document",
          };
        }),
      };
    });
  } catch (error) {
    console.log(error.message);
    throw new Error(error);
  }
};

export const parser_fetch_patient_documents_request_url = (response) => {
  try {
    if (response?.data?.documents_request) {
      response = response.data?.documents_request;
    }
    if (!response) {
      return [];
    }

    return response.map((e) => {
      return {
        id: get(e, "id", ""),
        document_id: get(e, "hospital_tests.id", ""),
        title: get(e, "hospital_tests.title", ""),
      };
    });
  } catch (error) {
    console.log(error.message);
    throw new Error(error);
  }
};

export const parser_remove_request_docs_url = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }

    return response.map((e) => {
      return {
        id: get(e, "id", ""),
        title: get(e, "hospital_tests.title", ""),
      };
    });
  } catch (error) {
    console.log(error.message);
    throw new Error(error);
  }
};

export const parser_documents_comprehend_medical = (response) => {
  try {
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return [];
    }

   const entities = response.Entities.map((e) => {
      return {
        Category: get(e, "Category", ""),
        Text: get(e, "Text", ""),
        Type: get(e, "Type", ""),
        Traits: get(e, "Traits", []),
        Attributes: get(e, "Attributes", []),
      };
    });



    return entities ;

  } catch (error) {
    console.log(error.message);
    throw new Error(error);
  }
}; 
