import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import React from "react";

const UnlinkedPatientSkeleton = () => {
  return (
    <>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Skeleton width={150} height={35} />
          <Skeleton width={150} height={35} />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Skeleton width={150} height={35} />
          <Skeleton width={150} height={35} />
        </Stack>
        <Stack direction="row" justifyContent={"center"} alignItems="center">
          <Skeleton width={500} height={55} />
        </Stack>
      </Stack>
    </>
  );
};

const MultiplyUnlinkedSkeleton = () => {
  let multipleComponent = [];
  for (let i = 0; i < 6; i++) {
    multipleComponent.push(
      <UnlinkedPatientSkeleton key={"patient_skeleton_key_" + i} />
    );
  }
  return multipleComponent;
};

const PatientSkeleton = () => {
  return <MultiplyUnlinkedSkeleton />;
};

export default PatientSkeleton;
