import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "../../common/Button";
import Input from "../../common/Input";
import IconButtonImg from "../../common/IconButtonImg";
import { calculateMinutesFromSeconds } from "../../../helpers/datetime";

const VerifyOtpComponent = (props) => {
  const { email = "test@gmail.com", verifyOtp, loading } = props;
  const [counter, set_counter] = useState(600);
  const navigate = useNavigate();
  useEffect(() => {
    if (counter) {
      const myInterval = setInterval(() => {
        set_counter(counter - 1);
      }, 1000);
      return () => clearInterval(myInterval);
    }
  }, [counter]);

  const resendOtp = () => {
    set_counter(600);
  };

  const onFormSubmit = async (values) => {
    verifyOtp(values);
  };

  return (
    <>
      <Formik
        initialValues={{ otp: "", email }}
        onSubmit={(values) => onFormSubmit(values)}
        validationSchema={validateLoginForm}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          values,
          touched,
          errors,
        }) => (
          <>
            <Container maxWidth="xs">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: "100vh",
                  width: "auto",
                  mx: 4,
                }}
                spacing={1.5}
              >
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    fontWeight: "600",
                    textTransform: "uppercase",
                    fontSize: "28px",
                  }}
                >
                  OTP VERIFICATION
                </Typography>
                <Typography
                  variant="subtitle2"
                  align="center"
                  sx={{ fontSize: "15px" }}
                >
                  Enter 4 digit OTP sent to
                </Typography>
                <Typography
                  variant="subtitle2"
                  align="center"
                  sx={{ fontSize: "15px", color: "#0098FF", mb: 2.5 }}
                >
                  {email}
                  <IconButtonImg
                    src="/images/Iconly-Light-Edit.svg"
                    alt="edit"
                    title="Edit Email"
                    onClick={() => navigate("*")}
                    // onClick={() => console.log("reset")}
                  />
                </Typography>
                <Input
                  type="text"
                  fullWidth
                  placeholder=""
                  id="otp"
                  name="otp"
                  variant="outlined"
                  onChange={handleChange("otp")}
                  onBlur={handleBlur("otp")}
                  value={values.otp}
                  error={!touched.otp && Boolean(errors.otp)}
                  helperText={!touched.otp && errors.otp}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                   
                      handleSubmit();
                     
                    }
                  }}
                />
                <Stack
                  direction="row"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 2.2,
                  }}
                >
                  <CircularProgress
                    size={22}
                    variant="determinate"
                    value={(counter / 600) * 100}
                    sx={{ mr: 0.3 }}
                  />
                  <Typography
                    variant="subtitle2"
                    align="center"
                    sx={{ color: "#B2B9CC", pl: 0.5 }}
                  >
                    {counter ? calculateMinutesFromSeconds(counter) : "00:00"}
                  </Typography>
                </Stack>

                <Typography
                  variant="subtitle2"
                  sx={{ mt: 3, color: "#7D7D7D", textAlign: "center" }}
                >
                  Didn't received the otp?
                  <Button
                    disabled={counter ? true : false}
                    onClick={resendOtp}
                    variant="text"
                  >
                    Resend OTP
                  </Button>
                </Typography>

                <Button
                  onClick={handleSubmit}
                  disabled={!isValid}
                  loading={loading}
                  type="button"
                  fullWidth
                  variant="contained"
                >
                  Verify
                </Button>
              </Stack>
            </Container>
          </>
        )}
      </Formik>
    </>
  );
};

export default VerifyOtpComponent;

const validateLoginForm = yup.object().shape({
  otp: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4, "Must be exactly 4 digits")
    .max(4, "Must be exactly 4 digits"),
});
