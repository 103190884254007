import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";

const CancelPortal = () => {
  return (
    <>
      <Grid container alignItems="center" justifyContent="center" boxShadow="2">
        <CancelIcon
          sx={{
            marginTop:"10px",
            color: "red",
            fontSize: 70,
          }}
        />
        <Typography
          sx={{
            color: "green",
            margin: "10px",
            fontSize: "18px",
          }}
        >
          You have cancelled your payment. Please relogin to proceed with
          payment. In case of questions, email us at support@myhipaasafe.com.
        </Typography>
      </Grid>
    </>
  );
};

export default CancelPortal;
