import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  SUPPORT_USER,
  getOrgList,
  getOrg,
  REGISTER_SUPPORT_USER,
  adminUserUpdate,
} from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  return {
    support_user_data: state.user.support_user_data,
    support_user_is_loading: state.user.support_user_is_loading,
    get_org_data: state.user.get_org_data,
    admin_user_update_is_loading: state.user.admin_user_update_is_loading,
    admin_user_update_data: state.user.admin_user_update_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SUPPORT_USER,
      getOrgList,
      getOrg,
      REGISTER_SUPPORT_USER,
      adminUserUpdate,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
