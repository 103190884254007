import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

const PageNotFound = () => {
  return (
    <>
      <Stack
        direction="column"
        justifyContent={"center"}
        alignItems="center"
        spacing={2}
      >
        <Typography align="center" variant="h5">
          Some Error Occured
        </Typography>
        <img
          width="200"
          height="200"
          src="/assets/images/cancel_image.svg"
          alt="cancel_image"
        />
        <Typography align="center" variant="body">
          {" "}
        </Typography>
      </Stack>
    </>
  );
};
export default PageNotFound;
