import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MedicationIcon from "@mui/icons-material/Medication";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import ProfileImage from "../../common/ProfileImage";
import DetailsTextField from "../../common/DetailsTextField";

const MyPhysicianDetail = ({ data }) => {
  const { avatar, FirstName, LastName, Email, speciality2, tags2, mobile } =
    data;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Paper elevation={0} sx={{ alignItems: "center" }}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              spacing={2}
              p={2}
            >
              <ProfileImage
                alt={FirstName}
                src={avatar}
                width={150}
                height={150}
              />
              <Typography variant="h5">{FirstName + " " + LastName}</Typography>
              <Stack
                direction="row"
                sx={{
                  width: "80%",
                  display: "block",
                  textAlign: "center",
                }}
                spacing={1}
              >
                {tags2 &&
                  tags2.map((ele, i) => {
                    return (
                      <Chip
                        key={"chip_" + i}
                        label={ele.title}
                        variant="outlined"
                        sx={{ m: 1 }}
                      />
                    );
                  })}
              </Stack>
              <DetailsTextField
                label="Speciality"
                name="speciality"
                value={speciality2?.title}
                Icon={() => {
                  return <MedicationIcon />;
                }}
              />
              <DetailsTextField
                label="Email Address"
                name="email"
                value={Email}
                Icon={() => {
                  return <MailIcon />;
                }}
              />
              <DetailsTextField
                label="Mobile Number"
                name="mobile"
                value={mobile}
                Icon={() => {
                  return <PhoneIphoneIcon />;
                }}
              />
            </Stack>
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MyPhysicianDetail;
