import React from "react";
import Table from "../../../components/common/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "../../../components/common/Button";
import ProfileImage from "../../common/ProfileImage";
// import Stack from "@mui/material/Stack";

const MyPatientComponent = (props) => {
  const { patientsDetails, handleOpenChat, loading } = props;
  const columns = [
    {
      title: "Sr.No.",
    },
    {
      title: "Patient Name",
    },
    {
      title: "Email",
    },
    {
      title: "Phone",
    },
    {
      title: "City",
    },
    {
      title: "Chat",
    },
  ];
  const renderTableData = patientsDetails.length ? (
    patientsDetails.map((row, index) => {
      const { guid, PatientId } = row;
     
      return (
        <TableRow key={index} sx={{ "td, th": { border: 0, padding: "10px" } }}>
          <TableCell align="center" component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell
            align="center"
            onClick={() => props.showDetails(PatientId)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ProfileImage
                alt={row.FirstName}
                src={row.avatar}
                style={{ marginRight: 10 }}
              />
              {row.FirstName + " " + row.LastName}
            </div>
          </TableCell>
          <TableCell align="center">{row.EmailAddress}</TableCell>
          <TableCell align="center">{row.mobile}</TableCell>
          <TableCell align="center">{row.City}</TableCell>
          <TableCell sx={{ width: "120px" }} size="small" align="center">
            <Button
              // disableElevation
              color="warning"
              variant="contained"
              sx={{ borderRadius: "50px" }}
              onClick={() => handleOpenChat(guid)}
            >
              Chat
            </Button>
          </TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
      <TableCell align="center" colSpan={columns.length}>
        Data not found
      </TableCell>
    </TableRow>
  );

  return (
    <Table columns={columns} tableData={renderTableData} loading={loading} />
  );
};

export default MyPatientComponent;
