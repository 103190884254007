import { configureStore } from "@reduxjs/toolkit";

import axiosInterceptor from "../helpers/axios.js";
import commonSlice from "./common/commonSlice.js";
import querySlice from "./query/querySlice.js";
import userSlice from "./user/userSlice.js";
import appointmentSlice from "./appointment/appointmentSlice.js";
import documentSlice from "./document/documentSlice.js";
import notesSlice from "./notes/notesSlice.js";

const store = configureStore({
  reducer: {
    common: commonSlice,
    query: querySlice,
    user: userSlice,
    appointment: appointmentSlice,
    document: documentSlice,
    notes: notesSlice,
  },
});

axiosInterceptor(store.dispatch);

export { store };
