import React from 'react'
import Table from '../../components/common/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Button, Typography } from '@mui/material'
import format from 'date-fns/format'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'

const Feedback = (props) => {
  const { tableDetails, loading, handleOpen, handleOnChange } = props

  const columns = [
    // {
    //   title: 'id',
    // },
    {
      title: 'Name',
    },
    {
      title: 'Email',
    },

    {
      title: 'Message',
    },
    {
      title: 'Date & Time',
    },
    {
      title: 'Status',
    },
    {
      title: 'Action',
    },
  ]

  const renderTableData = tableDetails?.length ? (
    tableDetails?.map((row, index) => {
      return (
        <TableRow key={index} sx={{ 'td, th': { border: 0, padding: '10px' } }}>
          {/* <TableCell align="center" component="th" scope="row">
            {row.id}
          </TableCell> */}
          <TableCell align="center">{row.FirstName + ' ' + row.LastName}</TableCell>
          <TableCell align="center">
            <a style={{ textDecoration: 'none' }} href={`mailto:${row.Email}`}>
              {row.Email}
            </a>
          </TableCell>
          <TableCell align="center">
            <Tooltip title="Click to view full message" placement="top-end">
              {row.query.length > 20 ? (
                <Typography
                  onClick={() => handleOpen(row)}
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: 'pointer' }}
                >
                  {row.query.substring(0, 20) + '...'}
                </Typography>
              ) : (
                <Typography
                  onClick={() => handleOpen(row)}
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: 'pointer' }}
                >
                  {row.query}
                </Typography>
              )}
            </Tooltip>
          </TableCell>
          <TableCell align="center">
            <Grid container spacing={1} direction="column">
              <Typography variant="body2" color="text.secondary">
                {format(new Date(row.createdAt), 'hh:mm a')}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                {format(new Date(row.createdAt), 'dd/MM/yyyy')}
              </Typography>
            </Grid>
          </TableCell>

          <TableCell align="center">
            <Select
              value={row.status ? row.status : 'Pending'}
              onChange={(e) => {
                handleOnChange(row.id, e.target.value)
              }}
              sx={{ width: '100%' }}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Resolved">Responded</MenuItem>
              <MenuItem value="Reviewed">Reviewed</MenuItem>
            </Select>
          </TableCell>

          <TableCell align="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleOpen(row)
              }}
            >
              View
            </Button>
          </TableCell>
        </TableRow>
      )
    })
  ) : (
    <TableRow sx={{ 'td, th': { border: 0, padding: '10px' } }}>
      <TableCell align="center">Data not found</TableCell>
    </TableRow>
  )

  return <Table columns={columns} tableData={renderTableData} loading={loading} />
}

export default Feedback
