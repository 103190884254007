import * as React from "react";
import Drawer from "@mui/material/Drawer";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import Button from "../../common/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { useFormik } from "formik";
import CountryList from "../schedule/CountriesList";
import { useDispatch } from "react-redux";
import { set_patient_drawer_state } from "../../../store/common/commonSlice";
import * as yup from "yup";


const validatePatient = yup.object().shape({
  EmailAddress: yup.string().email().required(),
  FirstName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
  LastName: yup
    .string()
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .required("Required"),
    number: yup.number().required("Phone  number is required!").positive().min(10),
   country_code: yup.string().required(),
});

export default function AddPatientDrawer({ loading, addPatient, open, close }) {
  const onSubmit = async (values) => {
    addPatient(values);
  };

  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      EmailAddress:"",
      FirstName: "",
      LastName: "",
      country_code: "IN",
      number: "",
      appointment_sms_enable: false,
      login_sms_enable: true,
      register_by_admin: true,


    },

    onSubmit: (values) => {
      onSubmit(values);

      //clear form 
      formik.resetForm();
    },
   
    validationSchema: validatePatient,

  });
  return (
    <Drawer
      anchor={"right"}
      open={open}
      PaperProps={{
        sx: { width: "35%" },
      }}
      onClose={() => dispatch(set_patient_drawer_state({ show: false }))}
    >
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        alignItems="Left"
        spacing={1}
        sx={{ height: "100%", px: 5, py: 3 }}
      >
        <Stack
          sx={{ width: "100%" }}
          justifyContent="Left"
          alignItems="Left"
          spacing={2}
        >
          <Stack sx={{ pb: 3 }}>
            <Typography variant="h6">Create a Patient</Typography>
          </Stack>
          <Stack sx={{ pb: 1 }}>
            <Typography>Patient Details</Typography>
          </Stack>

          <form onSubmit={formik.handleSubmit}>
            <Stack
              sx={{ width: "100%" }}
              justifyContent="Left"
              alignItems="Left"
              spacing={2}
            >
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                type="text"
                onChange={formik.handleChange("FirstName")}
                onBlur={formik.handleBlur("FirstName")}
                value={formik.values.FirstName}
                error={
                  !formik.touched.FirstName && Boolean(formik.errors.FirstName)
                }
                helperText={
                  !formik.touched.FirstName && formik.errors.FirstName
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: "#B2B9CC" }}>
                      <PersonOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                type="text"
                onChange={formik.handleChange("LastName")}
                onBlur={formik.handleBlur("LastName")}
                value={formik.values.LastName}
                error={
                  !formik.touched.LastName && Boolean(formik.errors.LastName)
                }
                helperText={!formik.touched.LastName && formik.errors.LastName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: "#B2B9CC" }}>
                      <PersonOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                onChange={formik.handleChange("EmailAddress")}
                onBlur={formik.handleBlur("EmailAddress")}
                value={formik.values.EmailAddress}
                error={
                  !formik.touched.EmailAddress &&
                  Boolean(formik.errors.EmailAddress)
                }
                helperText={
                  !formik.touched.EmailAddress && formik.errors.EmailAddress
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: "#B2B9CC" }}>
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <CountryList formik={formik} />

              <TextField
                fullWidth
                label="Mobile Number"
                variant="outlined"
                type="text"
                onChange={formik.handleChange("number")}
                onBlur={formik.handleBlur("number")}
                value={formik.values.number}
                error={!formik.touched.number && Boolean(formik.number)}
                helperText={!formik.touched.number && formik.number}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: "#B2B9CC" }}>
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <FormGroup>
                <FormControlLabel
                 
                  control={<Checkbox defaultChecked />}
                  label="Opt in to receive SMS and Email"
                  checked={formik.values.login_sms_enable}
                  onChange={formik.handleChange("login_sms_enable")}

                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={<Checkbox  />}
                  label="Opt in to receive SMS For Appointment "
                  checked={formik.values.appointment_sms_enable}
                  onChange={formik.handleChange("appointment_sms_enable")}
                />
              </FormGroup>

              <Button
                type="submit"
                disabled={!formik.isValid}
                loading={loading}
                fullWidth
              >
                ADD PATIENT
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Drawer>
  );
}
