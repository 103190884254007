import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { set_dark_mode } from "../../../store/common/commonSlice";
import { isAuth, signout } from "../../../helpers/cookies";
import { useTheme } from "@mui/styles";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const drawerWidth = 250;

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function BaseLayout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mode } = useTheme().palette;
  //   const { chat_window_state } = useSelector((state) => state.feedback);
  const path_url = window.location.pathname;
  const { modules, ChildComponent } = props;
  //   const [auth, setAuth] = React.useState(true);
  //   const [anchorEl, setAnchorEl] = React.useState(null);
  //   const { doctor_id } = useSelector((state) => state.doctor);

  //   const handleMenu = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  const handleSignOut = () => {
    signout(() => navigate(0));
    window.location.reload();
  };

  //   React.useEffect(() => {
  //     if (isAuth()) {
  //       let mappedDoctor = isAuth().doctors_mapped;
  //       if (mappedDoctor && mappedDoctor.length > 0) {
  //         dispatch(setDoctorId(mappedDoctor[0].ProviderId));
  //       }
  //     }
  //   }, [dispatch]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {/* <Fab
          sx={{ zIndex: 9999, position: "fixed", bottom: 18, right: 18 }}
          color="primary"
          aria-label="add"
          // onClick={() => {
          //   dispatch(set_chat_window_state());
          // }}
        >
          <CloseIcon />
        </Fab> */}

        <AppBar
          position="fixed"
          elevation={1}
          open={true}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            zIndex: 1200,
            bgcolor: "background.paper",
            shadow: "none",
          }}
          enableColorOnDark
        >
          {/* <Toolbar sx={isAuth().role_name === "NURSE" ? { width: "100%" } : null}>
          {isAuth().role_name === "NURSE" ? (
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <FormControl>
                <InputLabel id="doctor-id-select-label">Doctor</InputLabel>
                <Select
                  labelId="doctor-id-select-label"
                  id="doctor-id-select"
                  size="small"
                  value={
                    doctor_id
                      ? doctor_id
                      : isAuth()?.doctors_mapped.length > 0
                      ? isAuth()?.doctors_mapped[0].ProviderId
                      : null
                  }
                  label="Doctor"
                  onChange={(val) => dispatch(setDoctorId(val.target.value))}
                >
                  {isAuth().doctors_mapped.map(function (dd) {
                    return (
                      <MenuItem value={dd.ProviderId}>
                        <Typography>
                          {dd.FirstName + " " + dd.LastName}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          ) : null}

          {auth && (
            <Box
              pr={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton size="large" onClick={handleMenu}>
                {isAuth().avatar ? (
                  <Avatar
                    alt={isAuth().FistName}
                    src={isAuth().avatar}
                    sx={{ bgcolor: "grey" }}
                  />
                ) : (
                  <AccountCircle
                    sx={{ color: "#bdbdbd", width: "32px", height: "32px" }}
                  />
                )}
              </IconButton>
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                {isAuth().FirstName}
              </Typography>
              <IconButton title="Log Out" size="large" onClick={handleSignOut}>
                <LogoutIcon
                  sx={{
                    // color: "#1C1C1C",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </IconButton>
              // <Menu
              //   id="menu-appbar"
              //   anchorEl={anchorEl}
              //   anchorOrigin={{
              //     vertical: "bottom",
              //     horizontal: "center",
              //   }}
              //   keepMounted
              //   transformOrigin={{
              //     vertical: "top",
              //     horizontal: "center",
              //   }}
              //   open={Boolean(anchorEl)}
              //   onClose={handleClose}
              // >
              //   <MenuItem
              //     onClick={() => {
              //       setAnchorEl(null);
              //       navigate("/doctor/profile");
              //     }}
              //   >
              //     My Profile
              //   </MenuItem>
              // </Menu>
            </Box>
          )}
        </Toolbar> */}
          <Box
            pr={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
             <FormControlLabel
                control={<MaterialUISwitch defaultChecked />}
                label="Night Mode"
                onClick={() => {
                  dispatch(set_dark_mode());
                  // window.location.reload();
                }}
              />


            <AccountCircle
              sx={{ color: "#bdbdbd", width: "32px", height: "32px" }}
            />
            <Typography variant="subtitle1" color="text.primary" sx={{ mr: 2 }}>
              {isAuth().name}
            </Typography>


            

           
            <IconButton title="Log Out" size="large" onClick={handleSignOut}>
              <LogoutIcon
                sx={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </IconButton>

          </Box>
        </AppBar>
        <Drawer
          variant="permanent"
          open={true}
          PaperProps={{
            sx: {
              bgcolor: "background.paper",
              border: "none",
            },
            elevation: 1,
          }}
        >
          <DrawerHeader
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={
                mode === "dark"
                  ? "/hipaa_safe_logo_dark.svg"
                  : "/hipaa_safe_logo_light.svg"
              }
              alt="hipaasafe_logo"
            />
          </DrawerHeader>
          <List>
            {modules.map((module, i) => {
              return (
                <Tooltip
                  key={("module_", i)}
                  placement="right"
                  title={module.title}
                >
                  <ListItem
                    button
                    key={module.id}
                    onClick={() => navigate(module.link)}
                    selected={path_url === module.link ? true : false}
                    sx={{
                      mx: 2,
                      my: 0.5,
                      width: "unset",
                      borderRadius: "10px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "35px",
                        ...(path_url === module.link && { color: "#0098FF" }),
                      }}
                    >
                      {module.logo}
                    </ListItemIcon>
                    <ListItemText
                      primary={module.title}
                      sx={{
                        ...(path_url === module.link && { color: "#0098FF" }),
                      }}
                    />
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
          <Divider />

          

        
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, mx: 3, my: 1 }}>
          <Toolbar />
          <ChildComponent />
        </Box>
      </Box>
    </>
  );
}
