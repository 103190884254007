import React from "react";
import Button from "../../common/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Formik } from "formik";
import Input from "../../common/Input";
import * as yup from "yup";

const validateLoginForm = yup.object().shape({
  email: yup.string().email().required(),
});

const LoginFormComponent = (props) => {
  const { submitOtp, loading ,goTOregister } = props;
  const initialValues = {
    email: "",
  };

  const onFormSubmit = async (values) => {
    submitOtp(values);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onFormSubmit(values)}
        validationSchema={validateLoginForm}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          values,
          touched,
          errors,
        }) => (
          <>
            <Container maxWidth="xs">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: "100vh",
                  width: "auto",
                  mx: 4,
                }}
                spacing={1.5}
              >
                <Typography
                  variant="h5"
                  align="center"
                  sx={{
                    fontWeight: "600",
                    textTransform: "uppercase",
                    // color: "#212121",
                    fontSize: "28px",
                  }}
                >
                  Login
                </Typography>

                <Typography
                  variant="subtitle2"
                  align="center"
                  sx={{ fontSize: "15px", color: "#7D7D7D" }}
                >
                  Welcome Back
                </Typography>

                <Typography
                  variant="subtitle2"
                  alignSelf="start"
                  sx={{ color: "#707070" }}
                >
                  Email
                </Typography>

                <Input
                  type="text"
                  fullWidth
                  placeholder="Enter email address"
                  id="email"
                  name="email"
                  variant="outlined"
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                  value={values.email}
                  error={!touched.email && Boolean(errors.email)}
                  helperText={!touched.email && errors.email}

                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                   
                      handleSubmit();
                     
                    }
                  }}

                />

                <Button
                  onClick={handleSubmit}
                  disabled={!isValid}
                  loading={loading}
                  fullWidth
                >
                  Continue
                </Button>
                <Button
                
                  onClick={goTOregister}
                  
                  fullWidth
                sx={{
                  color: "#ffffff",
                  backgroundColor: "orange",
                  "&:hover": {
                    backgroundColor: "orange",
                  },
                }}
                >
                  New - Click to register
                </Button>
              </Stack>
            </Container>
          </>
        )}
      </Formik>
    </>
  );
};
export default LoginFormComponent;
