import { axios } from "../../helpers/axios";

import {
  DOCTOR_MY_TEAMS,
  DOCTOR_MY_PATIENTS,
  APPOINTMENT_HISTORY,
  KPI_DASHBOARD_COUNT_URL,
  SUPPORT_DASHBOARD_KPI,
  DOCTOR_NURSE_LIST,
  SPECIALITY_LIST_URL,
  STATE_LIST_URL,
  CITY_LIST_URL,
  REQUEST_DOCUMENTS_URL,
  DOCTOR_DIRECTORY_URL,
  MAP_NURSE_DOCTOR_URL,
  MAP_NURSE_DOCTOR_UPDATE_URL,
  DASHBOARD_STATS_URL,
  COMMON_LIST_URL,
  ADD_SPECIALITY,
  UPDATE_SPECIALITY,
  UPDATE_REPORT,
  ADD_REPORT,
  COMMON_ADD_URL, 
  COMMON_UPDATE_URL,
  DOCTOR_ORGNIZATION_MAP_ADD,
  DOCTOR_MAPPING_GET,
  ADMIN_DOCTOR_LIST,
} from "./queryConstants";

export const api_doctor_my_teams = async (params) => {
  return axios.get(DOCTOR_MY_TEAMS, { params });
};

export const api_nurse_doctor_map_list = async (params) => {
  return axios.get(MAP_NURSE_DOCTOR_URL, { params });
};

export const api_nurse_doctor_map_update = async (data) => {
  return axios.put(MAP_NURSE_DOCTOR_UPDATE_URL, data);
};

export const api_doctor_my_patients = async (params) => {
  return axios.get(DOCTOR_MY_PATIENTS, { params });
};

export const api_appointment_history = async (params) => {
  return axios.get(APPOINTMENT_HISTORY, { params });
};

export const api_dashboard_kpi_counts = async (params) => {
  return axios.get(KPI_DASHBOARD_COUNT_URL, { params });
};

export const api_dashboard_stats = async (params) => {
  return axios.get(DASHBOARD_STATS_URL, { params });
};

export const api_support_dashboard_kpi = async (params) => {
  return axios.get(SUPPORT_DASHBOARD_KPI, { params });
};

export const api_doctor_nurse_list = async (params) => {
  return axios.get(DOCTOR_NURSE_LIST, { params });
};

export const api_speciality_list_url = async (params) => {
  return axios.get(SPECIALITY_LIST_URL, { params });
};

export const api_request_documents_url = async (params) => {
  return axios.get(REQUEST_DOCUMENTS_URL, { params });
};

export const api_doctor_directory_url = async (params) => {
  return axios.get(DOCTOR_DIRECTORY_URL, { params });
};

export const api_state_list_url = async (params) => {
  return axios.get(STATE_LIST_URL, { params });
};
export const api_city_list_url = async (params) => {
  return axios.get(CITY_LIST_URL, { params });
};

export const api_common_add = async (data) => {
  return axios.post(COMMON_ADD_URL, data);
};

export const api_common_update = async (data) => {
  return axios.put(COMMON_UPDATE_URL, data);
};

export const api_common_list = async (params) => {
  return axios.get(COMMON_LIST_URL, { params });
};

export const add_speciality_api = async (data) => {
  return axios.post(ADD_SPECIALITY, data);

}

export const update_speciality_api = async (data) => {
  return axios.put(UPDATE_SPECIALITY, data);

}
export const update_report_api = async (data) => {
  return axios.put(UPDATE_REPORT, data);

}
export const add_report_api = async (data) => {
  return axios.post(ADD_REPORT, data);

}


export const admin_doctor_mapping_add_api = async (data) => {
  return axios.post(DOCTOR_ORGNIZATION_MAP_ADD, data);
}


export const admin_doctor_mapping_update_api = async (data) => {
  return axios.post(DOCTOR_ORGNIZATION_MAP_ADD, data);
}

export const admin_doctor_mapping_list_api = async (params) => {
  return axios.get(DOCTOR_MAPPING_GET, { params });
}

export const admin_doctor_list_api = async (params) => {
  return axios.get(ADMIN_DOCTOR_LIST, { params });
}