import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addDoctorsByCode } from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  return {
    add_doctors_by_code_is_loading: state.user.add_doctors_by_code_is_loading,
    DoctorId: state.common.DoctorId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDoctorsByCode,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
