import { PermIdentityOutlined } from "@mui/icons-material";
import { Avatar, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { format, formatDistance, parseISO  , addMinutes} from "date-fns";
import { calculateBirthDate } from "../../../helpers/datetime";
import Button from "../../common/Button";
import { useState } from "react";

export default function PatientDataComponent({
  theme,
  update,
  reschedule,
  loading,
  data,
  handleChatOpen,
}) {

  const [reminderLoading, setReminderLoading]  = useState(false)
  const [confirmLoading, setConfirmLoading]  = useState(false)
  const updateAppointmentAction = async(data, action) => {
    let payload = {
      AppointmentId: data.AppointmentId,
      appointment_date: data.StartTime,
      type: action,
    };
    if(action==='NOTIFY_CONFIRMATION'){
      setReminderLoading(true)
      await update(payload);
      setReminderLoading(false)
    }else{
      setConfirmLoading(true)
      await update(payload);
      setConfirmLoading(false)

    }
  };


  const RenderButtons = ({ status }) => {
    switch (status.id) {
      case "completed": // Appointment is completed
        return (
          <Button
            disableElevation
            variant="outlined"
            color={status.color}
            disableRipple
            disableFocusRipple
            sx={{ borderRadius: "50px" }}
          >
            {status.value}
          </Button>
        );
      case "arrived": // Patient has arrived
        return (
          <Button
            disableElevation
            variant="outlined"
            color={status.color}
            disableRipple
            disableFocusRipple
            sx={{ borderRadius: "50px" }}
          >
            {status.value}
          </Button>
        );
      case "nextinq": // Patient's turn, now show mark as complete button
        return (
          <Button
            onClick={() => updateAppointmentAction(data, "COMPLETED")}
            color={status.color}
            sx={{ borderRadius: "50px" }}
            loading={loading}
          >
            {status.value}
          </Button>
        );
      case "confirmed":
        if (status.isToday === 0) {
          return (
            <Button
              onClick={() => updateAppointmentAction(data, "PATIENT_ARRIVED")}
              disableElevation
              // variant="outlined"
              color={status.color}
              sx={{ borderRadius: "50px" }}
              loading={loading}
            >
              Check In
            </Button>
          );
        } else if (status.isToday === -1) {
          return (
            <Button
              disableElevation
              variant="outlined"
              color={status.color}
              disableRipple
              disableFocusRipple
              sx={{ borderRadius: "50px" }}
            >
              Appointment Missed
            </Button>
          );
        } else {
          return (
            <Button
              disableElevation
              variant="outlined"
              color={status.color}
              disableRipple
              disableFocusRipple
              sx={{ borderRadius: "50px" }}
            >
              Confirmed
            </Button>
          );
        }
      case "cancelled":
        return (
          <Button
            disableElevation
            variant="outlined"
            color={status.color}
            disableRipple
            disableFocusRipple
            sx={{ borderRadius: "50px" }}
          >
            Cancelled
          </Button>
        );
      case "rescheduled":
        return (
          <Button
            onClick={() => reschedule()}
            color={status.color}
            sx={{ borderRadius: "50px" }}
          >
            Reschedule Now
          </Button>
        );
      case "reminder":
        if (status.isToday === -1) {
          return (
            <Button
              disableElevation
              variant="outlined"
              color={status.color}
              disableRipple
              disableFocusRipple
              sx={{ borderRadius: "50px" }}
            >
              Appointment Expired
            </Button>
          );
        } else {
          return (
            <Stack direction="row" spacing={1}>
            <Button
              disableElevation
              variant="outlined"
              color={status.color}
              disableRipple
              disableFocusRipple
              sx={{ borderRadius: "50px" }}
            >
              Reminder Sent
            </Button>
            <Button
              onClick={() =>
                updateAppointmentAction(data, "CONFIRM")
              }
              // variant="outlined"
              //color={status.color}
              sx={{ borderRadius: "50px" }}
              loading={loading}
            >
              Confirm Appointment
            </Button>
            </Stack>
          );
        }
      case "pending":
        if (status.isToday === -1) {
          return (
            <Button
              disableElevation
              variant="outlined"
              color={status.color}
              disableRipple
              disableFocusRipple
              sx={{ borderRadius: "50px" }}
            >
              Appointment Expired
            </Button>
          );
        } else {
          return (
            <Stack direction="row" spacing={1}>
            <Button
              onClick={() =>
                updateAppointmentAction(data, "NOTIFY_CONFIRMATION")
              }
              // variant="outlined"
              color={status.color}
              sx={{ borderRadius: "50px" }}
              loading={reminderLoading}
            >
              Send reminder
            </Button>
              <Button
              onClick={() =>
                updateAppointmentAction(data, "CONFIRM")
              }
              // variant="outlined"
              //color={status.color}
              sx={{ borderRadius: "50px" }}
              loading={confirmLoading}
            >
              Confirm Appointment
            </Button>
            </Stack>
          );
        }
      default:
        return "error";
    }
  };
 
  return (
    <Stack sx={{ p: 3 }} spacing={1}>
      <Typography variant="body1">Patient Details</Typography>
      <Stack spacing={1}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
            border: "1px solid #E0E3E9",
            borderRadius: "10px",
            p: 2,
          }}
        >
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar>
                <PermIdentityOutlined />
              </Avatar>
              <Stack>
                <Typography variant="body2">
                  {data?.FirstName + " " + data?.LastName}
                </Typography>
                <Typography variant="caption">
                  {`Age: ${calculateBirthDate(data.BirthDate).years} `}
                </Typography>
               
              </Stack>
            </Stack>

            <Button
              fullWidth={false}
              // disableElevation
              variant="contained"
              color="warning"
              sx={{ borderRadius: "50px" }}
              onClick={() => {
                handleChatOpen(data.guid);
              }}
            >
              Chat
            </Button>
          </Stack>
        </Box>

        <Stack spacing={1}>
          <Typography variant="body2">
            <b>Date Appointed: </b>
            {format(parseISO(data?.StartTime), "MMMM dd, yyyy")}
          </Typography>
          <Stack direction="row" spacing={2}>
          <Typography variant="body2">
            <b>Start Appointed: </b>
            {format(parseISO(data?.StartTime), "hh:mm a")}
          </Typography>
          <Typography variant="body2">
            <b>End Appointed: </b>
            {format( data?.EndTime ? parseISO(data?.EndTime) : addMinutes(parseISO(data?.StartTime), 10), "hh:mm a")}
          </Typography>
          </Stack>
          <Typography variant="body2">
            <b>Appoinment Duration: </b>
            {formatDistance(parseISO(data?.StartTime), data?.EndTime ? parseISO(data?.EndTime) : addMinutes(parseISO(data?.StartTime), 10))}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <RenderButtons status={data.AppointmentParsedStatus} />
        </Stack>
      </Stack>
    </Stack>
  );
}
