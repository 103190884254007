import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const generateLabel = (label, info) => {
  return label + " (" + info?.length + ")";
};

export default function OrgDetail({ orgData }) {
 
  return (
    <TreeView
      aria-label="doctor"
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, maxWidth: 450, overflowY: "auto" }}
    >
      <StyledTreeItem
        nodeId="1"
        labelText={generateLabel("Support", orgData?.user)}
        labelIcon={AccountCircleIcon}
      >
        {orgData?.user?.map((user) => (
          <StyledTreeItem
            nodeId="5"
            labelText={user?.name}
            labelInfo={user?.email}
            color="#1a73e8"
            bgColor="#e8f0fe"
          />
        ))}
      </StyledTreeItem>
      <StyledTreeItem
        nodeId="2"
        labelText={generateLabel("Doctor", orgData?.doctor)}
        labelIcon={PersonIcon}
      >
        {orgData?.doctor?.map((doctor) => (
          <StyledTreeItem
            nodeId="6"
            labelText={doctor?.FirstName + " " + doctor?.LastName}
            labelInfo={doctor?.Email}
            color="#1a73e8"
            bgColor="#e8f0fe"
          />
        ))}
      </StyledTreeItem>
      <StyledTreeItem
        nodeId="3"
        labelText={generateLabel("Nurse", orgData?.nurse)}
        labelIcon={PeopleIcon}
      >
        {orgData?.nurse?.map((nurse) => (
          <StyledTreeItem
            nodeId="7"
            labelText={nurse?.FirstName + " " + nurse?.LastName}
            labelInfo={nurse?.Email}
            color="#1a73e8"
            bgColor="#e8f0fe"
          />
        ))}
      </StyledTreeItem>
      <StyledTreeItem
        nodeId="4"
        labelText={generateLabel("Patient", orgData?.patient)}
        labelIcon={LocalHospitalIcon}
      >
        {orgData?.patient?.map((patient) => (
     
          <StyledTreeItem
            nodeId="8"
            labelText={patient?.FirstName + " " + patient?.LastName}
            labelInfo={patient?.EmailAddress + " (" + patient?.mobile + ")"}        
            color="#e3742f"
            bgColor="#fcefe3"
          />
        ))}
      </StyledTreeItem>
    </TreeView>
  );
}
