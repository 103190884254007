import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'

const DoctorSwitch = ({ ProviderId, MappedDoctors, handleChange ,isDrawerExpanded }) => {
  return (
    <FormControl
    sx={{ width: 300, marginTop: 1, marginRight: "200px" , marginLeft: isDrawerExpanded ? "300px" : "100px"}}
             
    >
      <InputLabel id="doctor-id-select-label">Doctor</InputLabel>
      <Select
        fullWidth
        labelId="doctor-id-select-label"
        id="doctor-id-select"
        size="small"
        value={ProviderId}
        sx={{ width: 300, marginTop: 1 }}
        label="Doctor"
        onChange={(event) => handleChange(event.target.value)}
      >
        {MappedDoctors.map(function (dd) {
          return (
            <MenuItem value={dd.ProviderId}>
             

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>
              
                { "Dr. " + dd.FirstName + ' ' + dd.LastName }
              </Typography>
                <ListItemIcon
                  sx={{
                    color: '#1D9BE6',
                  }}
                >
                  Provider Id - {dd.ProviderId}
                </ListItemIcon>

              
              </Box>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default DoctorSwitch
