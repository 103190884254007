import React from "react";
import Typography from "@mui/material/Typography";

const DoctorHeading = ({is_update}) => {
  // const { title } = props;

  return (
    <>
      <Typography variant="h6">{is_update ? "Update Doctors / Nurse" : "Add Doctors / Nurse"} ​</Typography>
    </>
  );
};

export default DoctorHeading;
