import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import Button from "../../common/Button";

const PaymentSuccess = () => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "70vh", backgroundColor: "#f7f7f7" }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <CheckCircleIcon
          sx={{
            color: "#00C853",
            fontSize: 70,
            marginBottom: "10px",
          }}
        />

        <Typography
          variant="h6"
          sx={{
            color: "#00C853",
            marginBottom: "20px",
            fontWeight: "bold",
          }}
        >
          Payment Successful!
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: "#555555",
            marginBottom: "20px",
            fontSize: "16px",
          }}
        >
          Thank you for your payment. Your transaction has been completed successfully.
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "gray",
            marginBottom: "20px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          In case of any questions, email us at
          <EmailIcon sx={{ fontSize: "16px", marginLeft: "5px", marginRight: "2px" }} />
          <a href="mailto:support@myhipaasafe.com">support@myhipaasafe.com</a>.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "40%",
            borderRadius: "999px",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#1976d2",
            },
          }}
          href="/login"
        >
          Proceed to Login
        </Button>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccess;
