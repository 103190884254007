import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AddDoctorWeb,
  getOrgList,
  postOrgList,
  checkNPI,
  verifyOrg,
  STRIPE_DOCTOR_VERIFY,
  STRIPE_DOCTOR_PRICE,
} from "../../../store/user/userSlice";
import {
  getSpecialityList,
  getCityList,
  queryCommonList,
} from "../../../store/query/querySlice";
import { setSnackBar } from "../../../store/common/commonSlice";

const mapStateToProps = (state) => {
  return {
    add_doctors_loading: state.user.add_doctors_loading,
    speciality_is_loading: state.query.speciality_is_loading,
    speciality_list_data: state.query.speciality_list_data,
    state_list_is_loading: state.query.state_list_is_loading,
    state_list_data: state.query.state_list_data,
    city_list_is_loading: state.query.city_list_is_loading,
    city_list_data: state.query.city_list_data,
    get_org_is_loading: state.user.get_org_is_loading,
    get_org_data: state.user.get_org_data,
    get_npi_data: state.user.get_npi_data,
    get_npi_is_loading: state.user.get_npi_is_loading,
    post_org_data: state.user.post_org_data,
    verify_org_is_loading: state.user.verify_org_is_loading,
    verify_org_data: state.user.verify_org_data,
    stripe_doctor_verify_is_loading: state.user.stripe_doctor_verify_is_loading,
    stripe_doctor_verify_data: state.user.stripe_doctor_verify_data,
    stripe_doctor_price_is_loading: state.user.stripe_doctor_price_is_loading,
    stripe_doctor_price_data: state.user.stripe_doctor_price_data,
    common_list_is_loading: state.query.common_list_is_loading,
    common_list_data: state.query.common_list_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDoctors: AddDoctorWeb,
      getSpecialityList,
      getCityList,
      getOrgList,
      postOrgList,
      checkNPI,
      setSnackBar,
      verifyOrg,
      STRIPE_DOCTOR_VERIFY,
      STRIPE_DOCTOR_PRICE,
      queryCommonList,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
