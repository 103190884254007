import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { queryDoctorMyTeams } from "../../../store/query/querySlice";
import { getDoctorDataUrl, downloadQrCode } from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
  return {
    doctor_my_teams_loading: state.query.doctor_my_teams_loading,
    doctor_my_teams_data: state.query.doctor_my_teams_data,

    doctors_data_is_loading: state.user.doctors_data_is_loading,
    doctors_data: state.user.doctors_data,

    qr_code_download_data: state.user.qr_code_download_data,
    qr_code_download_loading: state.user.qr_code_download_loading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      queryDoctorMyTeams,
      getDoctorDataUrl,
      downloadQrCode,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
