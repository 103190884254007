import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { queryAppointmentHistoryList } from "../../../store/query/querySlice";

const mapStateToProps = (state) => {
  return {
    DoctorId: state.common.DoctorId,
    doctor_appointment_history_data:
      state.query.doctor_appointment_history_data,
    doctor_appointment_history_loading:
      state.query.doctor_appointment_history_loading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ queryAppointmentHistoryList }, dispatch);

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
