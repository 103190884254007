import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DatePickerComponent from "../../common/DatePicker";
import TimePickerComponent from "../../common/TimePicker";
import Button from "../../common/Button";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from "react";

export default function RescheduleAppointmentDrawer({
  date,
  handleDateChange,
  patientsData,
  patientsLoading,
  getPatientsByNameOrPhone,
  drawerOpen,
  rescheduleDoctorAppointment,
  loading,
  open,
  onClose,
  addPatient,
  rescheduleData,
}) {
  const [StartTime, setStartTime] = React.useState();
  const [EndTime, setEndTime] = React.useState();
  const [patientSearch, setPatientSearch] = React.useState("");
  const [PatientId, setPatientId] = React.useState(null);
  const [Duration, setDuration] = React.useState(20);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    if (patientSearch.length > 2) {
      getPatientsByNameOrPhone(patientSearch);
    }
  }, [patientSearch]);

  React.useEffect(() => {
    setStartTime(date.toISOString());
  }, [date]);

  React.useEffect(() => {
    const time = new Date(StartTime);
    const endTime = new Date(time.getTime() + 20 * 60000);
    setEndTime(endTime);
  }, [date]);

  React.useEffect(() => {
    if (rescheduleData) {
      setStartTime(rescheduleData.StartTime);
      setPatientId(rescheduleData.PatientId);
    }
  }, [rescheduleData]);

  React.useEffect(() => {
    if (StartTime) {
      const time = new Date(StartTime);
      const endTime = new Date(time);
      endTime.setMinutes(endTime.getMinutes() + Duration);
      setEndTime(endTime); 
    }
  }, [StartTime, Duration]);

  

  return (
    <Drawer
      anchor={"right"}
      open={open}
      PaperProps={{
        sx: { width: "35%" },
      }}
      onClose={() => onClose(false)}
    >
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        alignItems="center"
        spacing={1}
        sx={{ height: "100%", px: 5, py: 3 }}
      >
        <Stack
          sx={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Stack sx={{ pb: 3 }}>
            <Typography>Reschedule an appointment</Typography>
          </Stack>

          <TextField
            disabled
            value={rescheduleData?.FirstName + " " + rescheduleData?.LastName}
            label="Patient Name"
            fullWidth
            size="small"
          />

          <DatePickerComponent
            date={StartTime}
            label="Appointment Date"
            minDate={new Date()}
            setDate={(newDate) => {
              setStartTime(newDate);
            }}
            onError={(err) => {
              // console.log(err);
              setError(err);
            }}
          />

          <TimePickerComponent
            date={StartTime}
            label="Appointment Time"
            setDate={(newDate) => {
              setStartTime(newDate);
            }}
          />
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="doctor-id-select-label">
              Appointment Duration
            </InputLabel>
            <Select
              fullWidth
              labelId="doctor-id-select-label"
              id="doctor-id-select"
              size="small"
              label="Appointment Duration"
              defaultValue={20}
              onChange={(e) => {
                setDuration(e.target.value);
              }}
            >
              <MenuItem value={10}>10 Minutes</MenuItem>
              <MenuItem value={20}>20 Minutes</MenuItem>
              <MenuItem value={30}>30 Minutes</MenuItem>
              <MenuItem value={40}>40 Minutes</MenuItem>
              <MenuItem value={50}>50 Minutes</MenuItem>
              <MenuItem value={60}>60 Minutes</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          sx={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            loading={loading}
            disabled={PatientId ? false : true}
            onClick={() =>
              rescheduleDoctorAppointment({ PatientId, StartTime, EndTime })
            }
            children="Reschedule Appointment"
          />
        </Stack>
      </Stack>
    </Drawer>
  );
}
