import * as React from "react";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButtonIcons from "../../common/IconButtonIcons";

export default function DocumentsRequests({ id, title, removeRequestDocs }) {
  return (
    <Alert
      action={
        <IconButtonIcons
          title="Remove"
          IconComponent={CloseIcon}
          onClick={() => {
            removeRequestDocs(id);
          }}
        ></IconButtonIcons>
      }
      icon={<img src="/assets/images/document.svg" alt="document_icon_" />}
      severity="info"
      sx={{ alignItems: "center" }}
    >
      {title}
    </Alert>
  );
}
