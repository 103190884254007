import {
  add,
  format,
  isBefore,
  isAfter,
  isEqual,
  parseISO,
  differenceInMinutes,
} from "date-fns";
import { get } from "lodash-es";
import { checkIfDatesAreEqual } from "../../helpers/datetime";

export const parser_doctor_my_appointments = (response) => {
  try {
    let data = {};
    if (response?.data) {
      response = response.data;
    }
    if (!response) {
      return {};
    }

    data.kpi = appointmentKpiData(response);
    data.data = appointmentParseCommon(response);
    data.no_filter = response;

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const parser_doctor_update_appointments = (response) => {
  try {
    if (response?.data && response?.data.length > 0) {
      response = response.data;
    }
    if (!response) {
      return {};
    }

    response = appointmentParseCommon(response);

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const appointmentParseCommon = (response) => {
  try {
    let appointmentData = [];

    response.map((e) => {

      const queue_no = typeof e.queue_no === "number" ? e.queue_no : e.queue_no;



      let payloadData = {
        guid: get(e, "guid", ""),
        AppointmentId: get(e, "AppointmentId", ""),
        StartTime: get(e, "StartTime", ""),
        EndTime: get(e, "EndTime", ""),
        PatientId: get(e, "PatientId", ""),
        ProviderId: get(e, "ProviderId", ""),
        Classification: get(e, "Classification", ""),
        DateAppointed: get(e, "DateAppointed", ""),
        DateConfirmed: get(e, "DateConfirmed", ""),
        SoonerIfPossible: get(e, "SoonerIfPossible", ""),
        InchairTime: get(e, "InchairTime", ""),
        WalkoutTime: get(e, "WalkoutTime", ""),
        ConfirmationStatus: get(e, "ConfirmationStatus", ""),
        ConfirmationNote: get(e, "ConfirmationNote", ""),
        queue_status: get(e, "queue_status", ""),
        AppointmentArrivalStatus: get(e, "AppointmentArrivalStatus", ""),
        ArrivalTime: get(e, "ArrivalTime", ""),
        is_completed: get(e, "is_completed", ""),
        createdBy: get(e, "createdBy", ""),
        updatedBy: get(e, "updatedBy", ""),
        createdAt: get(e, "createdAt", ""),
        updatedAt: get(e, "updatedAt", ""),
        FirstName: get(e, "appointment_patient.FirstName", ""),
        LastName: get(e, "appointment_patient.LastName", ""),
        PatientName: get(
          e,
          "appointment_patient.FirstName" +
            " " +
            "appointment_patient.LastName",
          ""
        ),
        PatientEmail: get(e, "appointment_patient.EmailAddress", ""),
        AppointmentParsedStatus: appointmentParseStatus(e),
        BirthDate: get(e, "appointment_patient.BirthDate", ""),
        CellPhone: get(e, "appointment_patient.CellPhone", ""),
        queue_no,
        next_in_q: get(e, "next_in_q", false),
        AppointmentDate: format(
          parseISO(get(e, "StartTime", new Date())),
          "MMMM dd, yyyy"
        ),
        AppointmentTime: format(
          parseISO(get(e, "StartTime", new Date())),
          "hh:mm bbb"
        ),
      };

      // breaking appointment booking here
      let start_time = parseISO(e.StartTime).toISOString();
      let end_time = e.EndTime
        ? parseISO(e.EndTime).toISOString()
        : add(parseISO(e.StartTime), { minutes: 20 }).toISOString();

      if (appointmentData.length > 0) {
        let foundIndex = null;
        appointmentData.forEach((aptDt, key) => {
          const duration = differenceInMinutes(
            parseISO(aptDt.end_time),
            parseISO(aptDt.start_time)
          );

          if (
            (isBefore(new Date(aptDt.end_time), new Date(aptDt.start_time)) &&
              isAfter(
                new Date(aptDt.start_time),
                new Date(aptDt.start_time)
              )) ||
            (isBefore(new Date(end_time), new Date(aptDt.end_time)) &&
              isAfter(new Date(end_time), new Date(aptDt.start_time))) ||
            (isBefore(new Date(start_time), new Date(aptDt.start_time)) &&
              isAfter(new Date(end_time), new Date(aptDt.start_time))) ||
            (isBefore(new Date(start_time), new Date(aptDt.end_time)) &&
              isAfter(new Date(end_time), new Date(aptDt.end_time))) ||
            (isEqual(new Date(start_time), new Date(aptDt.start_time)) &&
              isEqual(new Date(end_time), new Date(aptDt.end_time)))
          ) {
            foundIndex = key;
            aptDt.start_time = isBefore(start_time, aptDt.start_time)
              ? start_time
              : aptDt.start_time;
            aptDt.end_time = isBefore(aptDt.end_time, end_time)
              ? end_time
              : aptDt.end_time;

            aptDt.data.push(payloadData);
          }
        });
        if (foundIndex === null) {
          let appintmentPayload = {
            start_time: start_time,
            end_time: end_time,
            data: [payloadData],
          };

          appointmentData.push(appintmentPayload);
        }
      } else {
        let appintmentPayload = {
          start_time: start_time,
          end_time: end_time,
          data: [payloadData],
        };

        appointmentData.push(appintmentPayload);
      }
    });

    return appointmentData;
  
  } catch (e) {
    console.log(e);
  }
};

const appointmentDuration = (start, end) => {
  let duration = 10;
  if (start && end) {
    duration = differenceInMinutes(parseISO(end), parseISO(start));
  }
  return duration;
};

export const appointmentParseStatus = (e) => {
  let isToday = checkIfDatesAreEqual(e.StartTime, new Date().toISOString());
  let AppointmentParsedStatus = {
    id: "pending",
    value: isToday === -1 ? "Appointment Expired" : "Pending",
    color: isToday === -1 ? "error" : "warning",
    isToday: isToday,
  };

  if (e.is_completed === true) {
    AppointmentParsedStatus.id = "completed";
    AppointmentParsedStatus.value = "Completed";
    AppointmentParsedStatus.color = "success";
  } else if (e.next_in_q === true) {
    AppointmentParsedStatus.id = "nextinq";
    AppointmentParsedStatus.value = "Mark as complete";
    AppointmentParsedStatus.color = "secondary";
  } else if (e.AppointmentArrivalStatus === "2") {
    AppointmentParsedStatus.id = "arrived";
    AppointmentParsedStatus.value = "Patient Arrived";
    AppointmentParsedStatus.color = "secondary";
  } else if (e.Classification === 1 && e.ConfirmationStatus === 1) {
    AppointmentParsedStatus.id = "confirmed";
    AppointmentParsedStatus.value =
      isToday === 0
        ? "Check In"
        : isToday === -1
        ? "Appointment Missed"
        : "Confirmed";
    AppointmentParsedStatus.color =
      isToday === 0 ? "primary" : isToday === -1 ? "error" : "success";
  } else if (e.Classification === 32) {
    AppointmentParsedStatus.id = "cancelled";
    AppointmentParsedStatus.value = "Cancelled";
    AppointmentParsedStatus.color = "error";
  } else if (e.Classification === 4) {
    AppointmentParsedStatus.id = "rescheduled";
    AppointmentParsedStatus.value = "Rescheduled";
    AppointmentParsedStatus.color = "info";
  } else if (e.ConfirmationStatus !== 0 && e.ConfirmationStatus !== 1) {
    AppointmentParsedStatus.id = "reminder";
    AppointmentParsedStatus.value =
      isToday === -1 ? "Appointment Expired" : "Reminder Sent";
    AppointmentParsedStatus.color = isToday === -1 ? "error" : "warning";
  }

  AppointmentParsedStatus.appointment_hour = format(
    parseISO(e.StartTime),
    "HH"
  );
  AppointmentParsedStatus.appointment_start_minute = format(
    parseISO(e.StartTime),
    "mm"
  );
  AppointmentParsedStatus.appointment_end_minute =
    parseInt(format(parseISO(e.StartTime), "mm")) +
    appointmentDuration(e.StartTime, e.EndTime);

  return AppointmentParsedStatus;
};

export const appointmentKpiData = (rows, type = "SCHEDULE") => {
  let result = {};
  if (type === "DASHBOARD") {
    result.total_patients = rows.length;
    result.completed_patients = rows.filter(
      (x) => x.is_completed === true
    ).length;
    result.waiting_patients = rows.filter(
      (x) =>
        x.ConfirmationStatus === 1 &&
        x.Classification === 1 &&
        x.is_completed === false
    ).length;
  } else if (type === "SCHEDULE") {
    result.total_appointments = rows.length;
    result.confirmation_pending = rows.filter(
      (x) => x.ConfirmationStatus !== 1 && x.Classification !== 32
    ).length;
    result.confirmed = rows.filter(
      (x) =>
        (x.Classification === 1 &&
          x.ConfirmationStatus === 1 &&
          !x.is_completed) ||
        x.AppointmentArrivalStatus === "2"
    ).length;
    result.completed = rows.filter((x) => x.is_completed).length;
    result.expired_missed = rows.filter(
      (x) =>
        (x.ConfirmationStatus !== 1 || !x.is_completed) &&
        x.Classification !== 32
    ).length;
    result.cancelled = rows.filter((x) => x.Classification === 32).length;
  }
  return result;
};
