import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DatePickerComponent from "../../common/DatePicker";

const HeaderDateRange = ({ from_date, to_date, setDate }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      alignItems="center"
     
    >
      <Typography variant="h6">Appointment History</Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        <DatePickerComponent
          date={from_date}
          setDate={(newDate) => setDate(newDate, "from_date")}
          label="From"
          onError={(err) => console.log(err)}
        />
        <DatePickerComponent
          date={to_date}
          setDate={(newDate) => setDate(newDate, "to_date")}
          label="To"
          onError={(err) => console.log(err)}
        />
      </Stack>
    </Stack>
  );
};

export default HeaderDateRange;
