import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPatientDocuments,
  requestPatientDocuments,
  removeRequestDocs,
  ComprehendMedical,
} from "../../../store/document/documentSlice";
import { requestDocumentsUrl } from "../../../store/query/querySlice";
import {
  getPatientNotesUrl,
  addPatientNotes,
} from "../../../store/notes/notesSlice";
import {
  set_appointment_drawer_state,
  set_chat_window_ids,
} from "../../../store/common/commonSlice";

const mapStateToProps = (state) => {
  return {
    DoctorId: state.common.DoctorId,
    patient_documents_loading: state.document.patient_documents_loading,
    patient_documents_data: state.document.patient_documents_data,
    patient_documents_request_data:
      state.document.patient_documents_request_data,

    // Hostpital reports list
    request_documents_loading: state.query.request_documents_loading,
    request_documents_data: state.query.request_documents_data,

    // Get Patient Notes
    patient_notes_loading: state.notes.patient_notes_loading,
    patient_notes_data: state.notes.patient_notes_data,

    // Request patient documents
    request_patient_documents_loading:
      state.document.request_patient_documents_loading,

    // Remove Documents
    remove_request_docs_is_loading:
      state.document.remove_request_docs_is_loading,

      // Comprehend Medical
      comprehend_medical_data: state.document.comprehend_medical_data,
      comprehend_medical_is_loading: state.document.comprehend_medical_is_loading,

    // Add Patient Notes
    add_notes_is_loading: state.notes.add_notes_is_loading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPatientDocuments,
      requestDocumentsUrl,
      getPatientNotesUrl,
      set_appointment_drawer_state,
      requestPatientDocuments,
      removeRequestDocs,
      addPatientNotes,
      set_chat_window_ids,
      ComprehendMedical,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
