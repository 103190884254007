import React from "react";
import Table from "../../../components/common/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MuiButton from "../../../components/common/Button";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
const DoctorList = (props) => {
  const {
    tableDetails,
    redirectToEdit,
    loading,
    handlePaymentAdmin,
    handleMappingDialogOpen,
  } = props;

  const columns = [
    {
      title: "Provider Id",
    },
    {
      title: "Doctors Name",
    },
    {
      title: "Email Id",
    },
    {
      title: "Phone",
    },
    {
      title: "City",
    },
    {
      title: "Payment Status",
    },
    {
      title: "Action",
    },
    {
      title: "Organization Mapping",
    },
  ];

  const renderTableData = tableDetails.length ? (
    tableDetails.map((row, index) => {
      const { ProviderId } = row;

      return (
        <TableRow key={index} sx={{ "td, th": { border: 0, padding: "10px" } }}>
          <TableCell align="center" component="th" scope="row">
            <Typography variant="subtitle1">{row.ProviderId}</Typography>
          </TableCell>
          <TableCell align="center">
            {row.FirstName + " " + row.LastName}
          </TableCell>
          <TableCell align="center">{row.Email ? row.Email : "-"}</TableCell>
          <TableCell align="center">{row.Phone ? row.Phone : "-"}</TableCell>

          <TableCell align="center">{row.City}</TableCell>
          <TableCell align="center">
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              {row.is_payment_made_by_admin ? <AdminPanelSettingsIcon /> : ""}

              <Switch
                checked={row.payment_status}
                onChange={(event) =>
                  handlePaymentAdmin(row.ProviderId, event.target.checked)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
          </TableCell>
          <TableCell align="center">
            <MuiButton
              disableElevation
              variant="contained"
              sx={{ borderRadius: "50px" }}
              onClick={() => redirectToEdit(ProviderId)}
            >
              Edit
            </MuiButton>
          </TableCell>
          <TableCell align="center">
            <MuiButton
              disableElevation
              variant="contained"
              sx={{ borderRadius: "50px" }}
              onClick={() => handleMappingDialogOpen(ProviderId)}
            >
              Org Map
            </MuiButton>
          </TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
      <TableCell align="center">Data not found</TableCell>
    </TableRow>
  );

  return (
    <Table columns={columns} tableData={renderTableData} loading={loading} />
  );
};

export default DoctorList;
