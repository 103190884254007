import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import AppointmentHeader from "../../../components/doctor/schedule/AppointmentHeader";
import KpiCards from "../../../components/common/KpiCards2";
import ScheduleHeader from "../../../components/doctor/schedule/ScheduleHeader";
import { add, format, parseISO } from "date-fns";
import AddAppointmentDrawer from "../../../components/doctor/schedule/AddAppointmentDrawer";
import ScheduleTimes from "../../../components/doctor/schedule/ScheduleTimes";
import { appointmentSlots, generateHours } from "../../../helpers/utils";
import PatientDetailsDrawer from "../../../components/doctor/schedule/PatientDetailsDrawer";
import AddPatientDrawer from "../../../components/doctor/schedule/AddPatientDrawer";
import RescheduleAppointmentDrawer from "../../../components/doctor/schedule/RescheduleAppointmentDrawer";
import LinkPatientDrawer from "../../../components/doctor/schedule/LinkPatientDrawer";
import { checkIfDatesAreEqual } from "../../../helpers/datetime";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import {
  appointmentKpiData,
  appointmentParseCommon,
} from "../../../store/appointment/appointmentParser";
import { useTheme } from "@mui/material/styles";
class ScheduleContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      dateTimeNow: new Date().toISOString(),
      viewAppointmentData: null,
      rescheduleState: false,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  getAppointmentsList() {
    this.props.doctorGetMyAppointments({
      DoctorId: this.props.DoctorId,
      date: this.state.dateTimeNow,
    });
  }

  getPatientsByNameOrPhone(keyword) {
    this.props.getPatientsByNameOrPhone({ keyword });
  }

  componentDidMount() {
    this.getAppointmentsList();
    // this.interval = setInterval(() => this.getAppointmentsList(), 300000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.DoctorId !== this.props.DoctorId) {
      this.getAppointmentsList();
    }

    if (
      prevProps.doctor_appointment_data_no_filter !==
      this.props.doctor_appointment_data_no_filter
    ) {
      let new_data = appointmentParseCommon(
        this.props.doctor_appointment_data_no_filter
      );
      let new_kpi = appointmentKpiData(
        this.props.doctor_appointment_data_no_filter
      );

      this.props.set_doctor_my_appointments_data({
        data: new_data,
        kpi: new_kpi,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleDateChange(newDate, callApi = true) {
    if (newDate != "Invalid Date" && newDate !== null) {
      this.setState({ dateTimeNow: newDate.toISOString() }, () => {
        this.getAppointmentsList();
      });
    } else {
      console.log("Invalid Date");
    }
  }

  toggleDrawer(action, hour, slot) {
    const today = checkIfDatesAreEqual(
      this.state.dateTimeNow,
      new Date().toISOString()
    );
    if (today !== -1) {
      this.props.set_chat_window_state({ state: false });
      this.setState(
        {
          dateTimeNow: add(hour, { minutes: slot + "0" }).toISOString(),
        },
        () => {
          this.props.set_appointment_drawer_state({
            show: action,
          });
        }
      );
    }
  }

  addDoctorAppointment = async (data) => {
    data.ProviderId = this.props.DoctorId;
    let res = await this.props.addDoctorAppointments(data);
    if (!res.error) {
      // this.getAppointmentsList();
      this.props.set_appointment_drawer_state({
        show: false,
      });
    }
  };

  rescheduleDoctorAppointment(data) {
    let payload = {
      AppointmentId: this.props.view_appointment_data.AppointmentId,
      appointment_date: data.StartTime,
      appointment_end_date: data.EndTime,
      type: "RESCHEDULE",
    };
    this.props.updateAppointment(payload);
    this.setState({ rescheduleState: false });
    this.getAppointmentsList();
  }

  updateDoctorAppointment = async (data) => {
    await this.props.updateAppointment(data);
    // this.getAppointmentsList();
    this.closePatientDetailsDrawer();
  };

  closePatientDetailsDrawer() {
    // this.setState({ viewAppointmentData: null });
    // this.getAppointmentsList();
    this.props.set_view_appointment_data({
      data: null,
    });
  }

  handleSubmit = (values) => {
    this.props.addPatients(values); //done
    setTimeout(() => {
      this.showPatient(false);
    }, 2000);
  };

  showPatient = (action) => {
    this.props.set_patient_drawer_state({
      show: action,
    });
  };

  handleUnLinkedPatientSubmit = (values) => {
    this.props.getUnlinkedPatient(values);
  };

  render() {
    const {
      theme,
      // My Appointments Data
      doctor_my_appointments_data,
      doctor_my_appointments_kpi,
      doctor_my_appointments_loading,
      doctor_add_appointments_loading,
      // Search patients by Name
      patients_by_search_loading,
      patients_by_search_data,
      add_appointment_drawer_state,
      add_patient_drawer_state,
      patient_link_drawer_state,
      // Add  Patient
      unlinked_patient_is_loading,
      unlinked_patient_data,
      link_patient_with_org_is_loading,
      update_appointment_is_loading,
      view_appointment_data,
    } = this.props;

    return (
      <>
        <AddAppointmentDrawer
          date={this.state.dateTimeNow}
          handleDateChange={(newDate) => this.handleDateChange(newDate)}
          patientsData={patients_by_search_data}
          patientsLoading={patients_by_search_loading}
          getPatientsByNameOrPhone={(keyword) =>
            this.getPatientsByNameOrPhone(keyword)
          }
          addDoctorAppointment={(data) => this.addDoctorAppointment(data)}
          drawerOpen={add_appointment_drawer_state}
          loading={doctor_add_appointments_loading}
          addPatient={() => this.showPatient(true)}
          addPatientLink={() =>
            this.props.set_patient_link_drawer_state({ show: true })
          }
        />
        <LinkPatientDrawer
          open={patient_link_drawer_state}
          close={() => {
            this.props.getUnlinkedPatient();
            this.props.set_patient_link_drawer_state({ show: false });
          }}
          unlinked_patient_is_loading={unlinked_patient_is_loading}
          searchPatient={this.handleUnLinkedPatientSubmit}
          unlinked_patient_data={unlinked_patient_data}
          linkPatient={(PatientId) =>
            this.props.linkPatientWithOrg({ PatientId })
          }
          link_patient_with_org_is_loading={link_patient_with_org_is_loading}
        />
        <RescheduleAppointmentDrawer
          date={parseISO(this.state.dateTimeNow)}
          handleDateChange={(newDate) => this.handleDateChange(newDate)}
          patientsData={patients_by_search_data}
          patientsLoading={patients_by_search_loading}
          getPatientsByNameOrPhone={(keyword) =>
            this.getPatientsByNameOrPhone(keyword)
          }
          rescheduleDoctorAppointment={(data) =>
            this.rescheduleDoctorAppointment(data)
          }
          drawerOpen={add_appointment_drawer_state}
          loading={update_appointment_is_loading}
          addPatient={() => this.showPatient(true)}
          rescheduleData={
            this.state.rescheduleState ? view_appointment_data : null
          }
          open={this.state.rescheduleState}
          onClose={() => this.setState({ rescheduleState: false })}
        />
        <PatientDetailsDrawer
          close={() => this.closePatientDetailsDrawer()}
          appointment_data={view_appointment_data}
          guid={view_appointment_data?.guid}
          reschedule={(d) => this.setState({ rescheduleState: true })}
          updateDoctorAppointment={(d) => this.updateDoctorAppointment(d)}
          update_appointment_is_loading={update_appointment_is_loading}
        />
        <AddPatientDrawer
          open={add_patient_drawer_state}
          loading={false}
          addPatient={this.handleSubmit}
        />
        {/* <PatientDetailsDrawer guid="3680961d-cc30-40f5-ae29-b45d720a7036" /> */}
        <Box
          sx={{
            position: "sticky",
            top: "62px!important",
            height: "23px",
            background: theme.palette.mode === "dark" ? "#000000" : "#f8f8f8",
            zIndex: theme.zIndex.appBar + 1,
          }}
        />
        <Box
          className="sticky_appointment"
          style={
            theme?.palette?.mode === "dark"
              ? { background: "#000" }
              : { background: "#fff" }
          }
          sx={{
            boxShadow: 2,
            borderRadius: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppointmentHeader
                title="Appointments"
                date={format(parseISO(this.state.dateTimeNow), "MMMM dd, yyyy")}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <KpiCards
                  img="/assets/images/total_appointments.svg"
                  alt="total_appointments"
                  title="Total"
                  count={doctor_my_appointments_kpi?.total_appointments}
                  loading={doctor_my_appointments_loading}
                />
                <KpiCards
                  img="/assets/images/confirmation_pending.svg"
                  alt="confirmatino_pending"
                  title={
                    checkIfDatesAreEqual(
                      this.state.dateTimeNow,
                      new Date().toISOString()
                    ) === -1
                      ? "Expired/Missed"
                      : "Pending"
                  }
                  count={
                    checkIfDatesAreEqual(
                      this.state.dateTimeNow,
                      new Date().toISOString()
                    ) === -1
                      ? doctor_my_appointments_kpi?.expired_missed
                      : doctor_my_appointments_kpi?.confirmation_pending
                  }
                  loading={doctor_my_appointments_loading}
                />
                <KpiCards
                  img="/assets/images/completed.svg"
                  alt="confirmed_appointments"
                  title={
                    checkIfDatesAreEqual(
                      this.state.dateTimeNow,
                      new Date().toISOString()
                    ) === -1
                      ? "Completed"
                      : "Confirmed"
                  }
                  count={
                    checkIfDatesAreEqual(
                      this.state.dateTimeNow,
                      new Date().toISOString()
                    ) === -1
                      ? doctor_my_appointments_kpi?.completed
                      : doctor_my_appointments_kpi?.confirmed
                  }
                  loading={doctor_my_appointments_loading}
                />
                <KpiCards
                  img="/assets/images/cancelled.svg"
                  alt="cancelled"
                  title="Cancelled"
                  count={doctor_my_appointments_kpi?.cancelled}
                  loading={doctor_my_appointments_loading}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <ScheduleHeader
                loading={doctor_my_appointments_loading}
                date={parseISO(this.state.dateTimeNow)}
                handleDateChange={(newDate) => this.handleDateChange(newDate)}
              />
            </Grid>
          </Grid>
        </Box>
        {doctor_my_appointments_loading ? (
          <Stack
            sx={{ height: "110%" }}
            justifyContent="center"
            alignItems={"center"}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              boxShadow: 1,
              borderRadius: 2,
              pt: 2,
              mt: 2,
              mb: 2,
            }}
          >
            <Grid item xs={12}>
              <ScheduleTimes
                data={doctor_my_appointments_data}
                loading={doctor_my_appointments_loading}
                toggleDrawer={(action, hour, slot) =>
                  this.toggleDrawer(action, hour, slot)
                }
                appointmentHours={generateHours(
                  parseISO(this.state.dateTimeNow)
                )}
                appointmentSlots={appointmentSlots()}
                viewAppointment={(d) => {
                  this.props.set_chat_window_state({ state: false });
                  this.props.set_view_appointment_data({
                    data: d,
                  });
                  // this.setState({ viewAppointmentData: d });
                }}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

export default ScheduleContainer;
