import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";

import TransferList from "../../../components/common/TransferList";
import CircularProgress from "@mui/material/CircularProgress";

import {
  AppBar,
  Dialog,
  Slide,
  Stack,
  Typography,
  Toolbar,
  IconButton,
} from "@mui/material";

export default function MappingDialog({
  data,
  close,
  mappingData,
  DoctorToNurseMapping,
  nurseId,
  nurse_doctor_map_list_is_loading,
}) {
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      open={data ? true : false}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Doctor Nurse Mapping
          </Typography>
        </Toolbar>
      </AppBar>
      {nurse_doctor_map_list_is_loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "300px", width:'100%' }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <TransferList
          mappingData={mappingData}
          nurseId={nurseId}
          DoctorToNurseMapping={DoctorToNurseMapping}
        />
      )}
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
