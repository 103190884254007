import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addDoctorDirectory, updateDoctorDirectory, getDoctorDirectory } from "../../../store/user/userSlice";
import { getSpecialityList } from "../../../store/query/querySlice";

const mapStateToProps = (state) => {
  return {
    DoctorId: state.common.DoctorId,
    // Add Doctor
    add_doctors_directory_loading: state.user.add_doctors_directory_loading,
    update_doctor_directory_data: state.user.update_doctor_directory_data,

    speciality_is_loading: state.query.speciality_is_loading,
    speciality_list_data: state.query.speciality_list_data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        addDoctorDirectory: addDoctorDirectory,
        updateDoctorDirectory: updateDoctorDirectory,
        getDoctorDirectory: getDoctorDirectory,
      getSpecialityList,
    },
    dispatch
  );

const Store = (Container) =>
  connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
