import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BasicSelect(props) {
  const {
    id,
    label,
    value,
    name,
    onChange,
    input,
    key,
    items,
    labelId,
    ...other
  } = props;

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        name={name}
        value={value ? value : null}
        label={label}
        onChange={onChange}
        {...other}
      >
        {items?.map((element, index) => {
          return (
            <MenuItem key={"select_key_" + label + index} value={element.value}>
              {element.key}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
