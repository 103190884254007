
import React from "react";
import {  signout } from "../../helpers/cookies";
class LogoutContainer extends React.Component {
  componentDidMount() {
   
    signout(() => window.location.reload());
  }


  render() {
    return <></>;
  }


 
  
}

export default LogoutContainer;
