import React from "react";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import Button from "../../common/Button";
import * as yup from "yup";
import Input from "../../common/Input";
import Dialog from "@mui/material/Dialog";

const AddByUniqueCode = (props) => {
  const { loading, addDoctorsByCode , handleClose , open } = props;

  const validateCode = yup.object().shape({
    uid_or_email: yup.string().required(),
  });

  const onSubmit = async (values) => {
    addDoctorsByCode(values);
  };

  const formik = useFormik({
    initialValues: {
      //   uid_or_email,
      uid_or_email: "",
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
    validationSchema: validateCode,
  });

  return (
    <>
       <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        // maxWidth={"xl"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Stack
          spacing={1}
          sx={{ justifyContent: "center", alignItems: "center", m: 3 }}
        >
      <form onSubmit={formik.handleSubmit}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
          spacing={1}
        >
          <Input
            fullWidth
            id="outlined-multiline-flexible"
            label="Unique Code"
            placeholder="Unique Code"
            value={formik.values.uid_or_email}
            onChange={formik.handleChange("uid_or_email")}
            onBlur={formik.handleBlur("uid_or_email")}
            error={
              !formik.touched.uid_or_email &&
              Boolean(formik.errors.uid_or_email)
            }
            helperText={
              !formik.touched.uid_or_email && formik.errors.uid_or_email
            }
          />
          <Button
            type="submit"
            disabled={!formik.isValid}
            loading={loading}
            fullWidth
          >
            Add
          </Button>
        </Stack>
       
      </form>
      </Stack>
      </Dialog>
    </>
  );
};

export default AddByUniqueCode;
