import { axios } from "../../helpers/axios";

import {
  ADD_DOCTORS_BY_CODE,
  ADD_DOCTORS_URL,
  ADD_FEEDBACK_URL,
  ADD_PATIENTS_URL,
  DOWNLOAD_QR_CODE_URL,
  GET_DOCTOR_DIRECTORY_ADD_URL,
  GET_DOCTOR_DIRECTORY_UPDATE_URL,
  GET_DOCTOR_DIRECTORY_URL,
  GET_PATIENTS_BY_NAME_OR_PHONE,
  GET_PATIENTS_LIST,
  GET_PHYSICIAN_LIST,
  GET_UNLINKED_PATIENT,
  GET_UPDATE_DOCTOR_URL,
  LINK_PATIENT_WITH_ORG,
  REMOVE_DOCTOR_DIRECTORY_URL,
  UPDATE_DOCTOR_PROFILE_URL,
  UPDATE_DOCTOR_URL,
  UPDATE_PROFILE_PIC,
  UPDATE_TERMS_AND_CONDITIONS,
  ADD_DOCTORS_WEB_URL,
  GET_ORGANIZATION_LIST,
  POST_ORGANIZATION_LIST,
  CHECK_NPI_NUMBER,
  VERIFY_ORGANIZATION,
  GET_ORGANIZATION,
  STRIPE_DOCTOR_VERIFY,
  STRIPE_DOCTOR_PRICE,
  SUPPORT_USER_LIST,
  UPDATE_ORGANIZATION,
  GET_FEEDBACK_URL,
  REGISTER_SUPPORT_USER,
  ADMIN_USER_UPDATE,
  ADMIN_USER_TREE,
  ADMIN_PAYMENT_STATUS,
  DOCTOR_ORGNIZATION_MAP_LIST,
  DOCTOR_ORGNIZATION_MAP_SWITCH,
  DOCTOR_ORGNIZATION_MAP_ADD,
  STATUS_FEEDBACK_URL,
  DOCTOR_INVITE,
  USER_LOGOUT

} from "./userConstants";

// Move to users store

export const api_add_feedback_url = async (data) => {
  return axios.post(ADD_FEEDBACK_URL, data);
};

export const api_get_feedback_url = async (params) => {
  return axios.get(GET_FEEDBACK_URL, { params });
};


export const api_add_doctors_url = async (data) => {
  return axios.post(ADD_DOCTORS_URL, data);
};
export const api_update_doctors_url = async (data) => {
  return axios.put(UPDATE_DOCTOR_URL, data);
};
export const api_get_patients_by_name_or_phone = async (params) => {
  return axios.get(GET_PATIENTS_BY_NAME_OR_PHONE, { params });
};
export const api_add_patients_url = async (data) => {
  return axios.post(ADD_PATIENTS_URL, data);
};
export const api_get_update_doctor_url = async (params) => {
  return axios.get(GET_UPDATE_DOCTOR_URL, { params });
};
export const api_add_doctor_directory_url = async (payload) => {
  return axios.post(GET_DOCTOR_DIRECTORY_ADD_URL, payload);
};

export const api_update_doctor_directory_url = async (payload) => {
  return axios.post(GET_DOCTOR_DIRECTORY_UPDATE_URL, payload);
};

export const api_get_doctor_directory_url = async (params) => {
  return axios.get(GET_DOCTOR_DIRECTORY_URL, { params });
};

export const api_update_doctor_profile_url = async (payload) => {
  return axios.put(UPDATE_DOCTOR_PROFILE_URL, payload);
};

export const api_download_qr_code_url = async (payload) => {
  return axios.post(DOWNLOAD_QR_CODE_URL, payload);
};

export const api_update_profile_pic = async (payload) => {
  return axios.post(UPDATE_PROFILE_PIC, payload);
};

export const api_get_patients_list = async (params) => {
  return axios.get(GET_PATIENTS_LIST, { params });
};

export const api_get_physician_list = async (params) => {
  return axios.get(GET_PHYSICIAN_LIST, { params });
};

export const api_get_unlinked_patient = async (params) => {
  return axios.get(GET_UNLINKED_PATIENT, { params });
};

export const api_link_profile_with_org = async (payload) => {
  return axios.post(LINK_PATIENT_WITH_ORG, payload);
};

export const api_add_doctors_by_code = async (payload) => {
  return axios.post(ADD_DOCTORS_BY_CODE, payload);
};

export const api_remove_doctor_directory = async (payload) =>{
  return axios.post(REMOVE_DOCTOR_DIRECTORY_URL, payload);
};

export const api_update_terms_and_conditions = async ( ) =>{
  return axios.get(UPDATE_TERMS_AND_CONDITIONS);
};

export const api_add_doctors_web_url = async (data) => {
  return axios.post(ADD_DOCTORS_WEB_URL, data);
}

export const api_get_org_url = async () => {
  return axios.get(GET_ORGANIZATION_LIST);
}

export const api_get_single_org_url = async (data) => {
  return axios.post(GET_ORGANIZATION, data);
}

export const api_post_org_url = async (data) => {
  return axios.post(POST_ORGANIZATION_LIST, data);
}

export const api_verify_org_url = async (data) => {
  return axios.post(VERIFY_ORGANIZATION, data);
}


export const api_npi_get = async (data) => {
  return axios.post(CHECK_NPI_NUMBER, data);
}

export const stripe_payment_verify = async (data) => {
  return axios.post(STRIPE_DOCTOR_VERIFY, data);
}


export const stripe_payment_price = async (data) => {
  return axios.get(STRIPE_DOCTOR_PRICE, data);
}

export const support_user = async (params) => {
  return axios.get(SUPPORT_USER_LIST, { params });
}

export const update_org_api = async (data) => {
  return axios.put(UPDATE_ORGANIZATION, data);
}

export const register_support_user_api = async (data) => {
  return axios.post(REGISTER_SUPPORT_USER, data);
}

export const admin_user_update_api = async (data) => {
  return axios.put(ADMIN_USER_UPDATE, data);
}

export const admin_user_tree_api = async (params) => {
  return axios.get(ADMIN_USER_TREE, { params });
}

export const admin_user_payment_api = async (data) => {
  return axios.post(ADMIN_PAYMENT_STATUS, data);
}

export const doctor_orgnization_list_api = async (params) => {
  return axios.get(DOCTOR_ORGNIZATION_MAP_LIST, { params });
}

export const doctor_orgnization_switch_api = async (data) => {
  return axios.put(DOCTOR_ORGNIZATION_MAP_SWITCH, data);
}


export const api_add_doctor_org_mapping = async (data) => {
  return axios.post(DOCTOR_ORGNIZATION_MAP_ADD, data);
}

export const api_status_feedback_url = async (data) => {
  return axios.post(STATUS_FEEDBACK_URL, data);
}

export  const api_doctor_invite = async (data) => {
  return axios.post(DOCTOR_INVITE, data);
}

export const api_user_logout = async (data) => {
  return axios.get(USER_LOGOUT,data);
}