import * as React from "react";
import Box from "@mui/material/Box";

import Button from "../common/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  key: yup.string().required("Key is required"),
  value: yup.string().required("Value is required"),
  // parent: yup.string().required("Parent is required"),
});

export default function CommonEditDialog({
  open,
  handleClose,
  handleSave,
  Data,
}) {
  const handleSubmit = async (val) => {
    handleSave(val);
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <Box
          sx={{
            boxSizing: "border-box",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            borderRadius: "8px",
          }}
        >
          <DialogContent
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
              <Typography onClick={handleClose} sx={{ cursor: "pointer" }}>
                X
              </Typography>
            </Box>

            <Typography
              sx={{
                fontFamily: "inherit",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                textAlign: "center",
                letterSpacing: "-0.01em",
                flex: "none",
                order: "0",
                flexGrow: "0",
              }}
            >
              Edit Data
            </Typography>
            <Formik
              initialValues={{
                key: Data.key,
                value: Data.value,
                parent: Data.parent,
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                errors,
                isValid,
              }) => (
                <>
                  <TextField
                    difaultValue={Data.value}
                    required={true}
                    disableUnderline={true}
                    label="Gender"
                    sx={{
                      borderRadius: "10px",

                      height: "40px",
                      width: "250px",
                      marginTop: "20px",
                      alignSelf: "center",
                      marginLeft: "20%",
                    }}
                    placeholder="Enter Gender "
                    size="small"
                    onChange={handleChange("value")}
                    onBlur={handleBlur("value")}
                    error={errors.value}
                    value={values.value}
                    onInput={(e) => {
                      e.target.value = e.target.value.trimStart();
                    }}
                  />
                  <TextField
                    defaultValue={Data.key}
                    required={true}
                    disableUnderline={true}
                    label="Value"
                    sx={{
                      borderRadius: "10px",

                      height: "40px",
                      width: "250px",
                      marginTop: "20px",
                      alignSelf: "center",
                      marginLeft: "20%",
                    }}
                    placeholder="Enter  Value"
                    size="small"
                    onChange={handleChange("key")}
                    onBlur={handleBlur("key")}
                    value={values.key}
                    error={errors.key}
                    onInput={(e) => {
                      e.target.value = e.target.value.trimStart();
                    }}
                  />

                  {/* <TextField
                    difaultValue={Data.parent}
                    required={true}
                    disableUnderline={true}
                    label="Parent"
                    sx={{
                      borderRadius: "10px",
                   
                      height: "40px",
                      width: "250px",
                      marginTop: "20px",
                      alignSelf: "center",
                      marginLeft: "20%",
                    }}
                    placeholder="Enter Parent "
                    size="small"
                    onChange={handleChange("parent")}
                    onBlur={handleBlur("parent")}
                    error={errors.parent}
                    value={values.parent}
                    onInput={(e) => {
                      e.target.value = e.target.value.trimStart();
                    }}
                  /> */}

                  <Button
                    disabled={!isValid}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "187px",

                      background: "#004AAD",
                      borderRadius: "5px",
                      alignSelf: "center",
                      marginLeft: "27%",
                      marginTop: "5%",
                      // flex: "none",
                      // order: "0",
                      // flexGrow: "0",
                    }}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </>
              )}
            </Formik>
          </DialogContent>
          <DialogActions></DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
