import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CircleIcon from "@mui/icons-material/Circle";
import { useTheme } from "@mui/material";

export default function Legends() {
  const { palette } = useTheme();
  return (
    <List sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: palette.warning.main,
              background: palette.warning.main,
            }}
          >
            <CircleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Pending" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: palette.primary.main,
              background: palette.primary.main,
            }}
          >
            <CircleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Requested Reschedule" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: palette.success.main,
              background: palette.success.main,
            }}
          >
            <CircleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Confirmed/Completed" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: palette.error.main,
              background: palette.error.main,
            }}
          >
            <CircleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Cancelled/Missed/Expired" />
      </ListItem>
    </List>
  );
}
