import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AssessmentIcon from '@mui/icons-material/Assessment';
import Tooltip from "@mui/material/Tooltip";
import BoltIcon from '@mui/icons-material/Bolt';

import { FileIcon } from "react-file-icon";

export default function DocumentsComponent({ documents, openDocument ,ComprehendMedicalReport}) {
  const { palette } = useTheme();
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <nav aria-label="documents">
        <List>
          {documents.map((document, index) => {
            return (
              <Stack direction="row" spacing={2}
               key={"document_lising_" + index}>
              <ListItem key={"document_lising_" + index} disablePadding onClick={() => openDocument(document)}>
                <ListItemButton >
                  <ListItemIcon>
                    {/* <InboxIcon /> */}
                    <Stack sx={{ width: 40, height: 40 }}>
                      <FileIcon
                        color={palette.primary.main}
                        foldColor={palette.primary.dark}
                        glyphColor={palette.background.paper}
                        labelColor={palette.background.paper}
                        labelTextColor={palette.text.primary}
                        labelUppercase={true}
                        extension={document.document_extension}
                        type={document.document_type}
                      />
                    </Stack>
                  </ListItemIcon>
                  <ListItemText
                    primary={document.document_name}
                    secondary={document.document_date || document.createdAt}
                  />
                </ListItemButton>
              </ListItem>
              <Tooltip 
             placement="left-start"
              title="Eugenize It"
              >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => openDocument(document)}
                sx={{ p: 2,
                  mt: 2,
                  mb: 2,
                  height: 40,
                  width: 40,
                
                }}
              >
                <AssessmentIcon />
              </Button>
              </Tooltip>
              </Stack>
            );
          })}
        </List>
      </nav>
    </Box>
  );
}
