import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

const QRCodeSkeleton = () => {
  return (
    <>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Skeleton height={40} width={250} />
        <Skeleton height={40} width={200} />
        <Skeleton height={250} width={200} />
        <Stack alignItems={"center"} alignContent={"center"}>
          <Skeleton height={45} width={150} />
          <Skeleton height={25} width={350} />
        </Stack>
        <Stack>
          <Skeleton height={55} width={450} />
        </Stack>
      </Stack>
    </>
  );
};

export default QRCodeSkeleton;
