import React from "react";
import HospitalReports from "../../../components/admin/HospitalReports";
import SupportHeader from "../../../components/support/dashboard/SupportHeader";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import EditSpecDialog from "../../../components/admin/EditSpecDialog";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import Button from "../../../components/common/Button";

class hospitalreportsContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      editDialogVisible: false,
      editDialogData: {},
      addInputVisible: false,
      report_name: "@",
    };
  }

  componentDidMount() {
    this.props.requestDocumentsUrl();
  }
  handleSave = async (data) => {
 
    await this.props.updateReport({
      title: data.name,
      id: this.state.editDialogData.id,
    });
    await this.props.requestDocumentsUrl();
  };
  toggleAddInput = () => {
    this.setState({
      addInputVisible: !this.state.addInputVisible,
      report_name: "@",
    });
  };
  handleAddOrganization = async () => {
    const { report_name } = this.state;
    if (report_name === "@") {
      this.setState({
        report_name: "",
      });
    } else if (report_name !== "" && report_name !== "@") {
      await this.props.addReport({
        title: report_name,
      });
      await this.props.requestDocumentsUrl();
      this.setState({
        report_name: "@",
      });
    }
  };

  render() {
    const { request_documents_loading, request_documents_data } = this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SupportHeader title="Documents Type" />
            {this.state.addInputVisible ? (
              <Stack
                direction="row"
                spacing={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CloseIcon
                  onClick={this.toggleAddInput}
                  sx={{ cursor: "pointer" }}
                />
                <TextField
                  required={true}
                  disableUnderline={true}
                  label="Report Name"
                  sx={{
                    borderRadius: "10px",
                   
                    height: "40px",
                    width: "250px",
                  }}
                  placeholder="Enter Report Name"
                  size="small"
                  onChange={(e) => {
                    this.setState({
                      report_name: e.target.value,
                    });
                  }}
                  error={this.state.report_name === ""}
                  value={
                    this.state.report_name === "@" ? "" : this.state.report_name
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.trimStart();
                  }}
                />

                <Button
                  loading={this.props.add_report_loading}
                  onClick={() => {
                   
                    this.handleAddOrganization();
                  }}
                  sx={{
                    borderRadius: "50px",
                    backgroundColor: "#004AAD",
                    marginRight: "1rem",
                    color: "white",
                    width: "150px",
                    
                  }}
                  disabled={this.state.report_name === ""}
                >
                  + Add
                </Button>
              </Stack>
            ) : (
              <Button
                onClick={() => {
                  this.toggleAddInput();
                }}
                sx={{
                  borderRadius: "50px",
                  backgroundColor: "#004AAD",
                  marginRight: "1rem",
                  color: "white",
                  width: "200px",
                  
                }}
              >
                + Add
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <Paper elevation={0}>
                <Stack sx={{ p: 5 }} spacing={1}>
                  <HospitalReports
                    tableDetails={request_documents_data}
                    loading={request_documents_loading}
                    handleEditDialog={(data) => {
                      this.setState({
                        editDialogVisible: true,
                        editDialogData: data,
                      });
                    }}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Grid>
        </Grid>
        <EditSpecDialog
          title="Report"
          open={this.state.editDialogVisible}
          handleClose={() => {
            this.setState({ editDialogVisible: false });
          }}
          data={this.state.editDialogData}
          handleSave={this.handleSave}
          isLoading={this.props.update_report_loading}
        />
      </>
    );
  }
}

export default hospitalreportsContainer;
