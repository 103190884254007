import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "../../common/Button";

const UnlinkedPatientCards = ({
  name,
  mobile,
  BirthDate,
  City,
  linked_with_org,
  linkPatient,
  PatientId,
  loading,
}) => {
  const handleClick = () => {
    linkPatient(PatientId);
  };
  return (
    <Paper variant="outlined" sx={{ p: 1 }}>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{name}</Typography>
          <Typography>{BirthDate}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{mobile}</Typography>
          <Typography>{City}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            loading={loading}
            variant={linked_with_org ? "outlined" : "contained"}
            children={linked_with_org ? " Already Linked " : "Link Patient"}
            // disabled={linked_with_org ? true : false}
            onClick={() => {
              handleClick();
            }}
            color="success"
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default UnlinkedPatientCards;
