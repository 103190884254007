import { add } from "date-fns";

export const generateHoursMinutes = () => {
  const hours = [...Array(24).keys()];
  const minutes = [...Array(60).keys()];

  let convertedTime = [];
  for (const h of hours) {
    for (const m of minutes) {
      convertedTime.push(h + ":" + m);
    }
  }
  return convertedTime;
};

export const generateHours = (date) => {
  const initialTime = new Date(date.setHours(0, 0, 0, 0));

  let hrs = [];
  for (let i = 0; i < 24; i++) {
    hrs.push(add(initialTime, { hours: i }));
  }

  return hrs;
};

export const appointmentSlots = () => {
  return ["00", "10", "20", "30", "40", "50"];
};

export const findBookedSlots = (data, hour, slot) => {
  let res = [];
  data.map(function (dd) {
    if (
      dd.AppointmentParsedStatus.appointment_hour === hour &&
      dd.AppointmentParsedStatus.appointment_start_minute === slot
    ) {
      res.push(dd);
    }
    return false;
  });
  return res;
};

export const generateAppointmentTop = ({
  appointment_hour,
  appointment_start_minute,
}) => {
  let minutes =
    parseInt(appointment_hour) * 120 + parseInt(appointment_start_minute) * 2;
  return minutes;
};

export const generateAppointmentHeight = ({
  appointment_end_minute,
  appointment_start_minute,
}) => {
  let minutes =
    (parseInt(appointment_end_minute) - parseInt(appointment_start_minute)) * 2;
  return minutes;
};

export const generateDoctorQRCode = (ProviderId, organization_id,organization_unique_code) => {
  return JSON.stringify({
    DoctorId: ProviderId,
    organization_id: organization_id,
    QR_VERIFICATION: "g0N1w",
    QR_TYPE: "APPOINTMENT",
    organization_unique_code: organization_unique_code,
    QR_USER: "DOCTOR",
  });
};

// export const downloadHTMLasPDF = async (elementId) => {
//   const input = document.getElementById(elementId);
//   const canvas = await html2canvas(input);

//   var doc = new jsPDF("p", "mm");
//   doc.addImage(canvas, "PNG", 10, 10);
//   doc.save("sample-file.pdf");
//   // try {
//   // const input = document.getElementById(elementId);
//   // html2canvas(input).then((canvas) => {
//   // const canvas = await html2canvas(input);
//   // const imgData = input
//   //   .toDataURL("image/png")
//   //   .replace("image/png", "image/octet-stream");

//   // downloadImage(imgData, "qr.png");

//   // const pdf = new jsPDF("p", "pt", "a4", true);

//   // const imgProps = pdf.getImageProperties(imgData);
//   // const pdfWidth = pdf.internal.pageSize.getWidth();
//   // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//   // pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight, elementId, "FAST");

//   // // pdf.output('dataurlnewwindow');
//   // pdf.save("doctor_qr_code.pdf");
//   // });
//   // } catch (error) {
//   //   throw new Error(error.message);
//   // }

//   // const svg = document.getElementById(elementId);
//   // const svgData = new XMLSerializer().serializeToString(svg);
//   // const canvas = document.createElement("canvas");
//   // const ctx = canvas.getContext("2d");
//   // const img = new Image();
//   // img.onload = function () {
//   //   canvas.width = img.width;
//   //   canvas.height = img.height;
//   //   ctx.drawImage(img, 0, 0);
//   //   const pngFile = canvas
//   //     .toDataURL("image/png")
//   //     .replace("image/png", "image/octet-stream");

//   //   downloadImage(pngFile, "qr.png");

//   //   const downloadLink = document.createElement("a");
//   //   downloadLink.download = "qrcode";
//   //   downloadLink.href = `${pngFile}`;
//   //   downloadLink.click();
//   // };

//   // downloadImage("data:image/svg+xml;base64," + btoa(svgData), "qr.png");
// };

// export const downloadHTMLasMultiplePDF = async (rawElements) => {
//   try {
//     const chunkedElement = chunk(rawElements, 1);

//     // let currentFile = 1;
//     for (const elementIds of chunkedElement) {
//       // const lastElement = elementIds.at(-1);
//       // const pdf = new jsPDF("p", "pt", "a4", true);

//       for (const elementId of elementIds) {
//         const input = document.getElementById(elementId);
//         const canvas = await html2canvas(input);

//         const imgData = canvas
//           .toDataURL("image/png")
//           .replace("image/png", "image/octet-stream");

//         downloadImage(imgData, "multiple_qr.png");
//         // const imgProps = pdf.getImageProperties(imgData);

//         // const pdfWidth = pdf.internal.pageSize.getWidth();
//         // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//         // pdf.addImage(
//         //   imgData,
//         //   "JPEG",
//         //   0,
//         //   0,
//         //   pdfWidth,
//         //   pdfHeight,
//         //   elementId,
//         //   "FAST"
//         // );

//         // if (lastElement !== elementId) {
//         //   pdf.addPage();
//         // }
//       }

//       // pdf.output('dataurlnewwindow');
//       // pdf.save(`multiple_doctor_qr_codes_${currentFile}.pdf`);
//       // currentFile += 1;
//     }
//   } catch (error) {
//     throw new Error(error.message);
//   }
// };

// const downloadImage = (data, filename = "untitled.png") => {
//   const a = document.createElement("a");
//   a.href = data;
//   a.download = filename;
//   document.body.appendChild(a);
//   a.click();
// };
