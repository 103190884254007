import parseISO from "date-fns/parseISO";
import differenceInYears from "date-fns/differenceInYears";
import { compareAsc } from "date-fns/esm";

export const calculateMinutesFromSeconds = (full_seconds) => {
  const minute = Math.floor(full_seconds / 60);
  const seconds = full_seconds % 60;
  return `${minute}:${seconds}`;
};

export const calculateBirthDate = (BirthDate) => {
  const date1 = new Date();
  const date2 = parseISO(BirthDate);
  const years = differenceInYears(date1, date2);
  return { years };
};

export const checkIfDatesAreEqual = (leftDate, rightDate) => {
  leftDate = parseISO(leftDate);
  rightDate = parseISO(rightDate);
  const newLeftDate = new Date(
    leftDate.getFullYear(),
    leftDate.getMonth(),
    leftDate.getDate()
  );
  const newRightDate = new Date(
    rightDate.getFullYear(),
    rightDate.getMonth(),
    rightDate.getDate()
  );
  return compareAsc(newLeftDate, newRightDate);
};
