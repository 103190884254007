import React from "react";
import MyTeamComponent from "../../../components/doctor/chat/MyTeam";
import MyPatientComponent from "../../../components/doctor/chat/MyPatient";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import PatientModal from "../../../components/doctor/chat/PatientModal";
import DoctorDirectoryView from "../../../pages/doctor/DoctorDirectory";
import ProfileDataModal from "../../../components/common/ProfileDataModal";

class MyChatContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "1",
      doctor_profile_modal: false,
      patient_profile_modal: false,
    };
    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.handleTabSwitchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.DoctorId !== this.props.DoctorId) {
      this.handleTabSwitchData();
    }
    if (prevProps.tab_value_state !== this.props.tab_value_state) {
      this.handleTabSwitchData();
    }
  }

  openDoctorProfileModal() {
    this.setState({
      doctor_profile_modal: true,
    });
  }

  closeDoctorProfileModal() {
    this.setState({
      doctor_profile_modal: false,
    });
  }

  handleTabSwitchData() {
    const { tab_value_state } = this.props;
    if (tab_value_state == 1) {
      this.props.queryDoctorMyTeams();
    } else if (tab_value_state == 2) {
      this.getPatientList();
    }
  }

  getPatientList() {
    this.props.queryDoctorMyPatients({
      ProviderId: this.props.DoctorId,
    });
  }

  handleChange(newValue) {
    // this.setState({ value: newValue }, () => this.handleTabSwitchData());
    this.props.setTabValue({ state: newValue });
  }

  handleOpenChat(type, id) {
    try {
      if (type === "uid") {
        this.props.set_chat_window_ids({ uid: id });
      } else {
        this.props.set_chat_window_ids({ guid: id });
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  showProfileDetail(id) {
    this.props.getDoctorDataUrl({ uid: id });
    this.openDoctorProfileModal();
  }

  showPatientDetails(id) {
    this.props.getPatientDataUrl({ PatientId: id });
    this.openPatientProfileModal();
  }
  openPatientProfileModal() {
    this.setState({
      patient_profile_modal: true,
    });
  }

  closePatientProfileModal() {
    this.setState({
      patient_profile_modal: false,
    });
  }

  render() {
    const {
      doctor_my_teams_data,
      doctor_my_teams_loading,
      doctor_my_patients_data,
      doctor_my_patients_loading,
      doctors_data_is_loading,
      doctors_data,
      patients_list_is_loading,
      patients_data,
    } = this.props;
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper
              sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
              elevation={0}
            >
              <TabContext value={this.props.tab_value_state}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={this.props.tab_value_state}
                    onChange={(e, newValue) => this.handleChange(newValue)}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="My Team" value="1" index={0} />
                    <Tab label="Patients" value="2" index={1} />
                    <Tab label="My Physicians" value="3" index={1} />
                  </Tabs>
                </Box>
                <TabPanel value={"1"} index={0} sx={{ px: 0 }}>
                  <MyTeamComponent
                    teamDetails={doctor_my_teams_data}
                    loading={doctor_my_teams_loading}
                    handleOpenChat={(id) => this.handleOpenChat("uid", id)}
                    showDetails={(d) => this.showProfileDetail(d)}
                  />
                </TabPanel>
                <TabPanel value={"2"} index={1} sx={{ px: 0 }}>
                  <MyPatientComponent
                    patientsDetails={doctor_my_patients_data}
                    loading={doctor_my_patients_loading}
                    handleOpenChat={(id) => this.handleOpenChat("guid", id)}
                    showDetails={(d) => this.showPatientDetails(d)}
                  />
                </TabPanel>
                <TabPanel value={"3"} index={1} sx={{ px: 0 }}>
                  <DoctorDirectoryView />
                </TabPanel>
              </TabContext>
            </Paper>
            <ProfileDataModal
              loading={doctors_data_is_loading}
              data={doctors_data}
              open={this.state.doctor_profile_modal}
              handleClose={() => this.closeDoctorProfileModal()}
            />
            <PatientModal
              loading={patients_list_is_loading}
              data={patients_data}
              open={this.state.patient_profile_modal}
              handleClose={() => this.closePatientProfileModal()}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default MyChatContainer;
