import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";

import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Button from "../../common/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";

import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

import DrawerHeader from "../../common/DrawerHeader";
import UnlinkedPatientCards from "./UnlinkedPatientCards";
import UnlinkedPatientSkeleton from "../../common/UnlinkedPatientSkeleton";

const validateSearchByNumber = yup.object().shape({
  CellPhone: yup
    .number()
    .min(9999999, "Please enter minimum 8 digits")
    .max(999999999999, "Too Long!")
    .required("Required"),
});

export default function LinkPatientDrawer({
  unlinked_patient_is_loading,
  open,
  close,
  searchPatient,
  linkPatient,
  unlinked_patient_data,
  link_patient_with_org_is_loading
}) {
  const onSubmit = async (values) => {
    searchPatient(values);
  };

  const formik = useFormik({
    initialValues: {
      CellPhone: "",
    },
    validationSchema: validateSearchByNumber,
    onSubmit: (values) => {
      onSubmit(values);
      formik.resetForm();
    },
  });
  return (
    <Drawer
      anchor={"right"}
      open={open}
      PaperProps={{
        sx: { width: "35%" },
      }}
      onClose={() => close()}
    >
      <Stack
        direction={"column"}
        alignItems="center"
        justifyContent={"center"}
        spacing={1}
        sx={{ width: "100%", px: 5, py: 3 }}
      >
        <Stack
          sx={{ width: "100%" }}
          justifyContent="Left"
          alignItems="Left"
          spacing={2}
        >
          <form onSubmit={formik.handleSubmit}>
            <DrawerHeader title="Link Patients" onClick={() => close()} />
            <Stack spacing={1}>
              <TextField
                size="small"
                fullWidth
                label="Mobile Number"
                variant="outlined"
                type="text"
                onChange={formik.handleChange("CellPhone")}
                onBlur={formik.handleBlur("CellPhone")}
                value={formik.values.CellPhone}
                error={
                  formik.touched.CellPhone && Boolean(formik.errors.CellPhone)
                }
                helperText={
                  formik.touched.CellPhone ? formik.errors.CellPhone : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ color: "#B2B9CC" }}>
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  ),
                }}
                onInput={(e) => {
                 e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
              <Button
                type="submit"
                disabled={!formik.isValid}
                loading={unlinked_patient_is_loading}
                fullWidth
              >
                Search Patients
              </Button>

              <Divider />
              
              {unlinked_patient_is_loading ? (
                <UnlinkedPatientSkeleton />
              ) : (
                <>
                  {unlinked_patient_data.map((patient, index) => {
                    return (
                      <UnlinkedPatientCards
                        key={"patient_data_index_" + index}
                        name={patient.name}
                        mobile={patient.mobile}
                        BirthDate={patient.BirthDate ? patient.BirthDate : "N/A"}
                        City={patient.City}
                        avatar={patient.avatar}
                        linked_with_org={patient.linked_with_org}
                        PatientId={patient.PatientId}
                        linkPatient={(PatientId)=>{linkPatient(PatientId)}}
                        loading={ link_patient_with_org_is_loading}
                      />
                    );
                  })}
                </>
              )}
            </Stack>
          </form>
        </Stack>

        {/* <Stack
          sx={{
            position: "absolute",
            bottom: 0,
            padding: "inherit",
            width: "100%",
          }}
        >
          <Button type="button" fullWidth>
            ADD PATIENT
          </Button>
        </Stack> */}
      </Stack>
    </Drawer>
  );
}
