import { Routes, Route, Navigate } from "react-router-dom";
import ChatView from "../pages/doctor/Chat";
import ProfileView from "../pages/doctor/Profile";
import FeedbackView from "../pages/doctor/Feedback";
import HistoryView from "../pages/doctor/History";
import DashboardView from "../pages/doctor/Dashboard";
import ScheduleView from "../pages/doctor/Schedule";
import QrCodeView from "../pages/doctor/QrCodeView";
import DoctorDirectoryAddView from "../pages/doctor/DoctorDirectoryAdd";
import DoctorProfileUpdate from "../pages/doctor/DoctorProfileUpdate";
import AddDoctorsByCodeView from "../pages/doctor/AddDoctorsByCode";
import PageNotFoundView from "../pages/doctor/PageNotFound";
import LogoutView from "../pages/doctor/Logout";
import Success from "../pages/doctor/Success.jsx";
const DoctorRoutes = () => {
  return (
    <Routes>
      <Route exact path="/logout" element={<LogoutView />} />
      <Route path="/page-not-found" element={<PageNotFoundView />} />
      {/* <Route exact path="/" element={<DashboardView />} /> */}
      <Route exact path="/dashboard" element={<DashboardView />} />
      <Route exact path="/schedule" element={<ScheduleView />} />
      <Route exact path="/chat" element={<ChatView />} />
      {/* <Route exact path="/doctor-directory" element={<DoctorDirectoryView />} /> */}
      <Route exact path="/qr-code" element={<QrCodeView />} />
      <Route exact path="/profile" element={<ProfileView />} />
      <Route exact path="/feedback" element={<FeedbackView />} />
      <Route
        exact
        path="/profile-update/:ProviderId"
        element={<DoctorProfileUpdate />}
      />
      <Route exact path="/history" element={<HistoryView />} />
      <Route path="*" element={<Navigate replace to="/schedule" />} />
      <Route
        exact
        path="/add-update-doctor-directory"
        element={<DoctorDirectoryAddView />}
      />
      <Route exact path="/add-unique-code" element={<AddDoctorsByCodeView />} />
      <Route
        exact
        path="/add-update-doctor-directory/:ProviderId"
        element={<DoctorDirectoryAddView />}
      />

      <Route exact path="/logout" element={<LogoutView />} />
      <Route exact path="/success" element={<Success />} />
    </Routes>
  );
};

export default DoctorRoutes;
