import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateProfilePic } from "../../../store/user/userSlice";

const mapStateToProps = (state) => {
    return {
        UserAvatar: state.common.UserAvatar,
        DoctorId: state.common.DoctorId,
        update_profile_pic_loading: state.user.update_profile_pic_loading,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateProfilePic
        },
        dispatch
    );

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;
