import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const DoctorHeading = ({ is_update }) => {
  return (
    <>
      <Stack
        sx={{
          flexGrow: 1,
          textAlign: "center",
          marginTop: "20px",
          borderRadius: "50%",
        }}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <img
          src="/logo192.png"
          alt="doctor"
          width={50}
          height={50}
          style={{
            borderRadius: "50% !important",
            marginTop: "10px",
            
          }}
        />
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Register For Hipaasafe 
        </Typography>
      </Stack>
    </>
  );
};

export default DoctorHeading;
