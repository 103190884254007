import React from "react";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
const DocumentSkeleton = () => {
  return (
    <>
      <Stack marginLeft={2}>
        <Skeleton animation="wave" height={20} width="30%" />
      </Stack>
      <CardHeader
        avatar={
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        }
        title={<Skeleton animation="wave" height={15} width="40%" />}
        subheader={<Skeleton animation="wave" height={15} width="40%" />}
      />
    </>
  );
};

const MultiplyDocumentSkeleton = () => {
    let multipleComponent = [];
    for (let i = 0; i < 2; i++) {
      multipleComponent.push(
        <DocumentSkeleton key={"document_skeleton_key_" + i} />
      );
    }
    return multipleComponent;
  };
  
  const ListSkeleton = () => {
    return <MultiplyDocumentSkeleton />;
  };

export default ListSkeleton;
