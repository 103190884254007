import React from "react";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";

class SuccessContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      payment_session_id: "",
      step: 1,
    };
  }

  componentDidMount() {
    this.getParameterByName("session_id");
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
   
    if (name === "session_id") {
     
      this.setState({
        payment_session_id: decodeURIComponent(results[2].replace(/\+/g, " ")),
      });
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }



  componentDidUpdate(prevProps, prevState) {
    
    if (this.state.payment_session_id !== prevState.payment_session_id) {
      this.props.STRIPE_DOCTOR_VERIFY({
        id: this.state.payment_session_id,
      });
      this.setState({ step: 4 });
    }
  }

  render() {
 
    return (
      <>
        {this.state.step === 4 ? (
          <Grid container alignItems="center" justifyContent="center"
          sx={{
            marginTop: 20,
          }}
          >
            <CheckCircleIcon
              sx={{
                color: "green",
                fontSize: 100,
              }}
            />

            <Typography
              variant="h5"
              sx={{
                color: "green",
              }}
            >
              Payment Done Successfully !
            </Typography>
          </Grid>
        ) : (
          <>
          </>
        )}
      </>
    );
  }
}

export default SuccessContainer;
