import React from "react";
import Table from "../../../components/common/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MuiButton from "../../../components/common/Button";
import Stack from "@mui/material/Stack";

const NurseList = (props) => {
  let { tableDetails, loading, handleMappingDialogOpen, redirectToEdit } =
    props;
  const columns = [
    {
      title: "Nurse Id",
    },
    {
      title: "Nurse Name",
    },
    {
      title: "Email",
    },
    {
      title: "Phone",
    },
    {
      title: "City",
    },

    {
      title: "Action",
    },
  ];

  const renderTableData = tableDetails.length ? (
    tableDetails.map((row, index) => {
      return (
        <TableRow key={index} sx={{ "td, th": { border: 0, padding: "10px" } }}>
          <TableCell align="center" component="th" scope="row">
            {row.ProviderId}
          </TableCell>
          <TableCell align="center">
            {row.FirstName + " " + row.LastName}
          </TableCell>
          <TableCell align="center">{row.Email}</TableCell>
          <TableCell align="center">{row.Phone}</TableCell>
          <TableCell align="center">{row.City}</TableCell>

          <TableCell align="center">
            <Stack direction="row" spacing={1} alignItems="center">
              <MuiButton
                onClick={() => redirectToEdit(row.ProviderId)}
                variant="contained"
                sx={{ borderRadius: "50px" }}
              >
                Edit
              </MuiButton>
              <MuiButton
                onClick={() => handleMappingDialogOpen(row.ProviderId)}
                variant="contained"
                sx={{ borderRadius: "50px" }}
              >
                Map
              </MuiButton>
            </Stack>
          </TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
      <TableCell align="center">Data not found</TableCell>
    </TableRow>
  );

  return (
    <Table columns={columns} tableData={renderTableData} loading={loading} />
  );
};

export default NurseList;
