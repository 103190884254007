import React from "react";
import Table  from "../../common/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MuiButton from "../../common/Button";
import ProfileImage from "../../common/ProfileImage";
import { isAuth } from "../../../helpers/cookies";

const MyTeam = (props) => {
  const { teamDetails, loading, handleOpenChat } = props;

  const columns = [
    {
      title: "Sr.No.",
    },
    {
      title: "Name",
    },
    {
      title: "Role",
    },
    {
      title: "Email Id",
    },
    {
      title: "Phone Number",
    },
    {
      title: "Speciality",
    },
    {
      title: "Chat",
    },
  ];

  const renderSpeciality = (row) => {
    if (row.role_name !== "NURSE") {
      return <TableCell align="center">{row.speciality?.title}</TableCell>;
    } else {
      return <TableCell align="center">-</TableCell>;
    }
  };

  const renderTableData = teamDetails.length ? (
    teamDetails.map((row, index) => {
      const { ProviderId } = row;
      return (
        <TableRow key={ProviderId} sx={{ "td, th": { border: 0, padding: "10px" } }}>
          <TableCell align="center" component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell
            align="center"
            onClick={props.showDetails.bind(null, ProviderId)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ProfileImage
                alt={row.FirstName}
                src={row.avatar}
                style={{ marginRight: 10 }}
              />
              {row.FirstName + " " + row.LastName}
            </div>
          </TableCell>
          <TableCell align="center">{row.role_name}</TableCell>
          <TableCell align="center">{row.Email}</TableCell>
          <TableCell align="center">{row.Phone}</TableCell>
          {renderSpeciality(row)}
          <TableCell sx={{ width: "120px" }} align="center">
            <MuiButton
              variant="contained"
              color="warning"
              sx={{ borderRadius: "50px" }}
              onClick={() => handleOpenChat(ProviderId + isAuth().org_details.unique_id)}
            >
              Chat
            </MuiButton>
          </TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
    <TableCell align="center" colSpan={columns.length}>
      Data not found
    </TableCell>
  </TableRow>
  );

  return (
    <Table columns={columns} tableData={renderTableData} loading={loading} />
  );
};

export default MyTeam;
