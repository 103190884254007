import { axios } from "../axios";
import { oneSignalAppId } from "../constants";
import { isAuth, updateLocalStorage } from "../cookies";
import OneSignal from "react-onesignal";

export const initializeOneSignal = async () => {
  try {
    await OneSignal.init({
      appId: oneSignalAppId,
    });
    console.log("== ONE SIGNAL INITIALIZED ==");
  } catch (error) {
    console.log(error.message);
    // throw new Error(error.message);
  }
};

export const generateOneSignalToken = async () => {
  try {
    const isEnabled = await OneSignal.isPushNotificationsEnabled();
    if (isEnabled) {
      const token = await OneSignal.getUserId();
      console.log("== One Signal token generated ==");
      return token;
    } else {
      throw new Error("Please Allow Notifications.");
    }
  } catch (error) {
    console.log(error.message);
    return false;
    // throw new Error(error.message);
  }
};

export const registerOneSignalTokenWithServer = async (player_id) => {
  try {
    const payload = { player_id, device_platform: "WEB" };
    const { player_id: old_player_id } = isAuth();
    if (old_player_id !== player_id) {
      await axios.post("/user/patient/login/update-player_id", payload);
      updateLocalStorage({ player_id });
      console.log("== One Signal Token synced with Hipaa Safe ==");
    }
    return true;
  } catch (error) {
    console.log(error.message);
    return false;
    // throw new Error(error.message);
  }
};
