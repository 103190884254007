import React from "react";
import SupportBaseLayout from "../../../components/support/layout/SupportBaseLayout";
import AdminRoutes from "../../../routes/AdminRoutes";
import HomeIcon from "@mui/icons-material/Home";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import MedicationIcon from '@mui/icons-material/Medication';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import HelpIcon from '@mui/icons-material/Help';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';



const doctorModules = [
  {
    id: "dashboard",
    title: "Dashboard",
    logo: <HomeIcon />,
    link: "/dashboard",
  },
  {
    id: "add_doctors",
    title: "Add Doctors/Nurses",
    logo: <LocalHospitalOutlinedIcon />,
    link: "/add-update-doctors",
  },
  {
    id: "organizations",
    title: "Organizations",
    logo: <MedicationIcon />,
    link: "/organizations/list",
  },{
    id: "add_doctors",
    title: "Support Accounts",
    logo: <AdminPanelSettingsIcon />,
    link: "/support/user",
  },{
    id: "speciality",
    title: "Speciality",
    logo: <VaccinesIcon />,
    link: "/speciality",
  },{
    id: "help",
    title: "Help Page",
    logo: <HelpIcon />,
    link: "/help",
  },
  {
    id: "gender",
    title: "Gender Dropdown",
    logo: <ArrowDropDownCircleIcon />,
    link: "/gender",
  },
  
  
  {
    id: "hospital-report",
    title: "Documents Type",
    logo: <AssessmentIcon />,
    link: "/hospital/report",
  },
  {
    id: "feedback",
    title: "Feedback Receipts",
    logo: <FeedbackIcon />,
    link: "/feedback",
  },
  
];

const SupportContainer = () => {
  return (
    <>
      <SupportBaseLayout
        modules={doctorModules}
        ChildComponent={AdminRoutes}
      />
    </>
  );
};

export default SupportContainer;
