import React from "react";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";

const RequestSkeleton = () => {
  return (
    <>
      <Stack sx={{ pb: 1 }}>
        <Box xs={12} sx={{ paddingBottom: 0.5 }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={300} />
        </Box>
      </Stack>
    </>
  );
};

const MultiplyRequestSkeleton = () => {
  let multipleComponent = [];
  for (let i = 0; i < 6; i++) {
    multipleComponent.push(
      <RequestSkeleton key={"request_skeleton_key_" + i} />
    );
  }
  return multipleComponent;
};

const ListSkeleton = () => {
  return <MultiplyRequestSkeleton />;
};

export default ListSkeleton;
