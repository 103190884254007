import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MailIcon from "@mui/icons-material/Mail";
import AccountCircle from "@mui/icons-material/AccountCircle";
import QRCodeComponent from "../../common/QRCode";
import { generateDoctorQRCode } from "../../../helpers/utils";
import IconButtonIcons from "../../common/IconButtonIcons";
import Edit from "@mui/icons-material/Edit";
import { S3ProfileImage } from "../../../helpers/constants";
import { Badge, IconButton, Skeleton, Tooltip } from "@mui/material";
import { useRef } from "react";
import { isAuth } from "../../../helpers/cookies";
import { CameraAlt } from "@mui/icons-material";
import DetailsTextField from "../../common/DetailsTextField";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MedicationIcon from "@mui/icons-material/Medication";
import PersonIcon from "@mui/icons-material/Person";

const ProfileComponent = (props) => {
  const {
    FirstName,
    LastName,
    Email,
    role_name,
    City,
    mobile,
    ProviderId,
    organization_id,
    speciality,
    tags,
    org_details,
    npi_no,
  } = props.data;
 
  const inputFile = useRef(null);

  const onProfileClick = () => {
    inputFile.current.click();
  };

  const handleProfileSelect = (e) => {
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("profile_pic", e.target.files[0]);
      props.updateProfilePic(formData);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <Grid item xs={6}>
        <Stack spacing={1}>
          {/* <Typography variant="h6">My Profile</Typography> */}
          <Paper elevation={0} sx={{ alignItems: "center" }}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              spacing={2}
              p={2}
              sx={{ position: "relative" }}
            >
              <div style={{ position: "absolute", top: 1, right: 1 }}>
                <IconButtonIcons
                  title="Edit Profile"
                  IconComponent={Edit}
                  onClick={() => {
                    props.navigate("/profile-update/" + ProviderId);
                  }}
                />
              </div>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={handleProfileSelect}
              />
              {props.update_profile_pic_loading ? (
                <Skeleton variant="circular" width={150} height={150} />
              ) : (
                <Tooltip title={"Click to choose new Profile Image"}>
                  <IconButton
                    onClick={() => onProfileClick()}
                    color="primary"
                    component="span"
                  >
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={<CameraAlt />}
                    >
                      {isAuth().avatar ? (
                        <Avatar
                          alt={FirstName}
                          src={S3ProfileImage + isAuth().avatar}
                          sx={{
                            bgcolor: "grey",
                            width: "150px",
                            height: "150px",
                          }}
                        />
                      ) : (
                        <AccountCircle
                          sx={{
                            color: "#bdbdbd",
                            width: "150px",
                            height: "150px",
                          }}
                        />
                      )}
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
              <Typography variant="h5">{FirstName + " " + LastName}</Typography>
              {role_name !== "NURSE" ? (
                <>
                  <Stack
                    direction="row"
                    sx={{
                      width: "70%",
                      display: "block",
                      textAlign: "center",
                    }}
                    spacing={1}
                  >
                    {tags &&
                      tags.map((ele, i) => {
                        return (
                          <Chip
                            key={"chip_" + i}
                            label={ele.title}
                            variant="outlined"
                            sx={{ m: 1 }}
                          />
                        );
                      })}
                  </Stack>
                  <DetailsTextField
                    label="Speciality"
                    name="speciality"
                    value={speciality?.title}
                    Icon={() => {
                      return <MedicationIcon />;
                    }}
                  />
                </>
              ) : null}

              <DetailsTextField
                label="Email Address"
                name="email"
                value={Email}
                Icon={() => {
                  return <MailIcon />;
                }}
              />
              <DetailsTextField
                label="Npi Number"
                name="npi"
                value={npi_no}
                Icon={() => {
                  return <PersonIcon />;
                }}
              />
              <DetailsTextField
                label="Mobile Number"
                name="mobile"
                value={mobile}
                Icon={() => {
                  return <PhoneIphoneIcon />;
                }}
              />
              <DetailsTextField
                label="City"
                name="city"
                value={City}
                Icon={() => {
                  return <LocationCityIcon />;
                }}
              />
            </Stack>
          </Paper>
        </Stack>
      </Grid>
      {role_name !== "NURSE" ? (
        <Grid item xs={6}>
          <Stack spacing={1}>
            {/* <Typography variant="h6">Organization and Other Details</Typography> */}
            {/* gello */}
            <Paper elevation={0}>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"center"}
                spacing={2}
                p={2}
              >
                <Typography variant="h6">
                  My Organization Code : {org_details?.unique_id}
                </Typography>
                <Stack>
                  {/* <img
                  src={S3QRCodeBaseUrl + qr_code}
                  width={100}
                  height={100}
                  alt="doctor_qr_code"
                /> */}
                  <QRCodeComponent
                    value={generateDoctorQRCode(ProviderId, organization_id,org_details?.unique_id)}
                  />
                </Stack>
                <Stack alignItems={"center"} alignContent={"center"}>
                  <Typography variant={"h5"}> Provider's Code : {ProviderId+org_details?.unique_id}</Typography>
                  <Typography variant={"caption"}>
                    Share your code with other Doctors and Patients to connect
                    and chat with them.
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ProfileComponent;
