import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
const AppntSkeleton = () => {
  return (
    <>
      <Grid item xs={12} sx={{ px: 2.5, py: 0.5 }}>
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} />
      </Grid>
      <Grid sx={{ px: 2.5, py: 0.5 }}>
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={300} />
        </Grid>
        <Grid item xs={12} sx={{ mt: 0.5 }}>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            marginLeft={-45}
          >
            <Skeleton variant="rounded" width={210} height={20} />
            <Skeleton variant="rounded" width={100} height={20} />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

const MultiplyAppntsSkeleton = () => {
    let multipleComponent = [];
    for (let i = 0; i < 3; i++) {
      multipleComponent.push(<AppntSkeleton key={"appnts_skeleton_key_" + i} />);
    }
    return multipleComponent;
  };
  
  const MyAppntsSkeleton = () => {
    return <MultiplyAppntsSkeleton />;
  };
  
  export default MyAppntsSkeleton;
  
