import React from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { S3ProfileImage } from "../../helpers/constants";
import QRCodeComponent from "./QRCode";
import { generateDoctorQRCode } from "../../helpers/utils";
import DetailsTextField from "./DetailsTextField";
import MailIcon from "@mui/icons-material/Mail";
import MedicationIcon from "@mui/icons-material/Medication";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

const ProfileDataModal = (props) => {
  const { data, loading, open, handleClose } = props;


  const {
    avatar,
    FirstName,
    LastName,
    Email,
    Phone,
    City,
    role_name,
    ProviderId,
    organization_id,
    organization_details_doctor,
    speciality,
    tags,
  } = data;

 
  return (
    <>
      <Dialog
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { overflow: "hidden", height: "85vh", width: "100%" },
        }}
      >
        <Stack
          justifyContent={"center"}
          alignItems="center"
          sx={{ m: 0, height: "100%", width: "100%" }}
        >
          {!loading ? (
            <Grid
              container
              spacing={1}
              alignItems="center"
              sx={{
                height: "100%",
                p: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  height: "100%",
                  overflow: "auto",
                  p: 0,
                }}
              >
                <Paper elevation={0} sx={{ p: 2, height: "100%" }}>
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    alignContent={"center"}
                    spacing={2}
                  >
                    <Avatar
                      alt={FirstName}
                      src={S3ProfileImage + avatar}
                      sx={{
                        bgcolor: "grey",
                        width: "150px",
                        height: "150px",
                      }}
                    />
                    <Typography variant="h5">
                      {FirstName + " " + LastName}
                    </Typography>
                    {role_name !== "NURSE" ? (
                      <>
                        <Stack
                          direction="row"
                          sx={{
                            width: "80%",
                            display: "block",
                            textAlign: "center",
                          }}
                          spacing={1}
                        >
                          {tags &&
                            tags.map((ele, i) => {
                              return (
                                <Chip
                                  key={"chip_" + i}
                                  label={ele.title}
                                  variant="outlined"
                                  sx={{ m: 1 }}
                                />
                              );
                            })}
                        </Stack>
                      </>
                    ) : null}
                    {role_name !== "NURSE" ? (
                      <>
                        <DetailsTextField
                          label="Speciality"
                          name="speciality"
                          value={speciality?.title}
                          Icon={() => {
                            return <MedicationIcon />;
                          }}
                        />
                      </>
                    ) : null}
                    <DetailsTextField
                      label="Email Address"
                      name="email"
                      value={Email}
                      Icon={() => {
                        return <MailIcon />;
                      }}
                    />
                    <DetailsTextField
                      label="Mobile Number"
                      name="mobile"
                      value={Phone}
                      Icon={() => {
                        return <PhoneIphoneIcon />;
                      }}
                    />
                    <DetailsTextField
                      label="Address"
                      value={City}
                      name="Address"
                      Icon={() => {
                        return <ApartmentIcon />;
                      }}
                    />
                  </Stack>
                </Paper>
              </Grid>
              {role_name !== "NURSE" ? (
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    {/* <Typography variant="h6">Organization and Other Details</Typography> */}
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      alignContent={"center"}
                      spacing={2}
                      p={2}
                    >
                      <Typography variant="h6">
                        My Organization ID: <b>{organization_details_doctor?.unique_id}</b>
                      </Typography>
                      <Stack>
                        <QRCodeComponent
                          value={generateDoctorQRCode(
                            ProviderId,
                            organization_id,
                            organization_details_doctor?.unique_id
                          )}
                        />
                      </Stack>
                      <Stack alignItems={"center"} alignContent={"center"}>
                        <Typography variant={"h4"}>{ProviderId+organization_details_doctor?.unique_id}</Typography>
                        <Typography variant={"caption"}>
                          Share your code with other Doctors and Patients to
                          connect and chat with them.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
              ) : null}
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </Stack>
      </Dialog>
    </>
  );
};

export default ProfileDataModal;
