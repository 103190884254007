import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material";
import Button from "./Button";
import { signout } from "../../helpers/cookies";

const SplashScreen = ({ isError, errorMessage }) => {
  const { mode } = useTheme().palette;
  return (
    <Paper>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        elevation={0}
        sx={{ height: "100vh" }}
      >
        <img
          src={
            mode === "dark"
              ? "/hipaa_safe_logo_dark.svg"
              : "/hipaa_safe_logo_light.svg"
          }
          width={200}
          alt="hipaasafe_logo"
        />
        {isError ? (
          <>
            <Typography color="error" variant="h5" component="h5">
              Initialization Failed
            </Typography>
            <Typography color="error" variant="body" component="p">
              {errorMessage}
            </Typography>
            <Button
              fullWidth={false}
              children="Log Out"
              onClick={() => signout(() => window.location.reload())}
            />
          </>
        ) : (
          <CircularProgress sx={mode === "dark" ? { color: "white" } : {}} />
        )}
      </Stack>
    </Paper>
  );
};

export default SplashScreen;
