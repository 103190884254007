import React from "react";
import DoctorList from "../../../components/support/dashboard/DoctorList";
import NurseList from "../../../components/support/dashboard/NurseList";
import SupportHeader from "../../../components/support/dashboard/SupportHeader";
import KpiCards from "../../../components/common/KpiCards";

import MappingDialog from "../../../components/support/dashboard/MappingDialog";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import BackdropComponent from "../../../components/common/Backdrop";
import Typography from "@mui/material/Typography";
import { isAuth } from "../../../helpers/cookies";

class SupportDashboardContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "1",
      MappingDialog: false,
      nurse_id: "",
      organization_id: isAuth().organization_id,
      orgdetails: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getOrgList();
    this.props.querySupportDashboardKpi();
    this.handleTabSwitchData();
    this.props.getOrg({ id: this.state.organization_id });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.nurse_id !== this.state.nurse_id ||
      prevState.organization_id !== this.state.organization_id
    ) {
      let payload = {
        nurse_id: this.state.nurse_id,
        organization_id: this.state.organization_id,
      };

      this.props.queryNurseDoctorMappingList(payload);
    }
  }

  handleMappingDialogOpen = (nurse_id) => {
    this.setState({ nurse_id }, () => this.setState({ MappingDialog: true }));
  };

  handleDoctorToNurseMapping = (payload) => {
    payload.organization_id = this.state.organization_id;
    this.props.updateNurseDoctorMapping(payload);
  };

  handleTabSwitchData() {
    const { value } = this.state;
    const payload = {
      ignore_nurse: true,
      organization_id: this.state.organization_id,
    };
    value === "1"
      ? this.props.queryDoctorMyTeams(payload)
      : this.props.queryDoctorNurseList({
          organization_id: this.state.organization_id,
        });
    this.props.querySupportDashboardKpi({
      organization_id: this.state.organization_id,
    });
  }

  handleChange(e, newValue) {
    this.setState({ value: newValue }, () => this.handleTabSwitchData());
  }

  handleChangeOrganization = (event) => {
    this.setState({ organization_id: event }, () => this.handleTabSwitchData());
  };

  




  render() {
    const {
      support_dashboard_kpi_data,
      support_dashboard_kpi_loading,
      doctor_my_teams_data,
      doctor_my_teams_loading,
      doctor_nurse_list_loading,
      doctor_nurse_list_data,
      nurse_doctor_map_list_data,
      nurse_doctor_map_update_is_loading,
      nurse_doctor_map_list_is_loading,
      get_single_org_data,
    } = this.props;


    return (
      <>
        <BackdropComponent open={nurse_doctor_map_update_is_loading} />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SupportHeader title="Dashboard" />

         
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              {support_dashboard_kpi_data.map((element, index) => {
                return (
                  <KpiCards
                    key={"kpi_cards_index_" + index}
                    data={element}
                    loading={support_dashboard_kpi_loading}
                  />
                );
              })}

              <Paper
                elevation={0}
                sx={{ alignItems: "center", p: 3, width: "100%" }}
              >
                <Stack>
                  <Typography
                    variant="h5"
                    sx={{ color: "#1D9BFF", fontWeight: "bold" }}
                  >
                    {get_single_org_data?.unique_id}
                  </Typography>

                  <Typography variant="subtitle1" sx={{ color: "#808591" }}>
                    {get_single_org_data?.name}
                  </Typography>
                </Stack>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Paper elevation={0}>
                <Stack sx={{ p: 5 }} spacing={1}>
                  <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Doctors List" value="1" index={0} />
                        <Tab label="Nurses List" value="2" index={1} />
                      </Tabs>
                    </Box>
                    <TabPanel value={"1"} index={0} sx={{ px: 0 }}>
                      <DoctorList
                        tableDetails={doctor_my_teams_data}
                        loading={doctor_my_teams_loading}
                        redirectToEdit={(ProviderId) => {
                          this.props.navigate(
                            "/add-update-doctors/" + ProviderId
                          );
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={"2"} index={1} sx={{ px: 0 }}>
                      <NurseList
                        tableDetails={doctor_nurse_list_data}
                        loading={doctor_nurse_list_loading}
                        redirectToEdit={(ProviderId) => {
                          this.props.navigate(
                            "/add-update-doctors/" + ProviderId
                          );
                        }}
                        handleMappingDialogOpen={(nurse_id) => {
                          this.handleMappingDialogOpen(nurse_id);
                        }}
                      />
                    </TabPanel>
                  </TabContext>
                </Stack>
              </Paper>
            </Stack>
          </Grid>
        </Grid>
        <MappingDialog
          mappingData={nurse_doctor_map_list_data}
          DoctorToNurseMapping={(payload) => {
            this.handleDoctorToNurseMapping(payload);
          }}
          nurseId={this.state.nurse_id}
          nurse_doctor_map_update_is_loading={
            nurse_doctor_map_update_is_loading
          }
          nurse_doctor_map_list_is_loading={nurse_doctor_map_list_is_loading}
          data={this.state.MappingDialog}
          close={() => this.setState({ MappingDialog: null })}
        />
      </>
    );
  }
}

export default SupportDashboardContainer;
