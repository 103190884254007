import React from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import MyPhysicianDetail from "../profile/MyPhysicianDetail";

const MyPhysicianModal = (props) => {
  const { data, loading, open, handleClose } = props;

  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Stack justifyContent={"center"} alignItems="center" sx={{ m: 3 }}>
          {!loading ? <MyPhysicianDetail data={data} /> : <CircularProgress />}
        </Stack>
      </Dialog>
    </>
  );
};

export default MyPhysicianModal;
