import React from "react";
import SupportUser from "../../../components/admin/SupportUser";
import SupportHeader from "../../../components/support/dashboard/SupportHeader";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Button from "../../../components/common/Button";
import {  TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditSupportDialog from "../../../components/admin/EditSupportDialog";

class SupportUserContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      organization_id: undefined,
      addInputVisible: false,
      editDialog: false,
      name: "",
      email: "",
    };
  }

  componentDidMount() {
    this.props.SUPPORT_USER({ organization_id: undefined });
    this.props.getOrgList();
  }

  handleChangeOrganization = async (event) => {
    this.setState({ organization_id: event });
    await this.props.SUPPORT_USER({ organization_id: event });
  };

  toggleAddInput = () => {
    this.setState({
      addInputVisible: !this.state.addInputVisible,
      organization_name: "@",
      domain_name: "@",
    });
  };
  handleAddOrganization = async () => {
    const { name, email } = this.state;
    if (!this.state.organization_id) {
      alert("Please select organization");
      return;
    }

    const data = {
      name,
      email,
      organization_id: this.state.organization_id,
      type: "SUPPORT",
    };
    await this.props.REGISTER_SUPPORT_USER(data);

    await this.props.SUPPORT_USER({
      organization_id: this.state.organization_id,
    });
    this.setState({
      addInputVisible: false,
      name: "",
      email: "",
    });
  };

  handleClose = (val) => {
   
    this.setState({
      editDialog: !this.state.editDialog,
      id: val.uid,
      name: val.name,
      email: val.email,
    });
  };
  handleSave = async (data) => {
   
    await this.props.adminUserUpdate({ ...data, uid: this.state.id });
    await this.props.SUPPORT_USER({
      organization_id: this.state.organization_id,
    });
    this.setState({
      editDialog: false,
      name: "",
      email: "",
    });

  };

  render() {
    const { support_user_is_loading, support_user_data, get_org_data } =
      this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SupportHeader title="Support Accounts" />

            {this.state.addInputVisible ? (
              <Stack
                direction="row"
                spacing={1}
                sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
              >
                <CloseIcon
                  onClick={this.toggleAddInput}
                  sx={{ cursor: "pointer" }}
                />
                <TextField
                  required={true}
                  disableUnderline={true}
                  label="Name"
                  sx={{
                    borderRadius: "10px",
                   
                    height: "40px",
                    width: "250px",
                  }}
                  placeholder="Enter Name"
                  size="small"
                  onChange={(e) => {
                    this.setState({
                      name: e.target.value,
                    });
                  }}
                  error={this.state.name === ""}
                  value={this.state.name}
                  onInput={(e) => {
                    e.target.value = e.target.value.trimStart();
                  }}
                />
                <TextField
                  required={true}
                  disableUnderline={true}
                  label="Email"
                  sx={{
                    borderRadius: "10px",
                   
                    height: "40px",
                  }}
                  placeholder="Email"
                  size="small"
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                  error={this.state.email === ""}
                  value={this.state.email}
                  onInput={(e) => {
                    e.target.value = e.target.value.trimStart();
                  }}
                />
                <Button
                  onClick={() => {
                    
                    this.handleAddOrganization();
                  }}
                  sx={{
                    borderRadius: "50px",
                    backgroundColor: "#004AAD",
                    marginRight: "1rem",
                    color: "white",
                    width: "150px",
                   
                  }}
                  disabled={this.state.name === "" || this.state.email === ""}
                >
                  + Add
                </Button>
              </Stack>
            ) : (
              <Button
                onClick={() => {
                  this.toggleAddInput();
                }}
                sx={{
                  borderRadius: "50px",
                  backgroundColor: "#004AAD",
                  marginRight: "1rem",
                  color: "white",
                  width: "200px",
                  // set to right
                  marginTop: "1rem",
                }}
              >
                + Add 
              </Button>
            )}
          </Grid>

          <FormControl
          sx={{ width: 300, marginTop: 1, marginLeft: 2 }}
         
        >
            <InputLabel id="doctor-id-select-label">
              Select Organization
            </InputLabel>
            <Select
              fullWidth
              labelId="doctor-id-select-label"
              id="doctor-id-select"
              size="small"
              label="Select Organization"
              sx={{ width: 300, marginTop: 1 }}
              onChange={(event) =>
                this.handleChangeOrganization(event.target.value)
              }
            >
              {get_org_data.map(function (dd) {
                return (
                  <MenuItem value={dd.value} key={dd.key}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <ListItemIcon
                        sx={{
                          color: "#1D9BE6",
                        }}
                      >
                        Organization Id - {dd.value}
                      </ListItemIcon>

                      <Typography> {dd.key}</Typography>

                      <Typography>Unique Id ({dd.unique_id})</Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Stack spacing={1}>
              <Paper elevation={0}>
                <Stack sx={{ p: 5 }} spacing={1}>
                  <SupportUser
                    tableDetails={support_user_data}
                    loading={support_user_is_loading}
                    toggleDialog={this.handleClose}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Grid>
        </Grid>

        <EditSupportDialog
          open={this.state.editDialog}
          Data={{
            id: this.state.id,
            name: this.state.name,
            email: this.state.email,
          }}
          handleClose={() => {
            this.setState({
              editDialog: !this.state.editDialog,
            });
          }}
          handleSave={this.handleSave}
        />
      </>
    );
  }
}

export default SupportUserContainer;
