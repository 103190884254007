import { CometChat } from "@cometchat-pro/chat";
import cookie from "js-cookie";
import { deleteFCMToken } from "./config/initializeFCM";

// Set in Cookie
export const setCookie = (key, value) => {
  if (window !== "undefined") {
    cookie.set(key, value, {
      // 1 Day
      expires: 1,
    });
  }
};
// remove from cookie
export const removeCookie = (key) => {
  if (window !== "undefined") {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

// Get from cookie such as stored token
// Will be useful when we need to make request to server with token
export const getCookie = (key) => {
  return cookie.get(key);
};

// Set in localstorage
export const setLocalStorage = (key, value) => {
  if (window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// Remove from localstorage
export const removeLocalStorage = (key) => {
  if (window !== "undefined") {
    localStorage.removeItem(key);
  }
};

// Auth enticate user by passing data to cookie and localstorage during signin
export const authenticate = (response, next) => {
  console.log(response);
  setCookie("token", response.access_token);
  setLocalStorage("user", { ...response,
     access_token: response.access_token,
      refresh_token: response.refresh_token,
    });

  next();
};

export const updateLocalStorage = (data, next) => {
  const user_data = isAuth();
  setLocalStorage("user", { ...user_data, ...data });
  next();
};

// Access user info from localstorage
export const isAuth = () => {
  if (window !== "undefined") {
    const cookieChecked = getCookie("token");
    if (cookieChecked) {
      if (localStorage.getItem("user")) {
        return JSON.parse(localStorage.getItem("user"));
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};

export const authDoctorId = () => {
  if (window !== "undefined") {
    const cookieChecked = getCookie("token");
    if (cookieChecked) {
      if (localStorage.getItem("user")) {
        let userData = JSON.parse(localStorage.getItem("user"));

        switch (userData.role_name) {
          case "DOCTOR":
            return userData.uid;
          case "NURSE":
            return userData.doctors_mapped.length > 0
              ? userData.doctors_mapped[0].uid
              : false;
          default:
            break;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};


export const signout = async (next) => {
  try {
    removeCookie("token");
    removeCookie("fcm_token");
    removeCookie("refresh");
    removeLocalStorage("user");
    await deleteFCMToken();
    
    await CometChat.logout();
    console.log("Logout completed successfully");
  } catch (error) {
    console.log("Logout failed with exception:", error);
  }
  
  // Refresh the page or perform any necessary action
  next();
};


export const updateUser = (response, next) => {
  if (typeof window !== "undefined") {
    let auth = JSON.parse(localStorage.getItem("user"));
    auth = response.data;
    localStorage.setItem("user", JSON.stringify(auth));
  }
};

export const updateUserTerms = (response, next) => {
  if (typeof window !== "undefined") {
    let auth = JSON.parse(localStorage.getItem("user"));
    auth.terms_and_conditions = true;
    localStorage.setItem("user", JSON.stringify(auth));
   
  }
};


export const updatePaymentStatus = (response, next) => {
  if (typeof window !== "undefined") {
    let auth = JSON.parse(localStorage.getItem("user"));
    auth.payment_status = true;
    localStorage.setItem("user", JSON.stringify(auth));
  
  }
};

