import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";

function TermsAndConditionsDialog({ open, onClose, onAgree }) {
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
 
    setLoading(false);


  };

 
  return (
    <Dialog
      open={open}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        Privacy Policy and Terms and Conditions of Use
      </DialogTitle>
      <DialogContent>
        {loading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          
            <iframe
              title="terms"
              id="myiframe" 
              src={"https://www.hipaasafe.app/privacy.html"}
              style={{ width: "100%", height: "60vh", border: "none" }}
              onLoad={ () => handleIframeLoad()}
            />
       
          By clicking Agree, you agree to our Terms, Privacy Policy and Cookies Policy.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Button sx={{ color: "red" }} onClick={onClose}>
          Disagree
        </Button>

        <Button onClick={onAgree} color="primary" disabled={loading}>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}




export default TermsAndConditionsDialog;
