import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { calculateBirthDate } from "../../../helpers/datetime";
import DatePickerComponent from "../../common/DatePicker";
import TimePickerComponent from "../../common/TimePicker";
import Button from "../../common/Button";
import { useDispatch } from "react-redux";
import { set_appointment_drawer_state } from "../../../store/common/commonSlice";
import { parseISO } from "date-fns";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function AddAppointmentDrawer({
  date,
  handleDateChange,
  patientsData,
  patientsLoading,
  getPatientsByNameOrPhone,
  drawerOpen,
  addDoctorAppointment,
  loading,
  addPatient,
  addPatientLink,
}) {
  const dispatch = useDispatch();
  const [StartTime, setStartTime] = React.useState();
  const [EndTime, setEndTime] = React.useState();
  const [patientSearch, setPatientSearch] = React.useState("");
  const [PatientId, setPatientId] = React.useState(null);
  const [Duration, setDuration] = React.useState(20);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (patientSearch.length > 2) {
      getPatientsByNameOrPhone(patientSearch);
    }
  }, [patientSearch]);

  React.useEffect(() => {
    setStartTime(parseISO(date));
  }, [date]);

  React.useEffect(() => {
    const time = new Date(StartTime);
    const endTime = new Date(time.getTime() + 20 * 60000);

    setEndTime(endTime);
  }, [date]);

  React.useEffect(() => {
    if (StartTime) {
      const time = new Date(StartTime);
      const endTime = new Date(time);
      endTime.setMinutes(endTime.getMinutes() + Duration);
 
      setEndTime(endTime);
    }
  }, [StartTime, Duration]);


  const closeDrawer = () => {
    setPatientId(null);
    dispatch(set_appointment_drawer_state({ show: false }));
  };

  const handleDoctorSubmit = (req) => {
    setPatientId(null);
    addDoctorAppointment(req);
  };

  return (
    <Drawer
      anchor={"right"}
      open={drawerOpen}
      PaperProps={{
        sx: { width: "35%" },
      }}
      onClose={() => closeDrawer()}
    >
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        alignItems="center"
        spacing={1}
        sx={{ height: "100%", px: 5, py: 3 }}
      >
        <Stack
          sx={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Stack sx={{ pb: 2 }}>
            <Typography>Add Appointment</Typography>
          </Stack>

          <Autocomplete
            open={open}
            loading={patientsLoading}
            onOpen={() => {
              if (patientSearch) {
                setOpen(true);
              }
            }}
            onClose={() => setOpen(false)}
            inputValue={patientSearch}
            onInputChange={(e, value, reason) => {
              setPatientSearch(value);

              if (!value.length) {
                setOpen(false);
              }
            }}
            fullWidth
            options={patientsData}
            renderOption={(props, option, state) => (
              <>
                <div {...props}>
                  {option.FirstName} {option.LastName}
                </div>
                {option.BirthDate ? (
                  <div>
                    &nbsp; &nbsp; Age:{" "}
                    {calculateBirthDate(option.BirthDate).years}
                  </div>
                ) : null}
              </>
            )}
            getOptionLabel={(option) =>{

              if(option.BirthDate){
                return (
                  option.FirstName +
                  " " +
                  option.LastName +
                  " Age: " +
                  calculateBirthDate(option.BirthDate).years
                )
              }else{
                return (
                  option.FirstName 
                )
              }
           }
            }
            onChange={(event, newValue) => {
              setPatientId(newValue?.PatientId);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Search Patient" size="small" />
            )}
          />

          <DatePickerComponent
            date={StartTime}
            label="Appointment Date"
            minDate={new Date()}
            setDate={(newDate) => {
              setStartTime(newDate);
            }}
            onError={(err) => {
              setError(err);
            }}
          />

          <TimePickerComponent
            date={StartTime}
            label="Appointment Time"
            setDate={(newDate) => {
              setStartTime(newDate);
            }}
          />

          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="doctor-id-select-label">
              Appointment Duration
            </InputLabel>
            <Select
              fullWidth
              labelId="doctor-id-select-label"
              id="doctor-id-select"
              size="small"
              label="Appointment Duration"
              defaultValue={20}
              onChange={(e) => {
                setDuration(e.target.value);
              }}
            >
              <MenuItem value={10}>10 Minutes</MenuItem>
              <MenuItem value={20}>20 Minutes</MenuItem>
              <MenuItem value={30}>30 Minutes</MenuItem>
              <MenuItem value={40}>40 Minutes</MenuItem>
              <MenuItem value={50}>50 Minutes</MenuItem>
              <MenuItem value={60}>60 Minutes</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          sx={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            loading={loading}
            disabled={PatientId && error == null ? false : true}
            onClick={() =>
              handleDoctorSubmit({ PatientId, StartTime, EndTime })
            }
            children="Add Appointment"
          />
          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            onClick={() => addPatientLink()}
            children="Link Patient"
          />
          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            onClick={() => addPatient()}
            children="Add Patient"
          />
        </Stack>
      </Stack>
    </Drawer>
  );
}
