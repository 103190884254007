import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ProfileImage from "../../common/ProfileImage";
import DetailsTextField from "../../common/DetailsTextField";

import MailIcon from "@mui/icons-material/Mail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EventNoteIcon from "@mui/icons-material/EventNote";
import WcIcon from "@mui/icons-material/Wc";
import NotesIcon from "@mui/icons-material/Notes";
import ApartmentIcon from "@mui/icons-material/Apartment";

const PatientProfileDetails = ({ data }) => {
  const {
    avatar,
    FirstName,
    LastName,
    EmailAddress,
    mobile,
    BirthDate,
    Sex,
    address,
    my_pharmacy,
  } = data;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Paper elevation={0} sx={{ alignItems: "center" }}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              spacing={2}
              p={2}
            >
              <ProfileImage
                alt={FirstName}
                src={avatar}
                width={150}
                height={150}
              />
              <Typography variant="h5">{FirstName + " " + LastName}</Typography>
              <DetailsTextField
                label="Email Address"
                name="email"
                value={EmailAddress}
                Icon={() => {
                  return <MailIcon />;
                }}
              />
              <DetailsTextField
                label="Mobile Number"
                name="mobile"
                value={mobile}
                Icon={() => {
                  return <PhoneIphoneIcon />;
                }}
              />
              <DetailsTextField
                label="Birth Date"
                value={BirthDate}
                name="BirthDate"
                Icon={() => {
                  return <EventNoteIcon />;
                }}
              />

              <DetailsTextField
                label="Gender"
                value={Sex}
                name="Gender"
                Icon={() => {
                  return <WcIcon />;
                }}
              />
              <DetailsTextField
                label="My Pharmacy"
                value={my_pharmacy}
                name="My Pharmacy"
                multiline={true}
                Icon={() => {
                  return <NotesIcon />;
                }}
              />
              <DetailsTextField
                label="Address"
                value={address}
                name="Address"
                Icon={() => {
                  return <ApartmentIcon />;
                }}
              />
            </Stack>
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PatientProfileDetails;
