import React from "react";
import Speciality from "../../../components/admin/Speciality";
import SupportHeader from "../../../components/support/dashboard/SupportHeader";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import EditSpecDialog from "../../../components/admin/EditSpecDialog";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import Button from "../../../components/common/Button";

class SupportUserContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      editDialogVisible: false,
      editDialogData: {},
      addInputVisible: false,
      speciality_name: "@",
    };
  }

  componentDidMount() {
    this.props.getSpecialityList();
  }
  toggleAddInput = () => {
    this.setState({
      addInputVisible: !this.state.addInputVisible,
      speciality_name: "@",
    });
  };

  handleAddOrganization = async () => {
    const { speciality_name } = this.state;
    if (speciality_name === "@") {
      this.setState({
        speciality_name: "",
      });
    } else if (speciality_name !== "" && speciality_name !== "@") {
      await this.props.addSpeciality({
        title: speciality_name,
      });
      await this.props.getSpecialityList();
      this.setState({
        speciality_name: "@",
      });
    }
  };

  handleSave = async (data) => {
    await this.props.updateSpeciality({
      title: data.name,
      speciality_id: this.state.editDialogData.speciality_id,
    });

    await this.props.getSpecialityList();
  };

  render() {
    const { speciality_is_loading, speciality_list_data } = this.props;

    return (
      <>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SupportHeader title="Speciality" />
            {this.state.addInputVisible ? (
              <Stack
                direction="row"
                spacing={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <CloseIcon
                  onClick={this.toggleAddInput}
                  sx={{ cursor: "pointer" }}
                />
                <TextField
                  required={true}
                  disableUnderline={true}
                  label="Speciality Name"
                  sx={{
                    borderRadius: "10px",
                    
                    height: "40px",
                    width: "250px",
                  }}
                  placeholder="Enter Speciality Name"
                  size="small"
                  onChange={(e) => {
                    this.setState({
                      speciality_name: e.target.value,
                    });
                  }}
                  error={this.state.speciality_name === ""}
                  value={
                    this.state.speciality_name === "@"
                      ? ""
                      : this.state.speciality_name
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.trimStart();
                  }}
                />

                <Button
                loading={this.props.add_speciality_is_loading}
                  onClick={() => {
                   
                    this.handleAddOrganization();
                  }}
                  sx={{
                    borderRadius: "50px",
                    backgroundColor: "#004AAD",
                    marginRight: "1rem",
                    color: "white",
                    width: "150px",
                    
                  }}
                  disabled={
                    this.state.speciality_name === "" 
                  }
                >
                  + Add
                </Button>
              </Stack>
            ) : (
              <Button
                onClick={() => {
                  this.toggleAddInput();
                }}
                sx={{
                  borderRadius: "50px",
                  backgroundColor: "#004AAD",
                  marginRight: "1rem",
                  color: "white",
                  width: "200px",
                  
                }}
              >
                + Speciality
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1}>
              <Paper elevation={0}>
                <Stack sx={{ p: 5 }} spacing={1}>
                  <Speciality
                    tableDetails={speciality_list_data}
                    loading={speciality_is_loading}
                    handleEditDialog={(val) => {
                      this.setState({
                        editDialogVisible: true,
                        editDialogData: val,
                      });
                    }}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Grid>
        </Grid>
        <EditSpecDialog
          title="Speciality"
          open={this.state.editDialogVisible}
          handleClose={() => {
            this.setState({ editDialogVisible: false });
          }}
          data={this.state.editDialogData}
          handleSave={this.handleSave}
          isLoading={this.props.update_speciality_is_loading}
        />
      </>
    );
  }
}

export default SupportUserContainer;
